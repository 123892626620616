// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/currentUser";
import { Spin } from "antd";
import ProfileForm from "./Form";

class Profile extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCurrentUser());
  };

  onSave = () => {
    this.props.dispatch(Actions.onUpdate());
  };

  onClose = () => {
    this.props.history.push(`/`);
  };

  onChangeCurrentUserName = e => {
    this.props.dispatch(Actions.onChangeCurrentUserName(e.target.value));
  };

  onChangeCurrentUserMiddleName = e => {
    this.props.dispatch(Actions.onChangeCurrentUserMiddleName(e.target.value));
  };

  onChangeCurrentUserLastName = e => {
    this.props.dispatch(Actions.onChangeCurrentUserLastName(e.target.value));
  };

  onChangeCurrentUserEmail = e => {
    this.props.dispatch(Actions.onChangeCurrentUserEmail(e.target.value));
  };

  // change password
  onUpdateCurrentUsePassword = () => {
    this.props.dispatch(Actions.onUpdateCurrentUsePassword());
  };

  onChangeCurrentUsePassword = e => {
    this.props.dispatch(Actions.onChangeCurrentUsePassword(e.target.value));
  };

  onChangeCurrentUsePasswordConfirmation = e => {
    this.props.dispatch(Actions.onChangeCurrentUsePasswordConfirmation(e.target.value));
  };



  render() {
    const { isLoading, currentUser } = this.props;
    return (
      <ProfileForm
        isLoading={isLoading}
        user={currentUser}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeCurrentUserName={this.onChangeCurrentUserName}
        onChangeCurrentUserMiddleName={this.onChangeCurrentUserMiddleName}
        onChangeCurrentUserLastName={this.onChangeCurrentUserLastName}
        onChangeCurrentUserEmail={this.onChangeCurrentUserEmail}
        onUpdateCurrentUsePassword={this.onUpdateCurrentUsePassword}
        onChangeCurrentUsePassword={this.onChangeCurrentUsePassword}
        onChangeCurrentUsePasswordConfirmation={this.onChangeCurrentUsePasswordConfirmation}
      />
    );
  }
}

Profile.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  currentUser: PropTypes.object,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.currentUser.isLoading,
  currentUser: state.currentUser.currentUser
});

export default connect(mapStateToProps)(Profile);
