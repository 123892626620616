import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Input, AutoComplete } from "antd";
import api from "../../api";

const { Option, OptGroup } = AutoComplete;

function renderTitle(title) {
  return (
    <span>
      {title}
      <a
        style={{ float: "right" }}
        href="https://www.google.com/search?q=antd"
        target="_blank"
        rel="noopener noreferrer"
      ></a>
    </span>
  );
}

class NavSearch extends Component {
  static defaultProps = {
    url: "/search/document"
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetching: false
    };
    this.onSearch = this.onSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onSearch(value) {
    this.setState({ data: [], fetching: true });
    api
      .fetch(`${this.props.url}?&term=${encodeURIComponent(value)}`)
      .then(response => {
        let data = [];
        const documents = response.data.documents;
        const companies = response.data.companies;
        const contacts = response.data.contacts;
        if (documents.length > 0) {
          data.push({ title: "Документы", children: documents });
        }
        if (companies.length > 0) {
          data.push({ title: "Компании", children: companies });
        }
        if (contacts.length > 0) {
          data.push({ title: "Контакты", children: contacts });
        }
        this.setState({ data: data, fetching: false });
      });
  }

  onSelect = (value, option) => {
    this.props.history.push(option.key);
  }

  render() {
    const { data, fetching } = this.state;

    const options = data.map(group => (
      <OptGroup key={group.title} label={renderTitle(group.title)}>
        {group.children.map((opt) => (
          <Option key={opt.url} value={opt.title}>
            <div className="certain-search-item">
              <div className="certain-search-item-title">{opt.title}</div>
              <div className="certain-search-item-subtitle">
                {opt.subtitle}
              </div>
            </div>
          </Option>
        ))}
      </OptGroup>
    ));

    return (
      <div className="certain-category-search-wrapper" style={{ width: 350 }}>
        <AutoComplete
          allowClear
          className="certain-category-search"
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: 350 }}
          style={{ width: "100%" }}
          dataSource={options}
          onSearch={this.onSearch}
          onSelect={this.onSelect}
          placeholder="глобальный поиск"
          optionLabelProp="value"
        >
          <Input
            suffix={<Icon type="search" className="certain-category-icon" />}
          />
        </AutoComplete>
      </div>
    );
  }
}

export default withRouter(NavSearch);
