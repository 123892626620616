// @flow
import React, { Component } from "react";
import { Table, DatePicker, Button } from "antd";
import { Row, Col, PageHeader, Tooltip, Modal } from "antd";
import _ from "lodash/array";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import CashFlowValue from "./CashFlowValue";
import CashFlowItem from "./CashFlowItem";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { MonthPicker } = DatePicker;
const dateFormat = "DD.MM.YYYY";
const ButtonGroup = Button.Group;
const { confirm } = Modal;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisibleCashFlowValue: false,
      cashFlowValue: {},
      isVisibleCashFlowItem: false,
      cashFlowItem: {},
    };
  }

  // cashFlowItem
  onOpenCashFlowItem = (item) => {
    this.setState({ cashFlowItem: item }, () => {
      this.setState({ isVisibleCashFlowItem: true });
    });
  };

  onCancelCashFlowItem = () => {
    this.setState({ isVisibleCashFlowItem: false });
  };

  onAddCashFlowItem = () => {
    const { cashFlowItems } = this.props;
    this.setState(
      {
        cashFlowItem: {
          id: null,
          name: null,
          position: cashFlowItems.length + 1,
          cashflow_type: 1,
        },
      },
      () => {
        this.setState({ isVisibleCashFlowItem: true });
      }
    );
  };

  onDeleteConfirm(cashFlowItemId) {
    const { onDeleteCashFlowItem } = this.props;
    confirm({
      title: "Удалить статью ДДС?",
      content: "Все значения введенные для этой статьи также будут удалены.",
      onOk() {
        onDeleteCashFlowItem(cashFlowItemId);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  onSaveCashFlowItem = () => {
    this.setState({ isVisibleCashFlowItem: false }, () => {
      this.props.onSaveCashFlowItem(this.state.cashFlowItem);
    });
  };

  onChangeCashFlowItemName = (e) => {
    this.setState({
      cashFlowItem: update(this.state.cashFlowItem, {
        name: { $set: e.target.value },
      }),
    });
  };

  onChangeCashflowType = (value) => {
    this.setState({
      cashFlowItem: update(this.state.cashFlowItem, {
        cashflow_type: { $set: value },
      }),
    });
  };

  onChangePosition = (value) => {
    this.setState({
      cashFlowItem: update(this.state.cashFlowItem, {
        position: { $set: value },
      }),
    });
  };

  // cashFlowValues
  onAddCashFlowValue = (cashFlowItemId) => {
    const { fetchParams } = this.props;
    this.setState(
      {
        cashFlowValue: {
          cash_flow_item_id: cashFlowItemId,
          date: fetchParams.endDate ? fetchParams.endDate : moment(),
          amount: 0,
        },
      },
      () => {
        this.setState({ isVisibleCashFlowValue: true });
      }
    );
  };

  onCancelCashFlowValue = () => {
    this.setState({ isVisibleCashFlowValue: false });
  };

  onSaveCashFlowValue = () => {
    this.setState({ isVisibleCashFlowValue: false }, () => {
      this.props.onSaveCashFlowValue(this.state.cashFlowValue);
    });
  };

  onDeleteCashFlowValues = (cashFlowItemId) => {
    this.setState({ isVisibleCashFlowValue: false }, () => {
      this.props.onDeleteCashFlowValues(cashFlowItemId);
    });
  };

  onChangeCashFlowValueDate = (value) => {
    this.setState({
      cashFlowValue: update(this.state.cashFlowValue, {
        date: { $set: moment(value).endOf('day') },
      }),
    });
  };

  onChangeCashFlowValueAmount = (value) => {
    this.setState({
      cashFlowValue: update(this.state.cashFlowValue, {
        amount: { $set: value },
      }),
    });
  };

  render() {
    const {
      isVisibleCashFlowValue,
      cashFlowValue,
      isVisibleCashFlowItem,
      cashFlowItem,
    } = this.state;
    const {
      loading,
      cashFlowItems,
      fetchParams,
      onClose,
      onChangePeriod,
    } = this.props;

    const positiveFlows = cashFlowItems.filter(function (item) {
      return item.cashflow_type == 1;
    });
    const negativeFlows = cashFlowItems.filter(function (item) {
      return item.cashflow_type == 2;
    });

    const totalPositiveFlows = positiveFlows.reduce(
      (sum, record) => sum + parseFloat(record.amount),
      0
    );

    const totalNegativeFlows = negativeFlows.reduce(
      (sum, record) => sum + parseFloat(record.amount),
      0
    );

    let data = [];

    data.push({
      id: uuidv4(),
      isGroup: true,
      name: "Поступления",
      amount: totalPositiveFlows,
    });

    positiveFlows.map((item) => {
      data.push({
        id: item.id,
        isGroup: false,
        name: item.name,
        amount: item.amount,
        cashflow_type: item.cashflow_type,
        position: item.position,
      });
    });

    data.push({
      id: uuidv4(),
      isGroup: true,
      name: "Расходы",
      amount: totalNegativeFlows,
    });

    negativeFlows.map((item) => {
      data.push({
        id: item.id,
        isGroup: false,
        name: item.name,
        amount: item.amount,
        cashflow_type: item.cashflow_type,
        position: item.position,
      });
    });

    const columns = [
      {
        title: "Статья ДС",
        dataIndex: "name",
        render: (name, record) =>
          record.isGroup ? (
            name
          ) : (
            <a onClick={this.onOpenCashFlowItem.bind(this, record)}>{name}</a>
          ),
        key: "name",
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          return accounting.formatNumber(amount, 0, " ");
        },
        key: "amount",
        align: "right",
      },
      {
        title: "#",
        dataIndex: "action",
        render: (action, record) => {
          return record.isGroup ? null : (
            <ButtonGroup>
              <Tooltip placement="bottom" title={"Добавить значение"}>
                <Button
                  type="dashed"
                  icon={"plus"}
                  onClick={this.onAddCashFlowValue.bind(this, record.id)}
                />
              </Tooltip>
              <Tooltip placement="bottom" title={"Удалить все значения"}>
                <Button
                  type="dashed"
                  icon={"reload"}
                  onClick={this.onDeleteCashFlowValues.bind(this, record.id)}
                />
              </Tooltip>
              <Tooltip placement="bottom" title={"Удалить статью ДДС"}>
                <Button
                  type="dashed"
                  icon={"close"}
                  onClick={this.onDeleteConfirm.bind(this, record.id)}
                />
              </Tooltip>
            </ButtonGroup>
          );
        },
        key: "action",
        align: "center",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Настройки платежного календаря`}
        extra={[
          <Button key="3" type="dashed" onClick={onClose}>
            Закрыть
          </Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 20 }}>
            <Col span={12}>
              <Table
                bordered
                // size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={data}
                rowKey="id"
                rowClassName={(record) =>
                  record.isGroup ? "ant-table-total-row" : null
                }
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <MonthPicker
              placeholder="Выберите месяц"
              value={moment(fetchParams.startDate, dateFormat)}
              onChange={onChangePeriod}
              format={"MMMM"}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{ textAlign: "right" }}
          >
            <Button key="4" type="dashed" onClick={this.onAddCashFlowItem}>
              Добавить статью ДДС
            </Button>
          </Col>
        </Row>
        <CashFlowItem
          visible={isVisibleCashFlowItem}
          cashFlowItem={cashFlowItem}
          onSave={this.onSaveCashFlowItem}
          onCancel={this.onCancelCashFlowItem}
          onChangeName={this.onChangeCashFlowItemName}
          onChangeCashflowType={this.onChangeCashflowType}
          onChangePosition={this.onChangeCashflowItenPosition}
        />
        <CashFlowValue
          visible={isVisibleCashFlowValue}
          cashFlowValue={cashFlowValue}
          onSave={this.onSaveCashFlowValue}
          onCancel={this.onCancelCashFlowValue}
          onChangeCashFlowValueDate={this.onChangeCashFlowValueDate}
          onChangeCashFlowValueAmount={this.onChangeCashFlowValueAmount}
        />
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
