// @flow
import React, { Component } from "react";
import "./login.css";
import { Form, Icon, Input, Button } from "antd";

const FormItem = Form.Item;



class LoginForm extends Component {
  

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { submitting } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Form
            className="login-form"
            onSubmit={this.onSubmit}
          >
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Пожалуйста, введите логин!" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="пользователь"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Пожалуйста, введите пароль!" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="пароль"
                />
              )}
            </FormItem>

            <FormItem>
              <Button
                type="submit"
                disabled={submitting}
                htmlType="submit"
                className="login-form-button"
              >
                {submitting ? "Вход..." : "Войти"}
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create()(LoginForm);
