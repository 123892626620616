// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, Tabs, Button } from "antd";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      isNew,
      firstName,
      middleName,
      lastName,
      email,
      password,
      password_confirmation,
      getFieldDecorator,
      onChangeName,
      onChangeMiddleName,
      onChangeLastName,
      onChangeEmail,
      onChangePassword,
      onChangePasswordConfirmation,
      onUpdatePassword
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Tabs defaultActiveKey={"1"}>
            <TabPane tab="Основные данные" key="1">
              <Col span={12}>
                <FormItem label={"Имя"}>
                  {getFieldDecorator("first_name", {
                    onChange: onChangeName,
                    initialValue: firstName,
                    rules: [
                      {
                        required: true,
                        message: "Введите имя!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label={"Отчество"}>
                  {getFieldDecorator("middle_name", {
                    onChange: onChangeMiddleName,
                    initialValue: middleName,
                    rules: [
                      {
                        required: true,
                        message: "Введите отчество!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Фамилия"}>
                  {getFieldDecorator("last_name", {
                    onChange: onChangeLastName,
                    initialValue: lastName,
                    rules: [
                      {
                        required: true,
                        message: "Введите фамилию!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Email"}>
                  {getFieldDecorator("email", {
                    onChange: onChangeEmail,
                    initialValue: email,
                    rules: [
                      {
                        required: true,
                        message: "Введите email!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
            </TabPane>

            <TabPane tab="Смена пароля" key="2">
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="Пароль">
                    {getFieldDecorator("password", {
                      onChange: onChangePassword,
                      initialValue: password
                    })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Подтверждение пароля">
                    {getFieldDecorator("password_confirmation", {
                      onChange: onChangePasswordConfirmation,
                      initialValue: password_confirmation
                    })(<Input />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Button onClick={onUpdatePassword} type="primary">
                    Сохранить пароль
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
