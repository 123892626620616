import React, { Component } from "react";
import PropTypes from "prop-types";
import accounting from "accounting";
import { Input } from "antd";
const { TextArea } = Input;

class EditableTextAreaCell extends React.Component {
  constructor(props) {
    super(props);
    this.onEscFunction = this.onEscFunction.bind(this);
  }

  static defaultProps = {
    defaultStyle: { paddingRight: 24 }
  };

  state = {
    value: this.props.value,
    editing: false
  };

  componentDidMount() {
    document.addEventListener("click", this.onClickOutside, true);
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutside, true);
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  onClickOutside = e => {
    const { editing } = this.state;
    if (editing && this.cell !== e.target && !this.cell.contains(e.target)) {
      this.onSave();
    }
  };

  onEscFunction(e) {
    const { editing } = this.state;
    if (editing && this.cell.contains(e.target) && e.keyCode === 27) {
      this.toggleEdit();
      this.setState({ value: this.props.value });
    }
  }

  onChange = e => {
    const value = e.target.value;
    this.setState({ value });
  };

  onSave = () => {
    const { recordId, onChange } = this.props;
    this.toggleEdit();
    onChange(recordId, this.state.value);
  };

  render() {
    const { value, editing } = this.state;
    const { defaultStyle, style } = this.props;
    return (
      <div className="editable-cell" ref={node => (this.cell = node)}>
        {editing ? (
          <TextArea
            ref={node => (this.input = node)}
            value={value}
            onChange={this.onChange}
            onPressEnter={this.onSave}
            autoSize={{ minRows: 1, maxRows: 2 }}
          />
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ ...defaultStyle, ...style }}
            onClick={this.toggleEdit}
          >
            {value}
          </div>
        )}
      </div>
    );
  }
}

export default EditableTextAreaCell;
