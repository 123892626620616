// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/countries";
import { Spin } from "antd";
import Form from "./Form";

class Country extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchCountry(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/references/countries/${this.props.country.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/references/countries`);
  };

  onChangeCode = e => {
    this.props.dispatch(Actions.onChangeCode(e.target.value));
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };



  render() {
    const { isLoading, country } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
        <Form
          isNew={this.state.isNew}
          country={country}
          onSave={this.onSave}
          onClose={this.onClose}
          onChangeCode={this.onChangeCode}
          onChangeName={this.onChangeName}
        />
      );
  }
}

Country.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  country: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.countries.isLoading,
  errors: state.countries.errors,
  country: state.countries.country
});

export default connect(mapStateToProps)(withRouter(Country));
