// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Badge, Select, Icon, Tooltip } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";
import SelectFetch from "../../../components/SelectFetch";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Option = Select.Option;

function shippingStatus(status) {
  switch (status) {
    case "approving":
      return "error";
    case "working":
      return "processing";
    case "for_shipping":
      return "warning";
    case "shipped":
      return "success";
    case "closed":
      return "success";

    default:
      return "default";
  }
}

const Search = Input.Search;

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangePage = (value) => {
    this.props.onChangePage(value);
  };

  renderExpandedRow = (record) => {
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={12}>
          <table style={{ width: "100%" }}>
            <tbody>
              {record.order_items.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <td style={{ width: "65%" }}>{item.description}</td>
                    <td style={{ width: "15%" }}>{`${item.quantity} шт.`}</td>
                    <td style={{ width: "20%" }}>{`${accounting.formatNumber(
                      item.amount,
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  onChange = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const {
      loading,
      orders,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterSourceType,
      onChangeFilterPeriod,
      onChangeFilterAssignee,
      onChangeFilterShippingPeriod,
      onChangeFilterDownloadRows,
      downloadReport
    } = this.props;
    const { selectedRowKeys, isFilterVisible } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Номер, статус",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>
              <Badge
                status={shippingStatus(record.status)}
                text={record.status_locale}
              />
            </span>
            <span>
              <Link to={`/orders/${record.id}/edit`}>{number}</Link>
            </span>
          </div>
        ),
        width: "10%",
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "number",
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: (date) => moment(date).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "date",
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.currentYear == "true" ? "#1890ff" : undefined,
            }}
          />
        ),
        width: "10%",
      },
      {
        title: "План отгрузок",
        dataIndex: "start_shipping",
        render: (start_shipping, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>{moment(start_shipping).format("L")}</span>
            <span>{moment(record.end_shipping).format("L")}</span>
          </div>
        ),
        width: "10%",
      },
      {
        title: "Компания, счет",
        dataIndex: "company_name",
        render: (company_name, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>
              {record.invoices.map((item) => (
                <Link key={item.id} to={`/invoices/${item.id}/edit`}>
                  {item.number}
                </Link>
              ))}
            </span>
            <span>{company_name}</span>
          </div>
        ),
        width: "17%",
      },
      {
        title: "Сумма, объем собственных",
        dataIndex: "total",
        render: (total, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(total, 0, " ")}</span>
            <span>
              {accounting.formatNumber(
                Number(record.total) -
                  Number(record.subcontracts) -
                  Number(record.materials) -
                  Number(record.transports),
                0,
                " "
              )}
            </span>
          </div>
        ),
        align: "right",
        width: "10%",
      },
      {
        title: "Оплачено, % оплаченных",
        dataIndex: "payment_status",
        render: (paid, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(record.paid, 0, " ")}</span>
            <span>
              {`${accounting.formatNumber(
                record.paid
                  ? (Number(record.paid) / Number(record.total)) * 100
                  : 0,
                0,
                " "
              )}%`}
            </span>
          </div>
        ),
        align: "right",
        width: "10%",
      },
      {
        title: "Отгружено, % отгруженных",
        dataIndex: "shipped",
        render: (shipped, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(shipped, 0, " ")}</span>
            <span>
              {accounting.formatNumber(
                record.shipped
                  ? (Number(record.shipped) / Number(record.total)) * 100
                  : 0,
                0,
                " "
              )}
            </span>
          </div>
        ),
        align: "right",
        width: "10%",
      },
      {
        title: "Субподряд, материалы, транспорт",
        dataIndex: "subcontracts",
        render: (subcontracts, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(subcontracts, 0, " ")}</span>
            <span>{accounting.formatNumber(record.materials, 0, " ")}</span>
            <span>{accounting.formatNumber(record.transports, 0, " ")}</span>
          </div>
        ),
        align: "right",
        width: "10%",
      },
      {
        title: "Ответственный",
        dataIndex: "user",
        width: "13%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/orders/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate || fetchParams.status || fetchParams.assignees
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Заказы`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={orders}
                onChange={this.onChange}
                expandedRowRender={(record) => this.renderExpandedRow(record)}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                // hideOnSinglePage
                // showSizeChanger
                // showQuickJumper
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          onClose={this.onFilterToggle}
          status={fetchParams.status}
          source_type={fetchParams.source_type}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          startShippingDate={fetchParams.startShippingDate}
          endShippingDate={fetchParams.endShippingDate}
          assignees={fetchParams.assignees}
          isDownloadRows={fetchParams.isDownloadRows}
          onChangeStatus={onChangeFilterStatus}
          onChangeSourceType={onChangeFilterSourceType}
          onChangePeriod={onChangeFilterPeriod}
          onChangeShippingPeriod={onChangeFilterShippingPeriod}
          onChangeUsers={onChangeFilterAssignee}
          onChangeDownloadRows={onChangeFilterDownloadRows}
        />
      </PageHeader>
    );
  }
}

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default OrdersList;
