// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/firms";
import { Spin } from "antd";
import Form from "./Form";

class Firm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }
  
  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchFirm(this.props.match.params.id))
        
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/firms/${this.props.firm.id}/edit`
          );
        }
      });
    }
  };

  // header
  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeFullName = e => {
    this.props.dispatch(Actions.onChangeFullName(e.target.value));
  };

  onChangePrefix = e => {
    this.props.dispatch(Actions.onChangePrefix(e.target.value));
  };

  onChangeInn = e => {
    this.props.dispatch(Actions.onChangeInn(e.target.value));
  };

  onChangeKpp = e => {
    this.props.dispatch(Actions.onChangeKpp(e.target.value));
  };

  onChangeOgrn = e => {
    this.props.dispatch(Actions.onChangeOgrn(e.target.value));
  };

  onChangeOkpo = e => {
    this.props.dispatch(Actions.onChangeOkpo(e.target.value));
  };

  onChangeCeo = e => {
    this.props.dispatch(Actions.onChangeCeo(e.target.value));
  };

  onChangeCeoTitle = e => {
    this.props.dispatch(Actions.onChangeCeoTitle(e.target.value));
  };

  onChangeCfo = e => {
    this.props.dispatch(Actions.onChangeCfo(e.target.value));
  };

  onChangeCfoTitle = e => {
    this.props.dispatch(Actions.onChangeCfoTitle(e.target.value));
  };

  onChangeBankAccountPrimary = item => {
    this.props.dispatch(Actions.onChangeBankAccountPrimary(item));
  };

  onChangeBankAccount = (item) => {
    this.props.dispatch(Actions.onChangeBankAccount(item));
  }

  onChangeEmail = (item) => {
    this.props.dispatch(Actions.onChangeEmail(item));
  }

  // address
  

  render() {
    const {
      isLoading,
      firm
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={this.state.isNew}
        isLoading={isLoading}
        firm={firm}
        onSave={this.onSave}
        // header
        onChangeName={this.onChangeName}
        onChangeFullName={this.onChangeFullName}
        onChangePrefix={this.onChangePrefix}
        onChangeInn={this.onChangeInn}
        onChangeKpp={this.onChangeKpp}
        onChangeOgrn={this.onChangeOgrn}
        onChangeOkpo={this.onChangeOkpo}
        onChangeCeo={this.onChangeCeo}
        onChangeCeoTitle={this.onChangeCeoTitle}
        onChangeCfo={this.onChangeCfo}
        onChangeCfoTitle={this.onChangeCfoTitle}
        onChangeBankAccountPrimary={this.onChangeBankAccountPrimary}
        onChangeBankAccount={this.onChangeBankAccount}
        onChangeEmail={this.onChangeEmail}
      />
    );
  }
}

Firm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  firm: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.firms.isLoading,
  errors: state.firms.errors,
  firm: state.firms.firm,
});

export default connect(mapStateToProps)(withRouter(Firm));
