// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Card,
  Pagination,
  Cascader,
} from "antd";
import PropTypes from "prop-types";
import accounting from "accounting";

const Search = Input.Search;

function categoryLeaf(item = {}) {
  return {
    value: item.id,
    label: item.name,
    children: item.children.map((child) => {
      return categoryLeaf(child);
    }),
  };
}

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onEditProduct = (id) => {
    this.context.router.history.push(`/products/${id}`);
  };

  onChangeCategory = (value, selectedOptions) => {
    // console.log(`last element category array: ${value[value.length - 1]}`)
    // console.log(`selected: ${this.state.selectedRowKeys}`)
    this.props.onChangeCategory({
      product_category_id: value[value.length - 1],
      product_ids: [this.state.selectedRowKeys],
    });
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const {
      isLoading,
      products,
      params,
      meta,
      fetchParams,
      categories,
      onSearch,
      onDelete,
      onChangePage,
    } = this.props;

    let categoriesOptions = categories.map((item, i) => categoryLeaf(item))

    const columns = [
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link
                key={record.id}
                to={`/categories/${record.product_category.id}/products/${record.id}/edit`}
              >
                {name}
              </Link>
            </span>
            <span>{record.description}</span>
          </div>
        ),
        width: "45%",
      },
      {
        title: "Артикул",
        dataIndex: "sku",
        key: "sku",
        width: "15%",
      },
      {
        title: "Цена",
        dataIndex: "price",
        render: (price, record) => accounting.formatNumber(price, 0, " "),
        width: "20%",
        align: "right",
      },
      {
        title: "Ед.изм.",
        dataIndex: "unit",
        render: (unit, record) => unit.name,
        width: "20%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/categories/${params.categoryID}/products/new` }}
      >
        Добавить
      </Link>
    );

    let categoryButton = hasSelected ? (
      <Cascader
        options={categoriesOptions}
        onChange={this.onChangeCategory}
      >
        <Button
          key="3"
          type="primary"
          icon="retweet"
          ghost
          style={{ marginLeft: "5px" }}
        >
          Переместить
        </Button>
      </Cascader>
    ) : null;

    return (
      <Col xs={24} sm={24} md={24} lg={17} xl={17}>
        <Card
          title={[mainButton, categoryButton]}
          extra={
            <Search
              placeholder="поиск..."
              value={fetchParams.search}
              onChange={onSearch}
              style={{ width: 250 }}
            />
          }
        >
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={isLoading}
                pagination={false}
                columns={columns}
                dataSource={products}
                rowKey="id"
              />
            </Col>
            <Col span={24} style={{ paddingTop: "15px" }}>
              <Pagination
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                total={meta.total_count}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }
}

ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ProductsList;
