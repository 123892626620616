import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  payments: [],
  payment: { payment_items: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    type: null,
    startDate: null,
    endDate: null,
    companies: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const paymentsFilters = localStorage.getItem("paymentsFilters");
  if (paymentsFilters) {
    localFetchParams = JSON.parse(paymentsFilters);
  } else {
    localStorage.setItem("paymentsFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let idx = -1;
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_PAYMENTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PAYMENTS":
      return {
        ...state,
        payments: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_PAYMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PAYMENT":
      return {
        ...state,
        payment: action.response.data,
        vatRates: action.response.vat_rates,
        units: action.response.units,
        isLoading: false,
      };
    case "REQUEST_PAYMENT_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_PAYMENT_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    // filters
    case "PAYMENTS_CLEAR_FILTERS":
      localStorage.removeItem("paymentsFilters");

      return update(state, {
        fetchParams: {
          type: { $set: null },
          startDate: { $set: null },
          endDate: { $set: null },
          companies: { $set: null },
        },
      });

    case "PAYMENTS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "PAYMENTS_FILTER_TYPE":
      return update(state, {
        fetchParams: {
          type: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "PAYMENTS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "PAYMENTS_FILTER_COMPANIES":
      return update(state, {
        fetchParams: {
          companies: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
