import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class LossReasons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment().startOf("month").format("DD.MM.YYYY"),
        endDate: moment().format("DD.MM.YYYY"),
        page: 1,
        limit: 100,
      },
      period: 0,
    };
  }

  componentWillMount = () => {
    this.props.dispatch(
      Actions.fetchLossReasons(this.state.fetchParams)
    );
  };

  onChangeDateRange = (dates) => {
    this.state.fetchParams.startDate = moment(dates[0]).format("L");
    this.state.fetchParams.endDate = moment(dates[1]).format("L");
    this.setState(
      { fetchParams: this.state.fetchParams, period: 0 },
      function () {
        this.props.dispatch(
          Actions.fetchLossReasons(this.state.fetchParams)
        );
      }
    );
  };

  onChangePeriod = (value) => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 1:
        startDate = moment().startOf("months").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 2:
        startDate = moment().startOf("quarter").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 3:
        startDate = moment().startOf("years").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY");
        endDate = moment().add(-1, "years").endOf("year").format("DD.MM.YYYY");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
    }
    this.state.fetchParams.startDate = startDate;
    this.state.fetchParams.endDate = endDate;
    this.setState(
      { fetchParams: this.state.fetchParams, period: value },
      function () {
        this.props.dispatch(
          Actions.fetchLossReasons(this.state.fetchParams)
        );
      }
    );
  };


  render() {
    const { fetchParams, period } = this.state;
    const { isLoading, report } = this.props;

    return (
      <DataList
        loading={isLoading}
        report={report}
        fetchParams={fetchParams}
        period={period}
        onChangeDateRange={this.onChangeDateRange}
        onChangePeriod={this.onChangePeriod}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  report: state.reports.lossReasons,
});

export default connect(mapStateToProps)(LossReasons);
