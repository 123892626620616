// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../../actions/countries";
import CountriesList from "./List";

class Countries extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCountries());
  };

  onDelete = id => {
    this.props
      .dispatch(Actions.onDelete(id))
      .then(() => this.props.dispatch(Actions.fetchCountries()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchCountries()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchCountries()));
  };

  render() {
    const { isLoading, countries, meta, fetchParams } = this.props;
    return (
      <CountriesList
        loading={isLoading}
        countries={countries}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
      />
    );
  }
}

Countries.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.countries.isLoading,
  countries: state.countries.countries,
  meta: state.countries.meta,
  fetchParams: state.countries.fetchParams,
});

export default connect(mapStateToProps)(Countries);
