// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import { message } from "antd";
import Actions from "../../../actions/purchases";
import ModalPurchaseForm from "./Form";

class ModalPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false, tempAttachments: [] };
  }

  onCreate = () => {
    const { tempAttachments } = this.state;
    this.props.dispatch(Actions.onCreate(tempAttachments));
  };

  onCancel = () => {
    this.props.dispatch(Actions.onChangeVisibleModalForm());
  };

  onChangeCompany = (value) => {
    this.props.dispatch(Actions.onChangeCompany(value)).then(() => {
      if (!value.extra.code) {
        message.warning(
          "У поставщика нет шифра в настройках! Шифр будет сгенерирован неверно."
        );
      }
    });
  };

  onChangeAgreementDate = (value) => {
    this.props.dispatch(Actions.onChangeAgreementDate(value));
  };

  onChangeAgreement = (e) => {
    this.props.dispatch(Actions.onChangeAgreement(e.target.value));
  };

  onChangeCode = (e) => {
    this.props.dispatch(Actions.onChangeCode(e.target.value));
  };

  onChangeTotal = (e) => {
    this.props.dispatch(Actions.onChangeTotal(e.target.value));
  };

  onChangeVatTotal = (e) => {
    this.props.dispatch(Actions.onChangeVatTotal(e.target.value));
  };

  // Items
  onAddItem = () => {
    this.props.dispatch(Actions.onAddItem());
  };

  onDeleteItems = (ids) => {
    this.props.dispatch(Actions.onDeleteItems(ids));
  };

  onChangeItemDate = (id, value) => {
    this.props.dispatch(Actions.onChangeItemDate(id, value));
  };

  onChangeItemPaymentType = (id, value) => {
    this.props.dispatch(Actions.onChangeItemPaymentType(id, value));
  };

  onChangeItemAmount = (id, value) => {
    // console.log(id, value)
    this.props.dispatch(Actions.onChangeItemAmount(id, value));
  };

  onChangeItemPercent = (id, value) => {
    this.props.dispatch(Actions.onChangeItemPercent(id, value));
  };

  // tempAttachments
  onTempUpload = (attachment) => {
    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $push: [attachment],
      }),
    });
  };

  onDeleteTempAttachment = (id) => {
    let idx = this.state.tempAttachments.findIndex(function (o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $splice: [[idx, 1]],
      }),
    });
  };

  render() {
    const { tempAttachments } = this.state;
    const { purchase, isVisibleModalForm } = this.props;
    return (
      <ModalPurchaseForm
        isVisible={isVisibleModalForm}
        purchase={purchase}
        onCreate={this.onCreate}
        onCancel={this.onCancel}
        onChangeCompany={this.onChangeCompany}
        onChangeAgreementDate={this.onChangeAgreementDate}
        onChangeAgreement={this.onChangeAgreement}
        onChangeCode={this.onChangeCode}
        onChangeTotal={this.onChangeTotal}
        onChangeVatTotal={this.onChangeVatTotal}
        onAddItem={this.onAddItem}
        onDeleteItems={this.onDeleteItems}
        onChangeItemDate={this.onChangeItemDate}
        onChangeItemPaymentType={this.onChangeItemPaymentType}
        onChangeItemAmount={this.onChangeItemAmount}
        onChangeItemPercent={this.onChangeItemPercent}
        // tempAttachments
        tempAttachments={tempAttachments}
        onTempUpload={this.onTempUpload}
        onDeleteTempAttachment={this.onDeleteTempAttachment}
      />
    );
  }
}

ModalPurchase.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  purchase: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.purchases.isLoading,
  isVisibleModalForm: state.purchases.isVisibleModalForm,
  errors: state.purchases.errors,
  purchase: state.purchases.purchase,
});

export default connect(mapStateToProps)(withRouter(ModalPurchase));
