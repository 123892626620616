import api from "../api";

const Actions = {};

Actions.fetchMaterialOrders = workOrderID => {
  return dispatch => {
    dispatch({ type: "FETCH_MATERIAL_ORDERS_REQUEST" });
    api
      .fetch("/material_orders", { work_order_id: workOrderID })
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_MATERIAL_ORDERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_MATERIAL_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchMaterialOrder = id => {
  return dispatch => {
    dispatch({ type: "FETCH_MATERIAL_ORDER_REQUEST" });
    return api
      .fetch(`/material_orders/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_MATERIAL_ORDER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Списание материалов", error);
          });
        }
        dispatch({ type: "FETCH_MATERIAL_ORDER_FAILURE", response });
      });
  };
};

Actions.fetchWorkOrderItems = id => {
  return dispatch => {
    dispatch({ type: "FETCH_WORK_ORDER_ITEMS_REQUEST" });
    return api
      .fetch(`/work_orders/${id}work_order_items`)
      .then(response => {
        dispatch({ type: "FETCH_WORK_ORDER_ITEMS", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Списание материалов", error);
          });
        }
        dispatch({ type: "FETCH_WORK_ORDER_ITEMS_FAILURE", response });
      });
  };
};

Actions.onNew = id => {
  return dispatch => {
    dispatch({ type: "FETCH_MATERIAL_ORDER_REQUEST" });
    return api
      .fetch(`/material_orders/material_order`, { work_order_id: id })
      .then(response => {
        dispatch({ type: "FETCH_MATERIAL_ORDER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Списание материалов", error);
          });
        }
        dispatch({ type: "FETCH_MATERIAL_ORDER_FAILURE", response });
      });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_MATERIAL_ORDER_REQUEST" });
    const materialOrder = getState().materialOrders.materialOrder;
    const materialOrderItems = materialOrder.material_order_items.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        work_order_item_id: item.work_order_item_id,
        quantity: item.quantity,
        cost: item.cost,
        price: item.price,
        _destroy: item._destroy
      };
    });

    const data = {
      material_order: {
        active: materialOrder.active,
        firm_id: materialOrder.firm_id,
        date: materialOrder.date,
        number: materialOrder.number,
        order_id: materialOrder.order_id,
        work_order_id: materialOrder.work_order_id,
        user_id: materialOrder.user_id,
        material_order_items_attributes: materialOrderItems
      }
    };

    return api
      .patch(`/material_orders/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_MATERIAL_ORDER", response });
        api.openNotification(
          "success",
          "Списание материалов",
          "Успешно обновлено!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Списание материалов", error);
          });
        }
        dispatch({ type: "PATCH_MATERIAL_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreate = (ids) => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_MATERIAL_ORDER_REQUEST" });
    const materialOrder = getState().materialOrders.materialOrder;
    const materialOrderItems = materialOrder.material_order_items.filter(function(item) {
      return ids.includes(item.id);
    }).map(item => {
      return {
        work_order_item_id: item.work_order_item_id,
        quantity: item.quantity,
        cost: item.cost
      };
    });

    
    // console.log(materialOrderItems)
    const data = {
      material_order: {
        active: materialOrder.active,
        firm_id: materialOrder.firm_id,
        date: materialOrder.date,
        number: materialOrder.number,
        order_id: materialOrder.order_id,
        work_order_id: materialOrder.work_order_id,
        user_id: materialOrder.user_id,
        material_order_items_attributes: materialOrderItems
      }
    };

    return api
      .post(`/material_orders`, data)
      .then(response => {
        dispatch({ type: "POST_MATERIAL_ORDER", response });
        api.openNotification(
          "success",
          "Списание материалов",
          "Успешно создано!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Списание материалов", error);
          });
        }
        dispatch({ type: "POST_MATERIAL_ORDER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_MATERIAL_ORDER_REQUEST" });
    return api
      .delete(`/material_orders/${id}`)
      .then(response => {
        api.openNotification("success", "Списание материалов", "Успешно удалено!");
        dispatch({ type: "DELETE_MATERIAL_ORDER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Списание материалов", error);
          });
        }
        dispatch({
          type: "DELETE_MATERIAL_ORDER_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "MATERIAL_ORDER_TOGGLE_VISIBLE"
    });
  };
};

Actions.onDeleteItems = items => {
  return (dispatch, getState) => {
    items.map(item => {
      let materialOrderItems = getState().materialOrders.materialOrder
        .material_order_items;
      let idx = materialOrderItems.findIndex(function(p) {
        return p.id == item;
      });
      if (idx === -1) {
        return null;
      }

      dispatch({
        type: "MATERIAL_ORDER_ITEM_DELETE",
        idx: idx
      });
    });
  };
};

Actions.onChangeNumber = value => {
  return dispatch => {
    dispatch({
      type: "MATERIAL_ORDER_CHANGE_NUMBER",
      value: value
    });
  };
};

Actions.onChangeDate = value => {
  return dispatch => {
    dispatch({
      type: "MATERIAL_ORDER_CHANGE_DATE",
      value: value
    });
  };
};

Actions.onChangeItemQuantity = (idx, value) => {
  return dispatch => {
    dispatch({
      type: "MATERIAL_ORDER_CHANGE_ITEM_QUANTITY",
      idx: idx,
      value: value
    });
  };
};

Actions.onChangeItemCost = (idx, value) => {
  return dispatch => {
    dispatch({
      type: "MATERIAL_ORDER_CHANGE_ITEM_COST",
      idx: idx,
      value: value
    });
  };
};

export default Actions;
