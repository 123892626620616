// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Input,
  InputNumber,
  Checkbox,
  Tabs
} from "antd";
import Specification from "./Specification";
import SelectFetch from "../../../components/SelectFetch";
import Orders from "./Orders";

const { TextArea } = Input;
const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = { tabIndex: "1" };
  }

  onChangeTab = key => {
    this.setState({ tabIndex: key }, () => {
      if (key == 2) {
        this.props.fetchOrders();
      }
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      isNew,
      isLoading,
      onClose,
      product,
      onChangeName,
      onChangeDescription,
      onChangeSku,
      onChangePrice,
      onChangeUnit,
      onChangeActive,
      onChangeIsPrice,
      onAddSpecificationItem,
      onDeleteSpecificationItems,
      onChangeSpecificationProduct,
      onChangeSpecificationCost,
      onChangeSpecificationQuantity,
      isOrdersLoading,
      orders
    } = this.props;

    const { getFieldDecorator } = this.props.form;
    const specificationItems = product.product_specifications.filter(function(
      item
    ) {
      return !item._destroy;
    });

    return (
      <Col xs={24} sm={24} md={24} lg={17} xl={17}>
        <Card
          loading={isLoading}
          title={
            <h3 style={{ marginBottom: 0 }}>
              {isNew ? "новый" : product.name}
            </h3>
          }
          extra={
            <div>
              <Button onClick={onClose}>Закрыть</Button>
              <Button
                type="primary"
                ghost
                style={{ marginLeft: "8px" }}
                onClick={this.onSubmit}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <Form layout={"vertical"} onSubmit={this.onSubmit}>
            <Row>
              <Col span={15}>
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItem label="Наименование">
                      {getFieldDecorator("name", {
                        initialValue: product.name,
                        onChange: onChangeName,
                        rules: [
                          {
                            type: "string",
                            message: "Введите наименование!"
                          },
                          {
                            required: true,
                            message: "Введите наименование!"
                          }
                        ]
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Описание">
                      {getFieldDecorator("description", {
                        onChange: onChangeDescription,
                        initialValue: product.description
                      })(<TextArea autoSize={{ minRows: 2, maxRows: 3 }} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Артикул">
                      {getFieldDecorator("sku", {
                        onChange: onChangeSku,
                        initialValue: product.sku
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Цена">
                      {getFieldDecorator("price", {
                        onChange: onChangePrice,
                        initialValue: product.price
                      })(<Input type={"number"} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label={"Ед. измерения"}>
                      {getFieldDecorator(
                        "unit_id",
                        {
                          initialValue: product.unit_id
                        },
                        {
                          rules: [
                            {
                              required: true,
                              message: "Введите единицу измерения!"
                            }
                          ]
                        }
                      )(
                        <SelectFetch
                          onChange={onChangeUnit}
                          selected={product.unit ? product.unit : null}
                          url={"/units/search"}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={1} />
              <Col span={8}>
                <Card>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem>
                        {getFieldDecorator("active", {
                          initialValue: product.active,
                          valuePropName: "checked",
                          onChange: onChangeActive
                        })(<Checkbox>показывать при подборе</Checkbox>)}
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem>
                        {getFieldDecorator("is_price", {
                          initialValue: product.is_price,
                          valuePropName: "checked",
                          onChange: onChangeIsPrice
                        })(<Checkbox>включать в прайс-лист</Checkbox>)}
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Tabs activeKey={this.state.tabIndex} onChange={this.onChangeTab}>
                <TabPane tab="Спецификация" key="1">
                  <Specification
                    specificationItems={specificationItems}
                    onAddSpecificationItem={onAddSpecificationItem}
                    onDeleteSpecificationItems={onDeleteSpecificationItems}
                    onChangeSpecificationProduct={onChangeSpecificationProduct}
                    onChangeSpecificationQuantity={
                      onChangeSpecificationQuantity
                    }
                    onChangeSpecificationCost={onChangeSpecificationCost}
                  />
                </TabPane>
                <TabPane tab="Заказы" key="2">
                <Orders loading={isOrdersLoading} orders={orders} />
                </TabPane>
              </Tabs>
            </Row>
          </Form>
        </Card>
      </Col>
    );
  }
}

ProductForm.propTypes = {
  product: PropTypes.object
};

export default Form.create()(ProductForm);
