import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  shipments: [],
  shipment: {
    number: null,
    date: null,
    total: 0,
    total_subcontract: 0,
    total_material: 0,
    shipment_items: []
  },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isVisible: false
};

function recalculationItem(item) {
  let price = Number(item.price);
  let quantity = Number(item.quantity);
  let vat_rate = item.vat_rate ? Number(item.vat_rate.rate) : 0;
  let amount = Number(quantity * price).toFixed(2);
  let amount_vat = Number((amount / 100) * vat_rate).toFixed(2);

  return {
    price: price,
    quantity: quantity,
    amount: amount,
    amount_vat: amount_vat
  };
}

export default function(state = initialState, action) {
  let idx = -1;
  let recalcItem = {};
  switch (action.type) {
    case "FETCH_SHIPMENTS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_SHIPMENTS":
      return {
        ...state,
        shipments: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_SHIPMENT_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_SHIPMENT":
      return {
        ...state,
        shipment: action.response.data,
        isLoading: false
      };
    case "PATCH_SHIPMENT_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_SHIPMENT":
      return {
        ...state,
        shipment: action.response.data,
        isLoading: false
      };

    case "POST_SHIPMENT_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_SHIPMENT":
      return {
        ...state,
        errors: false,
        shipment: {
          number: null,
          date: null,
          total: 0,
          total_subcontract: 0,
          total_material: 0,
          shipment_items: []
        },
        isLoading: false
      };

    case "POST_SHIPMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };
    case "DELETE_SHIPMENT_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_SHIPMENT":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_SHIPMENT_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_SHIPMENT_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_SHIPMENT_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "SHIPMENT_CHANGE_DATE":
      return update(state, {
        shipment: {
          date: { $set: action.value }
        }
      });

    case "SHIPMENT_TOGGLE_VISIBLE":
      return {
        ...state,
        isVisible: !state.isVisible
      };

    case "SHIPMENT_CHANGE_DATE":
      return update(state, {
        shipment: {
          date: { $set: action.value }
        }
      });

    case "SHIPMENT_CHANGE_ITEM_QUANTITY":
      idx = _.findIndex(state.shipment.shipment_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: state.shipment.shipment_items[idx].price,
        quantity: action.value,
        vat_rate: state.shipment.shipment_items[idx].vat_rate,
        amount: state.shipment.shipment_items[idx].amount,
        amount_vat: state.shipment.shipment_items[idx].amount_vat
      });

      return update(state, {
        shipment: {
          shipment_items: {
            [idx]: {
              quantity: { $set: action.value },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat }
            }
          }
        }
      });

    case "SHIPMENT_CHANGE_ITEM_AMOUNT":
      idx = _.findIndex(state.shipment.shipment_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: state.shipment.shipment_items[idx].price,
        quantity: state.shipment.shipment_items[idx].quantity,
        vat_rate: state.shipment.shipment_items[idx].vat_rate,
        amount: action.value,
        amount_vat: state.shipment.shipment_items[idx].amount_vat
      });

      return update(state, {
        shipment: {
          shipment_items: {
            [idx]: {
              amount: { $set: action.value },
              amount_vat: { $set: recalcItem.amount_vat }
            }
          }
        }
      });

    case "SHIPMENT_CHANGE_ITEM_AMOUNT_VAT":
      idx = _.findIndex(state.shipment.shipment_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        shipment: {
          shipment_items: {
            [idx]: { amount_vat: { $set: action.value } }
          }
        }
      });

    case "SHIPMENT_ITEM_DELETE":
      return update(state, {
        shipment: {
          shipment_items: {
            [action.idx]: { _destroy: { $set: true } }
          }
        }
      });

    case "SHIPMENT_DESTROY_ITEM":
      return update(state, {
        shipment: {
          shipment_items: { $splice: [[action.idx, 1]] }
        }
      });

    default:
      return state;
  }
}
