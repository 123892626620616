// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form, Input } from "antd";
import PropTypes from "prop-types";

const FormItem = Form.Item;

class ModalForm extends Component {
  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { businessField, visible, onCancel, onChangeName } = this.props;

    return (
      <Modal
        title={`Сфера дефтельности #${
          businessField.id ? businessField.id : "новая"
        } `}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: businessField.name,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите наименование!"
                    }
                  ]
                })(<Input placeholder="введите наименование" />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  businessField: PropTypes.object
};

export default Form.create()(ModalForm);
