// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/documents";
import DocumentsList from "./List";

class Documents extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    const { ownerID, ownerType } = this.props;
    if (ownerID && ownerType) {
      this.props.dispatch(Actions.fetchDocuments(ownerID, ownerType));
    }
  };

  onDelete = id => {
    const { ownerID, ownerType } = this.props;
    this.props.dispatch(Actions.onDelete(id)).then(() => {
      if (!this.props.errors) {
        this.props.dispatch(Actions.fetchDocuments(ownerID, ownerType));
      }
    });
  };

  onUpload = file => {
    const { ownerID, ownerType } = this.props;
    this.props.dispatch(Actions.onUpload(ownerID, ownerType, file)).then(() => {
      if (!this.props.errors) {
        this.props.dispatch(Actions.fetchDocuments(ownerID, ownerType));
      }
    });
  };


  render() {
    const { isLoading, isUploading, documents } = this.props;
    return (
      <DocumentsList
        isLoading={isLoading}
        isUploading={isUploading}
        documents={documents}
        onUpload={this.onUpload}
        onDelete={this.onDelete}
      />
    );
  }
}

Documents.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  requestForProposal: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.documents.isLoading,
  isUploading: state.documents.isUploading,
  errors: state.documents.errors,
  documents: state.documents.documents
});

export default connect(mapStateToProps)(withRouter(Documents));
