import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { Select, Spin, Tooltip, Popover } from "antd";

const Option = Select.Option;

const popupContent = (editUrl = null, extra = null) => {
  return <div>{extra ? <p><Link to={`${editUrl}`} target="_blank">{extra.subtext}</Link></p> : null}</div>;
};

class EditableSelectFetchCell extends React.Component {
  static defaultProps = {
    placeholder: "",
    showSearch: false,
    showSubtitle: false,
    showTooltip: false,
    highlight: false,
    popup: false,
    editUrl: null,
    popupTitle: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      data: [],
      selected: { id: null, text: "", extra: "" },
      fetching: false
    };
    this.onEscFunction = this.onEscFunction.bind(this);
  }

  componentWillMount = () => {
    if (this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  componentWillUnmount() {
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        // this.input.focus();
      }
    });
  };

  onEscFunction(e) {
    const { editing } = this.state;
    if (editing && this.cell.contains(e.target) && e.keyCode === 27) {
      this.toggleEdit();
    }
  }

  onChange = value => {
    const { recordId } = this.props;
    this.setState({ selected: this.state.data[value], fetching: false }, () => {
      return this.props.onChange
        ? this.props.onChange(recordId, this.state.selected)
        : null;
    });

    this.toggleEdit();
  };

  onBlur = () => {
    this.toggleEdit();
  };

  onFocus = () => {
    this.setState({ data: [], fetching: true });
    api.fetch(`${this.props.url}`).then(response => {
      const data = response.data;
      if (this.state.selected.id) {
        data.push(this.state.selected);
      }
      this.setState({ data, fetching: false });
    });
  };

  onSearch = value => {
    api
      .fetch(`${this.props.url}&q=${encodeURIComponent(value)}`)
      .then(response => {
        const data = response.data;
        this.setState({ data, fetching: false });
      });
  };

  renderOptions = (data, showSubtitle) => {
    if (showSubtitle) {
      return data.map((d, i) => (
        <Option key={i} title={d.text} style={{ whiteSpace: "normal" }}>
          <div>
            <div>{d.text}</div>
            <div
              style={{
                fontSize: "13px",
                color: "#999",
                textOverflow: "text-overflow"
              }}
            >
              {d.extra ? d.extra.subtext : null}
            </div>
          </div>
        </Option>
      ));
    } else {
      return data.map((d, i) => (
        <Option key={i} title={d.text} style={{ whiteSpace: "normal" }}>
          {d.text}
        </Option>
      ));
    }
  };

  renderValue = (
    selected,
    highlight,
    popup,
    showTooltip,
    editUrl,
    popupTitle
  ) => {
    let selectedComponent = selected.text;
    if (highlight) {
      selectedComponent = <a>{selectedComponent}</a>;
    }
    if (showTooltip && selected.extra) {
      selectedComponent = (
        <Tooltip placement="right" title={selected.extra.subtext}>
          {selectedComponent}
        </Tooltip>
      );
    }
    if (popup) {
      selectedComponent = (
        <Popover
          content={popupContent(editUrl, selected.extra)}
          title={popupTitle}
        >
          {selectedComponent}
        </Popover>
      );
    }
    return selectedComponent;
  };

  render() {
    const { editing, fetching, data, selected } = this.state;
    const {
      placeholder,
      showSearch,
      highlight,
      popup,
      showSubtitle,
      showTooltip,
      editUrl,
      popupTitle
    } = this.props;
    return (
      <div className="editable-cell" ref={node => (this.cell = node)}>
        {editing ? (
          <Select
            ref={node => (this.input = node)}
            showSearch={showSearch}
            onSearch={this.onSearch}
            value={selected ? selected.text : null}
            dropdownMatchSelectWidth
            // autoFocus
            defaultOpen
            placeholder={placeholder}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onBlur={this.onBlur}
            filterOption={false}
            style={{ width: "100%" }}
          >
            {this.renderOptions(data, showSubtitle)}
          </Select>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
            onClick={this.toggleEdit}
          >
            {this.renderValue(
              selected,
              highlight,
              popup,
              showTooltip,
              editUrl,
              popupTitle
            )}
            {/* {highlight ? <a>{selected.text}</a> : selected.text} */}
          </div>
        )}
      </div>
    );
  }
}

export default EditableSelectFetchCell;
