// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Icon, Form, Input, Select, Tooltip } from "antd";
import InputMask from "react-input-mask";
import axios from "axios";
import api from "../../../api";

const FormItem = Form.Item;
const Option = Select.Option;

class Header extends Component {
  fillByInn = () => {
    const { inn, companyType, addresses } = this.props;
    let _this = this;
    if (
      inn && ((inn.length != 10 && companyType == "resident") || (inn.length != 12 && companyType == "person"))
    ) {
      api.openNotification(
        "error",
        "Компания",
        "Проверьте длину введенного ИНН"
      );
    } else {
      axios
        .post(
          `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party`, { query: inn },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Token 6d8daaa0925736c2b5b5d1f9759973258d877165`
            }
          }
        )
        .then(function(response) {
          if (response.suggestions.length > 0) {
            let suggestion = response.suggestions[0].data;
            let data = {};
            let address = {};
            data.name = suggestion.name.short_with_opf;
            data.kpp = suggestion.kpp;
            
            address.id = addresses.length > 0 ? addresses[0].id : Date.now();
            address.postindex     = suggestion.address.data.postal_code;
            address.country       = {id: 24, text: "Россия"};
            address.region        = suggestion.address.data.region;
            address.city_fias_id  = suggestion.address.data.city_fias_id;
            address.city_kladr_id = suggestion.address.data.city_kladr_id;
            address.city_type     = suggestion.address.data.city_type;
			    	address.city          = suggestion.address.data.city;
			    	address.street        = suggestion.address.data.street_with_type;
			    	address.building      = suggestion.address.data.house ? `${suggestion.address.data.house_type}. ${suggestion.address.data.house}` : null;
			    	address.building_ext  = suggestion.address.data.block ? `${suggestion.address.data.block_type}. ${suggestion.address.data.block}` : '';
            address.flat          = suggestion.address.data.flat ? `${suggestion.address.data.flat_type}. ${suggestion.address.data.flat}` : '';
            address.is_exist      = addresses.length > 0 ? true : false;
            address._destroy      = false
            // console.log(suggestion.address.data.house ? `${data.address.data.house_type}. ${data.address.data.house}` : null);
            _this.props.fillCompanyByInn(data, address)
          } else {
            api.openNotification(
              "error",
              "Компания",
              "По введенному ИНН ничего не найдено"
            );
          }
        })
        .catch(function(response) {});
    }
  };

  render() {
    
    const {
      name,
      code,
      full_name,
      inn,
      kpp,
      companyType,
      getFieldDecorator,
      onChangeName,
      onChangeCode,
      onChangeCompanyType,
      onChangeFullName,
      onChangeInn,
      onChangeKpp
    } = this.props;

    // console.log(full_name)

    const fillByInnButton = (
      <Tooltip placement="bottom" title={"заполнить по ИНН"}>
        <Icon
          type="download"
          style={{ cursor: "pointer" }}
          onClick={this.fillByInn}
        />
      </Tooltip>
    );
    return (
      <React.Fragment>
        <Col sm={{ span: 19 }} md={{ span: 13 }} xs={{ span: 13 }}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: name,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!"
                }
              ]
            })(<Input placeholder="введите наименование" />)}
          </FormItem>
        </Col>
        <Col sm={{ span: 5 }} md={{ span: 3 }} xs={{ span: 3 }}>
          <FormItem label={"Шифр"}>
            {getFieldDecorator("code", {
              onChange: onChangeCode,
              initialValue: code ? code : ""
            })(
              <InputMask className="ant-input" mask="99" autoComplete="off" />
            )}
          </FormItem>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 8 }} xs={{ span: 8 }}>
          <FormItem label={"Тип"}>
            {getFieldDecorator("company_type", {
              initialValue: companyType,
              onChange: onChangeCompanyType
            })(
              <Select
                style={{ width: "100%" }}
                placeholder="Тип компании"
                //optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"resident"}>резидент</Option>
                <Option value={"no_resident"}>не резидент</Option>
                <Option value={"person"}>физлицо</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
          <FormItem label={"Полное наименование"}>
            {getFieldDecorator("full_name", {
              onChange: onChangeFullName,
              initialValue: full_name,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите полное наименование!"
                }
              ]
            })(<Input placeholder="введите наименование" />)}
          </FormItem>
        </Col>

        {companyType == "resident" || "person" ? (
          <React.Fragment>
            <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"ИНН"}>
                {getFieldDecorator("inn", {
                  onChange: onChangeInn,
                  initialValue: inn
                })(
                  <Input
                    placeholder="введите инн"
                    addonAfter={fillByInnButton}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"КПП"}>
                {getFieldDecorator("kpp", {
                  onChange: onChangeKpp,
                  initialValue: kpp
                })(<Input />)}
              </FormItem>
            </Col>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
