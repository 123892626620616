// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Timeline, Icon, Button } from "antd";

import moment from "moment";
import "moment/locale/ru";

import Event from "./Event";

function eventIcon(eventType) {
  switch (eventType) {
    case "sys":
      return "history";
    case "value":
      return "history";
    case "email":
      return "mail";
    case "phone":
      return "phone";
    case "meeting":
      return "team";
    case "other":
      return "history";
    case "remind":
      return "bell";

    default:
      return "history";
  }
}

class Events extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      requestProposalEvents,
      isEventsLoading,
      isEventVisible,
      event,
      onSaveEvent,
      onAddEvent,
      onCancelEvent,
      onChangeEventType,
      onChangeDescriptionEvent,
      onChangeEventNotifyDate,
    } = this.props;

    return (
      <Card
        loading={isEventsLoading}
        size="small"
        title="Активность"
        extra={
          <Button type="primary" size="small" ghost onClick={onAddEvent}>
            Добавить
          </Button>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            {isEventVisible ? (
              <Event
                event={event}
                onSaveEvent={onSaveEvent}
                onCancelEvent={onCancelEvent}
                onChangeEventType={onChangeEventType}
                onChangeDescriptionEvent={onChangeDescriptionEvent}
                onChangeEventNotifyDate={onChangeEventNotifyDate}
              />
            ) : null}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Timeline>
              {requestProposalEvents.map((item) => {
                return (
                  <Timeline.Item
                    color={item.event_type > 2 ? "green" : "blue"}
                    key={item.id}
                    dot={
                      <Icon
                        type={eventIcon(item.event_type)}
                        style={{ fontSize: "20px" }}
                      />
                    }
                  >
                    <p style={{ opacity: "0.7" }}>
                      {moment(item.created_at).format("LLL")} - {item.user.text}
                    </p>
                    <p>{item.description}</p>
                    {item.event_type == "remind" ? `Напомнить ${moment(item.notify_date).format("LLL")}` : null}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Col>
        </Row>
      </Card>
    );
  }
}

Events.propTypes = {
  orderEvents: PropTypes.arrayOf(PropTypes.object),
};
export default Events;
