import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  companies: [],
  orders: [],
  company: {
    addresses: [],
    phones: [],
    emails: [],
    websites: [],
    contacts: [],
  },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startCreatedDate: null,
    endCreatedDate: null,
    businessFields: null,
    states: null,
    contact_types: null,
    search: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isLoadingOrders: true,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const companyFilters = localStorage.getItem("companyFilters");
  if (companyFilters) {
    localFetchParams = JSON.parse(companyFilters);
  } else {
    localStorage.setItem("companyFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let idx = -1;
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_COMPANIES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_COMPANIES":
      return {
        ...state,
        companies: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_COMPANY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_COMPANY":
      return {
        ...state,
        company: action.response.data,
        isLoading: false,
      };
    case "PATCH_COMPANY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "PATCH_COMPANY":
      return {
        ...state,
        company: action.response.data,
        isLoading: false,
      };

    case "PATCH_COMPANY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_COMPANY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_COMPANY":
      return {
        ...state,
        errors: false,
        company: action.response.data,
        isLoading: false,
      };

    case "POST_COMPANY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    // orders
    case "FETCH_COMPANY_ORDERS_REQUEST":
      return {
        ...state,
        isLoadingOrders: true,
      };
    case "FETCH_COMPANY_ORDERS":
      return {
        ...state,
        orders: action.response.data,
        meta: action.response.meta,
        isLoadingOrders: false,
      };

    case "FETCH_COMPANY_ORDERS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadingOrders: false,
      };

    case "COMPANY_NEW":
      return {
        ...state,
        errors: false,
        company: {
          code: null,
          company_type: "resident",
          logo: null,
          name: null,
          full_name: null,
          inn: null,
          kpp: null,
          description: null,
          assignee: null,
          phones: [],
          emails: [],
          websites: [],
          business_field: null,
          contact_source: null,
          addresses: [],
          contacts: [],
        },
        isLoading: false,
      };

    case "DELETE_COMPANY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_COMPANY":
      return {
        ...state,
        errors: false,
        companies: action.response.data,
        isLoading: false,
      };
    case "DELETE_COMPANY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "REQUEST_COMPANY_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_COMPANY_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    // header
    case "COMPANY_FILL_BY_INN":
      return update(state, {
        company: {
          name: { $set: action.data.name },
          full_name: { $set: action.data.name },
          kpp: { $set: action.data.kpp },
          addresses: { $set: [action.address] },
        },
      });

    case "COMPANY_CHANGE_NAME":
      return update(state, {
        company: { name: { $set: action.value } },
      });

    case "COMPANY_CHANGE_CODE":
      return update(state, {
        company: { code: { $set: action.value } },
      });

    case "COMPANY_CHANGE_COMPANY_TYPE":
      return update(state, {
        company: { company_type: { $set: action.value } },
      });

    case "COMPANY_CHANGE_FULL_NAME":
      return update(state, {
        company: { full_name: { $set: action.value } },
      });

    case "COMPANY_CHANGE_INN":
      return update(state, {
        company: { inn: { $set: action.value } },
      });

    case "COMPANY_CHANGE_KPP":
      return update(state, {
        company: { kpp: { $set: action.value } },
      });

    case "COMPANY_CHANGE_ASSIGNEE":
      return update(state, {
        company: { assignee: { $set: action.value } },
      });

    case "COMPANY_CHANGE_CONTACT_TYPE":
      return update(state, {
        company: { contact_type: { $set: action.value } },
      });

    case "COMPANY_CHANGE_CONTACT_SOURCE":
      return update(state, {
        company: { contact_source: { $set: action.value } },
      });

    case "COMPANY_CHANGE_BUSINESS_FIELD":
      return update(state, {
        company: { business_field: { $set: action.value } },
      });

    // addresses
    case "COMPANY_ADD_ADDRESS":
      return update(state, {
        company: { addresses: { $push: [action.data] } },
      });

    case "COMPANY_CHANGE_ADDRESS":
      return update(state, {
        company: { addresses: { [action.idx]: { $set: action.data } } },
      });

    case "COMPANY_DELETE_ADDRESS":
      return update(state, {
        company: {
          addresses: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "COMPANY_DESTROY_ADDRESS":
      return update(state, {
        company: {
          addresses: { $splice: [[action.idx, 1]] },
        },
      });

    // phones
    case "COMPANY_ADD_PHONE":
      return update(state, {
        company: { phones: { $push: [action.data] } },
      });

    case "COMPANY_CHANGE_PHONE":
      return update(state, {
        company: { phones: { [action.idx]: { $set: action.data } } },
      });

    case "COMPANY_DELETE_PHONE":
      return update(state, {
        company: {
          phones: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "COMPANY_DESTROY_PHONE":
      return update(state, {
        company: {
          phones: { $splice: [[action.idx, 1]] },
        },
      });

    // emails
    case "COMPANY_ADD_EMAIL":
      return update(state, {
        company: { emails: { $push: [action.data] } },
      });

    case "COMPANY_CHANGE_EMAIL":
      return update(state, {
        company: { emails: { [action.idx]: { $set: action.data } } },
      });

    case "COMPANY_DELETE_EMAIL":
      return update(state, {
        company: {
          emails: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "COMPANY_DESTROY_EMAIL":
      return update(state, {
        company: {
          emails: { $splice: [[action.idx, 1]] },
        },
      });

    // filters
    case "COMPANIES_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "COMPANIES_CLEAR_FILTERS":
      localStorage.removeItem("companyFilters");

      return update(state, {
        fetchParams: {
          startCreatedDate: { $set: null },
          endCreatedDate: { $set: null },
          businessFields: { $set: null },
        },
      });

    case "ORDERS_FILTER_CREATED_PERIOD":
      return update(state, {
        fetchParams: {
          startCreatedDate: { $set: action.startDate },
          endCreatedDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "COMPANIES_FILTER_BUSINESS_FIELDS":
      return update(state, {
        fetchParams: {
          businessFields: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "COMPANIES_FILTER_STATES":
      return update(state, {
        fetchParams: {
          states: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "COMPANIES_FILTER_CONTACT_TYPE":
      return update(state, {
        fetchParams: {
          contact_types: { $set: action.values },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
