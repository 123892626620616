import React from "react";
import { Chart, Tooltip, Geom } from "bizcharts";
import accounting from "accounting";

// @autoHeight()
export default class MiniBar extends React.Component {
  render() {
    const {
      height,
      forceFit = true,
      color = "#1890FF",
      data = []
    } = this.props;

    const scale = {
      x: {
        type: "cat"
      },
      y: {
        min: 0
      }
    };

    const padding = [36, 5, 30, 5];

    const tooltip = [
      "x*y",
      (x, y) => ({
        name: x,
        value: `${accounting.formatNumber(y, 0, " ")} руб.`
      })
    ];

    // for tooltip not to be hide
    const chartHeight = height + 54;

    return (
      <div className={"miniChart"} style={{ height }}>
        <div className={"chartContent"}>
          <Chart
            scale={scale}
            height={chartHeight}
            forceFit={forceFit}
            data={data}
            padding={padding}
          >
            <Tooltip showTitle={false} crosshairs={false} />
            <Geom
              type="interval"
              position="x*y"
              color={color}
              tooltip={tooltip}
            />
          </Chart>
        </div>
      </div>
    );
  }
}
