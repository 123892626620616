import api from "../api";

const Actions = {};

Actions.fetchUsers = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_USERS_REQUEST" });
    const params = getState().users.fetchParams;
    api
      .fetch("/users", params)
      .then(response => {
        dispatch({ type: "FETCH_USERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_USERS_FAILURE", response });
      });
  };
};

Actions.fetchUser = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_USER_REQUEST" });
    api
      .fetch(`/users/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_USER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "FETCH_USER_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "USER_NEW",
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        active: user.active,
        role: user.role,
        email: user.email,
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name
      }
    };
    api
      .patch(`/users/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_USER", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_USER_FAILURE", response });
      });
  };
};

Actions.onUpdatePassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        password: user.password,
        password_confirmation: user.password_confirmation
      }
    };
    api
      .patch(`/users/${user.id}/update_password`, data)
      .then(response => {
        dispatch({ type: "PATCH_PASSWORD_USER", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_USER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        active: user.active,
        role: user.role,
        email: user.email,
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
        password: user.password,
        password_confirmation: user.password_confirmation
      }
    };
    return api
      .post(`/users`, data)
      .then(response => {
        dispatch({ type: "POST_USER", response });
        api.openNotification(
          "success",
          "Пользователь системы",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь системы", error);
          });
        }
        dispatch({ type: "POST_USER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_USER_REQUEST" });
    return api
      .delete(`/users/${id}`)
      .then(response => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_USER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_USER_FAILURE"
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USER_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USER_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USER_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USERS_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "USERS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeRole = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_ROLE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeMiddleName = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_MIDDLE_NAME",
      value: value
    });
  };
};

Actions.onChangeLastName = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_LAST_NAME",
      value: value
    });
  };
};

Actions.onChangeEmail = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangePassword = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangePasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};

export default Actions;
