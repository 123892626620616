import api from "../api";

const Actions = {};

function setCurrentUser(dispatch, response) {
  
    dispatch({
      type: "AUTHENTICATION_SUCCESS",
      response: response
    });
    localStorage.setItem("token", response.token);
    return Promise.resolve();
}

Actions.login = (data, push) => {
  return dispatch => {
    api
      .post("/users/auth_user", data)
      .then(response => {
        setCurrentUser(dispatch, response).then(() => { push("/"); });
      })
      .catch(response => {
        api.openNotification("error", "Логин", response.errors[0]);
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
      });
  };
};

Actions.logout = router => {
  return dispatch => {
    return api.logout("/users/logout").then(() => {
      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT" });
      window.location = "/auth/login";
    });
  };
};

Actions.authenticate = () => {
  return dispatch => {
    dispatch({ type: "AUTHENTICATION_REQUEST" });
    return api
      .post("/users/auth_user_refresh")
      .then(response => {
        setCurrentUser(dispatch, response);
      })
      .catch(response => {
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
        localStorage.removeItem("token");
        window.location = "/auth/login";
      });
  };
};

Actions.unauthenticate = () => {
  return dispatch => {
    dispatch({ type: "AUTHENTICATION_FAILURE" });
  };
};

export default Actions;
