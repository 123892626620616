// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/firms";
import FirmsList from "./FirmsList";

class Firms extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchFirms());
  };

  
  render() {
    return (
      <FirmsList
        loading={this.props.isLoading}
        firms={this.props.firms}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.firms.isLoading,
  firms: state.firms.firms,
});

export default connect(mapStateToProps)(Firms);
