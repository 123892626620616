// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/cities";
import ModalForm from "./Form";

class CitydModal extends Component {
  onSave = () => {
    if (this.props.city.id) {
      this.props
        .dispatch(Actions.onUpdate(this.props.city.id))
        .then(() => {
          this.props.dispatch(Actions.fetchCities());
        });
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        this.props.dispatch(Actions.fetchCities());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeState = value => {
    this.props.dispatch(Actions.onChangeState(value));
  };

  onChangeCityType = value => {
    this.props.dispatch(Actions.onChangeCityType(value));
  };

  onChangeLat = value => {
    this.props.dispatch(Actions.onChangeLat(value));
  };

  onChangeLon = value => {
    this.props.dispatch(Actions.onChangeLon(value));
  };

  onChangeFias = e => {
    this.props.dispatch(Actions.onChangeFias(e.target.value));
  };
  
  onChangeKladr = e => {
    this.props.dispatch(Actions.onChangeKladr(e.target.value));
  };

  render() {
    const { isLoading, city, isModalVisible } = this.props;
    return (
      <ModalForm
        isLoading={isLoading}
        visible={isModalVisible}
        city={city}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeName={this.onChangeName}
        onChangeState={this.onChangeState}
        onChangeCityType={this.onChangeCityType}

        onChangeLat={this.onChangeLat}
        onChangeLon={this.onChangeLon}
        onChangeFias={this.onChangeFias}
        onChangeKladr={this.onChangeKladr}

        destroyOnClose
      />
    );
  }
}

CitydModal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  city: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.cities.isLoading,
  isModalVisible: state.cities.isModalVisible,
  errors: state.cities.errors,
  city: state.cities.city
});

export default connect(mapStateToProps)(withRouter(CitydModal));
