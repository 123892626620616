// @flow
import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Tabs,
  Form,
  Table,
  Input,
  DatePicker,
  Button
} from "antd";
import PropTypes from "prop-types";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";

import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;

class MaterialOrderModalForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave(this.state.selectedRowKeys);
      this.setState({ selectedRowKeys: [] });
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteItems = () => {
    this.props.onDeleteItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      materialOrder,
      visible,
      onCancel,
      onChangeNumber,
      onChangeDate,
      onChangeItemQuantity,
      onChangeItemCost
    } = this.props;

    const { selectedRowKeys } = this.state;
    const materialOrderItems = materialOrder.material_order_items.filter(function(
      item
    ) {
      return !item._destroy;
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Материал / Продукция",
        dataIndex: "product",
        render: (product, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column-reverse" }}>
              <span style={{ fontSize: "12px" }}>{record.order_product}</span>
              <span>{product}</span>
            </div>
          );
        },
        align: "center",
        width: "33%"
      },
      {
        title: "Кол-во",
        dataIndex: "quantity",
        render: (quantity, record, index) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={quantity}
              precision={0}
              onChange={onChangeItemQuantity.bind(this, index)}
            />
          );
        },
        align: "center",
        width: "14%"
      },
      {
        title: "Стоимость",
        dataIndex: "cost",
        render: (cost, record, index) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={cost}
              onChange={onChangeItemCost.bind(this, index)}
            />
          );
        },
        align: "center",
        width: "20%"
      },
      {
        title: "Поставщик / Договор / Шифр",
        dataIndex: "company",
        render: (company, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column-reverse" }}>
              <span style={{ fontSize: "12px" }}>{record.purchase.code}</span>
              <span style={{ fontSize: "12px" }}>
                {record.purchase.agreement}
              </span>
              <span>{company.name}</span>
            </div>
          );
        },
        align: "center",
        width: "33%"
      }
    ];

    return (
      <Modal
        title="Списание материалов (работ)"
        destroyOnClose
        visible={visible}
        width={1000}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={12}>
              <FormItem label={"Номер"}>
                {getFieldDecorator("number", {
                  onChange: onChangeNumber,
                  initialValue: materialOrder.number,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите номер!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Дата списания"}>
                {getFieldDecorator("date", {
                  onChange: onChangeDate,
                  initialValue: moment(materialOrder.date),
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите дату списания!"
                    }
                  ]
                })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ minHeight: "40px" }}>
            <Col span={24}>
              {hasSelected ? (
                <Button onClick={this.onDeleteItems}>Удалить</Button>
              ) : null}
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                rowSelection={rowSelection}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={materialOrderItems}
                columns={columns}
                pagination={false}
                rowKey="id"
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

MaterialOrderModalForm.propTypes = {
  workOrders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(MaterialOrderModalForm);
