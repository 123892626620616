// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input } from "antd";


const FormItem = Form.Item;


class Header extends Component {
  render() {
    const {
      department,
      getFieldDecorator,
      onChangeName,
      onChangeFullName
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={6}>
            <FormItem label={"Наименование"}>
              {getFieldDecorator("name", {
                onChange: onChangeName,
                initialValue: department.name,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите наименование!"
                  }
                ]
              })(<Input placeholder="введите код" />)}
            </FormItem>
          </Col>

          <Col span={18}>
            <FormItem label={"Полное наименование"}>
              {getFieldDecorator("full_name", {
                onChange: onChangeFullName,
                initialValue: department.full_name,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите наименование!"
                  }
                ]
              })(<Input placeholder="введите наименование" />)}
            </FormItem>
          </Col>


        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
