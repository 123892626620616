import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  firms: [],
  firm: { firm_items: [] },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_FIRMS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_FIRMS":
      return {
        ...state,
        firms: action.response.data,
        isLoading: false,
      };
    case "FETCH_FIRM_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_FIRM":
      return {
        ...state,
        firm: action.response.data,
        isLoading: false,
      };

    case "PATCH_FIRM_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_FIRM":
      return {
        ...state,
        errors: false,
        firm: action.response.data,
        isLoading: false,
      };

    case "PATCH_FIRM_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    // header
    case "FIRM_CHANGE_NAME":
      return update(state, {
        firm: { name: { $set: action.value } },
      });

    case "FIRM_CHANGE_FULL_NAME":
      return update(state, {
        firm: { full_name: { $set: action.value } },
      });

    case "FIRM_CHANGE_PREFIX":
      return update(state, {
        firm: { prefix: { $set: action.value } },
      });

    case "FIRM_CHANGE_INN":
      return update(state, {
        firm: { inn: { $set: action.value } },
      });

    case "FIRM_CHANGE_KPP":
      return update(state, {
        firm: { kpp: { $set: action.value } },
      });

    case "FIRM_CHANGE_OGRN":
      return update(state, {
        firm: { ogrn: { $set: action.value } },
      });

    case "FIRM_CHANGE_OKPO":
      return update(state, {
        firm: { okpo: { $set: action.value } },
      });

    case "FIRM_CHANGE_CEO":
      return update(state, {
        firm: { ceo: { $set: action.value } },
      });

    case "FIRM_CHANGE_CEO_TITLE":
      return update(state, {
        firm: { ceo_title: { $set: action.value } },
      });

    case "FIRM_CHANGE_CFO":
      return update(state, {
        firm: { cfo: { $set: action.value } },
      });

    case "FIRM_CHANGE_CFO_TITLE":
      return update(state, {
        firm: { cfo_title: { $set: action.value } },
      });

    case "FIRM_CHANGE_BANK_ACCOUNT_PRIMARY":
      return update(state, {
        firm: { bank_account_primary: { $set: action.value } },
      });

    case "FIRM_CHANGE_BANK_ACCOUNT":
      idx = _.findIndex(state.firm.bank_accounts, ["id", action.item.id]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        firm: {
          bank_accounts: {
            [idx]: { $set: action.item },
          },
        },
      });

    case "FIRM_CHANGE_EMAIL":
      idx = _.findIndex(state.firm.emails, ["id", action.item.id]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        firm: {
          emails: {
            [idx]: { $set: action.item },
          },
        },
      });

    default:
      return state;
  }
}
