// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Select, Button } from "antd";
import { Row, Col, PageHeader } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      data,
      fetchParams,
      onChangeDate,
      downloadReport,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    const columns = [
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{name}</span>
            <span style={{ opacity: "0.7" }}>{record.description}</span>
          </div>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        align: "left",
        width: "30%",
      },
      {
        title: "Количество",
        dataIndex: "quantity",
        render: (quantity, record) =>
          accounting.formatNumber(Number(quantity), 0, " "),
        align: "right",
        width: "15%",
      },
      {
        title: "Номер заказа",
        dataIndex: "number",
        render: (number, record) => {
          return (
            <Link target={"_blank"} to={`/orders/${record.order_id}/edit`}>
              {number}
            </Link>
          );
        },
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.number.localeCompare(b.number),
        sortDirections: ["ascend", "descend"],
        align: "center",
        width: "15%",
      },
      {
        title: "Заказчик",
        dataIndex: "company",
        sorter: (a, b) => a.company.localeCompare(b.company),
        sortDirections: ["ascend", "descend"],
        align: "center",
        width: "25%",
      },
      {
        title: "Статус",
        dataIndex: "status",
        align: "center",
        width: "15%",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Незавершенное производство на ${fetchParams.endDate}`}
        extra={[
          <Button
            key="3"
            type="dashed"
            icon="file-excel"
            onClick={downloadReport}
          >
            Загрузить
          </Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={data}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <DatePicker
              style={{ width: "100%" }}
              value={moment(fetchParams.endDate, dateFormat)}
              format={dateFormat}
              onChange={onChangeDate}
            />
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
