import api from "../api";

const Actions = {};

Actions.fetchPayments = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PAYMENTS_REQUEST" });
    const params = getState().payments.fetchParams;
    let companies = [];
    if (params.companies) {
      companies = params.companies.map(company => {
        if (company && company.id) {
          return company.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
      page: params.page,
      limit: params.limit,
      type: params.type,
      companies: companies
    };

    return api
      .fetch("/payments", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_PAYMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_PAYMENTS_FAILURE", response });
      });
  };
};

Actions.fetchPayment = (id) => {
  return dispatch => {
    dispatch({ type: "FETCH_PAYMENT_REQUEST" });
    api
      .fetch(`/payments/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_PAYMENT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "FETCH_PAYMENT_FAILURE", response });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PAYMENT_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PAYMENT_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

// filters
Actions.onSetFilters = () => {
  return dispatch => {
    dispatch({
      type: "PAYMENTS_SET_FILTERS"
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return dispatch => {
    dispatch({
      type: "PAYMENTS_CLEAR_FILTERS"
    });
    return Promise.resolve();
  };
};


Actions.onChangeFilterType = value => {
  return dispatch => {
    dispatch({
      type: "PAYMENTS_FILTER_TYPE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: "PAYMENTS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCompanies = values => {
  return dispatch => {
    dispatch({
      type: "PAYMENTS_FILTER_COMPANIES",
      values: values
    });
    return Promise.resolve();
  };
};



export default Actions;
