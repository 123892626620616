// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment()
          .startOf("month")
          .format("DD.MM.YYYY"),
        endDate: moment().format("DD.MM.YYYY"),
      },
      period: 2,
      height: 0, 
      hasError: false
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log(error, info);
  }

  componentWillMount = () => {
    this.props.dispatch(
      Actions.fetchShipmentsByCategoriesOwn(this.state.fetchParams)
    );
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.dispatch(
        Actions.fetchShipmentsByCategoriesOwn(this.state.fetchParams)
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }


  onChangeDateRange = dates => {
    this.state.fetchParams.startDate = moment(dates[0]).format('L');
    this.state.fetchParams.endDate = moment(dates[1]).format('L');
    this.setState({ fetchParams: this.state.fetchParams, period: 0 }, function() {
      this.props.dispatch(Actions.fetchSalesByPeriods(this.state.fetchParams));
    });
  };

  onChangePeriod = value => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 1:
        startDate = moment()
          .startOf("months")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 2:
        startDate = moment()
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 3:
        startDate = moment()
          .startOf("years")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "years")
          .endOf("year")
          .format("DD.MM.YYYY");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
    }
    this.state.fetchParams.startDate = startDate;
    this.state.fetchParams.endDate = endDate;
    this.setState(
      { fetchParams: this.state.fetchParams, period: value },
      function() {
        this.props.dispatch(
          Actions.fetchShipmentsByCategoriesOwn(this.state.fetchParams)
        );
      }
    );
  };

  downloadReport = () => {
    Actions.downloadReport(this.state.fetchParams, 'shipments_by_categories_own.xlsx', 'shipments_by_categories_own.xlsx'); 
  }

  render() {
    const { isLoading, report } = this.props;
    const { fetchParams, period, height } = this.state;
    return this.state.hasError ? (
      <span>ошибка</span>
    ) : (
      <DataList
        loading={isLoading}
        report={report}
        fetchParams={fetchParams}
        period={period}
        height={height}
        onChangeDateRange={this.onChangeDateRange}
        onChangePeriod={this.onChangePeriod}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.reports.isLoading,
  report: state.reports.shipmentsByCategoriesOwn
});

export default connect(mapStateToProps)(Report);
