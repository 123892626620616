// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../../actions/departments";
import DepartmentsList from "./List";

class Departments extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchDepartments());
  };

  onDelete = id => {
    this.props
      .dispatch(Actions.onDelete(id))
      .then(() => this.props.dispatch(Actions.fetchDepartments()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchDepartments()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchDepartments()));
  };

  render() {
    const { isLoading, departments, meta, fetchParams } = this.props;
    return (
      <DepartmentsList
        loading={isLoading}
        departments={departments}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
      />
    );
  }
}

Departments.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.departments.isLoading,
  departments: state.departments.departments,
  meta: state.departments.meta,
  fetchParams: state.departments.fetchParams,
});

export default connect(mapStateToProps)(Departments);
