// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

class Payments extends Component {
  
  render() {
    const {
      purchasePayments
    } = this.props;


    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        key: "date",
        render: (date, record) => (
          <div>
            <div>{record.outpayment ? moment(record.outpayment.date).format("L") : null}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Номер",
        dataIndex: "number",
        key: "number",
        render: (number, record) => (
          <div>
            <div>{record.outpayment ? record.outpayment.number : ""}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Номер п/п",
        dataIndex: "payment_number",
        render: (payment_number, record) => (
          <div>
            <div>{record.outpayment ? record.outpayment.payment_number : ""}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Счет",
        dataIndex: "invoice",
        key: "invoice",
        render: (invoice, record) => (
          <div>
           
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        key: "amount",
        render: (amount, record) =>
          accounting.formatNumber(
            Number(amount),
            0,
            " "
          ),
        width: "20%",
        align: "center"
      }
    ];

    return (
      <div>
        
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={purchasePayments}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Payments.propTypes = {
  orderPayments: PropTypes.arrayOf(PropTypes.object)
};
export default Payments;
