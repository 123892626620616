// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/workOrders";
import MaterialOrdersActions from "../../../actions/materialOrders";
import PurchasesActions from "../../../actions/purchases";
import { Spin } from "antd";
import WorkOrderForm from "./Form";
import moment from "moment";

class WorkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false, isVisibleMaterialOrder: false };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchWorkOrder(this.props.match.params.id));
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/workOrders/${this.props.workOrder.id}/edit`
          );
        }
      });
    }
  };

  downloadReport = (printForm) => {
    if (this.props.match.params.id) {
      Actions.downloadReport(
        this.props.match.params.id,
        this.props.workOrder.number,
        printForm
      );
    }
  };

  onDelete = (id) => {
    this.props.dispatch(Actions.deleteWorkOrder(id));
  };

  // General
  onChangeDate = (value) => {
    this.props.dispatch(Actions.onUpdateDate(this.props.match.params.id, value));
  };

  onChangeNumber = (e) => {
    this.props.dispatch(Actions.onUpdateNumber(this.props.match.params.id, e.target.value));
  };

  onChangeStatus = (value) => {
    // console.log(item)
    this.props.dispatch(Actions.onUpdateStatus(this.props.match.params.id, value));
  };

  onChangeAssignee = (item) => {
    // console.log(item)
    this.props.dispatch(Actions.onUpdateAssignee(this.props.match.params.id, item));
  };

  // orderItems
  onChangeStocked = (idx, value) => {
    this.props.dispatch(
      Actions.onChangeStocked(idx, moment(value).startOf("day").toString())
    );
  };

  onFillBySpecification = (orderItemID, productID) => {
    this.props.dispatch(Actions.onFillBySpecification(orderItemID, productID));
  };

  // work_items
  onSaveWorkOrderItem = (item) => {
    if (item.id) {
      this.props
        .dispatch(
          Actions.onUpdateWorkOrderItem(this.props.match.params.id, item)
        )
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchWorkOrderItems(this.props.match.params.id)
            );
          }
        });
    } else {
      this.props
        .dispatch(
          Actions.onCreateWorkOrderItem(this.props.match.params.id, item)
        )
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchWorkOrderItems(this.props.match.params.id)
            );
          }
        });
    }
  };

  onDeleteWorkOrderItems = (ids) => {
    ids.map(id => {
      this.props.dispatch(Actions.onDeleteWorkOrderItems(this.props.match.params.id, id)).then(() => {
        this.props.dispatch(Actions.fetchWorkOrderItems(this.props.match.params.id));
      });
    });
  };


  // purchases
  onCreatePurchase = (id, item) => {
    this.props.dispatch(PurchasesActions.onShowModalForm());
  };

  // purchasePaymentSchedules
  fetchPurchasePaymentSchedules = () => {
    this.props.dispatch(
      Actions.fetchPurchasePaymentSchedules(this.props.match.params.id)
    );
  };

  // materialOrders
  onDeleteMaterialOrders = (ids) => {
    
    ids.map((id) => {
      this.props
        .dispatch(MaterialOrdersActions.onDelete(id))
        .then(() => {
          this.props.dispatch(
            Actions.fetchWorkOrderItems(this.props.match.params.id)
          );
        })
        .then(() => {
          this.props.dispatch(
            MaterialOrdersActions.fetchMaterialOrders(
              this.props.match.params.id
            )
          );
        });
    });
    
  };

  fetchWorkOrderItems = () => {
    this.props.dispatch(
      Actions.fetchWorkOrderItems(this.props.match.params.id)
    );
  };

  onAddMaterialOrder = () => {
    this.props
      .dispatch(MaterialOrdersActions.onNew(this.props.match.params.id))
      .then(() => {
        this.props.dispatch(MaterialOrdersActions.onToggleVisible());
      });
  };

  onEditMaterialOrder = (id) => {
    // console.log(id);
    this.props
      .dispatch(MaterialOrdersActions.fetchMaterialOrder(id))
      .then(() => {
        this.props.dispatch(MaterialOrdersActions.onToggleVisible());
      });
  };

  onSaveMaterialOrder = (ids) => {
    if (this.props.materialOrder.id) {
      this.props
        .dispatch(MaterialOrdersActions.onUpdate(this.props.materialOrder.id))
        .then(() => {
          this.props.dispatch(
            MaterialOrdersActions.fetchMaterialOrders(
              this.props.match.params.id
            )
          );
        })
        .then(() => {
          this.props.dispatch(
            Actions.fetchWorkOrderItems(this.props.match.params.id)
          );
        })
        .then(() => {
          this.props.dispatch(MaterialOrdersActions.onToggleVisible());
        });
    } else {
      this.props
        .dispatch(MaterialOrdersActions.onCreate(ids))
        .then(() => {
          this.props.dispatch(
            MaterialOrdersActions.fetchMaterialOrders(
              this.props.match.params.id
            )
          );
        })
        .then(() => {
          this.props.dispatch(
            Actions.fetchWorkOrderItems(this.props.match.params.id)
          );
        })
        .then(() => {
          this.props.dispatch(MaterialOrdersActions.onToggleVisible());
        });
    }
  };

  onCloseMaterialOrderModal = () => {
    this.setState({ isVisibleMaterialOrder: false });
  };

  fetchMaterialOrders = () => {
    this.props.dispatch(
      MaterialOrdersActions.fetchMaterialOrders(this.props.match.params.id)
    );
  };

  render() {
    const {
      isLoading,
      workOrder,
      isMaterialOrdersLoading,
      isWorkOrderItemsLoading,
      materialOrders,
      isPurchasePaymentSchedulesLoading,
      purchasePaymentSchedules,
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <WorkOrderForm
        isNew={this.state.isNew}
        workOrder={workOrder}
        onSave={this.onSave}
        downloadReport={this.downloadReport}
        isMaterialOrdersLoading={isMaterialOrdersLoading}
        onChangeStatus={this.onChangeStatus}
        onChangeDate={this.onChangeDate}
        onChangeNumber={this.onChangeNumber}
        onChangeAssignee={this.onChangeAssignee}
        onChangeStocked={this.onChangeStocked}
        onFillBySpecification={this.onFillBySpecification}
        // purchasePaymentSchedules
        isPurchasePaymentSchedulesLoading={isPurchasePaymentSchedulesLoading}
        purchasePaymentSchedules={purchasePaymentSchedules}
        fetchPurchasePaymentSchedules={this.fetchPurchasePaymentSchedules}
        // materialOrders
        isVisibleMaterialOrder={this.state.isVisibleMaterialOrder}
        onDeleteMaterialOrders={this.onDeleteMaterialOrders}
        onAddMaterialOrder={this.onAddMaterialOrder}
        onEditMaterialOrder={this.onEditMaterialOrder}
        onSaveMaterialOrder={this.onSaveMaterialOrder}
        fetchMaterialOrders={this.fetchMaterialOrders}
        onCloseMaterialOrderModal={this.onCloseMaterialOrderModal}
        materialOrders={materialOrders}
        // purchases
        onCreatePurchase={this.onCreatePurchase}
        // work_items
        isWorkOrderItemsLoading={isWorkOrderItemsLoading}
        onSaveWorkOrderItem={this.onSaveWorkOrderItem}
        onDeleteWorkOrderItems={this.onDeleteWorkOrderItems}
      />
    );
  }
}

WorkOrder.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  workOrder: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.workOrders.isLoading,
  errors: state.workOrders.errors,
  workOrder: state.workOrders.workOrder,
  isMaterialOrdersLoading: state.materialOrders.isLoading,
  isWorkOrderItemsLoading: state.materialOrders.isWorkOrderItemsLoading,
  isPurchasePaymentSchedulesLoading:
    state.materialOrders.isPurchasePaymentSchedulesLoading,
  purchasePaymentSchedules: state.workOrders.purchasePaymentSchedules,
  errors: state.materialOrders.errors,
  materialOrder: state.materialOrders.materialOrder,
  materialOrders: state.materialOrders.materialOrders,
});

export default connect(mapStateToProps)(withRouter(WorkOrder));
