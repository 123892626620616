// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

class WorkOrderItems extends Component {
  
  render() {
    const {
      workOrderItems
    } = this.props;


    const columns = [
      {
        title: "Наименование",
        dataIndex: "product",
        render: (product, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column-reverse" }}>
              <span style={{ fontSize: "12px" }}>{record.description}</span>
              <span>
                <Link
                  to={`/categories/${
                    record.product.extra.product_category_id
                  }/products/${record.product.id}/edit`}
                >
                  {record.product.text}
                </Link>
              </span>
            </div>
          );
        },
        width: "60%"
      },
      {
        title: "Заказ-наряд",
        dataIndex: "number",
        width: "20%",
        align: "center"
      },
      {
        title: "Кол-во",
        dataIndex: "quantity",
        render: (quantity, record) => {
          return accounting.formatNumber(Number(quantity), 0, " ");
        },
        width: "20%",
        align: "center"
      },
      
    ];

    return (
      <div>
        
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={workOrderItems}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

WorkOrderItems.propTypes = {
  orderWorkOrderItems: PropTypes.arrayOf(PropTypes.object)
};
export default WorkOrderItems;
