// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/orders";
import OrdersList from "./OrdersList";

class Orders extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchOrders());
    });
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchOrders());
      });
    });
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchOrders()));
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterStatus = (values) => {
    this.props.dispatch(Actions.onChangeFilterStatus(values)).then(() => {
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterSourceType = (values) => {
    this.props.dispatch(Actions.onChangeFilterSourceType(values)).then(() => {
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterShippingPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterShippingPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterAssignee = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterAssignee(values))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterDownloadRows = (e) => {
    this.props
      .dispatch(Actions.onChangeFilterDownloadRows(e.target.checked))
      .then(() =>
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        )
      );
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadReport(
      params,
      "orders.xlsx",
      params.startDate
        ? `orders ${params.startDate}-${params.endDate}.xlsx`
        : "orders.xlsx"
    );
  };

  render() {
    const { isLoading, orders, meta, fetchParams } = this.props;
    return (
      <OrdersList
        loading={isLoading}
        orders={orders}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterSourceType={this.onChangeFilterSourceType}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterShippingPeriod={this.onChangeFilterShippingPeriod}
        onChangeFilterAssignee={this.onChangeFilterAssignee}
        onChangeFilterDownloadRows={this.onChangeFilterDownloadRows}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.orders.isLoading,
  orders: state.orders.orders,
  meta: state.orders.meta,
  fetchParams: state.orders.fetchParams,
});

export default connect(mapStateToProps)(Orders);
