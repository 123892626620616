import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  orders: [],
  order: {
    work_order: {
      id: null,
      text: "",
    },
    order_items: [],
    shipments: [],
    order_payment_schedules: [],
    invoices: [],
  },
  vatRates: [],
  units: [],
  details: {
    order: null,
    work_order: null,
    shipments: [],
    material_orders: [],
  },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    startShippingDate: null,
    endShippingDate: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    status: null,
    source_type: null,
    assignees: null,
    company: null,
    isDownloadRows: false,
    limit: 15,
  },
  errors: false,
  isFillInWithInvoice: false,
  isLoading: true,
  isLoadingWorkOrder: false,
  isLoadingInfo: false,
};

function recalculationItem(item) {
  let price = Number(item.price);
  let quantity = Number(item.quantity);
  let vat_rate = item.vat_rate ? Number(item.vat_rate.extra.rate) : 0;
  let amount = Number(quantity * price).toFixed(2);
  let amount_vat = Number((amount / 100) * vat_rate).toFixed(2);

  return {
    price: price,
    quantity: quantity,
    amount: amount,
    amount_vat: amount_vat,
  };
}

function loadLocalOrdersFilters(fetchParams) {
  let localFetchParams = null;
  const ordersFilters = localStorage.getItem("ordersFilters");
  if (ordersFilters) {
    localFetchParams = JSON.parse(ordersFilters);
  } else {
    localStorage.setItem("ordersFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let idx = -1;
  let recalcItem = {};
  let orderItemsTotal = 0;
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_ORDERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORDERS":
      return {
        ...state,
        orders: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORDER":
      return {
        ...state,
        order: action.response.data,
        isLoading: false,
      };

    // details
    case "FETCH_ORDER_DETAILS_REQUEST":
      return {
        ...state,
        isLoadingInfo: true,
      };

    case "FETCH_ORDER_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingInfo: false,
      };

    case "FETCH_ORDER_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingInfo: false,
      };

    case "FETCH_ORDER_DETAILS_FAILURE":
      return {
        ...state,
        errors: true,
        isOrderItemsLoading: true,
      };
    case "FETCH_ORDER_ITEMS":
      return update(state, {
        order: {
          order_items: { $set: action.response.data },
        },
        isOrderItemsLoading: { $set: false },
      });

    case "FETCH_ORDER_ITEMS_FAILURE":
      return {
        ...state,
        errors: true,
        isOrderItemsLoading: false,
      };

    case "PATCH_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_ORDER":
      return {
        ...state,
        errors: false,
        order: action.response.data,
        isLoading: false,
      };

    case "PATCH_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_ORDER":
      return {
        ...state,
        errors: false,
        order: action.response.data,
        isLoading: false,
      };

    case "POST_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "ORDER_FILL_IN_WITH_INVOICE":
      return {
        ...state,
        isFillInWithInvoice: true,
        order: action.data.order,
      };

    case "ORDER_NEW":
      return {
        ...state,
        errors: false,
        order: state.isFillInWithInvoice
          ? state.order
          : {
              status: "approving",
              number: null,
              date: moment(),
              agreement: null,
              additional: null,
              vat_included: false,
              total: 0,
              vat_total: 0,
              ship_address: null,
              shipping_address: null,
              special_instruction: null,
              company: null,
              assignee: null,
              work_order: null,
              order_items: [],
              order_payment_schedules: [],
              shipments: [],
              invoices: [],
            },
        isLoading: false,
        isFillInWithInvoice: false,
      };

    case "POST_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_ORDER":
      return {
        ...state,
        errors: false,
        order: action.response.data,
        isLoading: false,
      };
    case "POST_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_WORK_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadingWorkOrder: true,
      };
    case "POST_WORK_ORDER":
      return update(state, {
        order: { work_order: { $set: action.response.data } },
        isLoadingWorkOrder: { $set: false },
      });

    case "POST_WORK_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadingWorkOrder: false,
      };

    case "DELETE_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_ORDER":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "REQUEST_ORDER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_ORDER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_CHANGE_FILTER_COMPANY":
      return update(state, {
        fetchParams: {
          company: { $set: action.value },
          page: { $set: 1 },
          product_id: { $set: null },
        },
      });

    case "REQUEST_ORDER_FILTER_ASSIGNEE":
      return update(state, {
        fetchParams: { assignee: { $set: action.value } },
      });

    case "REQUEST_ORDER_FILTER_STATUS":
      return update(state, {
        fetchParams: { status: { $set: action.value } },
      });

    case "REQUEST_ORDER_FILTER_PERIOD":
      return update(state, {
        fetchParams: { currentYear: { $set: action.value } },
      });

    case "ORDER_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          assignee: { $set: null },
          status: { $set: null },
          company_id: { $set: null },
          product_id: { $set: null },
        },
      });

    case "ORDERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "ORDER_CHANGE_DATE":
      return update(state, {
        order: { date: { $set: action.value } },
      });

    case "ORDER_CHANGE_STATUS":
      return update(state, {
        order: { status: { $set: action.value } },
      });

    case "ORDER_CHANGE_SOURCE_TYPE":
      return update(state, {
        order: { source_type: { $set: action.value } },
      });

    case "ORDER_CHANGE_REQUEST_PROPOSAL":
      return update(state, {
        order: { request_proposal: { $set: action.value } },
      });

    case "ORDER_CHANGE_NUMBER":
      return update(state, {
        order: { number: { $set: action.value } },
      });

    case "ORDER_CHANGE_ASSIGNEE":
      return update(state, {
        order: { assignee: { $set: action.value } },
      });

    case "ORDER_CHANGE_COMPANY":
      return update(state, {
        order: {
          company: { $set: action.value },
          request_proposal: { $set: null },
        },
      });

    case "ORDER_CHANGE_AGREEMENT":
      return update(state, {
        order: { agreement: { $set: action.value } },
      });

    case "ORDER_CHANGE_AGREEMENT_DATE":
      return update(state, {
        order: {
          agreement_date: { $set: moment(action.value, '"L"').add(6, "hours") },
        },
        // order: { agreement_date: { $set: action.value } }
      });

    case "ORDER_CHANGE_ADDITIONAL":
      return update(state, {
        order: { additional: { $set: action.value } },
      });

    case "ORDER_CHANGE_SHIP_ADDRESS":
      return update(state, {
        order: { ship_address: { $set: action.value } },
      });

    case "ORDER_CHANGE_SHIPPING_ADDRESS":
      return update(state, {
        order: { shipping_address: { $set: action.value } },
      });

    case "ORDER_CHANGE_SPECIAL_INSTRUCTION":
      return update(state, {
        order: { special_instruction: { $set: action.value } },
      });

    // items
    case "ORDER_ADD_ITEM":
      return update(state, {
        order: {
          order_items: {
            $push: [
              {
                id: Date.now(),
                position: state.order.order_items.length + 1,
                description: "",
                product: {
                  id: null,
                  text: "выберите продукт",
                },
                vat_rate: {
                  id: null,
                  text: "",
                },
                quantity: 1,
                price: 0,
                amount: 0,
                amount_vat: 0,
                will_shipped: moment(),
                will_shipped_days: 0,
                shipped: 0,
                shipped_quantity: 0,
                is_exist: false,
                _destroy: false,
              },
            ],
          },
        },
      });

    case "ORDER_DELETE_ITEM":
      return update(state, {
        order: {
          order_items: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "ORDER_DESTROY_ITEM":
      return update(state, {
        order: {
          order_items: { $splice: [[action.idx, 1]] },
        },
      });

    case "ORDER_CHANGE_ITEM_PRODUCT":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              product: { $set: action.item },
              description: {
                $set: state.order.order_items[idx].description
                  ? state.order.order_items[idx].description
                  : action.item
                  ? action.item.text
                  : null,
              },
              unit: { $set: action.item.extra.unit },
              vat_rate: { $set: action.item.extra.vat_rate },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_DESCRIPTION":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              description: { $set: action.value },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_UNIT":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              unit: { $set: action.item },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_QUANTITY":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: state.order.order_items[idx].price,
        quantity: action.value,
        vat_rate: state.order.order_items[idx].vat_rate,
        amount: state.order.order_items[idx].amount,
        amount_vat: state.order.order_items[idx].amount_vat,
      });

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              quantity: { $set: action.value },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_PRICE":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: action.value,
        quantity: state.order.order_items[idx].quantity,
        vat_rate: state.order.order_items[idx].vat_rate,
        amount: state.order.order_items[idx].amount,
        amount_vat: state.order.order_items[idx].amount_vat,
      });

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              price: { $set: action.value },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_VAT_RATE":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: state.order.order_items[idx].price,
        quantity: state.order.order_items[idx].quantity,
        vat_rate: action.item,
        amount: state.order.order_items[idx].amount,
        amount_vat: state.order.order_items[idx].amount_vat,
      });

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              vat_rate: { $set: action.item },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_VAT":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              amount_vat: { $set: action.value },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_WILL_SHIPPED":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              will_shipped: { $set: action.value },
              will_shipped_days: {
                $set: action.value
                  ? moment(action.value)
                      .startOf("d")
                      .diff(moment(state.order.date).startOf("d"), "d")
                  : 0,
              },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_WILL_SHIPPED_DAYS":
      idx = _.findIndex(state.order.order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_items: {
            [idx]: {
              will_shipped_days: { $set: action.value },
              will_shipped: {
                $set: moment().add(action.value, "d"),
              },
            },
          },
        },
      });

    // paymentSchedules
    case "ORDER_ADD_ITEM_PAYMENT_SCHEDULES":
      orderItemsTotal = state.order.order_items.reduce(
        (sum, record) =>
          sum + Number(record.amount) + Number(record.amount_vat),
        0
      );
      let amountTotal = state.order.order_payment_schedules.reduce(
        (sum, record) => sum + Number(record.amount),
        0
      );
      let percentTotal = state.order.order_payment_schedules.reduce(
        (sum, record) => sum + Number(record.percent),
        0
      );
      return update(state, {
        order: {
          order_payment_schedules: {
            $push: [
              {
                id: Date.now(),
                date: moment(),
                payment_type: 1,
                amount: orderItemsTotal - amountTotal,
                percent: 100 - percentTotal,
                is_exist: false,
                _destroy: false,
              },
            ],
          },
        },
      });

    case "ORDER_DELETE_ITEM_PAYMENT_SCHEDULES":
      return update(state, {
        order: {
          order_payment_schedules: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "ORDER_DESTROY_ITEM_PAYMENT_SCHEDULES":
      return update(state, {
        order: {
          order_payment_schedules: { $splice: [[action.idx, 1]] },
        },
      });

    case "ORDER_CHANGE_ITEM_PAYMENT_SCHEDULES_DATE":
      idx = _.findIndex(state.order.order_payment_schedules, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_payment_schedules: {
            [idx]: {
              date: { $set: action.value },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_PAYMENT_PAYMENT_TYPE":
      idx = _.findIndex(state.order.order_payment_schedules, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_payment_schedules: {
            [idx]: {
              payment_type: { $set: action.item.value },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_PAYMENT_SCHEDULES_AMOUNT":
      idx = _.findIndex(state.order.order_payment_schedules, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        order: {
          order_payment_schedules: {
            [idx]: {
              amount: { $set: action.value },
            },
          },
        },
      });

    case "ORDER_CHANGE_ITEM_PAYMENT_SCHEDULES_PERCENT":
      idx = _.findIndex(state.order.order_payment_schedules, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      orderItemsTotal = state.order.order_items.reduce(
        (sum, record) =>
          sum + Number(record.amount) + Number(record.amount_vat),
        0
      );
      let amount = Number((orderItemsTotal / 100) * action.value).toFixed(2);

      return update(state, {
        order: {
          order_payment_schedules: {
            [idx]: {
              percent: { $set: action.value },
              amount: { $set: amount },
            },
          },
        },
      });

    // filters
    case "ORDERS_CLEAR_FILTERS":
      localStorage.removeItem("ordersFilters");

      return update(state, {
        fetchParams: {
          status: { $set: null },
          assignees: { $set: null },
        },
      });

    case "ORDERS_SET_FILTERS":
      localFetchParams = loadLocalOrdersFilters(state.fetchParams);
      if (localFetchParams) {
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "ORDERS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_SOURCE_TYPE":
      return update(state, {
        fetchParams: {
          source_type: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_SHIPPING_PERIOD":
      return update(state, {
        fetchParams: {
          startShippingDate: { $set: action.startDate },
          endShippingDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_ASSIGNEES":
      return update(state, {
        fetchParams: {
          assignees: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_DOWNLOAD_ROWS":
      return update(state, {
        fetchParams: {
          isDownloadRows: { $set: action.value },
        },
      });

    default:
      return state;
  }
}
