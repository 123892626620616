// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, InputNumber, Select } from "antd";
import PropTypes from "prop-types";
import SelectFetch from "../../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class ModalForm extends Component {
  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      city,
      visible,
      onCancel,
      onChangeName,
      onChangeState,
      onChangeCityType,
      onChangeLat,
      onChangeLon,
      onChangeFias,
      onChangeKladr
    } = this.props;

    return (
      <Modal
        title={`Город #${city.id ? city.name : "новая"} `}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={12}>
              <FormItem label={"Регион"}>
                {getFieldDecorator("state", {
                  initialValue: city.state ? city.state : null,
                  rules: [
                    {
                      required: true,
                      message: "Выберите регион!"
                    }
                  ]
                })(
                  <SelectFetch
                    showSubtitle
                    showSearch
                    placeholder="Регион"
                    onChange={onChangeState}
                    selected={city.state ? city.state : null}
                    url={"/states/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: city.name,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите наименование!"
                    }
                  ]
                })(<Input placeholder="введите наименование" />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("city_type", {
                  onChange: onChangeCityType,
                  initialValue: city.city_type
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={"city"}>г</Option>
                    <Option value={"rural_1"}>с</Option>
                    <Option value={"rural_2"}>пос</Option>
                    <Option value={"rural_3"}>д</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Широта"}>
                {getFieldDecorator("lat", {
                  onChange: onChangeLat,
                  initialValue: city.lat
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    precision={4}
                    min={0}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Широта"}>
                {getFieldDecorator("lon", {
                  onChange: onChangeLon,
                  initialValue: city.lon
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    precision={4}
                    min={0}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  city: PropTypes.object
};

export default Form.create()(ModalForm);
