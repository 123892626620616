// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Icon, Tooltip } from "antd";
import NavSearch from "../../components/Navsearch";

const { Header } = Layout;

class MainHeader extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  onMenuClick = () => {};

  render() {
    const {
      isSidebarCollapsed,
      onToggleSideBar,
      currentUser,
      onLogout
    } = this.props;
    const menu = (
      <Menu className={"menu"} selectedKeys={[]} onClick={this.onMenuClick}>
        <Menu.Item>
          <Link to={"/profile"}>Профиль</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={onLogout}>
          <Icon type="logout" />
          Выход
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Header
          className="main-header"
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Tooltip
            placement="bottom"
            title={isSidebarCollapsed ? "Развернуть меню" : "Свернуть меню"}
          >
            <Icon
              className="trigger custom_trigger"
              type={isSidebarCollapsed ? "menu-unfold" : "menu-fold"}
              onClick={onToggleSideBar}
            />
          </Tooltip>
          <div className={"right"} style={{ paddingRight: `${isSidebarCollapsed ? "100px" : "220px"}` }}>
            {currentUser ? (
              <Dropdown overlay={menu}>
                <span className={`${"action"} ${"account"}`}>
                  <Avatar
                    size="small"
                    className={"avatar"}
                    src={currentUser.avatar}
                  />
                  <span className={"name"}>{currentUser.name}</span>
                </span>
              </Dropdown>
            ) : null}
          </div>
          <div className={"right"}>
            <NavSearch />
          </div>
        </Header>
      </div>
    );
  }
}

MainHeader.propTypes = {
  isLoading: PropTypes.bool,
  isSidebarCollapsed: PropTypes.bool,
  onToggleSideBar: PropTypes.func,
  onLogout: PropTypes.func,
  currentUser: PropTypes.object
};

export default MainHeader;
