import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  businessFields: [],
  businessField: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isModalVisible: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_BUSINESS_FIELDS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_BUSINESS_FIELDS":
      return {
        ...state,
        businessFields: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_BUSINESS_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_BUSINESS_FIELD":
      return {
        ...state,
        businessField: action.response.data,
        isLoading: false,
        isModalVisible: true
      };
    case "PATCH_BUSINESS_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_BUSINESS_FIELD":
      return {
        ...state,
        businessField: action.response.data,
        isLoading: false,
        isModalVisible: false
      };

    case "POST_BUSINESS_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_BUSINESS_FIELD":
      return {
        ...state,
        errors: false,
        businessField: {},
        isLoading: false,
        isModalVisible: false
      };

    case "POST_BUSINESS_FIELD_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "BUSINESS_FIELD_ADD":
      return {
        ...state,
        businessField: {
          name: null
        },
        errors: false,
        isLoading: false,
        isModalVisible: true
      };

    case "DELETE_BUSINESS_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_BUSINESS_FIELD":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_BUSINESS_FIELD_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_BUSINESS_FIELD_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_BUSINESS_FIELD_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "BUSINESS_FIELD_TOGGLE_VISIBLE":
      return {
        ...state,
        isModalVisible: !state.isModalVisible
      };

    case "BUSINESS_FIELD_CHANGE_NAME":
      return update(state, {
        businessField: {
          name: { $set: action.value }
        }
      });

    default:
      return state;
  }
}
