// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, Checkbox, Select } from "antd";
import SelectFetch from "../../../components/SelectFetch";

import "moment/locale/ru";

const FormItem = Form.Item;
const Option = Select.Option;

class Category extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isLoading,
      visible,
      onCancel,
      category,
      onChangeName,
      onChangeActive,
      onChangeSuggest,
    } = this.props;
    return (
      <Modal
        title={"Категория"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: category.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem>
                {getFieldDecorator("is_active", {
                  initialValue: category.is_active,
                  valuePropName: "checked",
                  onChange: onChangeActive,
                })(<Checkbox>показывать при подборе</Checkbox>)}
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem>
                {getFieldDecorator("is_suggest", {
                  initialValue: category.is_suggest,
                  valuePropName: "checked",
                  onChange: onChangeSuggest,
                })(<Checkbox>показывать при подборе в заказ-наряде</Checkbox>)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Category);
