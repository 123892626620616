// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableSelectCell from "../../../components/EditableCells/EditableSelectCell";
import EditableDateCell from "../../../components/EditableCells/EditableDateCell";

const itemTypes = [
  { value: 1, text: "аванс (до начала работ)" },
  { value: 2, text: "предоплата (до отгрузки)" },
  { value: 3, text: "оплата (после отгрузки)" }
];

class PaymentSchedules extends Component {
  state = { isOpen: false, selectedRowKeys: [] };

  onSelectOption = item => {
    this.onCloseDropdown();
    this.props.onAddItem(item);
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteItems = () => {
    this.props.onDeletePaymentSchedules(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      paymentSchedules,
      onAddPaymentSchedule,
      onChangePaymentSchedulesDate,
      onChangePaymentSchedulesPaymentType,
      onChangePaymentSchedulesAmount,
      onChangePaymentSchedulesPercent
    } = this.props;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        key: "date",
        render: (date, record) => (
          <EditableDateCell
            recordId={record.id}
            value={date}
            onChange={onChangePaymentSchedulesDate}
          />
        ),
        width: "25%",
        align: "center"
      },
      {
        title: "Тип",
        dataIndex: "payment_type",
        key: "payment_type",
        render: (payment_type, record) => (
          <EditableSelectCell
            recordId={record.id}
            value={payment_type}
            items={itemTypes}
            onChange={onChangePaymentSchedulesPaymentType}
          />
        ),
        width: "25%",
        align: "center"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        key: "amount",
        render: (amount, record) => (
          <EditableInputCell
            recordId={record.id}
            value={amount}
            onChange={onChangePaymentSchedulesAmount}
          />
        ),
        width: "25%",
        align: "center"
      },
      {
        title: "Процент оплаты",
        dataIndex: "percent",
        key: "percent",
        render: (percent, record) => (
          <EditableInputCell
            recordId={record.id}
            value={percent}
            onChange={onChangePaymentSchedulesPercent}
          />
        ),
        width: "25%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            {hasSelected ? (
              <Button onClick={this.onDeleteItems}>Удалить</Button>
            ) : (
              <Button onClick={onAddPaymentSchedule}>Добавить</Button>
            )}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={paymentSchedules}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PaymentSchedules.propTypes = {
  paymentSchedules: PropTypes.arrayOf(PropTypes.object)
};
export default PaymentSchedules;
