// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startShippingDate: moment().startOf("year").format("DD.MM.YYYY"),
        endShippingDate: moment().endOf("year").format("DD.MM.YYYY"),
        periodicity: "month",
      },
    };
  }

  componentWillMount = () => {
    this.setFilters().then(() => {
      this.props.dispatch(Actions.fetchPlanShipments(this.state.fetchParams));
    });
  };

  setFilters = () => {
    const filters = localStorage.getItem("planShipmentsFilters");
    if (filters) {
      this.setState({ fetchParams: JSON.parse(filters) });
    }
    return Promise.resolve();
  };

  onChangePeriodicity = (value) => {
    this.state.fetchParams.periodicity = value;
    this.setState({ fetchParams: this.state.fetchParams }, () => {
      localStorage.setItem(
        "planShipmentsFilters",
        JSON.stringify(this.state.fetchParams)
      );
    });
  };

  onChangeShippingPeriod = (dates) => {
    this.state.fetchParams.startShippingDate = moment(dates[0]).format("L");
    this.state.fetchParams.endShippingDate = moment(dates[1]).format("L");
    this.setState({ fetchParams: this.state.fetchParams }, () => {
      this.props
        .dispatch(Actions.fetchPlanShipments(this.state.fetchParams))
        .then(() => {
          localStorage.setItem(
            "planShipmentsFilters",
            JSON.stringify(this.state.fetchParams)
          );
        });
    });
  };

  downloadReport = () => {
    Actions.downloadReport(
      this.state.fetchParams,
      "planshipments.xlsx",
      "planshipments.xlsx"
    );
  };

  render() {
    const { isLoading, report } = this.props;
    const { fetchParams } = this.state;
    return (
      <DataList
        loading={isLoading}
        report={report}
        fetchParams={fetchParams}
        onChangePeriodicity={this.onChangePeriodicity}
        onChangeShippingPeriod={this.onChangeShippingPeriod}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  report: state.reports.report,
});

export default connect(mapStateToProps)(Report);
