// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../../actions/lossReasons";
import List from "./List";

class LossReasons extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchLossReasons());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchLossReasons());
      });
    });
  };

  onFetchLossReason = (id) => {
    this.props.dispatch(Actions.fetchLossReason(id));
  }

  onNew = () => {
    this.props.dispatch(Actions.onNew());
  }

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchLossReasons()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchLossReasons()));
  };

  render() {
    const { isLoading, isModalVisible, lossReasons, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        isModalVisible={isModalVisible}
        lossReasons={lossReasons}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onFetchLossReason={this.onFetchLossReason}
        onNew={this.onNew}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.lossReasons.isLoading,
  isModalVisible: state.lossReasons.isModalVisible,
  lossReasons: state.lossReasons.lossReasons,
  meta: state.lossReasons.meta,
  fetchParams: state.lossReasons.fetchParams
});

export default connect(mapStateToProps)(LossReasons);
