// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Tooltip,
  Menu,
  Dropdown,
  Icon,
} from "antd";
import { Tabs } from "antd";
import Documents from "../../../components/Documents";
import Statuses from "./Statuses";
import Activities from "../../../components/Activities";
import WorkOrderItems from "./WorkOrderItems";
import MaterialOrders from "./MaterialOrders";
import OrderItems from "./OrderItems";
import PurchasePaymentSchedules from "./PurchasePaymentSchedules";
import ModalPurchase from "../../Purchases/Modal";
import moment from "moment";
import "moment/locale/ru";

const TabPane = Tabs.TabPane;

class WorkOrderForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { tabIndex: "1" };
  }

  onChangeTab = (key) => {
    this.setState({ tabIndex: key }, () => {
      if (key == 2) {
        this.props.fetchMaterialOrders();
      } else if (key == 3) {
        this.props.fetchPurchasePaymentSchedules();
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      isNew,
      isLoading,
      downloadReport,
      workOrder,
      onClose,
      onChangeStatus,
      onChangeDate,
      onChangeNumber,
      onChangeAssignee,
      onChangeStocked,
      onFillBySpecification,
      isVisibleMaterialOrder,
      onCloseMaterialOrderModal,
      isMaterialOrdersLoading,
      onDeleteMaterialOrders,
      onAddMaterialOrder,
      onEditMaterialOrder,
      onSaveMaterialOrder,
      materialOrders,
      isPurchasePaymentSchedulesLoading,
      purchasePaymentSchedules,
      onCreatePurchase,
      // work_items
      isWorkOrderItemsLoading,
      onSaveWorkOrderItem,
      onDeleteWorkOrderItems,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const operations = (
      <Tooltip placement="topLeft" title={"Создать договор с поставщиком"}>
        <Button icon="plus-circle" onClick={onCreatePurchase} />
      </Tooltip>
    );

    const menu = (
      <Menu>
        <Menu.Item key="print-form" onClick={downloadReport.bind(this,"form")}>
          <Icon type="printer" />
          Печать формы
        </Menu.Item>
        <Menu.Item
          key="print-specification"
          onClick={downloadReport.bind(this, "specification")}
        >
          <Icon type="printer" />
          Печать спецификации
        </Menu.Item>
      </Menu>
    );

    return (
      <Card
        loading={isLoading}
        title={
          <div>
            <h2 style={{ marginBottom: 0 }}>
              {`Заказ-наряд #${workOrder.number} от ${moment(
                workOrder.date
              ).format("LLL")}`}
            </h2>
            <span style={{ fontSize: "12px" }}>
              {workOrder.company ? workOrder.company.text : ""}
            </span>
          </div>
        }
        extra={[
          <Button onClick={() => window.history.back()}>Закрыть</Button>,

          <Dropdown overlay={menu}>
            <Button style={{ marginLeft: "8px" }}>
              Действия <Icon type="down" />
            </Button>
          </Dropdown>,
          <Activities
            key={"activityButton"}
            parentId={workOrder.id}
            parentType={"WorkOrder"}
          />,
        ]}
      >
        <Form layout={"vertical"} onSubmit={this.onSubmit}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <OrderItems
                getFieldDecorator={getFieldDecorator}
                orderItems={workOrder.order_items}
                onChangeStocked={onChangeStocked}
                onFillBySpecification={onFillBySpecification}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Row>
              <Statuses
                getFieldDecorator={getFieldDecorator}
                workOrder={workOrder}
                onChangeStatus={onChangeStatus}
                onChangeDate={onChangeDate}
                onChangeNumber={onChangeNumber}
                onChangeAssignee={onChangeAssignee}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Tabs
              tabBarExtraContent={operations}
              activeKey={this.state.tabIndex}
              onChange={this.onChangeTab}
            >
              <TabPane tab="Производство" key="1">
                <WorkOrderItems
                  isLoading={isWorkOrderItemsLoading}
                  items={workOrder.items}
                  onSaveWorkOrderItem={onSaveWorkOrderItem}
                  onDeleteWorkOrderItems={onDeleteWorkOrderItems}
                />
              </TabPane>
              <TabPane tab="Списания" key="2">
                <MaterialOrders
                  isVisibleMaterialOrder={isVisibleMaterialOrder}
                  onCloseMaterialOrderModal={onCloseMaterialOrderModal}
                  isMaterialOrdersLoading={isMaterialOrdersLoading}
                  onDeleteMaterialOrders={onDeleteMaterialOrders}
                  onAddMaterialOrder={onAddMaterialOrder}
                  onEditMaterialOrder={onEditMaterialOrder}
                  onSaveMaterialOrder={onSaveMaterialOrder}
                  materialOrders={materialOrders}
                />
              </TabPane>
              <TabPane tab="График платежей" key="3">
                <PurchasePaymentSchedules
                  isPurchasePaymentSchedulesLoading={
                    isPurchasePaymentSchedulesLoading
                  }
                  purchasePaymentSchedules={purchasePaymentSchedules}
                />
              </TabPane>
              {isNew ? null : (
                <TabPane tab="Файлы" key="4">
                  <Documents ownerID={workOrder.id} ownerType={"WorkOrder"} />
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Form>
        <ModalPurchase />
      </Card>
    );
  }
}

WorkOrderForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  workOrder: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(WorkOrderForm);
