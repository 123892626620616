// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  List,
  Avatar,
  Popover,
  Tooltip,
  Button,
  Row,
  Col,
  Upload,
} from "antd";

const { Dragger } = Upload;

function fileLink(url, name, target = "_self") {
  return (
    <a href={url} target={target} download={"123"}>
      {name.replace(/_/g, " ")}
    </a>
  );
}

function selectIcon(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "file-pdf";
    case "image/png":
      return "file-image";
    case "image/jpeg":
      return "file-image";
    case "application/msword":
      return "file-word";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "file-word";
    case "application/vnd.ms-excel":
      return "file-excel";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "file-excel";
    case "message/rfc822":
      return "mail";
    default:
      return "file-unknown";
  }
}

function selectColor(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "red";
    case "image/png":
      return "brown";
    case "image/jpeg":
      return "brown";
    case "application/msword":
      return "cornflowerblue";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "cornflowerblue";
    case "application/vnd.ms-excel":
      return "green";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "green";
    default:
      return "brown";
  }
}

function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
}

class DocumentsList extends Component {
  static defaultProps = {
    title: "Файлы",
    isReadOnly: false,
  };

  onUpload = ({ onSuccess, onError, file }) => {
    const { onUpload } = this.props;
    

    getBase64(file, (fileUrl) =>
      onUpload({
        id: file.uid,
        name: file.name,
        content_type: file.type,
        file_size: file.size,
        asset: fileUrl,
      })
    );

  };

  render() {
    const {
      documents,
      onDelete,
    } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <List
            dataSource={documents}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Tooltip placement="bottom" title={"Удалить"}>
                    <Popover
                      content={
                        <Button
                          type="danger"
                          style={{ width: "100%" }}
                          onClick={onDelete.bind(this, item.id)}
                        >
                          Удалить
                        </Button>
                      }
                      title="Удаление файла"
                      trigger="click"
                    >
                      <Button type="dashed" shape="circle" icon="close" />
                    </Popover>
                  </Tooltip>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "transparent",
                        color: selectColor(item.content_type),
                      }}
                      icon={selectIcon(item.content_type)}
                      size="large"
                    />
                  }
                  title={
                    <a href={item.asset} download={item.name}>
                      {item.name}
                    </a>
                  }
                  description={item.file_size}
                />
              </List.Item>
            )}
          >
            <Dragger customRequest={this.onUpload} showUploadList={false}>
              <p className="ant-upload-hint">
                Перетащите файл в эту область или кликните для загрузки.
              </p>
            </Dragger>
          </List>
        </Col>
      </Row>
    );
  }
}

DocumentsList.propTypes = {
  orderDocumentsList: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default DocumentsList;
