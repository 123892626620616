// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Form, Input, Select } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class Header extends Component {
  render() {
    const {
      name,
      full_name,
      prefix,
      ceo,
      ceo_title,
      cfo,
      cfo_title,
      bank_account_primary,
      getFieldDecorator,
      onChangeName,
      onChangeFullName,
      onChangePrefix,
      onChangeCeo,
      onChangeCeoTitle,
      onChangeCfo,
      onChangeCfoTitle,
      onChangeBankAccountPrimary
    } = this.props;

    return (
      <React.Fragment>
        <Col sm={{ span: 19 }} md={{ span: 19 }} xs={{ span: 19 }}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: name,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!"
                }
              ]
            })(<Input placeholder="введите наименование" />)}
          </FormItem>
        </Col>

        <Col sm={{ span: 5 }} md={{ span: 5 }} xs={{ span: 5 }}>
          <FormItem label={"Префикс"}>
            {getFieldDecorator("prefix", {
              onChange: onChangePrefix,
              initialValue: prefix,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!"
                }
              ]
            })(<Input placeholder="введите наименование" />)}
          </FormItem>
        </Col>
        
        
        <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
          <FormItem label={"Полное наименование"}>
            {getFieldDecorator("full_name", {
              onChange: onChangeFullName,
              initialValue: full_name,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите полное наименование!"
                }
              ]
            })(<Input placeholder="введите наименование" />)}
          </FormItem>
        </Col>

        <Col sm={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Должность CEO"}>
            {getFieldDecorator("ceo_title", {
              onChange: onChangeCeoTitle,
              initialValue: ceo_title,
              rules: [
                {
                  required: true,
                  message: "Введите должность!"
                }
              ]
            })(<Input placeholder="введите должность" />)}
          </FormItem>
        </Col>

        <Col sm={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"ФИО CEO"}>
            {getFieldDecorator("ceo", {
              onChange: onChangeCeo,
              initialValue: ceo,
              rules: [
                {
                  required: true,
                  message: "Введите имя!"
                }
              ]
            })(<Input placeholder="введите имя" />)}
          </FormItem>
        </Col>

        <Col sm={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Должность CFO"}>
            {getFieldDecorator("cfo_title", {
              onChange: onChangeCfoTitle,
              initialValue: cfo_title,
              rules: [
                {
                  required: true,
                  message: "Введите должность!"
                }
              ]
            })(<Input placeholder="введите должность" />)}
          </FormItem>
        </Col>

        <Col sm={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"ФИО CFO"}>
            {getFieldDecorator("cfo", {
              onChange: onChangeCfo,
              initialValue: cfo,
              rules: [
                {
                  required: true,
                  message: "Введите имя!"
                }
              ]
            })(<Input placeholder="введите имя" />)}
          </FormItem>
        </Col>

        <Col md={{ span: 24 }} xs={{ span: 24 }}>
            <FormItem label={"Банковский счет по-умолчанию"}>
              {getFieldDecorator("bank_account_primary", {
                initialValue: bank_account_primary
                  ? bank_account_primary
                  : null,
                // rules: [
                //   {
                //     required: true,
                //     message: "Введите способ доставки!"
                //   }
                // ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Банковский счет"
                  onChange={onChangeBankAccountPrimary}
                  selected={
                    bank_account_primary ? bank_account_primary : null
                  }
                  url={`/bank_accounts/search/?`}
                />
              )}
            </FormItem>
          </Col>

        
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
