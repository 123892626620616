// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Statuses from "./Statuses";

class UserForm extends Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      onClose,
      isNew,
      isLoading,
      user,
      onChangeActive,
      onChangeRole,
      onChangeName,
      onChangeMiddleName,
      onChangeLastName,
      onChangeEmail,
      onChangePassword,
      onChangePasswordConfirmation,
      onUpdatePassword
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Пользователь #${
            isNew
              ? " новый"
              : `${user.last_name} ${user.first_name} ${user.middle_name}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <Header
                isNew={isNew}
                firstName={user.first_name}
                middleName={user.middle_name}
                lastName={user.last_name}
                email={user.email}
                password={user.password}
                password_confirmation={user.password_confirmation}
                getFieldDecorator={getFieldDecorator}
                onChangeName={onChangeName}
                onChangeMiddleName={onChangeMiddleName}
                onChangeLastName={onChangeLastName}
                onChangeEmail={onChangeEmail}
                onChangePassword={onChangePassword}
                onChangePasswordConfirmation={onChangePasswordConfirmation}
                onUpdatePassword={onUpdatePassword}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Row>
              <Statuses
                active={user.active}
                role={user.role}
                getFieldDecorator={getFieldDecorator}
                onChangeActive={onChangeActive}
                onChangeRole={onChangeRole}
              />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

UserForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func
};

export default Form.create()(UserForm);
