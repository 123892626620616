// @flow
import React, { Component } from "react";
import { List, Divider, Tooltip, Button } from "antd";
import Address from "./Address";
import update from "immutability-helper";

const addressCategory = category => {
  switch (category) {
    case "work":
      return "юридический";
    case "shipping":
      return "отгрузки";
    default:
      return "другое";
  }
};

class Addresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      address: {}
    };
  }

  onAddAddress = () => {
    this.setState(
      {
        address: {
          id: Date.now(),
          category: "work",
          country: null,
          postindex: null,
          state: null,
          city: null,
          street: null,
          is_exist: false,
          _destroy: false
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveAddress = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveAddress(this.state.address);
    });
  };

  onEditAddress = id => {
    const { addresses } = this.props;
    let idx = addresses.findIndex(function(o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }
    this.setState({ address: addresses[idx] }, () => {
      this.setState({ isVisible: true });
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeCategory = value => {
    this.setState({
      address: update(this.state.address, {
        category: { $set: value }
      })
    });
  };

  onChangeCountry = value => {
    this.setState({
      address: update(this.state.address, {
        country: { $set: value }
      })
    });
  };

  onChangeIndex = e => {
    this.setState({
      address: update(this.state.address, {
        postindex: { $set: e.target.value }
      })
    });
  };

  onChangeState = value => {
    this.setState({
      address: update(this.state.address, {
        state: { $set: value }
      })
    });
  };

  onChangeCity = value => {
    this.setState({
      address: update(this.state.address, {
        city: { $set: value }
      })
    });
  };

  onChangeStreet = e => {
    this.setState({
      address: update(this.state.address, {
        street: { $set: e.target.value }
      })
    });
  };

  onChangeBuilding = e => {
    this.setState({
      address: update(this.state.address, {
        building: { $set: e.target.value }
      })
    });
  };

  onChangeBuildingExt = e => {
    this.setState({
      address: update(this.state.address, {
        building_ext: { $set: e.target.value }
      })
    });
  };

  onChangeFlat = e => {
    this.setState({
      address: update(this.state.address, {
        flat: { $set: e.target.value }
      })
    });
  };

  render() {
    const { isVisible, address } = this.state;
    const { addresses, onDeleteAddress } = this.props;

    return (
      <React.Fragment>
        <Divider style={{ opacity: "0.6" }}>
          {"Адреса"}{" "}
          <Tooltip placement="bottom" title={"Добавить адрес"}>
            <Button
              type="dashed"
              shape="circle"
              icon="plus"
              onClick={this.onAddAddress}
            />
          </Tooltip>
        </Divider>
        <List
          itemLayout="horizontal"
          dataSource={addresses}
          renderItem={(address, i) => {
            let addressStr = [
              address.country ? address.country.text : null,
              address.postindex,
              address.state ? address.state.text : null,
              address.city ? address.city.text : null,
              address.street,
              address.building,
              address.building_ext,
              address.flat
            ]
              .filter(function(val) {
                return val;
              })
              .join(", ");
            return (
              <List.Item
                actions={[
                  <Button
                    type="dashed"
                    shape="circle"
                    icon="close"
                    onClick={onDeleteAddress.bind(this, address.id)}
                  />
                ]}
              >
                <List.Item.Meta
                  title={addressCategory(address.category)}
                  description={
                    <a onClick={this.onEditAddress.bind(this, address.id)}>
                      {addressStr}
                    </a>
                  }
                />
              </List.Item>
            );
          }}
        />
        <Address
          visible={isVisible}
          address={address}
          onSave={this.onSaveAddress}
          onCancel={this.onCancel}
          onChangeCategory={this.onChangeCategory}
          onChangeCountry={this.onChangeCountry}
          onChangeIndex={this.onChangeIndex}
          onChangeState={this.onChangeState}
          onChangeCity={this.onChangeCity}
          onChangeStreet={this.onChangeStreet}
          onChangeBuilding={this.onChangeBuilding}
          onChangeBuildingExt={this.onChangeBuildingExt}
          onChangeFlat={this.onChangeFlat}
        />
      </React.Fragment>
    );
  }
}

export default Addresses;
