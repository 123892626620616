import api from "../api";
import download from "downloadjs";

const Actions = {};

Actions.fetchOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORDERS_REQUEST" });
    const params = getState().orders.fetchParams;
    let assignees = [];
    if (params.assignees) {
      assignees = params.assignees.map((assignee) => {
        if (assignee && assignee.id) {
          return assignee.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      startShippingDate: params.startShippingDate,
      endShippingDate: params.endShippingDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      source_type: params.source_type,
      assignees: assignees,
      company: params.company,
    };

    return api
      .fetch("/orders", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ORDERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchOrder = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_REQUEST" });
    api
      .fetch(`/orders/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_FAILURE", response });
      });
  };
};

Actions.fetchOrderItems = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_ITEMS_REQUEST" });
    return api
      .fetch(`/orders/${id}/order_items`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_ITEMS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_ITEMS_FAILURE", response });
      });
  };
};
Actions.onFillInWithInvoice = (data) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_FILL_IN_WITH_INVOICE",
      data: data,
    });
    return Promise.resolve();
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ORDER_REQUEST" });
    const order = getState().orders.order;
    const order_items_attributes = order.order_items.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        position: item.position,
        product_id: item.product ? item.product.id : null,
        unit_id: item.unit ? item.unit.id : null,
        vat_rate_id: item.vat_rate ? item.vat_rate.id : null,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        amount_vat: item.amount_vat,
        will_shipped: item.will_shipped,
        will_shipped_days: item.will_shipped_days,
        _destroy: item._destroy,
      };
    });
    const order_payment_schedules_attributes =
      order.order_payment_schedules.map((item) => {
        return {
          id: item.is_exist ? item.id : null,
          date: item.date,
          payment_type: item.payment_type,
          amount: item.amount,
          percent: item.percent,
          _destroy: item._destroy,
        };
      });
    const data = {
      order: {
        status: order.status,
        source_type: order.source_type,
        request_proposal_id: order.request_proposal
          ? order.request_proposal.id
          : null,
        number: order.number,
        date: order.date,
        agreement: order.agreement,
        agreement_date: order.agreement_date,
        additional: order.additional,
        vat_included: order.vat_included,
        total: order.total,
        vat_total: order.vat_total,
        ship_address: order.ship_address,
        shipping_address_id: order.shipping_address
          ? order.shipping_address.id
          : null,
        special_instruction: order.special_instruction,
        company_id: order.company ? order.company.id : null,
        assignee_id: order.assignee ? order.assignee.id : null,
        order_items_attributes: order_items_attributes,
        order_payment_schedules_attributes: order_payment_schedules_attributes,
      },
    };
    return api
      .patch(`/orders/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER", response });
        api.openNotification("success", "Заказ", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORDER_REQUEST" });
    const order = getState().orders.order;
    const order_items_attributes = order.order_items.map((item) => {
      return {
        position: item.position,
        product_id: item.product ? item.product.id : null,
        unit_id: item.unit ? item.unit.id : null,
        vat_rate_id: item.vat_rate ? item.vat_rate.id : null,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        amount_vat: item.amount_vat,
        will_shipped: item.will_shipped,
        will_shipped_days: item.will_shipped_days,
        _destroy: item._destroy,
      };
    });
    const order_payment_schedules_attributes =
      order.order_payment_schedules.map((item) => {
        return {
          date: item.date,
          payment_type: item.payment_type,
          amount: item.amount,
          percent: item.percent,
          _destroy: item._destroy,
        };
      });
    const data = {
      order: {
        status: order.status,
        source_type: order.source_type,
        request_proposal_id: order.request_proposal
          ? order.request_proposal.id
          : null,
        number: order.number,
        date: order.date,
        agreement: order.agreement,
        agreement_date: order.agreement_date,
        additional: order.additional,
        vat_included: order.vat_included,
        total: order.total,
        vat_total: order.vat_total,
        ship_address: order.ship_address,
        shipping_address_id: order.shipping_address
          ? order.shipping_address.id
          : null,
        special_instruction: order.special_instruction,
        company_id: order.company ? order.company.id : null,
        assignee_id: order.assignee ? order.assignee.id : null,
        order_items_attributes: order_items_attributes,
        order_payment_schedules_attributes: order_payment_schedules_attributes,
      },
    };
    return api
      .post(`/orders`, data)
      .then((response) => {
        dispatch({ type: "POST_ORDER", response });
        api.openNotification("success", "Заказ", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "POST_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreateWorkOrder = (data) => {
  return (dispatch) => {
    dispatch({ type: "POST_WORK_ORDER_REQUEST" });
    return api
      .post(`/work_orders`, data)
      .then((response) => {
        api.openNotification("success", "Заказ-наряд", "Успешно создан!");
        dispatch({ type: "POST_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "POST_WORK_ORDER_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORDER_REQUEST" });
    return api
      .delete(`/orders/${id}`)
      .then((response) => {
        api.openNotification("success", "Заказ", "Успешно удален!");
        dispatch({ type: "DELETE_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({
          type: "DELETE_ORDER_FAILURE",
        });
      });
  };
};

Actions.fetchSuggestions = (value = null) => {
  const params = { value: value };
  return api
    .fetch("/products/search", params)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.data;
    });
};

Actions.fetchInfo = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_DETAILS_REQUEST" });
    api
      .fetch(`/orders/${id}/details`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ (инфо)", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_DETAILS_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeCompanyParams = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_CHANGE_FILTER_COMPANY",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_DATE",
      value: value,
    });
  };
};

Actions.onChangeStatus = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_STATUS",
      value: value,
    });
  };
};

Actions.onChangeSourceType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_SOURCE_TYPE",
      value: value,
    });
  };
};

Actions.onChangeRequestProposal = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_REQUEST_PROPOSAL",
      value: value,
    });
  };
};

Actions.onChangeNumber = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_NUMBER",
      value: value,
    });
  };
};

Actions.onChangeAssignee = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ASSIGNEE",
      value: value,
    });
  };
};

Actions.onChangeCompany = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_COMPANY",
      value: value,
    });
  };
};

Actions.onChangeAgreement = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_AGREEMENT",
      value: value,
    });
  };
};

Actions.onChangeAgreementDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_AGREEMENT_DATE",
      value: value,
    });
  };
};

Actions.onChangeAdditional = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ADDITIONAL",
      value: value,
    });
  };
};

Actions.onChangeShipAddress = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_SHIP_ADDRESS",
      value: value,
    });
  };
};

Actions.onChangeShippingAddress = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_SHIPPING_ADDRESS",
      value: value,
    });
  };
};

Actions.onChangeSpecialInstruction = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_SPECIAL_INSTRUCTION",
      value: value,
    });
  };
};

// table
Actions.onAddItem = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_ADD_ITEM",
    });
  };
};

Actions.onDeleteItem = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_DELETE_ITEM",
      idx: idx,
    });
  };
};

Actions.onDestroyItem = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_DESTROY_ITEM",
      idx: idx,
    });
  };
};

Actions.onChangeItemProduct = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_PRODUCT",
      id: id,
      item: item,
    });
  };
};

Actions.onChangeItemDescription = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_DESCRIPTION",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemUnit = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_UNIT",
      id: id,
      item: item,
    });
  };
};

Actions.onChangeItemQuantity = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_QUANTITY",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemPrice = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_PRICE",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemVatRate = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_VAT_RATE",
      id: id,
      item: item,
    });
  };
};

Actions.onChangeItemVat = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_VAT",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemWillShipped = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_WILL_SHIPPED",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemWillShippedDays = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_WILL_SHIPPED_DAYS",
      id: id,
      value: value,
    });
  };
};

// paymentSchedules
Actions.onAddPaymentSchedule = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_ADD_ITEM_PAYMENT_SCHEDULES",
    });
  };
};

Actions.onDeletePaymentSchedule = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_DELETE_ITEM_PAYMENT_SCHEDULES",
      idx: idx,
    });
  };
};

Actions.onDestroyPaymentSchedule = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_DESTROY_ITEM_PAYMENT_SCHEDULES",
      idx: idx,
    });
  };
};

Actions.onChangePaymentSchedulesDate = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_PAYMENT_SCHEDULES_DATE",
      id: id,
      value: value,
    });
  };
};

Actions.onChangePaymentSchedulesPaymentType = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_PAYMENT_PAYMENT_TYPE",
      id: id,
      item: item,
    });
  };
};

Actions.onChangePaymentSchedulesAmount = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_PAYMENT_SCHEDULES_AMOUNT",
      id: id,
      value: value,
    });
  };
};

Actions.onChangePaymentSchedulesPercent = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ITEM_PAYMENT_SCHEDULES_PERCENT",
      id: id,
      value: value,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterAssignee = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_ASSIGNEES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_STATUS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterSourceType = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_SOURCE_TYPE",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterShippingPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_SHIPPING_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterDownloadRows = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_DOWNLOAD_ROWS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  let assignees = [];
  if (params.assignees) {
    assignees = params.assignees.map((assignee) => {
      if (assignee && assignee.id) {
        return assignee.id;
      }
    });
  }

  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    startShippingDate: params.startShippingDate,
    endShippingDate: params.endShippingDate,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    status: params.status,
    source_type: params.source_type,
    assignees: assignees,
    company: params.company,
    isDownloadRows: params.isDownloadRows,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      download(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
