// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Input
  
} from "antd";

const FormItem = Form.Item;

class Sidebar extends Component {
  onAddAddress = ({ key }) => {
    this.props.onAddAddress(key);
  };

  render() {
    const {
      inn,
      kpp,
      ogrn,
      okpo,
      getFieldDecorator,
      onChangeInn,
      onChangeKpp,
      onChangeOgrn,
      onChangeOkpo
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem label={"ИНН"}>
              {getFieldDecorator("inn", {
                onChange: onChangeInn,
                initialValue: inn
              })(<Input placeholder="введите инн" />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"КПП"}>
              {getFieldDecorator("kpp", {
                onChange: onChangeKpp,
                initialValue: kpp
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"ОГРН"}>
              {getFieldDecorator("ogrn", {
                onChange: onChangeOgrn,
                initialValue: ogrn
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"ОКПО"}>
              {getFieldDecorator("okpo", {
                onChange: onChangeOkpo,
                initialValue: okpo
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  invoice: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Sidebar;
