// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/purchases";
import List from "./List";

class Purchases extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchPurchases());
    });
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchPurchases());
      });
    });
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchPurchases()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchPurchases()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchPurchases()))
      .then(() => {
        localStorage.setItem(
          "purchasesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

   // filters
   onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchPurchases()).then(() => {
        localStorage.setItem(
          "purchasesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };


  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchPurchases()))
      .then(() => {
        localStorage.setItem(
          "purchasesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCompanies = values => {
    this.props
      .dispatch(Actions.onChangeFilterCompanies(values))
      .then(() => this.props.dispatch(Actions.fetchPurchases()))
      .then(() => {
        localStorage.setItem(
          "purchasesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadXLSReport(
      params,
      "purchases.xlsx",
      params.startDate
        ? `purchases ${params.startDate}-${params.endDate}.xlsx`
        : "purchases.xlsx"
    );
  };

  render() {
    return (
      <List
        loading={this.props.isLoading}
        purchases={this.props.purchases}
        meta={this.props.meta}
        fetchParams={this.props.fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterCompanies={this.onChangeFilterCompanies}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.purchases.isLoading,
  purchases: state.purchases.purchases,
  meta: state.purchases.meta,
  fetchParams: state.purchases.fetchParams
});

export default connect(mapStateToProps)(Purchases);
