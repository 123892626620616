// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Checkbox, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  render() {
    const {
      visible,
      status,
      source_type,
      startDate,
      endDate,
      startShippingDate,
      endShippingDate,
      assignees,
      isDownloadRows,
      onClose,
      onChangeStatus,
      onChangeSourceType,
      onChangePeriod,
      onChangeShippingPeriod,
      downloadReport,
      onChangeDownloadRows
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                onChange: onChangeStatus,
                initialValue: status ? status : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"0"}>согласование</Option>
                  <Option value={"1"}>в работе</Option>
                  <Option value={"2"}>к отгрузке</Option>
                  <Option value={"3"}>отгружен</Option>
                  <Option value={"4"}>закрыт</Option>
                  <Option value={"5"}>отменен</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Источник"}>
              {getFieldDecorator("source_type", {
                onChange: onChangeSourceType,
                initialValue: source_type ? source_type : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Источник"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"request"}>заявка</Option>
                  <Option value={"tender"}>тендер</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период создания"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период плана отгрузок"}>
              {getFieldDecorator("startShippingDate", {
                onChange: onChangeShippingPeriod,
                initialValue: [
                  startShippingDate ? moment(startShippingDate, "DD.MM.YYYY") : null,
                  endShippingDate ? moment(endShippingDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("assignees", {
                initialValue: assignees ? assignees : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={assignees ? assignees : []}
                  url={"/users/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Checkbox
              value="active"
              checked={isDownloadRows}
              onChange={onChangeDownloadRows}
            >
              Выгружать строки (только для выгрузки)
            </Checkbox>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
