// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Input } from "antd";
import Actions from "../../../actions/orders";
import Order from "./order";
import Shipment from "./shipment";
import MaterialOrder from "./materialOrder";

class Info extends Component {
  componentDidMount = () => {
    const { orderId } = this.props;
    // console.log(orderId)
    if (orderId) {
      this.props.dispatch(Actions.fetchInfo(orderId));
    }
  };

  render() {
    const { isLoading, details } = this.props;
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={11}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Order order={details.order} />
            </Col>
            <Col span={24}>
              {details.shipments.map(item => (
                // console.log(item)
                <Shipment key={item.id} shipment={item} />
              ))}
            </Col>
          </Row>
        </Col>
        <Col span={13}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              {details.material_orders.map(item => (
                <MaterialOrder key={item.id} material_order={item} />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.orders.isLoadingInfo,
  details: state.orders.details
});

export default connect(mapStateToProps)(Info);
