import api from "../api";

const Actions = {};

Actions.fetchCountries = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_COUNTRIES_REQUEST" });
    const params = getState().countries.fetchParams;
    api
      .fetch("/countries", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_COUNTRIES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_COUNTRIES_FAILURE", response });
      });
  };
};

Actions.fetchCountry = countryID => {
  return dispatch => {
    dispatch({ type: "FETCH_COUNTRY_REQUEST" });
    api
      .fetch(`/countries/${countryID}`)
      .then(response => {
        dispatch({ type: "FETCH_COUNTRY", response });
      })
      .catch(response => {
        dispatch({ type: "FETCH_COUNTRY_FAILURE", response });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_COUNTRY_REQUEST" });
    const country = getState().countries.country;
    const data = {
      country: {
        code: country.code,
        name: country.name
      }
    };
    return api
      .patch(`/countries/${country.id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_COUNTRY", response });
        api.openNotification(
          "success",
          "Страна",
          "Успешно обновлена!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Страна", error);
          });
        }
        dispatch({ type: "PATCH_COUNTRY_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "COUNTRY_NEW"
    });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_COUNTRY_REQUEST" });
    const country = getState().countries.country;
    const data = {
      country: {
        code: country.code,
        name: country.name
      }
    };
    return api
      .post(`/countries`, data)
      .then(response => {
        dispatch({ type: "POST_COUNTRY", response });
        api.openNotification(
          "success",
          "Страна",
          "Успешно создана!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Страна", error);
          });
        }
        dispatch({ type: "POST_COUNTRY_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_COUNTRY_REQUEST" });
    return api
      .delete(`/countries/${id}`)
      .then(response => {
        api.openNotification("success", "Страна", "Успешно удалена!");
        dispatch({ type: "DELETE_COUNTRY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Страна", error);
          });
        }
        dispatch({
          type: "DELETE_COUNTRY_FAILURE"
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_COUNTRY_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_COUNTRY_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_COUNTRY_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeCode = value => {
  return dispatch => {
    dispatch({
      type: "COUNTRY_CHANGE_CODE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "COUNTRY_CHANGE_NAME",
      value: value
    });
  };
};

export default Actions;
