// @flow
import React, { Component } from "react";
import { List, Divider, Tooltip, Button } from "antd";
import update from "immutability-helper";
import Email from "./Email";


const itemCategory = category => {
  switch (category) {
    case "work":
      return "рабочий";
    case "personal":
      return "личный";
    default:
      return "другой";
  }
};

class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      email: {}
    };
  }

  onAddEmail = () => {
    this.setState(
      {
        email: {
          id: Date.now(),
          category: "work",
          email: null,
          is_exist: false,
          _destroy: false
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveEmail = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveEmail(this.state.email);
    });
  };

  onEditEmail = id => {
    const { emails } = this.props;
    let idx = emails.findIndex(function(o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }
    this.setState({ email: emails[idx] }, () => {
      this.setState({ isVisible: true });
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeCategory = value => {
    this.setState({
      email: update(this.state.email, {
        category: { $set: value }
      })
    });
  };

  onChangeEmail = e => {
    this.setState({
      email: update(this.state.email, {
        email: { $set: e.target.value }
      })
    });
  };

  render() {
    const { isVisible, email } = this.state;
    const { emails, onDeleteEmail } = this.props;

    return (
      <React.Fragment>
        <Divider style={{ opacity: "0.6" }}>
          {"Emails"}{" "}
          <Tooltip placement="bottom" title={"Добавить email"}>
            <Button
              type="dashed"
              shape="circle"
              icon="plus"
              onClick={this.onAddEmail}
            />
          </Tooltip>
        </Divider>
        <List
          itemLayout="horizontal"
          dataSource={emails}
          renderItem={(email, i) => {
            return (
              <List.Item
                actions={[
                  <Button
                    type="dashed"
                    shape="circle"
                    icon="close"
                    onClick={onDeleteEmail.bind(this, email.id)}
                  />
                ]}
              >
                <List.Item.Meta
                  title={itemCategory(email.category)}
                  description={
                    <a onClick={this.onEditEmail.bind(this, email.id)}>
                      {email.email}
                    </a>
                  }
                />
              </List.Item>
            );
          }}
        />
        <Email
          visible={isVisible}
          email={email}
          onSave={this.onSaveEmail}
          onCancel={this.onCancel}
          onChangeCategory={this.onChangeCategory}
          onChangeEmail={this.onChangeEmail}
        />
      </React.Fragment>
    );
  }
}

export default Emails;
