// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/materialOrders";
import { Spin } from "antd";
import MaterialOrderModalForm from "./Form";
import moment from "moment";

class MaterialOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  onSave = () => {
    if (this.props.materialOrder.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate(this.props.match.params.id)).then(() => {
        this.props.dispatch(
          Actions.fetchMaterialOrders(this.props.match.params.id)
        );
        this.props.dispatch(Actions.onToggleVisible());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible())
  }

  onDeleteItems = (ids) => {
    this.props.dispatch(Actions.onDeleteItems(ids))
  }

  onChangeNumber = (value) => {
    this.props.dispatch(Actions.onChangeNumber(value))
  }

  onChangeDate = (value) => {
    this.props.dispatch(Actions.onChangeDate(value))
  }

  onChangeItemQuantity = (idx, id, value) => {
    this.props.dispatch(Actions.onChangeItemQuantity(idx, value))
  }

  onChangeItemCost = (idx, id, value) => {
    this.props.dispatch(Actions.onChangeItemCost(idx, value))
  }

  render() {
    const { isLoading, materialOrder, isVisible, onSave } = this.props;
    return (
      <MaterialOrderModalForm
        isLoading={isLoading}
        visible={isVisible}
        materialOrder={materialOrder}
        onSave={onSave}
        onCancel={this.onCancel}
        onDeleteItems={this.onDeleteItems}
        onChangeNumber={this.onChangeNumber}
        onChangeDate={this.onChangeDate}
        onChangeItemQuantity={this.onChangeItemQuantity}
        onChangeItemCost={this.onChangeItemCost}
        destroyOnClose
      />
    );
  }
}

MaterialOrderModal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  materialOrder: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.materialOrders.isLoading,
  isVisible: state.materialOrders.isVisible,
  errors: state.materialOrders.errors,
  materialOrder: state.materialOrders.materialOrder
});

export default connect(mapStateToProps)(withRouter(MaterialOrderModal));
