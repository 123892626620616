// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  Icon,
  Tooltip,
  DatePicker,
  Select,
  Tabs,
  Statistic
} from "antd";
import {
  ChartCard,
  Field,
  MiniArea,
  MiniBar,
  Pie,
  Bar,
  Area
} from "../../components/Charts";
import Trend from "../../components/Trend";
import Actions from "../../actions/dashboard";
import moment from "moment";
import accounting from "accounting";

const TabPane = Tabs.TabPane;
const { RangePicker } = DatePicker;
const Option = Select.Option;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 0
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCompanies());
    this.props.dispatch(Actions.fetchOrders());
    this.props.dispatch(Actions.fetchInvoices());
    this.props.dispatch(Actions.fetchSales());
    this.props.dispatch(Actions.fetchCategories());
    this.props.dispatch(Actions.fetchPayments());
    this.props.dispatch(Actions.fetchSchedules());
  };

  onChangeDateRange = dates => {
    this.setState({ period: 0 }, () => {
      this.props
        .dispatch(
          Actions.onChangeDateRange(
            moment(dates[0]).format("DD.MM.YYYY"),
            moment(dates[1]).format("DD.MM.YYYY")
          )
        )
        .then(() => {
          this.props.dispatch(Actions.fetchCompanies());
          this.props.dispatch(Actions.fetchOrders());
          this.props.dispatch(Actions.fetchInvoices());
          this.props.dispatch(Actions.fetchSales());
          this.props.dispatch(Actions.fetchCategories());
          this.props.dispatch(Actions.fetchPayments());
          this.props.dispatch(Actions.fetchSchedules());
        });
    });
  };

  onChangePeriod = value => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 1:
        startDate = moment()
          .startOf("months")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 2:
        startDate = moment()
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 3:
        startDate = moment()
          .startOf("years")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "years")
          .endOf("year")
          .format("DD.MM.YYYY");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
    }
    this.setState({ period: value }, () => {
      this.props
        .dispatch(Actions.onChangeDateRange(startDate, endDate))
        .then(() => {
          this.props.dispatch(Actions.fetchCompanies());
          this.props.dispatch(Actions.fetchOrders());
          this.props.dispatch(Actions.fetchInvoices());
          this.props.dispatch(Actions.fetchSales());
          this.props.dispatch(Actions.fetchCategories());
          this.props.dispatch(Actions.fetchPayments());
          this.props.dispatch(Actions.fetchSchedules());
        });
    });
  };

  render() {
    const {
      companies,
      orders,
      invoices,
      categories,
      sales,
      totals,
      payments,
      schedules,
      fetchParams,
      isCompaniesLoading,
      isOrdersLoading,
      isInvoicesLoading,
      isCategoriesLoading,
      isSalesLoading,
      isPaymentsLoading,
      isSchedulesLoading
    } = this.props;

    const debtTotal = companies.debt.reduce(
      (sum, record) => sum + Number(record.debt),
      0
    );

    const ordersData = [];
    let ordersTotal = 0;
    orders.series.map(item => {
      ordersTotal = ordersTotal + Number(item.total);
      ordersData.push({
        x: moment(item.period).format("DD.MM.YYYY"),
        y: Number(item.total)
      });
    });

    const invoicesData = [];
    let invoicesTotal = 0;
    invoices.series.map(item => {
      invoicesTotal = invoicesTotal + Number(item.total);
      invoicesData.push({
        x: moment(item.period).format("DD.MM.YYYY"),
        y: Number(item.total)
      });
    });

    const salesData = [];
    sales.map(item => {
      salesData.push({
        period: moment(item.period).format("DD.MM"),
        отгрузки: Number(item.sales),
        "материалы, субподряд и транспорт": Number(item.subcontracts) + Number(item.materials) + Number(item.service)
      });
    });

    const paymentsData = [];
    payments.map(item => {
      paymentsData.push({
        period: moment(item.period).format("DD.MM"),
        поступления: Number(item.inpayments),
        оплаты: Number(item.outpayments)
      });
    });

    const schedulesData = [];
    schedules.map(item => {
      schedulesData.push({
        period: moment(item.period).format("DD.MM"),
        поступления: Number(item.inpayments),
        оплаты: Number(item.outpayments)
      });
    });

    const ownData = [];
    // const salesTotal = sales.reduce(
    //   (sum, record) => sum + Number(record.sales),
    //   0
    // );
    const salesTotal = parseFloat(totals.total) - parseFloat(totals.vat_total)
    const subcontractsTotal = Number(totals.subcontracts);
    const materialsTotal = Number(totals.materials);
    const serviceTotal = Number(totals.service);

    ownData.push({
      item: "собственные",
      count: salesTotal - subcontractsTotal - materialsTotal - serviceTotal
    });

    ownData.push({
      item: "субподряд",
      count: subcontractsTotal
    });

    ownData.push({
      item: "материалы",
      count: materialsTotal
    });

    ownData.push({
      item: "транспорт",
      count: serviceTotal
    });

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8,
      style: { marginBottom: 24 }
    };

    // Specify Options
    const cols = {
      sales: { tickInterval: 20 }
    };

    const dateFormat = "DD.MM.YYYY";
    return (
      <Fragment>
        <Row
          gutter={24}
          type="flex"
          justify="end"
          style={{ marginBottom: "10px" }}
        >
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={this.onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={this.state.period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={this.onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...topColResponsiveProps}>
            <ChartCard
              bordered={false}
              title="Клиенты"
              loading={false}
              action={
                <Tooltip title="Количество новых клиентов">
                  <Icon type="info-circle-o" />
                </Tooltip>
              }
              total={() => <span>{companies.count}</span>}
              footer={<Field label="дебиторская задолженность:" value={accounting.formatNumber(debtTotal, 0, " ")} />}
              contentHeight={66}
            >
              <div></div>
            </ChartCard>
          </Col>
          <Col {...topColResponsiveProps}>
            <ChartCard
              bordered={false}
              title="Заказы"
              loading={isOrdersLoading}
              action={
                <Tooltip title="Количество заказов за период">
                  <Icon type="info-circle-o" />
                </Tooltip>
              }
              total={orders.count}
              footer={
                <Field
                  label="сумма:"
                  value={accounting.formatNumber(ordersTotal, 0, " ")}
                />
              }
              contentHeight={66}
            >
              <MiniArea color="#975FE4" data={ordersData} height={66} />
            </ChartCard>
          </Col>
          <Col {...topColResponsiveProps}>
            <ChartCard
              bordered={false}
              title="Счета"
              loading={isInvoicesLoading}
              action={
                <Tooltip title="Количество счетов за период">
                  <Icon type="info-circle-o" />
                </Tooltip>
              }
              total={invoices.count}
              footer={
                <Field
                  label="сумма:"
                  value={accounting.formatNumber(invoicesTotal, 0, " ")}
                />
              }
              contentHeight={66}
            >
              <MiniBar data={invoicesData} height={66} />
            </ChartCard>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Card
              loading={isSalesLoading}
              title={"Отгрузки (без НДС)"}
              bordered={false}
              extra={
                <Statistic
                  style={{ padding: 0 }}
                  groupSeparator=" "
                  value={salesTotal}
                  precision={2}
                  valueStyle={{ color: "#1777d1" }}
                  // suffix="р."
                />
              }
            >
              <Area
                fields={["отгрузки", "материалы, субподряд и траснпорт"]}
                data={salesData}
                height={280}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Card
              loading={isSalesLoading}
              title={"Доля собственных"}
              bordered={false}
              extra={
                <Statistic
                  style={{ padding: 0 }}
                  groupSeparator=" "
                  value={salesTotal - subcontractsTotal - materialsTotal  - serviceTotal}
                  precision={2}
                  valueStyle={{ color: "#1777d1" }}
                  // suffix="р."
                />
              }
            >
              <Pie data={ownData} height={280} />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14} />
        </Row>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Card loading={isPaymentsLoading} bordered={false}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Поступления и оплаты (факт)" key="1">
                  <Area
                    fields={["поступления", "оплаты"]}
                    data={paymentsData}
                    height={280}
                  />
                </TabPane>
                <TabPane tab="Поступления и оплаты (план)" key="2">
                  <Area
                    fields={["поступления", "оплаты"]}
                    data={schedulesData}
                    height={280}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Card
              loading={isCategoriesLoading}
              title={"Структура отгрузок (по категориям)"}
              bordered={false}
            >
              <Pie data={categories} height={280} />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.dashboard.isLoading,
  isCompaniesLoading: state.dashboard.isCompaniesLoading,
  isOrdersLoading: state.dashboard.isOrdersLoading,
  isInvoicesLoading: state.dashboard.isInvoicesLoading,
  isCategoriesLoading: state.dashboard.isCategoriesLoading,
  isSalesLoading: state.dashboard.isSalesLoading,
  isPaymentsLoading: state.dashboard.isPaymentsLoading,
  isSchedulesLoading: state.dashboard.isSchedulesLoading,
  fetchParams: state.dashboard.fetchParams,
  companies: state.dashboard.companies,
  orders: state.dashboard.orders,
  invoices: state.dashboard.invoices,
  sales: state.dashboard.sales,
  totals: state.dashboard.totals,
  payments: state.dashboard.payments,
  schedules: state.dashboard.schedules,
  categories: state.dashboard.categories,
  errors: state.users.errors
});

export default connect(mapStateToProps)(Dashboard);
