import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  serviceOrders: [],
  serviceOrder: {service_order_items: []},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isModalVisible: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_SERVICE_ORDERS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_SERVICE_ORDERS":
      return {
        ...state,
        serviceOrders: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_SERVICE_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_SERVICE_ORDER":
      return {
        ...state,
        serviceOrder: action.response.data,
        isLoading: false,
        isModalVisible: true
      };
    case "PATCH_SERVICE_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_SERVICE_ORDER":
      return {
        ...state,
        serviceOrder: action.response.data,
        isLoading: false,
        isModalVisible: false
      };

    case "POST_SERVICE_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_SERVICE_ORDER":
      return {
        ...state,
        errors: false,
        serviceOrder: {},
        isLoading: false,
        isModalVisible: false
      };

    case "POST_SERVICE_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "SERVICE_ORDER_ADD":
      return {
        ...state,
        serviceOrder: {
          active: true,
          date: moment(),
          order: null,
          shipment: null,
          product: { id: 145, text: "Транспортные расходы" },
          cost: 0,
          service_order_items: []
        },
        errors: false,
        isLoading: false,
        isModalVisible: true
      };

    case "DELETE_SERVICE_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_SERVICE_ORDER":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_SERVICE_ORDER_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_SERVICE_ORDER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_SERVICE_ORDER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "SERVICE_ORDER_TOGGLE_VISIBLE":
      return {
        ...state,
        isModalVisible: !state.isModalVisible
      };

    case "SERVICE_ORDER_CHANGE_DATE":
      return update(state, {
        serviceOrder: {
          date: { $set: action.value }
        }
      });

    case "SERVICE_ORDER_CHANGE_ORDER":
      return update(state, {
        serviceOrder: {
          order: { $set: action.value }
        }
      });

    case "SERVICE_ORDER_CHANGE_SHIPMENT":
      return update(state, {
        serviceOrder: {
          shipment: { $set: action.value }
        }
      });

    case "SERVICE_ORDER_CHANGE_PRODUCT":
      return update(state, {
        serviceOrder: {
          product: { $set: action.value }
        }
      });

    case "SERVICE_ORDER_CHANGE_COST":
      return update(state, {
        serviceOrder: {
          cost: { $set: action.value }
        }
      });

    default:
      return state;
  }
}
