import React from "react";
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from "bizcharts";
import DataSet from "@antv/data-set";
import accounting from "accounting";

export default class Area extends React.Component {
  

  render() {
    const {
      height,
      forceFit = true,
      data = [],
      fields = ["x, y"]
    } = this.props;

    
    var dv = new DataSet.View().source(data);
    dv.transform({
      type: "fold",
      fields: fields,
      key: "type",
      value: "value"
    });
    const scale = {
      value: {
        alias: 'сумма',
        formatter: function (val) {
          return `${accounting.formatNumber(val, 0, " ")} руб.`;
        }
      },
      period: {
        range: [0, 1]
      }
    };


    return (
      <div>
        <Chart
          height={height}
          data={dv}
          padding={"auto"}
          scale={scale}
          forceFit
        >
          <Tooltip crosshairs />
          <Axis />
          <Legend />
          <Geom type="area" position="period*value" color="type" shape="smooth" />
          <Geom
            type="line"
            position="period*value"
            // color='#ffffff'
            color="type"
            shape="smooth"
            size={2}
          />
        </Chart>
      </div>
    );
  }
}
