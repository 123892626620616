import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  units: [],
  unit: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_UNITS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_UNITS":
      return {
        ...state,
        units: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_UNIT_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_UNIT":
      return {
        ...state,
        unit: action.response.data,
        isLoading: false
      };

    case "PATCH_UNIT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "PATCH_UNIT":
      return {
        ...state,
        errors: false,
        unit: action.response.data,
        isLoading: false
      };

    case "PATCH_UNIT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "POST_UNIT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_UNIT":
      return {
        ...state,
        errors: false,
        unit: action.response.data,
        isLoading: false
      };

    case "POST_UNIT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "UNIT_NEW":
      return {
        ...state,
        errors: false,
        unit: { code: null, name: null },
        isLoading: false
      };

    case "DELETE_UNIT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "DELETE_UNIT":
      return {
        ...state,
        errors: false,
        isLoading: false
      };
    case "DELETE_UNIT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "REQUEST_UNIT_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_UNIT_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "REQUEST_UNIT_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value } }
      });

    case "UNIT_CHANGE_CODE":
      return update(state, {
        unit: { code: { $set: action.value } }
      });

    case "UNIT_CHANGE_NAME":
      return update(state, {
        unit: { name: { $set: action.value } }
      });

    default:
      return state;
  }
}
