// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/lossReasons";
import ModalForm from "./Form";

class LossReasonModal extends Component {
  onSave = () => {
    if (this.props.lossReason.id) {
      this.props
        .dispatch(Actions.onUpdate(this.props.lossReason.id))
        .then(() => {
          this.props.dispatch(Actions.fetchLossReasons());
        });
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        this.props.dispatch(Actions.fetchLossReasons());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  

  render() {
    const { isLoading, lossReason, isModalVisible } = this.props;
    return (
      <ModalForm
        isLoading={isLoading}
        visible={isModalVisible}
        lossReason={lossReason}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeName={this.onChangeName}
        destroyOnClose
      />
    );
  }
}

LossReasonModal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  lossReason: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.lossReasons.isLoading,
  isModalVisible: state.lossReasons.isModalVisible,
  errors: state.lossReasons.errors,
  lossReason: state.lossReasons.lossReason
});

export default connect(mapStateToProps)(withRouter(LossReasonModal));
