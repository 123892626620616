// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, Row, Col, PageHeader, Pagination, Button } from "antd";

class UnitsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      loading,
      countries,
      meta,
      fetchParams,
      onChangePage,
      onChangeLimit
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: "10%"
      },
      {
        title: "Код",
        dataIndex: "code",
        key: "code",
        width: "10%"
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/references/countries/${record.id}/edit`}>{name}</Link>
        ),
        key: "name"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/references/countries/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Страны`}
        extra={[mainButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={countries}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

UnitsList.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func
};

export default UnitsList;
