// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Select, Button } from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      loading,
      report,
      meta,
      totals,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      onChangePage,
      onChangeLimit,
      downloadReport
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    let reportData = report;
    if (reportData.length > 0) {
      reportData.unshift({
        id: Date.now(),
        product_id: null,
        product_name: null,
        description: null,
        date: null,
        company_name: null,
        assignee: null,
        quantity: totals.quantity,
        amount: totals.total,
        vat: totals.vat,
        cost: totals.cost,
        order_id: null,
        order_number: null,
        invoices: [],
        isGoup: true
      });
    }

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        render: (date, record) => {
          if (record.isGoup) {
            return {
              children: <b>Итого</b>,
              props: {
                colSpan: 2,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: moment(date).format("L")
          };
        },
        align: "left",
        width: "10%"
      },
      {
        title: "Заказ",
        dataIndex: "order_number",
        render: (order_number, record) => {
          if (record.isGoup) {
            return {
              props: {
                colSpan: 0,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  <Link to={`/orders/${record.order_id}/edit`}>
                    {order_number}
                  </Link>
                </span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {moment(record.order_date).format("L")}
                </span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {record.invoices}
                </span>
              </div>
            )
          };
        },
        align: "center",
        width: "10%"
      },
      {
        title: "Количество",
        dataIndex: "quantity",
        render: (quantity, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(quantity), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: accounting.formatNumber(Number(quantity), 0, " ")
          };
        },
        align: "right",
        width: "5%"
      },
      {
        title: "Сумма Собственные",
        dataIndex: "amount",
        render: (amount, record) => {
          if (record.isGoup) {
            return {
              children: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    <b>
                      {accounting.formatNumber(Number(record.amount), 0, " ")}
                    </b>
                  </span>
                  <span>
                    <b>
                      {accounting.formatNumber(
                        Number(record.amount) - Number(record.cost),
                        0,
                        " "
                      )}
                    </b>
                  </span>
                </div>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {accounting.formatNumber(Number(record.amount), 0, " ")}
                </span>
                <span>
                  {accounting.formatNumber(
                    Number(record.amount) - Number(record.cost),
                    0,
                    " "
                  )}
                </span>
              </div>
            )
          };
        },
        align: "right",
        width: "10%"
      },
      {
        title: "Оборудование",
        render: (product_name, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{record.product_name}</span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {record.description}
                </span>
              </div>
            )
          };
        },

        key: "product_name",
        width: "35%"
      },
      {
        title: "Компания",
        dataIndex: "company_name",
        render: (company_name, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: record.company_name
          };
        },

        key: "company_name",
        width: "20%"
      },
      {
        title: "Ответственный",
        render: (assignee, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: record.assignee
          };
        },
        width: "10%"
      }
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Последние отгрузки (оборудование)`}
        extra={[
          <Button key="3" type="dashed" icon="file-excel" onClick={downloadReport}>Загрузить</Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSizeOptions={['30', '60', '100', '200']}
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default DataList;
