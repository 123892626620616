// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Select, Button } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      loading,
      report,
      periodicity,
      fetchParams,
      onChangePeriodicity,
      onChangePaymentType,
      downloadReport
    } = this.props;

    const reportGroupByPeriod = report.reduce(
      (
        r,
        v,
        i,
        a,
        k = moment(v.date)
          .startOf(periodicity)
          // .format("MM.DD.YY")
      ) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
    console.log(reportGroupByPeriod);

    let reportData = [];
    Object.keys(reportGroupByPeriod).map(key => {
      let amountTotal = reportGroupByPeriod[key].reduce(
        (sum, record) => sum + Number(record.amount),
        0
      );

      reportData.push({
        id: `period-${key}`,
        period: null,
        date: key,
        company_name: null,
        amount: amountTotal,
        payment_type: null,
        order_id: null,
        purchase_code: null,
        agreement_date: null,
        isGoup: true,
        isSubtotal: false
      });
      reportGroupByPeriod[key].map(item => {
        reportData.push({
          id: item.id,
          period: item.period,
          date: item.date,
          company_name: item.company_name,
          amount: item.amount,
          purchase_id: item.purchase_id,
          purchase_code: item.purchase_code,
          agreement_date: item.agreement_date,
          payment_type: item.payment_type,
          isGoup: false,
          isSubtotal: false
        });
      });
    });

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        render: (date, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>
                  {periodicity == "month"
                    ? moment(date).format("MMMM YYYY")
                    : `${moment(date).quarter()} квартал`}
                </b>
              ),
              props: {
                colSpan: 2,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: moment(date).format("L")
          };
        },
        align: "center",
        width: "15%"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <div style={{ textAlign: "center" }}>
              <span>Договор субподряда</span>
              <br />
              <span>Дата</span>
            </div>
          </div>
        ),
        dataIndex: "purchase_code",
        render: (purchase_code, record) => {
          if (record.isGoup) {
            return {
              props: {
                colSpan: 0,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  <Link to={`/purchases/${record.purchase_id}/edit`}>
                    {purchase_code}
                  </Link>
                </span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {moment(record.agreement_date).format("L")}
                </span>
              </div>
            )
          };
        },
        align: "center",
        width: "20%"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(record.amount), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: accounting.formatNumber(Number(record.amount), 0, " ")
          };
        },
        align: "right",
        width: "15%"
      },
      {
        title: "Тип",
        dataIndex: "payment_type",
        render: (payment_type, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: record.payment_type ==1 ? "аванс" : "кредит"
          };
        },

        key: "payment_type",
        align: "center",
        width: "15%"
      },
      {
        title: "Компания",
        dataIndex: "company_name",
        render: (company_name, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: record.company_name
          };
        },

        key: "company_name",
        width: "35%"
      },
      
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Списания ДС (план)`}
        extra={[
          <Button
            key="3"
            type="dashed"
            icon="file-excel"
            onClick={downloadReport}
          >
            Загрузить
          </Button>
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={periodicity}
              placeholder="Периодичность"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriodicity}
            >
              <Option value={"month"}>месяц</Option>
              <Option value={"quarter"}>квартал</Option>
            </Select>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={fetchParams.paymentType}
              placeholder="Вид платежа"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePaymentType}
            >
              <Option value={null}>все</Option>
              <Option value={"1"}>аванс</Option>
              <Option value={"2"}>кредит</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default DataList;
