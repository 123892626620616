import api from "../api";

const Actions = {};

Actions.fetchCompanies = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_COMPANIES_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/companies", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_COMPANIES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_COMPANIES_FAILURE", response });
      });
  };
};

Actions.fetchOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_ORDERS_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/orders", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_ORDERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchInvoices = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_INVOICES_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/invoices", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_INVOICES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_INVOICES_FAILURE", response });
      });
  };
};

Actions.fetchSales = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_SALES_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/sales", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_SALES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_SALES_FAILURE", response });
      });
  };
};

Actions.fetchPayments = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_PAYMENTS_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/payments", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_PAYMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_PAYMENTS_FAILURE", response });
      });
  };
};

Actions.fetchSchedules = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_SCHEDULES_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/schedules", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_SCHEDULES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_SCHEDULES_FAILURE", response });
      });
  };
};

Actions.fetchCategories = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_FETCH_CATEGORIES_REQUEST" });
    const data = getState().dashboard.fetchParams;
    return api
      .fetch("/dashboard/categories", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_FETCH_CATEGORIES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_FETCH_CATEGORIES_FAILURE", response });
      });
  };
};

Actions.onChangeDateRange = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: "DASHBOARD_CHANGE_DATE_RANGE",
      startDate: startDate,
      endDate: endDate
    });
    return Promise.resolve();
  };
};

export default Actions;
