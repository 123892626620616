import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  departments: [],
  department: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_DEPARTMENTS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_DEPARTMENTS":
      return {
        ...state,
        departments: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_DEPARTMENT":
      return {
        ...state,
        department: action.response.data,
        isLoading: false
      };

    case "PATCH_DEPARTMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "PATCH_DEPARTMENT":
      return {
        ...state,
        errors: false,
        department: action.response.data,
        isLoading: false
      };

    case "PATCH_DEPARTMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "POST_DEPARTMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_DEPARTMENT":
      return {
        ...state,
        errors: false,
        department: action.response.data,
        isLoading: false
      };

    case "POST_DEPARTMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "DEPARTMENT_NEW":
      return {
        ...state,
        errors: false,
        department: { name: null, full_name: null },
        isLoading: false
      };

    case "DELETE_DEPARTMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "DELETE_DEPARTMENT":
      return {
        ...state,
        errors: false,
        isLoading: false
      };
    case "DELETE_DEPARTMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "REQUEST_DEPARTMENT_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_DEPARTMENT_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "REQUEST_DEPARTMENT_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value } }
      });

    case "DEPARTMENT_CHANGE_NAME":
      return update(state, {
        department: { name: { $set: action.value } }
      });

      case "DEPARTMENT_CHANGE_FULL_NAME":
      return update(state, {
        department: { full_name: { $set: action.value } }
      });

    default:
      return state;
  }
}
