// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Select, Checkbox } from "antd";
import "moment/locale/ru";

const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      active,
      role,
      getFieldDecorator,
      onChangeActive,
      onChangeRole
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator("active", {
                initialValue: active,
                valuePropName: "checked",
                onChange: onChangeActive
              })(<Checkbox>активен</Checkbox>)}
            </FormItem>
          </Col>
          <Col span={24}>
          <FormItem label={"Роль"}>
            {getFieldDecorator("role", {
              initialValue: role,
              onChange: onChangeRole,
              rules: [
                {
                  required: true,
                  message: "Введите роль!"
                }
              ]
            })(
              <Select
                style={{ width: "100%" }}
                placeholder="Роль"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"manager"}>менеджер</Option>
                <Option value={"office_manager"}>делопроизводство</Option>
                <Option value={"viewer"}>пользователь</Option>
                <Option value={"administrator"}>администратор</Option>
              </Select>
            )}
          </FormItem>
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  getFieldDecorator: PropTypes.func
};

export default Statuses;
