// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select, Spin } from "antd";
import api from "../../api";

const Option = Select.Option;


class SelectMultiFetch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: null,
      fetching: false
    };
  }

  componentWillMount = () => {
    const data = this.props.selected.map(d => {
      return { value: d.id, text: d.text };
    });
    const value = this.props.selected.map(d => {
      return { key: d.id, label: d.text };
    });

    if (this.props.selected) {
      this.setState({
        data: data,
        value: value
      });
    }
  };

  onFocus = () => {
    this.setState({ data: [], fetching: true });
    
    api.fetch(`${this.props.url}`).then(response => {
      const data = response.data.map(item => ({
        text: `${item.text}`,
        value: `${item.id}`
      }));
      this.setState({ data, fetching: false });
    });
  };

  onChange = value => {
    const values = value.map(d => {
      return { key: d.key, label: d.label };
    });
    const selected = value.map(d => {
      return { id: d.key, text: d.label };
    });
    this.setState(
      {
        value: values,
        selected: selected,
        fetching: false
      },
      () => {
        return this.props.onChange ? this.props.onChange(this.state.selected) : null;
      }
    );
  };

  onSearch = value => {
    api.fetch(`${this.props.url}&q=${encodeURIComponent(value)}`).then(response => {
      const data = response.data.map(item => ({
        text: `${item.text}`,
        value: `${item.id}`
      }));
      this.setState({ data, fetching: false });
    });
  }

  render() {
    const { fetching, data, value } = this.state;
    const { placeholder } = this.props;
    return (
      <Select
        mode={"multiple"}
        labelInValue
        value={value}
        placeholder={placeholder ? placeholder : "выберите значение"}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onSearch={this.onSearch}
        style={{ width: "100%" }}
      >
        {data.map(d => <Option key={d.value}>{d.text}</Option>)}
      </Select>
    );
  }
}

SelectMultiFetch.propTypes = {
  url: PropTypes.string,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func
};

export default SelectMultiFetch;
