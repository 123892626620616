import api from "../api";
import download from "downloadjs";

const Actions = {};

Actions.fetchCompanies = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_COMPANIES_REQUEST" });
    const params = getState().companies.fetchParams;
    let businessFields = [];
    if (params.businessFields) {
      businessFields = params.businessFields.map((businessField) => {
        if (businessField && businessField.id) {
          return businessField.id;
        }
      });
    }

    let states = [];
    if (params.states) {
      states = params.states.map((state) => {
        if (state && state.id) {
          return state.id;
        }
      });
    }

    let contact_types = [];
    if (params.contact_types) {
      contact_types = params.contact_types.map((contact_type) => {
        if (contact_type && contact_type.id) {
          return contact_type.id;
        }
      });
    }

    const data = {
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      businessFields: businessFields,
      states: states,
      contact_types: contact_types,
      search: params.search,
      page: params.page,
      limit: params.limit,
    };

    return api
      .fetch("/companies", data)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_COMPANIES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_COMPANIES_FAILURE", response });
      });
  };
};

Actions.fetchCompany = id => {
  return dispatch => {
    dispatch({ type: "FETCH_COMPANY_REQUEST" });
    return api
      .fetch(`/companies/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_COMPANY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Компания", error);
          });
        }
        dispatch({ type: "FETCH_COMPANY_FAILURE", response });
      });
  };
};

Actions.fetchOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_COMPANY_ORDERS_REQUEST" });
    const company = getState().companies.company;
    
    return api
      .fetch("/orders", {company: company.id})
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_COMPANY_ORDERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_COMPANY_ORDERS_FAILURE", response });
      });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_COMPANY_REQUEST" });
    const company = getState().companies.company;
    const addresses = company.addresses.map(
      item => {
        return {
          id: item.is_exist ? item.id : null,
          category: item.category,
          postindex: item.postindex,
          state_id: item.state ? item.state.id : null,
          district: item.district,
          city_id: item.city ? item.city.id : null,
          street: item.street,
          building: item.building,
          building_ext: item.building_ext,
          flat: item.flat,
          country_id: item.country ? item.country.id : null,
          _destroy: item._destroy
        };
      }
    );

    const phones = company.phones.map(
      item => {
        return {
          id: item.is_exist ? item.id : null,
          category: item.category,
          number: item.number,
          _destroy: item._destroy
        };
      }
    );

    const emails = company.emails.map(
      item => {
        return {
          id: item.is_exist ? item.id : null,
          category: item.category,
          email: item.email,
          _destroy: item._destroy
        };
      }
    );
    
    const data = {
      company: {
        code: company.code,
        company_type: company.company_type,
        contact_source_id: company.contact_source ? company.contact_source.id : null,
        business_field_id: company.business_field ? company.business_field.id : null,
        name: company.name,
        full_name: company.full_name,
        inn: company.inn,
        kpp: company.kpp,
        assignee_id: company.assignee ? company.assignee.id : null,
        contact_type_id: company.contact_type ? company.contact_type.id : null,
        description: company.description,
        addresses_attributes: addresses,
        phones_attributes: phones,
        emails_attributes: emails,
      }
    };
    return api
      .patch(`/companies/${id}`, data)
      .then(response => {
        api.openNotification("success", "Компания", "Успешно обновлена!");
        dispatch({ type: "PATCH_COMPANY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Компания", error);
          });
        }
        dispatch({ type: "PATCH_COMPANY_FAILURE", response });
      });
  };
};

Actions.onCreate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_COMPANY_REQUEST" });
    const company = getState().companies.company;
    const addresses = company.addresses.map(
      item => {
        return {
          id: item.is_exist ? item.id : null,
          category: item.category,
          postindex: item.postindex,
          state_id: item.state ? item.state.id : null,
          district: item.district,
          city_id: item.city ? item.city.id : null,
          street: item.street,
          building: item.building,
          building_ext: item.building_ext,
          flat: item.flat,
          country_id: item.country ? item.country.id : null,
          _destroy: item._destroy
        };
      }
    );

    const phones = company.phones.map(
      item => {
        return {
          category: item.category,
          number: item.number,
          _destroy: item._destroy
        };
      }
    );

    const emails = company.emails.map(
      item => {
        return {
          category: item.category,
          email: item.email,
          _destroy: item._destroy
        };
      }
    );
    
    const data = {
      company: {
        code: company.code,
        company_type: company.company_type,
        contact_source_id: company.contact_source ? company.contact_source.id : null,
        business_field_id: company.business_field ? company.business_field.id : null,
        name: company.name,
        full_name: company.full_name,
        inn: company.company_type == "resident" ? company.inn : null,
        kpp: company.company_type == "resident" ? company.kpp  : null,
        assignee_id: company.assignee ? company.assignee.id : null,
        contact_type_id: company.contact_type ? company.contact_type.id : null,
        description: company.description,
        addresses_attributes: addresses,
        phones_attributes: phones,
        emails_attributes: emails,
      }
    };
    return api
      .post(`/companies`, data)
      .then(response => {
        api.openNotification("success", "Компания", "Успешно создана!");
        dispatch({ type: "POST_COMPANY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Компания", error);
          });
        }
        dispatch({ type: "POST_COMPANY_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "COMPANY_NEW",
    });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_COMPANY_REQUEST" });
    return api
      .delete(`/companies/${id}`)
      .then(response => {
        api.openNotification("success", "Компания", "Успешно удалена!");
        dispatch({ type: "DELETE_COMPANY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Компания", error);
          });
        }
        dispatch({
          type: "DELETE_COMPANY_FAILURE"
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_COMPANY_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_COMPANY_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

// header
Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeCode = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_CODE",
      value: value
    });
  };
};

Actions.onChangeCompanyType = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_COMPANY_TYPE",
      value: value
    });
  };
};

Actions.onChangeFullName = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_FULL_NAME",
      value: value
    });
  };
};

Actions.onChangeInn = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_INN",
      value: value
    });
  };
};

Actions.onChangeKpp = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_KPP",
      value: value
    });
  };
};

Actions.onChangeAssignee = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_ASSIGNEE",
      value: value
    });
  };
};

Actions.onChangeContactType = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_CONTACT_TYPE",
      value: value
    });
  };
};

Actions.onChangeContactSource = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_CONTACT_SOURCE",
      value: value
    });
  };
};

Actions.onChangeBusinessField = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_BUSINESS_FIELD",
      value: value
    });
  };
};

// addresses
Actions.onAddAddress = (data) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_ADD_ADDRESS",
      data: data
    });
  };
};

Actions.onChangeAddress = (idx, data) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_ADDRESS",
      idx: idx,
      data: data
    });
  };
};

Actions.onDeleteAddress = idx => {
  return dispatch => {
    dispatch({
      type: "COMPANY_DELETE_ADDRESS",
      idx: idx
    });
  };
};

Actions.onDestroyAddress = idx => {
  return dispatch => {
    dispatch({
      type: "COMPANY_DESTROY_ADDRESS",
      idx: idx
    });
  };
};

// phones
Actions.onAddPhone = (data) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_ADD_PHONE",
      data: data
    });
  };
};

Actions.onChangePhone = (idx, data) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_PHONE",
      idx: idx,
      data: data
    });
  };
};

Actions.onDeletePhone = idx => {
  return dispatch => {
    dispatch({
      type: "COMPANY_DELETE_PHONE",
      idx: idx
    });
  };
};

Actions.onDestroyPhone = idx => {
  return dispatch => {
    dispatch({
      type: "COMPANY_DESTROY_PHONE",
      idx: idx
    });
  };
};

// emails
Actions.onAddEmail = (data) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_ADD_EMAIL",
      data: data
    });
  };
};

Actions.onChangeEmail = (idx, data) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_EMAIL",
      idx: idx,
      data: data
    });
  };
};

Actions.onDeleteEmail = idx => {
  return dispatch => {
    dispatch({
      type: "COMPANY_DELETE_EMAIL",
      idx: idx
    });
  };
};

Actions.onDestroyEmail = idx => {
  return dispatch => {
    dispatch({
      type: "COMPANY_DESTROY_EMAIL",
      idx: idx
    });
  };
};

Actions.fillCompanyByInn = (data, address) => {
  return dispatch => {
    dispatch({
      type: "COMPANY_FILL_BY_INN",
      data: data,
      address: address
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "COMPANIES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "COMPANIES_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCreatedPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_CREATED_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterBusinessFields = (values) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANIES_FILTER_BUSINESS_FIELDS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStates = (values) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANIES_FILTER_STATES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterContactTypes = (values) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANIES_FILTER_CONTACT_TYPE",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {

  let businessFields = [];
    if (params.businessFields) {
      businessFields = params.businessFields.map((businessField) => {
        if (businessField && businessField.id) {
          return businessField.id;
        }
      });
    }

    let states = [];
    if (params.states) {
      states = params.states.map((state) => {
        if (state && state.id) {
          return state.id;
        }
      });
    }

    let contact_types = [];
    if (params.contact_types) {
      contact_types = params.contact_types.map((contact_type) => {
        if (contact_type && contact_type.id) {
          return contact_type.id;
        }
      });
    }

    const data = {
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      businessFields: businessFields,
      states: states,
      contact_types: contact_types,
      search: params.search,
      page: params.page,
      limit: params.limit,
    };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      //console.log(response)
      download(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
