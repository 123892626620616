// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

class Orders extends Component {
  onChangePage = value => {
    this.props.onChangePage(value);
  };

  render() {
    const { loading, orders } = this.props;

    const totalOuantity = orders.reduce((sum, record) => sum + Number(record.quantity), 0);
    const total = orders.reduce((sum, record) => sum + Number(record.total), 0);

    orders.unshift({
      id: Date.now(),
      date: null,
      number: null,
      total: total,
      price: Number(total / totalOuantity).toFixed(2),
      quantity: totalOuantity,
      is_total: true
    });

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        render: (date, record) => {
          if (record.is_total) {
            return {
              children: <b>Итого</b>,
              props: {
                colSpan: 2,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              moment(date).format("L")
            )
          };
        },
        width: "15%"
      },
      {
        title: "Номер",
        dataIndex: "number",
        render: (number, record) => {
          if (record.is_total) {
            return {
              props: {
                colSpan: 0,
              }
            };
          }
          return {
            children: (
              <Link to={`/orders/${record.order_id}/edit`}>{number}</Link>
            ),
          };
        },
        width: "15%"
      },
      {
        title: "Сумма",
        dataIndex: "total",
        render: (total, record) => {
          if (record.is_total) {
            return {
              children: (
                accounting.formatNumber(Number(record.total), 0, " ")
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              accounting.formatNumber(Number(record.price) * Number(record.quantity), 0, " ")
            ),
          };
        },
        align: "right",
        width: "30%"
      },
      {
        title: "Цена",
        dataIndex: "price",
        render: (price, record) => {
          if (record.is_total) {
            return {
              children: (
                accounting.formatNumber(price, 0, " ")
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              accounting.formatNumber(price, 0, " ")
            ),
          };
        },
        align: "right",
        width: "20%"
      },
      {
        title: "Кол-во",
        dataIndex: "quantity",
        render: (quantity, record) => {
          if (record.is_total) {
            return {
              children: (
                accounting.formatNumber(quantity, 0, " ")
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              accounting.formatNumber(quantity, 0, " ")
            ),
          };
        },
        align: "right",
        width: "20%"
      }
    ];

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Table
            bordered
            size={"middle"}
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={orders}
            rowKey="id"
          />
        </Col>
      </Row>
    );
  }
}

Orders.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Orders;
