// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table } from "antd";
import { Row, Col, Dropdown, Menu, Icon, Button } from "antd";
import update from "immutability-helper";
import accounting from "accounting";

import WorkOrderItem from "./WorkOrderItem";

function pluck(array, key) {
  return array.map(o => o[key]);
}

class WorkOrderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      workOrderItem: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    // console.log(selectedRowKeys)
    this.setState({ selectedRowKeys });
  };

  onSelectMenu = (orderItemId, product, { key }) => {
    console.log(orderItemId, product);
    if (key === "1") {
      this.onAddWorkOrderItem(orderItemId);
    } else {
      // this.props.onFillBySpecification(record.id, record.product.id);
      // console.log(record.id, record.product.id);
    }
  };

  onDeleteWorkOrderItems = () => {
    this.props.onDeleteWorkOrderItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // work_items
  onOpenWorkOrderItem = (item) => {
    this.setState({ workOrderItem: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddWorkOrderItem = (orderItemId) => {
    this.setState(
      {
        workOrderItem: {
          id: null,
          order_item_id: orderItemId,
          position: 0,
          product: null,
          description: null,
          cost_type: "material",
          unit: null,
          quantity: 1,
          for_order: 0,
          ordered: 0,
          company: null,
          purchase: null,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveWorkOrderItem = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveWorkOrderItem(this.state.workOrderItem);
    });
  };

  onOpenWorkOrderItem = (record) => {
    this.setState({ workOrderItem: record }, () => {
      this.setState({ isVisible: true });
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangePosition = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        position: { $set: value },
      }),
    });
  };

  onChangeProduct = (value) => {
    // console.log(value)
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        product: { $set: value },
        unit: { $set: value.extra ? value.extra.unit : null },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        description: { $set: e.target.value },
      }),
    });
  };

  onChangeCostType = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        cost_type: { $set: value },
      }),
    });
  };

  onChangeUnit = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        unit: { $set: value },
      }),
    });
  };

  onChangeQuantity = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        quantity: { $set: value },
      }),
    });
  };

  onChangeForOrder = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        for_order: { $set: value },
      }),
    });
  };

  onChangeOrdered = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        ordered: { $set: value },
      }),
    });
  };

  onChangeCompany = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        company: { $set: value },
      }),
    });
  };

  onChangePurchase = (value) => {
    this.setState({
      workOrderItem: update(this.state.workOrderItem, {
        purchase: { $set: value },
      }),
    });
  };

  renderItemsHeader = () => {
    return (
      <div
        className="ant-table ant-table-middle ant-table-bordered ant-table-scroll-position-left"
        style={{ paddingBottom: "5px" }}
      >
        <div className="ant-table-content">
          <div className="ant-table-body">
            <table>
              <colgroup>
                <col style={{ width: "28%", minWidth: "28%" }} />
                <col style={{ width: "10%", minWidth: "10%" }} />
                <col style={{ width: "12%", minWidth: "12%" }} />
                <col style={{ width: "12%", minWidth: "12%" }} />
                <col style={{ width: "15%", minWidth: "15%" }} />
                <col style={{ width: "23%", minWidth: "28%" }} />
              </colgroup>
              <thead className="ant-table-thead">
                <tr>
                  <th className="">
                    <div style={{ textAlign: "center" }}>Описание</div>
                  </th>
                  <th className="">
                    <div style={{ textAlign: "center" }}>Ед.изм.</div>
                  </th>
                  <th className="">
                    <div style={{ textAlign: "center" }}>Требуется</div>
                  </th>
                  <th className="">
                    <div style={{ textAlign: "center" }}>
                      <div style={{ textAlign: "center" }}>
                        <span>Заказать</span>
                        <br />
                        <span>Заказано</span>
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div style={{ textAlign: "center" }}>
                      <span>Списано кол-во</span>
                      <br />
                      <span>Списано сумма</span>
                    </div>
                  </th>
                  <th className="">
                    <div style={{ textAlign: "center" }}>
                      <span>Поставщик</span>
                      <br />
                      <span>Договор</span>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { selectedRowKeys, isVisible, workOrderItem } = this.state;
    const { items, isLoading } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };


    const columns = [
      {
        title: "Описание",
        dataIndex: "product",
        render: (product, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a onClick={this.onOpenWorkOrderItem.bind(this, record)}>
              {product ? product.text : null}
            </a>
            <span style={{ fontSize: "12px" }}>{record.description}</span>
          </div>
        ),
        width: "28%",
      },
      {
        title: "Ед.изм.",
        dataIndex: "unit",
        width: "10%",
        align: "center",
      },
      {
        title: "Требуется",
        dataIndex: "quantity",
        render: (quantity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(Number(quantity), 0, " ")}</span>
            <span>{record.cost_type_locale}</span>
          </div>
        ),
        width: "12%",
        align: "center",
      },
      {
        title: "Заказать",
        dataIndex: "for_order",
        render: (for_order, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(Number(for_order), 0, " ")}</span>
            <span>
              {accounting.formatNumber(Number(record.ordered), 0, " ")}
            </span>
          </div>
        ),
        width: "12%",
        align: "center",
      },
      {
        title: "",
        dataIndex: "material_quantity",
        render: (material_quantity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {accounting.formatNumber(Number(material_quantity), 0, " ")}
            </span>
            <span>
              {accounting.formatNumber(
                Number(record.material_cost),
                2,
                " ",
                ","
              )}
            </span>
          </div>
        ),
        className: "ant-table-total-column",
        align: "center",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "company",
        render: (company, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {company ? (
                <Link to={`/companies/${company.id}/edit`} target="_blank">
                  {company.text}
                </Link>
              ) : null}
            </span>
            <span style={{ fontSize: "12px" }}>
              {record.purchase ? (
                <Link
                  to={`/purchases/${record.purchase.id}/edit`}
                  target="_blank"
                >
                  {record.purchase.text}
                </Link>
              ) : "договор не выбран"}
            </span>
          </div>
        ),
        align: "center",
        width: "28%",
      },
    ];

    let locale = {
      emptyText: <span>Материалы/работы не выбраны</span>,
    };

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          {this.renderItemsHeader()}
          {items.map((item) => {
            const workOrderItemIds = item.work_order_items.map(item => item.id)
            const hasSelected = selectedRowKeys.length > 0 && selectedRowKeys.some( ai => workOrderItemIds.includes(ai) );

            const operations = hasSelected ? (
              <Button key="1" type="danger" ghost onClick={this.onDeleteWorkOrderItems}>
                Удалить
              </Button>
            ) : (
              <Dropdown
                overlay={
                  <Menu
                    onClick={this.onSelectMenu.bind(
                      this,
                      item.id,
                      item.product
                    )}
                  >
                    <Menu.Item key="1">
                      <Icon type="plus" />
                      добавить строку в таблицу
                    </Menu.Item>
                    <Menu.Item key="2" disabled={!item.has_specification}>
                      <Icon type="reload" />
                      заполнить по спецификации
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomLeft"
              >
                <Button icon="bars" />
              </Dropdown>
            );

            let title = (
              <div key={`${item.id}-title`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#000000d9",
                      fontWeight: "500",
                    }}
                  >
                    {item.product ? item.product.text : null}
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    {item.product ? item.product.description : null}
                  </span>
                </div>
                <div>{operations}</div>
              </div>
            );
            return (
              <Table
                key={`${item.id}-table`}
                loading={isLoading}
                locale={locale}
                bordered={false}
                showHeader={false}
                title={() => title}
                size={"small"}
                rowSelection={rowSelection}
                // bordered
                style={{ paddingBottom: "5px" }}
                pagination={false}
                columns={columns}
                dataSource={item.work_order_items}
                rowKey="id"
              />
            );
          })}
        </Col>
        <WorkOrderItem
          visible={isVisible}
          workOrderItem={workOrderItem}
          onSave={this.onSaveWorkOrderItem}
          onCancel={this.onCancel}
          onChangePosition={this.onChangePosition}
          onChangeProduct={this.onChangeProduct}
          onChangeUnit={this.onChangeUnit}
          onChangeQuantity={this.onChangeQuantity}
          onChangeForOrder={this.onChangeForOrder}
          onChangeOrdered={this.onChangeOrdered}
          onChangeDescription={this.onChangeDescription}
          onChangeCostType={this.onChangeCostType}
          onChangeCompany={this.onChangeCompany}
          onChangePurchase={this.onChangePurchase}
        />
      </Row>
    );
  }
}

WorkOrderItems.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default WorkOrderItems;
