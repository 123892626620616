// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: null,
        endDate: null,
        periodicity: "month",
      },
      height: 0,
      period: 0,
      saldo: 0,
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentWillMount = () => {
    this.setFilters().then(() => {
      this.props.dispatch(Actions.fetchCashflow(this.state.fetchParams));
      this.updateHeight();
      window.addEventListener("resize", this.updateHeight);
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  onOpenSettings = () => {
    this.props.history.push(`/reports/cashflow/settings`);
  };

  setFilters = () => {
    const filters = localStorage.getItem("cashflowFilters");
    if (filters) {
      this.setState({ fetchParams: JSON.parse(filters) });
    }
    return Promise.resolve();
  };

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  onChangeDateRange = (dates) => {
    this.state.fetchParams.startDate = moment(dates[0]).format("L");
    this.state.fetchParams.endDate = moment(dates[1]).format("L");
    this.setState({ fetchParams: this.state.fetchParams }, function () {
      this.props
        .dispatch(Actions.fetchCashflow(this.state.fetchParams))
        .then(() => {
          localStorage.setItem(
            "cashflowFilters",
            JSON.stringify(this.state.fetchParams)
          );
        });
    });
  };

  onChangePeriod = (value) => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 1:
        startDate = moment().startOf("months").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 2:
        startDate = moment().startOf("quarter").format("DD.MM.YYYY");
        endDate = moment().endOf("quarter").format("DD.MM.YYYY");
        break;
      case 3:
        startDate = moment().startOf("years").format("DD.MM.YYYY");
        endDate = moment().endOf("years").format("DD.MM.YYYY");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY");
        endDate = moment().add(-1, "years").endOf("year").format("DD.MM.YYYY");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
    }
    this.state.fetchParams.startDate = startDate;
    this.state.fetchParams.endDate = endDate;
    this.setState(
      { fetchParams: this.state.fetchParams, period: value },
      function () {
        this.props.dispatch(Actions.fetchCashflow(this.state.fetchParams));
      }
    );
  };

  onChangePeriodicity = (value) => {
    this.state.fetchParams.periodicity = value;
    this.setState({ fetchParams: this.state.fetchParams }, function () {
      this.props
        .dispatch(Actions.fetchCashflow(this.state.fetchParams))
        .then(() => {
          localStorage.setItem(
            "cashflowFilters",
            JSON.stringify(this.state.fetchParams)
          );
        });
    });
  };

  onChangeSaldo = (value) => {
    this.setState({saldo: value})
  }

  downloadReport = (url) => {
    Actions.downloadReport(
      this.state.fetchParams,
      url,
      url
    );
  };

  

  render() {
    const { isLoading, report, meta, periods } = this.props;
    const { fetchParams, period, height, saldo } = this.state;
    return (
      <DataList
        height={height}
        loading={isLoading}
        report={report}
        meta={meta}
        periods={periods}
        fetchParams={fetchParams}
        period={period}
        saldo={saldo}
        onChangeDateRange={this.onChangeDateRange}
        onChangePeriod={this.onChangePeriod}
        onChangePeriodicity={this.onChangePeriodicity}
        onChangeSaldo={this.onChangeSaldo}
        onOpenSettings={this.onOpenSettings}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  report: state.reports.report,
  meta: state.reports.meta,
  periods: state.reports.periods,
});

export default connect(mapStateToProps)(Report);
