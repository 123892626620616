import api from "../api";
import download from "downloadjs";

const Actions = {};

Actions.fetchWorkOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_WORK_ORDERS_REQUEST" });
    const params = getState().workOrders.fetchParams;
    api
      .fetch("/work_orders", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_WORK_ORDERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_WORK_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchWorkOrder = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_WORK_ORDER_REQUEST" });
    api
      .fetch(`/work_orders/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "FETCH_WORK_ORDER_FAILURE", response });
      });
  };
};

Actions.fetchPurchasePaymentSchedules = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PURCHASE_PAYMENT_SCHEDULES_REQUEST" });
    return api
      .fetch(`/work_orders/${id}/purchase_payment_schedules`)
      .then((response) => {
        dispatch({ type: "FETCH_PURCHASE_PAYMENT_SCHEDULES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({
          type: "FETCH_PURCHASE_PAYMENT_SCHEDULES_FAILURE",
          response,
        });
      });
  };
};

Actions.onUpdateStatus = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_WORK_ORDER_REQUEST" });
    
    const data = {
      work_order: {
        status: value,
      },
    };
    return api
      .patch(`/work_orders/${id}`, data)
      .then((response) => {
        api.openNotification("success", "Заказ-наряд", "Статус успешно обновлен!");
        dispatch({ type: "PATCH_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "PATCH_WORK_ORDER_FAILURE", response });
      });
  };
};

Actions.onUpdateNumber = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_WORK_ORDER_REQUEST" });
    
    const data = {
      work_order: {
        number: value,
      },
    };
    return api
      .patch(`/work_orders/${id}`, data)
      .then((response) => {
        api.openNotification("success", "Заказ-наряд", "Номер успешно обновлен!");
        dispatch({ type: "PATCH_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "PATCH_WORK_ORDER_FAILURE", response });
      });
  };
};

Actions.onUpdateDate = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_WORK_ORDER_REQUEST" });
    
    const data = {
      work_order: {
        date: value,
      },
    };
    return api
      .patch(`/work_orders/${id}`, data)
      .then((response) => {
        api.openNotification("success", "Заказ-наряд", "Дата успешно обновлена!");
        dispatch({ type: "PATCH_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "PATCH_WORK_ORDER_FAILURE", response });
      });
  };
};

Actions.onUpdateAssignee = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_WORK_ORDER_REQUEST" });
    
    const data = {
      work_order: {
        assignee_id: value ? value.id : null,
      },
    };
    return api
      .patch(`/work_orders/${id}`, data)
      .then((response) => {
        api.openNotification("success", "Заказ-наряд", "Ответсвенный успешно обновлен!");
        dispatch({ type: "PATCH_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "PATCH_WORK_ORDER_FAILURE", response });
      });
  };
};

// work_items
Actions.fetchWorkOrderItems = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_WORK_ORDER_ITEMS_REQUEST" });
    return api
      .fetch(`/work_orders/${id}/work_order_items`)
      .then((response) => {
        dispatch({ type: "FETCH_WORK_ORDER_ITEMS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Материал", error);
          });
        }
        dispatch({
          type: "FETCH_WORK_ORDER_ITEMS_FAILURE",
        });
      });
  };
};

Actions.onUpdateWorkOrderItem = (id, item) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_WORK_ORDER_ITEM_REQUEST" });

    const data = {
      work_order_item: {
        position: item.position,
        product_id: item.product ? item.product.id : null,
        unit_id: item.unit ? item.unit.id : null,
        description: item.description,
        cost_type: item.cost_type,
        order_item_id: item.order_item_id,
        quantity: item.quantity,
        for_order: item.for_order,
        ordered: item.ordered,
        company_id: item.company ? item.company.id : null,
        purchase_id: item.purchase ? item.purchase.id : null,
      },
    };
    return api
      .patch(`/work_orders/${id}/work_order_items/${item.id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_WORK_ORDER_ITEM", response });
        api.openNotification("success", "Материал", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Материал", error);
          });
        }
        dispatch({ type: "PATCH_WORK_ORDER_ITEM_FAILURE", response });
      });
  };
};

Actions.onCreateWorkOrderItem = (id, item) => {
  return (dispatch) => {
    dispatch({ type: "POST_WORK_ORDER_ITEM_REQUEST" });

    const data = {
      work_order_item: {
        position: item.position,
        product_id: item.product ? item.product.id : null,
        unit_id: item.unit ? item.unit.id : null,
        description: item.description,
        cost_type: item.cost_type,
        order_item_id: item.order_item_id,
        quantity: item.quantity,
        for_order: item.for_order,
        ordered: item.ordered,
        company_id: item.company ? item.company.id : null,
        purchase_id: item.purchase ? item.purchase.id : null,
      },
    };
    return api
      .post(`/work_orders/${id}/work_order_items`, data)
      .then((response) => {
        dispatch({ type: "POST_WORK_ORDER_ITEM", response });
        api.openNotification("success", "Материал", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Материал", error);
          });
        }
        dispatch({ type: "POST_WORK_ORDER_ITEM_FAILURE", response });
      });
  };
};

Actions.onDeleteWorkOrderItems = (id, itemId) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_WORK_ORDER_ITEMS_REQUEST" });
    return api
      .delete(`/work_orders/${id}/work_order_items/${itemId}`)
      .then((response) => {
        api.openNotification("success", "Материал", "Успешно удален!");
        dispatch({ type: "DELETE_WORK_ORDER_ITEMS_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Материал", error);
          });
        }
        dispatch({
          type: "DELETE_WORK_ORDER_ITEMS_FAILURE",
        });
      });
  };
};

Actions.onCreatePurchase = (data) => {
  return (dispatch) => {
    dispatch({ type: "POST_WORK_ORDER_PURCHASE_REQUEST" });
    return api
      .post(`/purchases`, data)
      .then((response) => {
        dispatch({ type: "POST_WORK_ORDER_PURCHASE", response });
        api.openNotification(
          "success",
          "Договор субподряда",
          "Успешно создан!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Договор субподряда", error);
          });
        }
        dispatch({ type: "POST_WORK_ORDER_PURCHASE_FAILURE", response });
      });
  };
};

Actions.onFillBySpecification = (orderItemID, productID) => {
  return (dispatch) => {
    dispatch({ type: "REQUEST_WORK_ORDER_SPECIFICATION" });
    return api
      .fetch(`/products/${productID}/specification`)
      .then((response) => {
        dispatch({
          type: "FETCH_WORK_ORDER_SPECIFICATION",
          response,
          orderItemID: orderItemID,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ-наряд", error);
          });
        }
        dispatch({ type: "FETCH_WORK_ORDER_SPECIFICATION_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_WORK_ORDER_REQUEST" });
    return api
      .delete(`/work_orders/${id}`)
      .then((response) => {
        api.openNotification("success", "Заказ-наряд", "Успешно удален!");
        dispatch({ type: "DELETE_WORK_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({
          type: "DELETE_WORK_ORDER_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_WORK_ORDER_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_WORK_ORDER_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDERS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// General
Actions.onChangeDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_DATE",
      value: value,
    });
  };
};

Actions.onChangeNumber = (value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_NUMBER",
      value: value,
    });
  };
};

Actions.onChangeStatus = (item) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_STATUS",
      item: item,
    });
  };
};

Actions.onChangeAssignee = (item) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ASSIGNEE",
      item: item,
    });
  };
};

Actions.onChangeStocked = (idx, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_STOCKED",
      idx: idx,
      value: value,
    });
  };
};

// Items
Actions.onAddItem = (orderItemID) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_ADD_ITEM",
      orderItemID: orderItemID,
    });
  };
};

Actions.onChangeProduct = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_PRODUCT",
      id: id,
      item: item,
    });
  };
};

Actions.onChangeUnit = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_UNIT",
      id: id,
      item: item,
    });
  };
};

Actions.onChangeQuantity = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_QUANTITY",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeForOrder = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_FOR_ORDER",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeOrdered = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_ORDERED",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeDescription = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_DESCRIPTION",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeSubcontract = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_SUBCONTRACT",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeCompany = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_COMPANY",
      id: id,
      item: item,
    });
  };
};

Actions.onChangePurchase = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDER_CHANGE_ITEM_PURCHASE",
      id: id,
      value: value,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDERS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDERS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterDownloadRows = (value) => {
  return (dispatch) => {
    dispatch({
      type: "WORK_ORDERS_FILTER_DOWNLOAD_ROWS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.downloadReport = (id, number = "", printForm = "form") => {
  api
    .fetchBlob(`/work_orders/${id}.pdf`, { print_form: printForm })
    .then((response) => {
      //console.log(response)
      download(response, `work_order_${printForm}_${number}.pdf`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

Actions.downloadXLSReport = (params, url = "", filename = "") => {
  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    company: params.company,
    isDownloadRows: params.isDownloadRows
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      download(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
