import React from "react";
import { Form } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

export default function Status({
  currentUser = null,
  getFieldDecorator = null,
  onChangeStatus = null,
  rfpId = null,
  status = null
}) {
  return (
    <FormItem label={"Статус (для перехода в статус в работе требуется КП)"}>
      {getFieldDecorator("status", {
        initialValue: status
      })(
        <SelectFetch
          placeholder="Статус"
          onChange={onChangeStatus}
          selected={status}
          url={`/request_proposals/${rfpId}/statuses`}
        />
      )}
    </FormItem>
  );
}
