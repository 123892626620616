// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/shipments";
import ShipmentModalForm from "./Form";
import _ from "lodash/array";

class ShipmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  onSave = () => {
    if (this.props.shipment.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props
        .dispatch(Actions.onCreate(this.props.match.params.id))
        .then(() => {
          this.props.dispatch(
            Actions.fetchShipments(this.props.match.params.id)
          );
          this.props.dispatch(Actions.onToggleVisible());
        });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onDeleteItems = ids => {
    ids.map(id => {
      let idx = _.findIndex(this.props.shipment.shipment_items, ["id", id]);
      if (idx === -1) {
        return null;
      }
      if (this.props.shipment.shipment_items[idx].is_exist) {
        this.props.dispatch(Actions.onDeleteItem(idx));
      } else {
        this.props.dispatch(Actions.onDestroyItem(idx));
      }
    });
  };

  onChangeNumber = value => {
    this.props.dispatch(Actions.onChangeNumber(value));
  };

  onChangeDate = value => {
    this.props.dispatch(Actions.onChangeDate(value));
  };

  onChangeItemQuantity = (id, value) => {
    this.props.dispatch(Actions.onChangeItemQuantity(id, value));
  };

  onChangeItemAmount = (id, value) => {
    this.props.dispatch(Actions.onChangeItemAmount(id, value));
  };

  onChangeItemAmountVat = (id, value) => {
    this.props.dispatch(Actions.onChangeItemAmountVat(id, value));
  };

  render() {
    const { isLoading, shipment, isVisible, onSave } = this.props;
    return (
      <ShipmentModalForm
        isLoading={isLoading}
        visible={isVisible}
        shipment={shipment}
        onSave={onSave}
        onCancel={this.onCancel}
        onDeleteItems={this.onDeleteItems}
        onChangeNumber={this.onChangeNumber}
        onChangeDate={this.onChangeDate}
        onChangeItemQuantity={this.onChangeItemQuantity}
        onChangeItemAmount={this.onChangeItemAmount}
        onChangeItemAmountVat={this.onChangeItemAmountVat}
        destroyOnClose
      />
    );
  }
}

ShipmentModal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  shipment: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.shipments.isLoading,
  isVisible: state.shipments.isVisible,
  errors: state.shipments.errors,
  shipment: state.shipments.shipment
});

export default connect(mapStateToProps)(withRouter(ShipmentModal));
