// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Menu, Dropdown, Icon } from "antd";
import { Tabs } from "antd";
import Documents from "../../../components/Documents";
import Activities from "../../../components/Activities";
import Statuses from "./Statuses";
import Items from "./Items";
import Shipments from "./Shipments";
import PaymentSchedules from "./PaymentSchedules";
import Payments from "./Payments";
import General from "./General";
import moment from "moment";
import "moment/locale/ru";

const TabPane = Tabs.TabPane;

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = { tabIndex: "1" };
  }

  onChangeTab = (key) => {
    this.setState({ tabIndex: key }, () => {
      if (key == 2 && !this.props.isNew) {
        this.props.fetchShipments();
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  onAdressEdit = (e) => {
    e.preventDefault();
    this.setState({ isAddressEdit: !this.state.isAddressEdit });
  };

  render() {
    const {
      isNew,
      isLoading,
      isLoadingWorkOrder,
      order,
      onClose,
      onFillInvoice,
      fetchSuggestions,
      onChangeCompany,
      onChangeDate,
      onChangeNumber,
      onChangeStatus,
      onChangeSourceType,
      onChangeRequestProposal,
      onChangeAssignee,
      onChangeAgreement,
      onChangeAgreementDate,
      onChangeAdditional,
      onChangeShipAddress,
      onChangeShippingAddress,
      onChangeSpecialInstruction,
      onCreateWorkOrder,
      onAddItem,
      onDeleteItems,
      onChangeItemProduct,
      onChangeItemDescription,
      onChangeItemUnit,
      onChangeItemQuantity,
      onChangeItemPrice,
      onChangeItemVatRate,
      onChangeItemVat,
      onChangeItemWillShipped,
      onChangeItemWillShippedDays,
      // shipments
      shipments,
      isVisibleShipment,
      onCloseShipmentModal,
      isShipmentsLoading,
      onAddShipment,
      onEditShipment,
      onSaveShipment,
      onDeleteShipments,
      // paymentSchedules
      onAddPaymentSchedule,
      onDeletePaymentSchedules,
      onChangePaymentSchedulesDate,
      onChangePaymentSchedulesPaymentType,
      onChangePaymentSchedulesAmount,
      onChangePaymentSchedulesPercent,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const orderItems = order.order_items.filter(function (item) {
      return !item._destroy;
    });
    const paymentSchedules = order.order_payment_schedules.filter(function (
      item
    ) {
      return !item._destroy;
    });

    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={onFillInvoice}>
          <Icon type="build" />
          Создать счет
        </Menu.Item>
      </Menu>
    );

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Заказ #${
            order.number ? order.number : "новый"
          } от ${moment(order.date).format("LLL")}`}</h2>
        }
        extra={[
          <Button onClick={onClose}>Закрыть</Button>,
          <Button
            onClick={this.onSubmit}
            type="primary"
            ghost
            style={{ marginLeft: "8px" }}
          >
            Сохранить
          </Button>,
          isNew || order.paid == order.total ? null : (
            <Dropdown overlay={menu}>
              <Button style={{ marginLeft: "8px" }}>
                Действия <Icon type="down" />
              </Button>
            </Dropdown>
          ),
          <Activities key={"activityButton"} parentId={order.id} parentType={'Order'}/>,
        ]}
      >
        <Form layout={"vertical"} onSubmit={this.onSubmit}>
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Row>
              <General
                getFieldDecorator={getFieldDecorator}
                order={order}
                fetchSuggestions={fetchSuggestions}
                onChangeCompany={onChangeCompany}
                onChangeAssignee={onChangeAssignee}
                onChangeAgreement={onChangeAgreement}
                onChangeAgreementDate={onChangeAgreementDate}
                onChangeAdditional={onChangeAdditional}
                onChangeShipAddress={onChangeShipAddress}
                onChangeShippingAddress={onChangeShippingAddress}
                onChangeSpecialInstruction={onChangeSpecialInstruction}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Row>
              <Statuses
                isNew={isNew}
                getFieldDecorator={getFieldDecorator}
                order={order}
                isLoadingWorkOrder={isLoadingWorkOrder}
                onChangeStatus={onChangeStatus}
                onChangeSourceType={onChangeSourceType}
                onChangeRequestProposal={onChangeRequestProposal}
                onChangeDate={onChangeDate}
                onChangeNumber={onChangeNumber}
                onCreateWorkOrder={onCreateWorkOrder}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Tabs activeKey={this.state.tabIndex} onChange={this.onChangeTab}>
              <TabPane tab="Продукция" key="1">
                <Items
                  orderItems={orderItems}
                  total={Number(order.total)}
                  shippingCost={Number(order.shipping_cost)}
                  amountDiscountCode={Number(order.amount_discount)}
                  discount={order.discount}
                  fetchSuggestions={fetchSuggestions}
                  onAddItem={onAddItem}
                  onDeleteItems={onDeleteItems}
                  onChangeItemProduct={onChangeItemProduct}
                  onChangeItemDescription={onChangeItemDescription}
                  onChangeItemUnit={onChangeItemUnit}
                  onChangeItemQuantity={onChangeItemQuantity}
                  onChangeItemPrice={onChangeItemPrice}
                  onChangeItemVatRate={onChangeItemVatRate}
                  onChangeItemVat={onChangeItemVat}
                  onChangeItemWillShipped={onChangeItemWillShipped}
                  onChangeItemWillShippedDays={onChangeItemWillShippedDays}
                />
              </TabPane>
              <TabPane tab="Отгрузки" key="2">
                <Shipments
                  shipments={shipments}
                  onEditShipment={onEditShipment}
                  isVisibleShipment={isVisibleShipment}
                  onCloseShipmentModal={onCloseShipmentModal}
                  isShipmentsLoading={isShipmentsLoading}
                  onAddShipment={onAddShipment}
                  onSaveShipment={onSaveShipment}
                  onDeleteShipments={onDeleteShipments}
                />
              </TabPane>
              <TabPane tab="График платежей" key="3">
                <PaymentSchedules
                  paymentSchedules={paymentSchedules}
                  onAddPaymentSchedule={onAddPaymentSchedule}
                  onDeletePaymentSchedules={onDeletePaymentSchedules}
                  onChangePaymentSchedulesDate={onChangePaymentSchedulesDate}
                  onChangePaymentSchedulesPaymentType={
                    onChangePaymentSchedulesPaymentType
                  }
                  onChangePaymentSchedulesAmount={
                    onChangePaymentSchedulesAmount
                  }
                  onChangePaymentSchedulesPercent={
                    onChangePaymentSchedulesPercent
                  }
                />
              </TabPane>
              <TabPane tab="Платежи" key="4">
                <Payments orderPayments={order.inpayment_items} />
              </TabPane>
              {isNew ? null : (
                <TabPane tab="Файлы" key="5">
                  <Documents ownerID={order.id} ownerType={"Order"} />
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Form>
      </Card>
    );
  }
}

OrderForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeShipAddress: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(OrderForm);
