import React from "react";
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../actions/session";
import MainHeader from "./MainHeader";
import MenuRoutes from "./MenuRoutes";
const { Sider, Content } = Layout;


class MainLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      isSidebarCollapsed: true,
      menuCollapsed: true,
    };
  }

  componentDidMount() {
    // const isSidebarCollapsed = localStorage.getItem("isSidebarCollapsed");
    // if (isSidebarCollapsed) {
    //   this.setState({
    //     isSidebarCollapsed: isSidebarCollapsed.value
    //   });
    // }
  }

  toggle = () => {
    this.setState(
      {
        isSidebarCollapsed: !this.state.isSidebarCollapsed,
      },
      () => {
        localStorage.setItem(
          "isSidebarCollapsed",
          this.state.isSidebarCollapsed
        );
      }
    );
  };

  onLogout = () => {
    this.props.dispatch(Actions.logout());
  };

  render() {
    const {currentUser}  = this.props;
    return (
      <Layout>
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
          trigger={null}
          collapsible
          collapsed={this.state.isSidebarCollapsed}
        >
          <div className="logo" />
          <MenuRoutes
              role={currentUser.role}
              collapsed={this.state.isSidebarCollapsed.toString()}
            />
        </Sider>
        <Layout
          style={{
            minHeight: "100vh",
            overflowX: "hidden",
            marginLeft: this.state.isSidebarCollapsed ? 80 : 200,
          }}
        >
          <MainHeader
            isSidebarCollapsed={this.state.isSidebarCollapsed}
            currentUser={currentUser}
            onToggleSideBar={this.toggle}
            onLogout={this.onLogout}
          />
          <Content
            style={{
              margin: "80px 16px 16px 16px",
              // padding: 24,
              // background: "#fff",
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.session.isLoading,
  currentUser: state.session.currentUser,
  errors: state.session.errors,
});

export default connect(mapStateToProps)(MainLayout);
