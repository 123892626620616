// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/companies";
import InviocesActions from "../../../actions/invoices";
import { Spin } from "antd";
import Form from "./Form";
import api from "../../../api";
import axios from "axios";

async function fetchPartOfAddress(url = "", params) {
  let API = "";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    API = "http://localhost:3000/api";
  } else {
    API = "http://mtcrm:3000/api";
  }
  try {
    const dataPromise = axios.get(`${API}/${url}`, { params: params });
    return await Promise.all([dataPromise]);
  } catch (error) {
    console.error(error);
  }
}

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      suggest: null
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props
        .dispatch(Actions.fetchCompany(this.props.match.params.id))
        .then(() => {
          this.fetchOrders();
        });
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/companies/${this.props.company.id}/edit`);
        }
      });
    }
  };

  onDelete = id => {
    this.props.dispatch(Actions.deleteOrder(id));
  };

  // header
  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeCode = e => {
    this.props.dispatch(Actions.onChangeCode(e.target.value));
  };

  onChangeCompanyType = value => {
    this.props.dispatch(Actions.onChangeCompanyType(value));
  };

  onChangeFullName = e => {
    this.props.dispatch(Actions.onChangeFullName(e.target.value));
  };

  onChangeInn = e => {
    this.props.dispatch(Actions.onChangeInn(e.target.value));
  };

  onChangeKpp = e => {
    this.props.dispatch(Actions.onChangeKpp(e.target.value));
  };

  onChangeAssignee = value => {
    this.props.dispatch(Actions.onChangeAssignee(value));
  };

  onChangeContactType = value => {
    this.props.dispatch(Actions.onChangeContactType(value));
  };

  onChangeContactSource = value => {
    this.props.dispatch(Actions.onChangeContactSource(value));
  };

  onChangeBusinessField = value => {
    this.props.dispatch(Actions.onChangeBusinessField(value));
  };

  // addresses

  fillCompanyByInn = (data, address) => {
    let suggest = null;
    console.log(address);

    // let statePromise = fetchPartOfAddress(`/states/suggest/?`, {
    //   search: address.region
    // });
    // let cityPromise = fetchPartOfAddress(`/cities/suggest/?`, {
    //   search: address.city
    // });
    // const [state, city] = Promise.all([statePromise, cityPromise]);
    // fetchPartOfAddress(`/states/suggest/?`, { search: address.region }).then(
    //   data => {
    //     address.state = data;
    //     address.city  = null;
    //     this.props.dispatch(Actions.fillCompanyByInn(data, address));
    //   }
    // );
    // if (res) {
    // console.log(state, city);
    // }
    // console.log(state)
    // this.props.dispatch(Actions.fillCompanyByInn(data, address));
  };

  onSaveAddress = data => {
    const { company } = this.props;
    let idx = company.addresses.findIndex(function(o) {
      return o.id == data.id;
    });

    if (idx !== -1) {
      this.props.dispatch(Actions.onChangeAddress(idx, data));
    } else {
      this.props.dispatch(Actions.onAddAddress(data));
    }
  };

  onDeleteAddress = id => {
    const { company } = this.props;
    let idx = company.addresses.findIndex(function(o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    if (company.addresses[idx].is_exist) {
      this.props.dispatch(Actions.onDeleteAddress(idx));
    } else {
      this.props.dispatch(Actions.onDestroyAddress(idx));
    }
  };

  onSaveEmail = data => {
    const { company } = this.props;
    let idx = company.emails.findIndex(function(o) {
      return o.id == data.id;
    });

    if (idx !== -1) {
      this.props.dispatch(Actions.onChangeEmail(idx, data));
    } else {
      this.props.dispatch(Actions.onAddEmail(data));
    }
  };

  onDeleteEmail = id => {
    const { company } = this.props;
    let idx = company.emails.findIndex(function(o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    if (company.emails[idx].is_exist) {
      this.props.dispatch(Actions.onDeleteEmail(idx));
    } else {
      this.props.dispatch(Actions.onDestroyEmail(idx));
    }
  };

  onSavePhone = data => {
    const { company } = this.props;
    let idx = company.phones.findIndex(function(o) {
      return o.id == data.id;
    });

    if (idx !== -1) {
      this.props.dispatch(Actions.onChangePhone(idx, data));
    } else {
      this.props.dispatch(Actions.onAddPhone(data));
    }
  };

  onDeletePhone = id => {
    const { company } = this.props;
    let idx = company.phones.findIndex(function(o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    if (company.phones[idx].is_exist) {
      this.props.dispatch(Actions.onDeletePhone(idx));
    } else {
      this.props.dispatch(Actions.onDestroyPhone(idx));
    }
  };

  // tabs
  fetchOrders = () => {
    this.props.dispatch(Actions.fetchOrders());
  };

  fetchInvoices = () => {
    this.props
      .dispatch(
        InviocesActions.onChangeCompanyParams(this.props.match.params.id)
      )
      .then(() => {
        this.props.dispatch(InviocesActions.fetchInvoices());
      });
  };

  fetchFiles = () => {
    console.log("files");
  };

  render() {
    const {
      isLoading,
      company,
      isLoadingOrders,
      orders,
      isInvoicesLoading,
      invoices
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={this.state.isNew}
        isLoading={isLoading}
        company={company}
        onSave={this.onSave}
        // header
        onChangeName={this.onChangeName}
        onChangeCode={this.onChangeCode}
        onChangeCompanyType={this.onChangeCompanyType}
        onChangeFullName={this.onChangeFullName}
        onChangeInn={this.onChangeInn}
        fillCompanyByInn={this.fillCompanyByInn}
        onChangeKpp={this.onChangeKpp}
        // sidebar
        onChangeAssignee={this.onChangeAssignee}
        onChangeContactType={this.onChangeContactType}
        onChangeContactSource={this.onChangeContactSource}
        onChangeBusinessField={this.onChangeBusinessField}
        // address
        onSaveAddress={this.onSaveAddress}
        onDeleteAddress={this.onDeleteAddress}
        // emails
        onSaveEmail={this.onSaveEmail}
        onDeleteEmail={this.onDeleteEmail}
        // phones
        onSavePhone={this.onSavePhone}
        onDeletePhone={this.onDeletePhone}
        // tabs
        fetchOrders={this.fetchOrders}
        fetchInvoices={this.fetchInvoices}
        fetchFiles={this.fetchFiles}
        isLoadingOrders={isLoadingOrders}
        orders={orders}
        isInvoicesLoading={isInvoicesLoading}
        invoices={invoices}
      />
    );
  }
}

Company.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  company: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.companies.isLoading,
  errors: state.companies.errors,
  company: state.companies.company,
  isLoadingOrders: state.companies.isLoadingOrders,
  orders: state.companies.orders,
  isInvoicesLoading: state.invoices.isLoading,
  invoices: state.invoices.invoices
});

export default connect(mapStateToProps)(withRouter(Company));
