// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker
} from "antd";

import SelectFetch from "../../../components/SelectFetch";
import InputMask from "react-input-mask";

import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;

class General extends Component {
  render() {
    const {
      isNew,
      getFieldDecorator,
      isLoading,
      purchase,
      onChangeCompany,
      onChangeAgreementDate,
      onChangeAgreement,
      onChangeCode,
      onChangeTotal,
      onChangeVatTotal
    } = this.props;

    return (
      <Row gutter={16}>
        <Col span={12}>
          <FormItem label={"Поставщик"}>
            {getFieldDecorator("company", {
              initialValue: purchase.company ? purchase.company.id : null,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, выберите подрядчика!"
                }
              ]
            })(
              <SelectFetch
                showSearch
                placeholder="Поставщик"
                onChange={onChangeCompany}
                selected={purchase.company ? purchase.company : null}
                url={"/companies/search?"}
              />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Дата договора"}>
            {getFieldDecorator("agreementDate", {
              onChange: onChangeAgreementDate,
              initialValue: purchase.agreement_date
                ? moment(purchase.agreement_date)
                : null,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите дату договора!"
                }
              ]
            })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("agreement", {
              onChange: onChangeAgreement,
              initialValue: purchase.agreement,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите наименование договора!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Шифр"}>
            {getFieldDecorator("code", {
              onChange: onChangeCode,
              initialValue: purchase.code,
              rules: [
                {
                  required: isNew ? false : true,
                  message: "Пожалуйста, введите шифр договора!"
                }
              ]
            })(<InputMask disabled={isNew ? true : false} className="ant-input" mask="99/99/999" />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Сумма договора"}>
            {getFieldDecorator("total", {
              onChange: onChangeTotal,
              initialValue: purchase.total,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите сумму договора!"
                }
              ]
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"НДС"}>
            {getFieldDecorator("vat_total", {
              onChange: onChangeVatTotal,
              initialValue: purchase.vat_total
            })(<Input type="number" />)}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  order: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default General;
