import api from "../api";

const Actions = {};

Actions.fetchDepartments = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DEPARTMENTS_REQUEST" });
    const params = getState().departments.fetchParams;
    api
      .fetch("/departments", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_DEPARTMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_DEPARTMENTS_FAILURE", response });
      });
  };
};

Actions.fetchDepartment = departmentID => {
  return dispatch => {
    dispatch({ type: "FETCH_DEPARTMENT_REQUEST" });
    api
      .fetch(`/departments/${departmentID}`)
      .then(response => {
        dispatch({ type: "FETCH_DEPARTMENT", response });
      })
      .catch(response => {
        dispatch({ type: "FETCH_DEPARTMENT_FAILURE", response });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_DEPARTMENT_REQUEST" });
    const department = getState().departments.department;
    const data = {
      department: {
        name: department.name,
        full_name: department.full_name
      }
    };
    return api
      .patch(`/departments/${department.id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_DEPARTMENT", response });
        api.openNotification(
          "success",
          "Подразделение",
          "Успешно обновлена!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Подразделение", error);
          });
        }
        dispatch({ type: "PATCH_DEPARTMENT_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "DEPARTMENT_NEW"
    });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_DEPARTMENT_REQUEST" });
    const department = getState().departments.department;
    const data = {
      department: {
        name: department.name,
        full_name: department.full_name
      }
    };
    return api
      .post(`/departments`, data)
      .then(response => {
        dispatch({ type: "POST_DEPARTMENT", response });
        api.openNotification(
          "success",
          "Подразделение",
          "Успешно создано!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Подразделение", error);
          });
        }
        dispatch({ type: "POST_DEPARTMENT_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_DEPARTMENT_REQUEST" });
    return api
      .delete(`/departments/${id}`)
      .then(response => {
        api.openNotification("success", "Подразделение", "Успешно удалено!");
        dispatch({ type: "DELETE_DEPARTMENT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Подразделение", error);
          });
        }
        dispatch({
          type: "DELETE_DEPARTMENT_FAILURE"
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_DEPARTMENT_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_DEPARTMENT_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_DEPARTMENT_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "DEPARTMENT_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeFullName = value => {
  return dispatch => {
    dispatch({
      type: "DEPARTMENT_CHANGE_FULL_NAME",
      value: value
    });
  };
};

export default Actions;
