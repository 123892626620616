// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Select, Button } from "antd";
import { Row, Col, PageHeader, Form, DatePicker } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      onChangePeriodicity,
      onChangeShippingPeriod,
      downloadReport,
    } = this.props;

    const reportGroupByPeriod = report.reduce(
      (
        r,
        v,
        i,
        a,
        k = moment(v.date).startOf(fetchParams.periodicity).format("MM.DD.YY")
      ) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
    // console.log(reportGroupByPeriod);

    let reportData = [];
    Object.keys(reportGroupByPeriod).map((key) => {
      let quantityTotal = reportGroupByPeriod[key].reduce(
        (sum, record) => sum + Number(record.quantity),
        0
      );
      let amountTotal = reportGroupByPeriod[key].reduce(
        (sum, record) => sum + Number(record.amount),
        0
      );
      let vatTotal = reportGroupByPeriod[key].reduce(
        (sum, record) => sum + Number(record.vat),
        0
      );
      reportData.push({
        id: `period-${key}`,
        product_id: null,
        product_name: null,
        description: null,
        date: key,
        company_name: null,
        assignee: null,
        quantity: quantityTotal,
        amount: amountTotal,
        vat: vatTotal,
        order_id: null,
        order_number: null,
        order_date: null,
        shipped_quantity: 0,
        shipped_amount: 0,
        shipped_amount_vat: 0,
        invoices: [],
        isGoup: true,
        isSubtotal: false,
      });
      reportGroupByPeriod[key].map((item) => {
        reportData.push({
          id: item.id,
          product_id: item.product_id,
          product_name: item.product_name,
          description: item.description,
          date: item.date,
          company_name: item.company_name,
          assignee: item.assignee,
          quantity: item.quantity,
          amount: item.amount,
          vat: item.vat,
          order_id: item.order_id,
          order_number: item.order_number,
          order_date: item.order_date,
          shipped_quantity: item.shipped_quantity,
          shipped_amount: item.shipped_amount,
          shipped_amount_vat: item.shipped_amount_vat,
          invoices: item.invoices,
          isGoup: false,
          isSubtotal: false,
        });
      });
    });

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        render: (date, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>
                  {fetchParams.periodicity == "month"
                    ? moment(date).format("MMMM YYYY")
                    : `${moment(date).quarter()} квартал`}
                </b>
              ),
              props: {
                colSpan: 2,
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: moment(date).format("L"),
          };
        },
        align: "center",
        width: "10%",
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <div style={{ textAlign: "center" }}>
              <span>Заказ</span>
              <br />
              <span>Дата заказа</span>
              <br />
              <span>Счет</span>
            </div>
          </div>
        ),
        dataIndex: "order_number",
        render: (order_number, record) => {
          if (record.isGoup) {
            return {
              props: {
                colSpan: 0,
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  <Link to={`/orders/${record.order_id}/edit`}>
                    {order_number}
                  </Link>
                </span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {moment(record.order_date).format("L")}
                </span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {record.invoices}
                </span>
              </div>
            ),
          };
        },
        align: "center",
        width: "10%",
      },
      {
        title: "Количество",
        dataIndex: "quantity",
        render: (quantity, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(quantity), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: accounting.formatNumber(Number(quantity), 0, " "),
          };
        },
        align: "right",
        width: "5%",
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(record.amount), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: accounting.formatNumber(Number(record.amount), 0, " "),
          };
        },
        align: "right",
        width: "10%",
      },
      {
        title: "Оборудование",
        render: (product_name, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{record.product_name}</span>
                <span style={{ fontSize: "12px", opacity: "0.7" }}>
                  {record.description}
                </span>
              </div>
            ),
          };
        },

        key: "product_name",
        width: "32%",
      },
      {
        title: "Компания",
        dataIndex: "company_name",
        render: (company_name, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: record.company_name,
          };
        },

        key: "company_name",
        width: "20%",
      },
      {
        title: "Ответственный",
        render: (assignee, record) => {
          if (record.isGoup) {
            return {
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: record.assignee,
          };
        },
        width: "13%",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Плановые отгрузки (оборудование)`}
        extra={[
          <Button
            key="3"
            type="dashed"
            icon="file-excel"
            onClick={downloadReport}
          >
            Загрузить
          </Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <FormItem label={"Группировка"}>
              <Select
                style={{ width: "100%" }}
                value={fetchParams.periodicity}
                placeholder="Периодичность"
                optionFilterProp="children"
                filterOption={false}
                onChange={onChangePeriodicity}
              >
                <Option value={"month"}>месяц</Option>
                <Option value={"quarter"}>квартал</Option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <FormItem label={"Период плана отгрузок"}>
              <RangePicker
                showTime={false}
                format={"DD.MM.YYYY"}
                placeholder={["начало", "окончание"]}
                style={{ width: "100%" }}
                value={[
                  fetchParams.startShippingDate
                    ? moment(fetchParams.startShippingDate, "DD.MM.YYYY")
                    : null,
                    fetchParams.endShippingDate
                    ? moment(fetchParams.endShippingDate, "DD.MM.YYYY")
                    : null,
                ]}
                onChange={onChangeShippingPeriod}
              />
            </FormItem>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
