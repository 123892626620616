import api from "../api";

const Actions = {};

Actions.fetchProducts = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PRODUCTS_REQUEST" });
    const params = getState().products.fetchParams;
    return api
      .fetch("/products", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_PRODUCTS", response });
      })
      .catch(response => {
        // console.log(response.error);
        dispatch({ type: "FETCH_PRODUCTS_FAILURE", response });
      });
  };
};

Actions.fetchProduct = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_PRODUCT_REQUEST" });
    api
      .fetch(`/products/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_PRODUCT", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_PRODUCT_FAILURE", response });
      });
  };
};

Actions.fetchCategories = () => {
  return dispatch => {
    dispatch({ type: "FETCH_CATEGORIES_REQUEST" });
    return api
      .fetch("/product_categories")
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_CATEGORIES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_CATEGORIES_FAILURE", response });
      });
  };
};

Actions.fetchCategory = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_CATEGORY_REQUEST" });
    return api
      .fetch(`/product_categories/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_CATEGORY", response });
      })
      .catch((error) => {
        api.handleError(error, "Категория");
        dispatch({ type: "FETCH_CATEGORY_FAILURE" });
      });
  };
};

Actions.fetchOrders = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PRODUCT_ORDERS_REQUEST" });
    return api
      .fetch(`/products/${id}/orders`)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_PRODUCT_ORDERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_PRODUCT_ORDERS_FAILURE", response });
      });
  };
};

Actions.onChangeCategory = data => {
  return (dispatch) => {
    dispatch({ type: "PRODUCT_CHANGE_CATEGORY_REQUEST" });
    
    return api
      .patch(`/products/move`, data)
      .then(response => {
        api.openNotification("success", "Продукты", "Успешно обновлены!");
        dispatch({ type: "PRODUCT_CHANGE_CATEGORY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Продукт", error);
          });
        }
        dispatch({ type: "PRODUCT_CHANGE_CATEGORY_FAILURE", response });
      });
  };
};

Actions.onUpdateProduct = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PRODUCT_REQUEST" });
    const product = getState().products.product;
    const productSpecifications = product.product_specifications.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        product_specification_id: item.product_specification
          ? item.product_specification.id
          : null,
        quantity: item.quantity,
        cost: item.cost,
        _destroy: item._destroy
      };
    });

    const data = {
      product: {
        active: product.active,
        name: product.name,
        description: product.description,
        sku: product.sku,
        price: product.price,
        is_price: product.is_price,
        unit_id: product.unit ? product.unit.id : null,
        user_id: product.user_id,
        is_price: product.is_price,
        product_specifications_attributes: productSpecifications
      }
    };
    return api
      .patch(`/products/${id}`, data)
      .then(response => {
        api.openNotification("success", "Продукт", "Успешно обновлен!");
        dispatch({ type: "PATCH_PRODUCT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Продукт", error);
          });
        }
        dispatch({ type: "PATCH_PRODUCT_FAILURE", response });
      });
  };
};

Actions.onCreateProduct = categoryId => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PRODUCT_REQUEST" });
    const product = getState().products.product;
    const productSpecifications = product.product_specifications.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        product_specification_id: item.product_specification
          ? item.product_specification.id
          : null,
        quantity: item.quantity,
        cost: item.cost,
        _destroy: item._destroy
      };
    });

    const data = {
      product: {
        product_category_id: categoryId,
        active: product.active,
        name: product.name,
        description: product.description,
        sku: product.sku,
        price: product.price,
        is_price: product.is_price,
        unit_id: product.unit ? product.unit.id : null,
        user_id: product.user_id,
        is_price: product.is_price,
        product_specifications_attributes: productSpecifications
      }
    };
    return api
      .post(`/products`, data)
      .then(response => {
        api.openNotification("success", "Продукт", "Успешно создан!");
        dispatch({ type: "POST_PRODUCT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Продукт", error);
          });
        }
        dispatch({ type: "POST_PRODUCT_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_PRODUCT_REQUEST" });
    return api
      .delete(`/products/${id}`)
      .then(response => {
        api.openNotification("success", "Продукт", "Успешно удален!");
        dispatch({ type: "DELETE_PRODUCT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Продукт", error);
          });
        }
        dispatch({
          type: "DELETE_PRODUCT_FAILURE"
        });
      });
  };
};

// categories
Actions.onCreateCategory = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CATEGORY_REQUEST" });
    const category = getState().products.category;
    const params = {
      product_category: {
        name: category.name,
        is_active: category.is_active,
        is_suggest: category.is_suggest,
        parent_id: category.ancestry,
      },
    };

    return api
      .post(`/product_categories`, params)
      .then((response) => {
        api.openNotification("success", "Категория", "Успешно создана!");
        dispatch({ type: "POST_CATEGORY_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Категория", error);
          });
        }
        dispatch({ type: "POST_CATEGORY_FAILURE", response });
      });
  };
};

Actions.onUpdateCategory = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CATEGORY_REQUEST" });
    const category = getState().products.category;

    const params = {
      product_category: {
        name: category.name,
        is_active: category.is_active,
        is_suggest: category.is_suggest,
        parent_id: category.parent_id,
      },
    };
    return api
      .patch(`/product_categories/${id}`, params)
      .then((response) => {
        api.openNotification("success", "Категория", "Успешно обновлена!");
        dispatch({ type: "PATCH_CATEGORY_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Категория", error);
          });
        }
        dispatch({ type: "PATCH_CATEGORY_FAILURE", response });
      });
  };
};

Actions.onDeleteCategory = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_CATEGORY_REQUEST" });
    return api
      .delete(`/product_categories/${id}`)
      .then((response) => {
        api.openNotification("success", "Категория", "Успешно удалена!");
        dispatch({ type: "DELETE_CATEGORY_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Категория", error);
          });
        }
        dispatch({
          type: "DELETE_CATEGORY_FAILURE",
        });
      });
  };
};

Actions.onChangeCategoryName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeCategoryPosition = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_POSITION",
      value: value,
    });
  };
};

Actions.onChangeCategoryActive = value => {
  return dispatch => {
    dispatch({
      type: "CATEGORY_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeCategorySuggest = value => {
  return dispatch => {
    dispatch({
      type: "CATEGORY_CHANGE_SUGGEST",
      value: value
    });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_NEW"
    });
  };
};

Actions.onNewCategory = (ancestry) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_NEW",
      ancestry: ancestry,
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PRODUCT_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSelectCategory = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCTS_SELECT_CATEGORY",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PRODUCTS_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeDescription = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_DESCRIPTION",
      value: value
    });
  };
};

Actions.onChangeSku = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_SKU",
      value: value
    });
  };
};

Actions.onChangePrice = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_PRICE",
      value: value
    });
  };
};

Actions.onChangeUnit = item => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_UNIT",
      value: item
    });
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeIsPrice = value => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_IS_PRICE",
      value: value
    });
  };
};

// Items
Actions.onAddSpecificationItem = () => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_ADD_SPECIFICATION_ITEM"
    });
  };
};

Actions.onDeleteSpecificationItems = items => {
  return (dispatch, getState) => {
    items.map(item => {
      let productSpecifications = getState().products.product
        .product_specifications;
      let idx = productSpecifications.findIndex(function(p) {
        return p.id == item;
      });
      if (idx === -1) {
        return null;
      }
      if (productSpecifications[idx].is_exist) {
        dispatch({
          type: "PRODUCT_SPECIFICATION_ITEM_DESTROY",
          idx: idx
        });
      } else {
        dispatch({
          type: "PRODUCT_SPECIFICATION_ITEM_DELETE",
          idx: idx
        });
      }
    });
  };
};

Actions.onChangeSpecificationProduct = (id, item) => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_SPECIFICATION_ITEM_PRODUCT",
      id: id,
      value: item
    });
  };
};

Actions.onChangeSpecificationCost = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_SPECIFICATION_ITEM_COST",
      id: id,
      value: value
    });
  };
};

Actions.onChangeSpecificationQuantity = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PRODUCT_CHANGE_SPECIFICATION_ITEM_QUANTITY",
      id: id,
      value: value
    });
  };
};

export default Actions;
