import api from "../api";

const Actions = {};

Actions.fetchServiceOrders = () => {
  return (dispatch, getState) => {
    const params = getState().serviceOrders.fetchParams;
    dispatch({ type: "FETCH_SERVICE_ORDERS_REQUEST" });
    api
      .fetch("/service_orders", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_SERVICE_ORDERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_SERVICE_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchServiceOrder = id => {
  return dispatch => {
    dispatch({ type: "FETCH_SERVICE_ORDER_REQUEST" });
    return api
      .fetch(`/service_orders/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_SERVICE_ORDER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Списание услуг", error);
          });
        }
        dispatch({ type: "FETCH_SERVICE_ORDER_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "SERVICE_ORDER_ADD" });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_SERVICE_ORDER_REQUEST" });
    const serviceOrder = getState().serviceOrders.serviceOrder;

    const data = {
      service_order: {
        active: serviceOrder.active,
        date: serviceOrder.date,
        order_id: serviceOrder.order ? serviceOrder.order.id : null,
        shipment_id: serviceOrder.shipment ? serviceOrder.shipment.id : null,
        product_id: serviceOrder.product ? serviceOrder.product.id : null,
        cost: serviceOrder.cost,
      }
    };

    return api
      .patch(`/service_orders/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_SERVICE_ORDER", response });
        api.openNotification(
          "success",
          "Списание услуг",
          "Успешно обновлено!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Списание услуг", error);
          });
        }
        dispatch({ type: "PATCH_SERVICE_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_SERVICE_ORDER_REQUEST" });
    const serviceOrder = getState().serviceOrders.serviceOrder;

    const data = {
      service_order: {
        active: serviceOrder.active,
        date: serviceOrder.date,
        order_id: serviceOrder.order ? serviceOrder.order.id : null,
        shipment_id: serviceOrder.shipment ? serviceOrder.shipment.id : null,
        product_id: serviceOrder.product ? serviceOrder.product.id : null,
        cost: serviceOrder.cost,
      }
    };

    return api
      .post(`/service_orders`, data)
      .then(response => {
        dispatch({ type: "POST_SERVICE_ORDER", response });
        api.openNotification(
          "success",
          "Списание услуг",
          "Успешно создано!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Списание услуг", error);
          });
        }
        dispatch({ type: "POST_SERVICE_ORDER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_SERVICE_ORDER_REQUEST" });
    return api
      .delete(`/service_orders/${id}`)
      .then(response => {
        api.openNotification("success", "Списание услуг", "Успешно удалено!");
        dispatch({ type: "DELETE_SERVICE_ORDER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Списание услуг", error);
          });
        }
        dispatch({
          type: "DELETE_SERVICE_ORDER_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "SERVICE_ORDER_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_SERVICE_ORDER_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_SERVICE_ORDER_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeDate = value => {
  return dispatch => {
    dispatch({
      type: "SERVICE_ORDER_CHANGE_DATE",
      value: value
    });
  };
};

Actions.onChangeOrder = (value) => {
  return dispatch => {
    dispatch({
      type: "SERVICE_ORDER_CHANGE_ORDER",
      value: value
    });
  };
};

Actions.onChangeShipment = (value) => {
  return dispatch => {
    dispatch({
      type: "SERVICE_ORDER_CHANGE_SHIPMENT",
      value: value
    });
  };
};

Actions.onChangeProduct = (value) => {
  return dispatch => {
    dispatch({
      type: "SERVICE_ORDER_CHANGE_PRODUCT",
      value: value
    });
  };
};

Actions.onChangeCost = (value) => {
  return dispatch => {
    dispatch({
      type: "SERVICE_ORDER_CHANGE_COST",
      value: value
    });
  };
};

export default Actions;
