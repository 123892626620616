// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Menu, Dropdown, Icon } from "antd";
import Statuses from "./Statuses";
import Items from "./Items";
import General from "./General";
import Activities from "../../../components/Activities";
import moment from "moment";
import "moment/locale/ru";

class InvoiceForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  handleMenuClick = (e) => {
    console.log("click", e);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      isLoading,
      invoice,
      onClose,
      downloadReport,
      downloadReportWithStamp,
      onFillInOrder,
      onChangeCompany,
      onChangeAssignee,
      onChangeAgreement,
      onChangeAgreementDate,
      onChangeAdditional,
      onChangeShippingMethod,
      onChangeBankAccount,
      onChangeShippingAddress,
      onChangeSpecialInstruction,
      // status
      onChangeDate,
      onChangeDuration,
      onChangeOrder,
      onChangeRequestProposal,
      onChangeNumber,
      onChangePaid,
      onChangeInvoiceType,
      onChangePaymentAmount,
      onChangePaymentPercent,
      // items
      onAddItem,
      onDeleteItems,
      onChangeItemProduct,
      onChangeItemDescription,
      onChangeItemUnit,
      onChangeItemQuantity,
      onChangeItemPrice,
      onChangeItemVatRate,
      onChangeItemVat,
      onChangeItemAmount,
    } = this.props;

    const invoiceItems = invoice.invoice_items.filter(function (item) {
      return !item._destroy;
    });

    const menu = (
      <Menu>
        {/* <Menu.Item key="1">
          <Icon type="copy" />
          Скопировать
        </Menu.Item> */}
        <Menu.Item key="2" onClick={onFillInOrder}>
          <Icon type="build" />
          Создать заказ
        </Menu.Item>
        <Menu.Divider />
        {invoiceItems.length > 0 ? (
          <Menu.Item key="3" onClick={downloadReport}>
            <Icon type="printer" />
            Печать
          </Menu.Item>
        ) : null}
        {invoiceItems.length > 0 ? (
          <Menu.Item key="4" onClick={downloadReportWithStamp}>
            <Icon type="printer" />C печатью
          </Menu.Item>
        ) : null}
      </Menu>
    );

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Счет #${
              isNew ? "новый" : invoice.number
            } от ${moment(invoice.date).format("LLL")}`}</h2>
          }
          extra={[
            <Button onClick={onClose}>Закрыть</Button>,
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
            isNew ? null : (
              <Dropdown overlay={menu}>
                <Button style={{ marginLeft: "8px" }}>
                  Действия <Icon type="down" />
                </Button>
              </Dropdown>
            ),
            <Activities
              key={"activityButton"}
              parentId={invoice.id}
              parentType={"Invoice"}
            />,
          ]}
        >
          <Col span={13}>
            <Row>
              <General
                getFieldDecorator={getFieldDecorator}
                invoice={invoice}
                onChangeCompany={onChangeCompany}
                onChangeAssignee={onChangeAssignee}
                onChangeAgreement={onChangeAgreement}
                onChangeAgreementDate={onChangeAgreementDate}
                onChangeAdditional={onChangeAdditional}
                onChangeShippingAddress={onChangeShippingAddress}
                onChangeShippingMethod={onChangeShippingMethod}
                onChangeBankAccount={onChangeBankAccount}
                onChangeSpecialInstruction={onChangeSpecialInstruction}
              />
            </Row>
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Row>
              <Statuses
                getFieldDecorator={getFieldDecorator}
                invoice={invoice}
                invoiceItems={invoiceItems}
                onChangeDate={onChangeDate}
                onChangeDuration={onChangeDuration}
                onChangeOrder={onChangeOrder}
                onChangeRequestProposal={onChangeRequestProposal}
                onChangeNumber={onChangeNumber}
                onChangePaid={onChangePaid}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangePaymentAmount={onChangePaymentAmount}
                onChangePaymentPercent={onChangePaymentPercent}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Items
              invoiceItems={invoiceItems}
              onAddItem={onAddItem}
              onDeleteItems={onDeleteItems}
              onChangeItemProduct={onChangeItemProduct}
              onChangeItemDescription={onChangeItemDescription}
              onChangeItemUnit={onChangeItemUnit}
              onChangeItemQuantity={onChangeItemQuantity}
              onChangeItemPrice={onChangeItemPrice}
              onChangeItemVatRate={onChangeItemVatRate}
              onChangeItemVat={onChangeItemVat}
              onChangeItemAmount={onChangeItemAmount}
            />
          </Col>
        </Card>
      </Form>
    );
  }
}

InvoiceForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  invoice: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(InvoiceForm);
