import api from "../api";

const Actions = {};

Actions.fetchCities = () => {
  return (dispatch, getState) => {
    const params = getState().cities.fetchParams;
    dispatch({ type: "FETCH_CITIES_REQUEST" });
    api
      .fetch("/cities", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_CITIES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_CITIES_FAILURE", response });
      });
  };
};

Actions.fetchCity = id => {
  return dispatch => {
    dispatch({ type: "FETCH_CITY_REQUEST" });
    return api
      .fetch(`/cities/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_CITY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Город", error);
          });
        }
        dispatch({ type: "FETCH_CITY_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "CITY_ADD" });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CITY_REQUEST" });
    const city = getState().cities.city;

    const data = {
      city: {
        name: city.name,
        state_id: city.state ? city.state.id : null,
        city_type: city.city_type,
        fias_id: city.fias_id,
        kladr_id: city.kladr_id,
        lat: city.lat,
        lon: city.lon
      }
    };

    return api
      .patch(`/cities/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_CITY", response });
        api.openNotification("success", "Город", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Город", error);
          });
        }
        dispatch({ type: "PATCH_CITY_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_CITY_REQUEST" });
    const city = getState().cities.city;

    const data = {
      city: {
        name: city.name,
        state_id: city.state ? city.state.id : null,
        city_type: city.city_type,
        fias_id: city.fias_id,
        kladr_id: city.kladr_id,
        lat: city.lat,
        lon: city.lon
      }
    };

    return api
      .post(`/cities`, data)
      .then(response => {
        dispatch({ type: "POST_CITY", response });
        api.openNotification("success", "Город", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Город", error);
          });
        }
        dispatch({ type: "POST_CITY_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_CITY_REQUEST" });
    return api
      .delete(`/cities/${id}`)
      .then(response => {
        api.openNotification("success", "Город", "Успешно удален!");
        dispatch({ type: "DELETE_CITY", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Город", error);
          });
        }
        dispatch({
          type: "DELETE_CITY_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "CITY_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CITY_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CITY_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeState = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_STATE",
      value: value
    });
  };
};

Actions.onChangeCityType = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_CITY_TYPE",
      value: value
    });
  };
};

Actions.onChangeLat = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_LAT",
      value: value
    });
  };
};

Actions.onChangeLon = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_LON",
      value: value
    });
  };
};

Actions.onChangeFias = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_FIAS",
      value: value
    });
  };
};

Actions.onChangeKladr = value => {
  return dispatch => {
    dispatch({
      type: "CITY_CHANGE_KLADR",
      value: value
    });
  };
};

export default Actions;
