// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, Menu, Dropdown, Icon, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import EditableDateCell from "../../../components/EditableCells/EditableDateCell";

import moment from "moment";
import "moment/locale/ru";

class OrderItems extends Component {
  onSelectMenu = (record, { key }) => {
    if (key === "1") {
      this.props.onAddItem(record.id);
    } else {
      this.props.onFillBySpecification(record.id, record.product.id);
      // console.log(record.id, record.product.id);
    }
  };

  render() {
    const { orderItems, onChangeStocked } = this.props;

    const columns = [
      {
        title: "Наименование",
        dataIndex: "product",
        render: (product, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column-reverse" }}>
              <span style={{ fontSize: "12px" }}>{record.description}</span>
              <span>
                <Link
                  to={`/categories/${
                    record.product.extra.category.id
                  }/products/${record.product.id}/edit`}
                >
                  {record.product.text}
                </Link>
              </span>
            </div>
          );
        },
        width: "40%"
      },
      {
        title: "Кол-во",
        dataIndex: "quantity",
        render: (quantity, record) => {
          return accounting.formatNumber(Number(quantity), 0, " ");
        },
        width: "10%",
        align: "center"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          return accounting.formatNumber(Number(amount), 2, " ", ",");
        },
        width: "15%",
        align: "center"
      },
      {
        title: "План отгрузки",
        dataIndex: "will_shipped",
        render: (will_shipped, record) => {
          return moment(will_shipped).format("L");
        },
        width: "15%",
        align: "center"
      },
      {
        title: "План изготовления",
        dataIndex: "stocked",
        render: (stocked, record, index) => {
          return (
            <EditableDateCell
              recordId={index}
              value={record.stocked}
              onChange={onChangeStocked}
            />
          );
        },
        width: "20%",
        align: "center"
      },
      // {
      //   title: "",
      //   render: (shipped, record) => {
      //     return (
      //       <Dropdown
      //         overlay={
      //           <Menu onClick={this.onSelectMenu.bind(this, record)}>
      //             <Menu.Item key="1">
      //               <Icon type="plus" />
      //               добавить строку в таблицу
      //             </Menu.Item>
      //             <Menu.Item key="2" disabled={!record.has_specification}>
      //               <Icon type="reload" />
      //               заполнить по спецификации
      //             </Menu.Item>
      //           </Menu>
      //         }
      //         placement="bottomLeft"
      //       >
      //         <Button icon="bars" />
      //       </Dropdown>
      //     );
      //   },
      //   width: "10%",
      //   align: "center"
      // }
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              // rowClassName={() => "editable-row"}
              size={"small"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={orderItems}
              scroll={{ y: 240 }}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

OrderItems.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.object)
};
export default OrderItems;
