// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;

class InvoicesList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const columns = [
      {
        title: "Организация",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/firms/${record.id}/edit`}>{name}</Link>
        ),
        align: "center",
        width: "40%",
      },
      {
        title: "ИНН/КПП",
        dataIndex: "inn",
        render: (inn, record)  => (
          `${inn}/${record.kpp}`
        ),
        align: "center",
        width: "30%"
      },
      {
        title: "CEO",
        dataIndex: "ceo",
        render: ceo => (
        `${ceo}`
        ),
        align: "center",
        width: "30%"
      }
    ];

    const { loading, firms } = this.props;

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Организации`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={firms}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

InvoicesList.propTypes = {
  firms: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default InvoicesList;
