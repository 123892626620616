import React, { Component } from "react";
import { Axis, Chart, Tooltip, Interval, Legend, getTheme } from "bizcharts";
import moment from "moment";
import "moment/locale/ru";

const theme = getTheme();

const colors = theme.colors10;
/**
 * 图例开关状态
 */
let legendMap = {};
/**
 * 图表实例
 */
let chartIns;
export default class Charts extends Component {
  render() {
    const { dateFormat, periods, data } = this.props;

    const filtredData = [];
    let amount = 0;
    let minAmount = -100;
    let maxAmount = 100;
    periods.map((key) => {
      data
        .filter(function (item) {
          return item.isGroup == true;
        })
        .sort(function (a, b) {
          return a.sort - b.sort;
        })
        .map((item) => {
          amount = Number(item[key]);
          minAmount = Math.min(minAmount, amount);
          minAmount = Math.max(maxAmount, amount);
          filtredData.push({
            name: item.name,
            period: moment(key).format(dateFormat),
            amount: amount,
          });
        });
    });

    

    return (
      <Chart
        height={400}
        data={filtredData}
        autoFit
        onGetG2Instance={(chart) => {
          chartIns = chart;
        }}
        scale={{
          value: { min: minAmount*1.25, max: minAmount },
          name: {
            values: ["Остаток на начало", "Поступления", "Оплаты", "Остаток на конец"],
          },
          item: {
            values: ["Остаток на начало", "Поступления", "Оплаты", "Остаток на конец"],
          },
        }}
      >
        <Interval
          adjust={[
            {
              type: "dodge",
              marginRatio: 0,
              dodgeBy: "name",
            },
          ]}
          color={["name", [colors[0], colors[1], colors[2], colors[3]]]}
          position="period*amount"
        />
      </Chart>
    );
  }
}
