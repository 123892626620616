// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/users";
import { Spin } from "antd";
import Form from "./Form";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchUser(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/references/users/${this.props.user.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/references/users`);
  };

  onChangeActive = e => {
    this.props.dispatch(Actions.onChangeActive(e.target.checked));
  };

  onChangeRole = value => {
    this.props.dispatch(Actions.onChangeRole(value));
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeMiddleName = e => {
    this.props.dispatch(Actions.onChangeMiddleName(e.target.value));
  };

  onChangeLastName = e => {
    this.props.dispatch(Actions.onChangeLastName(e.target.value));
  };

  onChangeEmail = e => {
    this.props.dispatch(Actions.onChangeEmail(e.target.value));
  };

  // change password
  onUpdatePassword = () => {
    this.props.dispatch(Actions.onUpdatePassword());
  };

  onChangePassword = e => {
    this.props.dispatch(Actions.onChangePassword(e.target.value));
  };

  onChangePasswordConfirmation = e => {
    this.props.dispatch(Actions.onChangePasswordConfirmation(e.target.value));
  };



  render() {
    const { isLoading, user } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
        <Form
          isNew={this.state.isNew}
          user={user}
          onSave={this.onSave}
          onClose={this.onClose}
          onChangeActive={this.onChangeActive}
          onChangeRole={this.onChangeRole}
          onChangeName={this.onChangeName}
          onChangeMiddleName={this.onChangeMiddleName}
          onChangeLastName={this.onChangeLastName}
          onChangeEmail={this.onChangeEmail}
          onUpdatePassword={this.onUpdatePassword}
          onChangePassword={this.onChangePassword}
          onChangePasswordConfirmation={this.onChangePasswordConfirmation}
        />
      );
  }
}

User.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.users.isLoading,
  errors: state.users.errors,
  user: state.users.user
});

export default connect(mapStateToProps)(withRouter(User));
