// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "./List";

class Attachments extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { isLoading, attachments, onUpload, onDelete } = this.props;
    return (
      <List
        isLoading={isLoading}
        documents={attachments}
        onUpload={onUpload}
        onDelete={onDelete}
      />
    );
  }
}

Attachments.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  requestForProposal: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};


export default Attachments;
