// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Button, Popover, Tooltip } from "antd";

import moment from "moment";
import "moment/locale/ru";

import Product from "./Product";

class Products extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      getFieldDecorator,
      isProductsLoading,
      isProductVisible,
      requestProposalItems,
      onSaveProduct,
      onDeleteProduct,
      onCancelProduct
    } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          {isProductVisible ? (
            <Product
              onSaveProduct={onSaveProduct}
              onCancelProduct={onCancelProduct}
            />
          ) : null}
        </Col>
        <Col span={24}>
          <List
            loading={isProductsLoading}
            itemLayout="horizontal"
            dataSource={requestProposalItems}
            renderItem={item => (
              <List.Item
                actions={[
                  <Tooltip placement="bottom" title={"Удалить"}>
                    <Popover
                      content={
                        <Button
                          type="danger"
                          style={{ width: "100%" }}
                          onClick={onDeleteProduct.bind(
                            this,
                            item.id
                          )}
                        >
                          Удалить
                        </Button>
                      }
                      title="Удаление продукции"
                      trigger="click"
                    >
                      <Button type="dashed" shape="circle" icon="close" />
                    </Popover>
                  </Tooltip>
                ]}
              >
                <List.Item.Meta
                  title={item.product ? item.product.name : null}
                  description={item.product ? item.product.category : null}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  }
}

Products.propTypes = {
  orderProducts: PropTypes.arrayOf(PropTypes.object)
};
export default Products;
