// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "antd";
import Actions from "../../../actions/products";
import ProductsList from "./ProductsList";

const Search = Input.Search;

class Products extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    // console.log(this.props.match.params.categoryID)
    // this.props.dispatch(Actions.fetchProducts());
  };

  componentDidUpdate(prevProps) {
    // console.log(this.props.match.url)
    if (
      this.props.fetchParams.category_id !== prevProps.fetchParams.category_id
    ) {
      this.props.dispatch(Actions.fetchProducts());
    }
    // if (
    //   this.props.match.url !== prevProps.match.url
    // ) {
    //   this.props.dispatch(Actions.fetchProducts());
    // }
  }

  onDelete = id => {
    this.props.dispatch(Actions.onDelete(id)).then(() => {
      if (!this.props.errors) {
        this.props.dispatch(Actions.fetchProducts());
      }
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchProducts()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchProducts()));
  };

  onChangeCategory = value => {
    this.props
      .dispatch(Actions.onChangeCategory(value))
      .then(() => this.props.dispatch(Actions.fetchProducts()));
  };

  render() {
    const { isLoading, products, meta, fetchParams, categories } = this.props;

    return (
      <ProductsList
        params={this.props.match.params}
        isLoading={isLoading}
        products={products}
        meta={meta}
        fetchParams={fetchParams}
        categories={categories}
        onSearch={this.onSearch}
        onDelete={this.onDelete}
        onChangeCategory={this.onChangeCategory}
        onChangePage={this.onChangePage}
      />
    );
  }
}

Products.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.products.isProductLoading,
  products: state.products.products,
  meta: state.products.meta,
  fetchParams: state.products.fetchParams,
  categories: state.products.categories,
});

export default connect(mapStateToProps)(Products);
