// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/requestForProposals";
import RequestForProposalsList from "./List";

class RequestForProposals extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchRequestForProposals());
    });
    // if (this.props.currentUser.role === "manager") {
    //   this.props
    //   .dispatch(Actions.onChangeFilterAssignee(this.props.currentUser.user_id))
    //   .then(() => this.props.dispatch(Actions.fetchRequestForProposals()));
    // } else {
    //   this.props.dispatch(Actions.fetchRequestForProposals());
    // }
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchRequestForProposals());
      });
    });
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()))
      .then(() => {
        localStorage.setItem(
          "requestForProposalsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()));
  };

  // filters
  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()))
      .then(() => {
        localStorage.setItem(
          "requestForProposalsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterStatuses = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterStatuses(values))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()))
      .then(() => {
        localStorage.setItem(
          "requestForProposalsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPaused = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterPaused(value))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()))
      .then(() => {
        localStorage.setItem(
          "requestForProposalsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterAssignees = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterAssignees(values))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()))
      .then(() => {
        localStorage.setItem(
          "requestForProposalsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterContactTypes = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterContactTypes(values))
      .then(() => this.props.dispatch(Actions.fetchRequestForProposals()))
      .then(() => {
        localStorage.setItem(
          "requestForProposalsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadXLSReport(
      params,
      "request_proposals.xlsx",
      params.startDate
        ? `request_proposals ${params.startDate}-${params.endDate}.xlsx`
        : "request_proposals.xlsx"
    );
  };

  render() {
    const { isLoading, requestForProposals } = this.props;
    return (
      <RequestForProposalsList
        loading={isLoading}
        requestForProposals={requestForProposals}
        meta={this.props.meta}
        fetchParams={this.props.fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterStatuses={this.onChangeFilterStatuses}
        onChangeFilterContactTypes={this.onChangeFilterContactTypes}
        onChangeFilterPaused={this.onChangeFilterPaused}
        onChangeFilterAssignees={this.onChangeFilterAssignees}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.requestForProposals.isLoading,
  requestForProposals: state.requestForProposals.requestForProposals,
  meta: state.requestForProposals.meta,
  fetchParams: state.requestForProposals.fetchParams,
  currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(RequestForProposals);
