// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Tabs, Form, Card } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Orders from "./Orders";
import Invoices from "./Invoices";

const TabPane = Tabs.TabPane;

class CompanyForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { visible: false, tabIndex: "1" };
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  onChangeTab = key => {
    this.setState({ tabIndex: key }, () => {
      if (key == 1) {
        this.props.fetchOrders();
      } else if (key == 2) {
        this.props.fetchInvoices();
      } else if (key == 3) {
        this.props.fetchFiles();
      }
    });
  };

  onClose = () => {
    window.history.back();
  };

  fillCompanyByInn = (data, address) => {
    this.props.form.setFieldsValue({
      name: data.name,
      full_name: data.name,
      kpp: data.kpp
    });
    this.props.fillCompanyByInn(data, address);
  };

  render() {
    const {
      isNew,
      isLoading,
      company,
      onChangeName,
      onChangeCode,
      onChangeCompanyType,
      onChangeFullName,
      onChangeInn,
      onChangeKpp,
      onChangeAssignee,
      onChangeContactType,
      onChangeContactSource,
      onChangeBusinessField,
      onAddPhone,
      onAddEmail,
      // addresses
      onSaveAddress,
      onDeleteAddress,
      // emails
      onSaveEmail,
      onDeleteEmail,
      // phones
      onSavePhone,
      onDeletePhone,
      isLoadingOrders,
      orders,
      isInvoicesLoading,
      invoices
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const addresses = company.addresses.filter(function(item) {
      return !item._destroy;
    });

    const phones = company.phones.filter(function(item) {
      return !item._destroy;
    });

    const emails = company.emails.filter(function(item) {
      return !item._destroy;
    });

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Компания ${
            isNew ? "новая" : company.name
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={this.onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"} onSubmit={this.onSubmit}>
          <Col sm={{ span: 24 }} md={{ span: 13 }} xs={{ span: 13 }}>
            <Row gutter={16}>
              <Header
                name={company.name}
                code={company.code}
                full_name={company.full_name}
                inn={company.inn}
                kpp={company.kpp}
                companyType={company.company_type}
                addresses={company.addresses}
                getFieldDecorator={getFieldDecorator}
                onChangeName={onChangeName}
                onChangeCode={onChangeCode}
                onChangeCompanyType={onChangeCompanyType}
                onChangeFullName={onChangeFullName}
                onChangeInn={onChangeInn}
                fillCompanyByInn={this.fillCompanyByInn}
                onChangeKpp={onChangeKpp}
              />
            </Row>
            {isNew ? null : (
              <Row gutter={16}>
                <Tabs
                  activeKey={this.state.tabIndex}
                  onChange={this.onChangeTab}
                >
                  <TabPane tab="Заказы" key="1">
                    <Orders loading={isLoadingOrders} orders={orders} />
                  </TabPane>

                  <TabPane tab="Счета" key="2">
                    <Invoices loading={isInvoicesLoading} invoices={invoices} />
                  </TabPane>
                  <TabPane tab="Файлы" key="3" />
                </Tabs>
              </Row>
            )}
          </Col>
          <Col sm={{ span: 0 }} md={{ span: 1 }} xs={{ span: 1 }} />
          <Col sm={{ span: 24 }} md={{ span: 10 }} xs={{ span: 10 }}>
            <Row gutter={16}>
              <Sidebar
                getFieldDecorator={getFieldDecorator}
                assignee={company.assignee}
                contactType={company.contact_type}
                contactSource={company.contact_source}
                businessField={company.business_field}
                onChangeAssignee={onChangeAssignee}
                onChangeContactType={onChangeContactType}
                onChangeContactSource={onChangeContactSource}
                onChangeBusinessField={onChangeBusinessField}
                onAddPhone={onAddPhone}
                onAddEmail={onAddEmail}
                // addresses
                addresses={addresses}
                onSaveAddress={onSaveAddress}
                onDeleteAddress={onDeleteAddress}
                // emails
                emails={emails}
                onSaveEmail={onSaveEmail}
                onDeleteEmail={onDeleteEmail}
                // phones
                phones={phones}
                onSavePhone={onSavePhone}
                onDeletePhone={onDeletePhone}
              />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

CompanyForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  company: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeShop: PropTypes.func,
  onChangeName: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onChangeSlug: PropTypes.func
};

export default Form.create()(CompanyForm);
