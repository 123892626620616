// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        paymentType: null
      },
      periodicity: 'month',
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchPlanCashflow(this.state.fetchParams));
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.dispatch(Actions.fetchPlanCashflow(this.state.fetchParams));
    }
  }


  onChangePeriodicity = value => {
    this.setState({ periodicity: value });
  };

  onChangePaymentType = value => {
    this.state.fetchParams.paymentType = value;
    this.setState({ fetchParams: this.state.fetchParams }, () => {
      this.props.dispatch(Actions.fetchPlanCashflow(this.state.fetchParams));
    });
  };

  downloadReport = () => {
    Actions.downloadReport(this.state.fetchParams, 'plancashflow.xlsx', 'plancashflow.xlsx'); 
  }

  render() {
    const { isLoading, report } = this.props;
    const { periodicity, fetchParams } = this.state;
    return (
      <DataList
        loading={isLoading}
        report={report}
        periodicity={periodicity}
        fetchParams={fetchParams}
        onChangePeriodicity={this.onChangePeriodicity}
        onChangePaymentType={this.onChangePaymentType}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.reports.isLoading,
  report: state.reports.report
});

export default connect(mapStateToProps)(Report);
