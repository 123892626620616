import api from "../api";

const Actions = {};

Actions.fetchCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CURRENT_USER_REQUEST" });
    const currentUserID = getState().session.currentUser.user_id;
    api
      .fetch(`/users/${currentUserID}`)
      .then(response => {
        dispatch({ type: "FETCH_CURRENT_USER", response });
      })
      .catch(response => {
        dispatch({ type: "FETCH_CURRENT_USER_FAILURE", response });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CURRENT_USER_REQUEST" });
    const currentUser = getState().currentUser.currentUser;
    const data = {
      user: {
        email: currentUser.email,
        first_name: currentUser.first_name,
        middle_name: currentUser.middle_name,
        last_name: currentUser.last_name
      }
    };
    api
      .patch(`/users/${currentUser.id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_CURRENT_USER", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_CURRENT_USER_FAILURE", response });
      });
  };
};

Actions.onUpdatePassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_CURRENT_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        password: user.password,
        password_confirmation: user.password_confirmation
      }
    };
    api
      .patch(`/users/${user.id}/update_password`, data)
      .then(response => {
        dispatch({ type: "PATCH_PASSWORD_CURRENT_USER", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_CURRENT_USER_FAILURE", response });
      });
  };
};

Actions.onChangeCurrentUserName = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeCurrentUserMiddleName = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_USER_CHANGE_MIDDLE_NAME",
      value: value
    });
  };
};

Actions.onChangeCurrentUserLastName = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_USER_CHANGE_LAST_NAME",
      value: value
    });
  };
};

Actions.onChangeCurrentUserEmail = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_USER_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangeCurrentUsePassword = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_USER_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangeCurrentUsePasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_USER_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};

export default Actions;
