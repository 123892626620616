// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/serviceOrders";
import List from "./List";

class ServiceOrders extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchServiceOrders());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchServiceOrders());
      });
    });
  };

  onFetchServiceOrder = (id) => {
    this.props.dispatch(Actions.fetchServiceOrder(id));
  }

  onNew = () => {
    this.props.dispatch(Actions.onNew());
  }

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchServiceOrders()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchServiceOrders()));
  };

  render() {
    return (
      <List
        loading={this.props.isLoading}
        isModalVisible={this.props.isModalVisible}
        serviceOrders={this.props.serviceOrders}
        meta={this.props.meta}
        fetchParams={this.props.fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onFetchServiceOrder={this.onFetchServiceOrder}
        onNew={this.onNew}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.serviceOrders.isLoading,
  isModalVisible: state.serviceOrders.isModalVisible,
  serviceOrders: state.serviceOrders.serviceOrders,
  meta: state.serviceOrders.meta,
  fetchParams: state.serviceOrders.fetchParams
});

export default connect(mapStateToProps)(ServiceOrders);
