import { combineReducers } from 'redux';
import session from './session';
import dashboard from './dashboard';
import currentUser from './currentUser';
import users from './users';
import orders from './orders';
import shipments from './shipments';
import workOrders from './workOrders';
import materialOrders from './materialOrders';
import serviceOrders from './serviceOrders';
import purchases from './purchases';
import invoices from './invoices';
import payments from './payments';
import products from './products';
import requestForProposals from './requestForProposals';
import firms from './firms';
import companies from './companies';
import reports from './reports';
import units from './units';
import departments from './departments';
import countries from './countries';
import cities from './cities';
import documents from './documents';
import contactSources from './contactSources';
import businessFields from './businessFields';
import lossReasons from './lossReasons';

const appReducer = combineReducers({
  session,
  dashboard,
  currentUser,
  users,
  orders,
  shipments,
  workOrders,
  materialOrders,
  serviceOrders,
  purchases,
  invoices,
  payments,
  products,
  requestForProposals,
  firms,
  companies,
  reports,
  units,
  departments,
  countries,
  cities,
  documents,
  contactSources,
  businessFields,
  lossReasons
});

export default function (state, action) {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}