// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Select, Button } from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import {
  Pie
} from "../../../components/Charts";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      downloadReport
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    const reportGroupByCategory = report.reduce(
      (r, v, i, a, k = v.parent_name) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
    // console.log(reportGroupByCategory);

    let reportData = [];
    const pieData = [];
    Object.keys(reportGroupByCategory).map(key => {
      let amountTotal = reportGroupByCategory[key].reduce(
        (sum, record) => sum + Number(record.amount),
        0
      );

      let vatTotal = reportGroupByCategory[key].reduce(
        (sum, record) => sum + Number(record.vat),
        0
      );

      let quantityTotal = reportGroupByCategory[key].reduce(
        (sum, record) => sum + Number(record.quantity),
        0
      );

      reportData.push({
        id: `group-${key}`,
        parent_id: `period-${key}`,
        parent_name: key,
        product_category_id: null,
        category_name: null,
        quantity: quantityTotal,
        amount: amountTotal,
        vat: vatTotal,
        isGoup: true,
        isSubtotal: false
      });

      pieData.push({
        item: key,
        count: amountTotal
      });

      reportGroupByCategory[key].map(item => {
        reportData.push({
          id: `item-${item.product_category_id}`,
          parent_id: item.parent_id,
          parent_name: item.parent_name,
          product_category_id: item.product_category_id,
          category_name: item.category_name,
          quantity: item.quantity,
          amount: item.amount,
          vat: item.vat,
          isGoup: false,
          isSubtotal: false
        });
      });
    });

    const columns = [
      {
        title: "Категория",
        dataIndex: "parent_name",
        render: (parent_name, record) => {
          if (record.isGoup) {
            return {
              children: <b>{parent_name}</b>,
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: record.category_name
          };
        },
        align: "left",
        width: "35%"
      },

      {
        title: "Количество",
        dataIndex: "quantity",
        render: (quantity, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>
                  {accounting.formatNumber(Number(record.quantity), 0, " ")}
                </b>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: accounting.formatNumber(Number(record.quantity), 0, " ")
          };
        },
        align: "right",
        width: "15%"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(record.amount), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: accounting.formatNumber(Number(record.amount), 0, " ")
          };
        },
        align: "right",
        width: "25%"
      },

      {
        title: "НДС",
        dataIndex: "vat",
        render: (vat, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(vat), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: accounting.formatNumber(Number(vat), 0, " ")
          };
        },
        align: "right",
        width: "25%"
      }
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Отгрузки по группам оборудования (диаграмма) c ${
          fetchParams.startDate
        } по ${fetchParams.endDate}`}
        extra={[
          <Button key="3" type="dashed" icon="file-excel" onClick={downloadReport}>Загрузить</Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
            <Pie data={pieData} height={380} />
            </Col>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="id"
                rowClassName={record =>
                  record.isGoup ? "ant-table-total-row" : null
                }
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default DataList;
