// @flow
import React, { Component } from "react";
import { List, Divider, Tooltip, Button } from "antd";
import update from "immutability-helper";
import Phone from "./Phone";


const itemCategory = category => {
  switch (category) {
    case "mobile":
      return "мобильный";
    case "work":
      return "рабочий";
    default:
      return "факс";
  }
};

class Phones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      phone: {}
    };
  }

  onAddPhone = () => {
    this.setState(
      {
        phone: {
          id: Date.now(),
          category: "mobile",
          number: null,
          is_exist: false,
          _destroy: false
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSavePhone = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSavePhone(this.state.phone);
    });
  };

  onEditPhone = id => {
    const { phones } = this.props;
    let idx = phones.findIndex(function(o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }
    this.setState({ phone: phones[idx] }, () => {
      this.setState({ isVisible: true });
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeCategory = value => {
    this.setState({
      phone: update(this.state.phone, {
        category: { $set: value }
      })
    });
  };

  onChangeNumber = e => {
    this.setState({
      phone: update(this.state.phone, {
        number: { $set: e.target.value }
      })
    });
  };

  render() {
    const { isVisible, phone } = this.state;
    const { phones, onDeletePhone } = this.props;

    return (
      <React.Fragment>
        <Divider style={{ opacity: "0.6" }}>
          {"Телефоны"}{" "}
          <Tooltip placement="bottom" title={"Добавить телефон"}>
            <Button
              type="dashed"
              shape="circle"
              icon="plus"
              onClick={this.onAddPhone}
            />
          </Tooltip>
        </Divider>
        <List
          itemLayout="horizontal"
          dataSource={phones}
          renderItem={(phone, i) => {
            return (
              <List.Item
                actions={[
                  <Button
                    type="dashed"
                    shape="circle"
                    icon="close"
                    onClick={onDeletePhone.bind(this, phone.id)}
                  />
                ]}
              >
                <List.Item.Meta
                  title={itemCategory(phone.category)}
                  description={
                    <a onClick={this.onEditPhone.bind(this, phone.id)}>
                      {phone.number}
                    </a>
                  }
                />
              </List.Item>
            );
          }}
        />
        <Phone
          visible={isVisible}
          phone={phone}
          onSave={this.onSavePhone}
          onCancel={this.onCancel}
          onChangeCategory={this.onChangeCategory}
          onChangeNumber={this.onChangeNumber}
        />
      </React.Fragment>
    );
  }
}

export default Phones;
