import api from "../api";

const Actions = {};

Actions.fetchContactSources = () => {
  return (dispatch, getState) => {
    const params = getState().contactSources.fetchParams;
    dispatch({ type: "FETCH_CONTACT_SOURCES_REQUEST" });
    api
      .fetch("/contact_sources", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_CONTACT_SOURCES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_CONTACT_SOURCES_FAILURE", response });
      });
  };
};

Actions.fetchContactSource = id => {
  return dispatch => {
    dispatch({ type: "FETCH_CONTACT_SOURCE_REQUEST" });
    return api
      .fetch(`/contact_sources/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_CONTACT_SOURCE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Источники контактов", error);
          });
        }
        dispatch({ type: "FETCH_CONTACT_SOURCE_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "CONTACT_SOURCE_ADD" });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CONTACT_SOURCE_REQUEST" });
    const contactSource = getState().contactSources.contactSource;

    const data = {
      contact_source: {
        name: contactSource.name
      }
    };

    return api
      .patch(`/contact_sources/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_CONTACT_SOURCE", response });
        api.openNotification("success", "Источник контактов", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Источник контактов", error);
          });
        }
        dispatch({ type: "PATCH_CONTACT_SOURCE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_CONTACT_SOURCE_REQUEST" });
    const contactSource = getState().contactSources.contactSource;

    const data = {
      contact_source: {
        name: contactSource.name
      }
    };

    return api
      .post(`/contact_sources`, data)
      .then(response => {
        dispatch({ type: "POST_CONTACT_SOURCE", response });
        api.openNotification("success", "Источник контактов", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Источник контактов", error);
          });
        }
        dispatch({ type: "POST_CONTACT_SOURCE_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_CONTACT_SOURCE_REQUEST" });
    return api
      .delete(`/contact_sources/${id}`)
      .then(response => {
        api.openNotification("success", "Источник контактов", "Успешно удален!");
        dispatch({ type: "DELETE_CONTACT_SOURCE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Источник контактов", error);
          });
        }
        dispatch({
          type: "DELETE_CONTACT_SOURCE_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "CONTACT_SOURCE_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CONTACT_SOURCE_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CONTACT_SOURCE_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "CONTACT_SOURCE_CHANGE_NAME",
      value: value
    });
  };
};



export default Actions;
