// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Button, Icon, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  render() {
    const {
      visible,
      startCreatedDate,
      endCreatedDate,
      businessFields,
      states,
      contactTypes,
      onClose,
      onClearFilters,
      onChangeCreatedPeriod,
      onChangeBusinessFields,
      onChangeStates,
      onChangeContactTypes,
      downloadReport,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период создания"}>
              {getFieldDecorator("start", {
                onChange: onChangeCreatedPeriod,
                initialValue: [
                  startCreatedDate
                    ? moment(startCreatedDate, "DD.MM.YYYY")
                    : null,
                  endCreatedDate ? moment(endCreatedDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Сферы деятельности"}>
              {getFieldDecorator("businessFields", {
                initialValue: businessFields ? businessFields : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Сферы деятельности"
                  onChange={onChangeBusinessFields}
                  selected={businessFields ? businessFields : []}
                  url={"/business_fields/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Тип контакта"}>
              {getFieldDecorator("contact_types", {
                initialValue: contactTypes ? contactTypes : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Тип контакта"
                  onChange={onChangeContactTypes}
                  selected={contactTypes ? contactTypes : []}
                  url={"/contact_types/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Регионы"}>
              {getFieldDecorator("states", {
                initialValue: states ? states : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Регионы"
                  onChange={onChangeStates}
                  selected={states ? states : []}
                  url={"/states/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
