// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Button,
  Form,
  Tabs,
  InputNumber,
  Menu,
  Dropdown,
  Icon,
} from "antd";
import { Row, Col, PageHeader } from "antd";
import Details from "./Details";
import Charts from "./Charts";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const { TabPane } = Tabs;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  callback(key) {
    // console.log(key);
  }

  render() {
    const {
      height,
      loading,
      report,
      periods,
      fetchParams,
      period,
      saldo,
      onChangeDateRange,
      onChangePeriod,
      onChangePeriodicity,
      onChangeSaldo,
      onOpenSettings,
      downloadReport,
    } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key={uuidv4()} onClick={downloadReport.bind(this, 'cashflow.xlsx')}>
          <Icon type="file-excel" />
          Отчет
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key={uuidv4()} onClick={downloadReport.bind(this, 'cashflowByOrders.xlsx')}>
          <Icon type="file-excel" />
          Детализация по контрагентам
        </Menu.Item>
      </Menu>
    );

    let data = [];
    report.map((item) => {
      data.push(item);
    });

    let startSaldo = saldo;
    let rowStartSaldo = {
      id: uuidv4(),
      name: "Остаток на начало",
      isGroup: true,
      cashflowType: 0,
      sort: 1,
    };
    let rowEndSaldo = {
      id: uuidv4(),
      name: "Остаток на конец",
      isGroup: true,
      cashflowType: 0,
      sort: 4,
    };

    periods.map((key) => {
      let positiveFlow = 0;
      let negativeFlow = 0;
      report
        .filter(function (item) {
          return item.isGroup == true;
        })
        .map((item) => {
          if (item.cashflowType == 1) {
            positiveFlow = parseFloat(item[key]) + positiveFlow;
          }

          if (item.cashflowType == 2) {
            negativeFlow = parseFloat(item[key]) + negativeFlow;
          }
        });
      rowStartSaldo[key] = startSaldo;
      rowEndSaldo[key] = startSaldo + positiveFlow - negativeFlow;
      startSaldo = startSaldo + positiveFlow - negativeFlow;
    });

    data.push(rowStartSaldo);
    data.push(rowEndSaldo);

    let dateFormat = "DD.MM.YYYY";

    if (fetchParams.periodicity == "week") {
      dateFormat = "ww YYYY";
    }

    if (fetchParams.periodicity == "month") {
      dateFormat = "MMM YYYY";
    }

    if (fetchParams.periodicity == "quarter") {
      dateFormat = "Qo квартал YYYY";
    }

    let isFixed = false;
    if (loading === false) {
      isFixed = periods.length > 6 ? true : false;
    }

    const columns = [
      {
        title: "Статья ДС",
        width: 300,
        dataIndex: "name",
        key: "name",
        fixed: isFixed,
      },
    ];

    periods.map((key) => {
      columns.push({
        title: moment(key).format(dateFormat),
        render: (amount, record, index) =>
          record.isGroup == false ? (
            <Details
              cashflowType={record.cashflowType}
              isAuto={record.isAuto}
              cashflowItemId={record.id}
              periodicity={fetchParams.periodicity}
              date={moment(key).format("DD.MM.YYYY")}
              amount={
                parseFloat(amount) != 0
                  ? accounting.formatNumber(amount, 0, " ")
                  : ""
              }
            />
          ) : parseFloat(amount) != 0 ? (
            accounting.formatNumber(amount, 0, " ")
          ) : (
            ""
          ),
        width: 140,
        dataIndex: key,
        key: key,
        align: "center",
      });
    });

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Движение ДС за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        extra={[
          <Button key="settingsButton" type="dashed" onClick={onOpenSettings}>
            Настройки
          </Button>,
          <Dropdown overlay={menu}>
            <Button style={{ marginLeft: "8px" }}>
              Загрузить <Icon type="down" />
            </Button>
          </Dropdown>,
        ]}
        footer={
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Отчет" key="1">
              <Row
                gutter={{ md: 8, lg: 24, xl: 48 }}
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Col span={24}>
                  <Table
                    bordered
                    size={"small"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    scroll={{ x: 1300, y: height - 260 }}
                    rowClassName={(record) =>
                      record.isGroup ? "ant-table-total-row" : null
                    }
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Графики" key="2">
              <Row
                gutter={{ md: 8, lg: 24, xl: 48 }}
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Col span={24}>
                  <Charts
                    dateFormat={dateFormat}
                    periods={periods}
                    data={data}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Период"}>
              <RangePicker
                style={{ width: "100%" }}
                value={[
                  moment(fetchParams.startDate, "DD.MM.YYYY"),
                  moment(fetchParams.endDate, "DD.MM.YYYY"),
                ]}
                format={"DD.MM.YYYY"}
                onChange={onChangeDateRange}
              />
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Выбор периода"}>
              <Select
                style={{ width: "100%" }}
                value={period}
                placeholder="Период"
                optionFilterProp="children"
                filterOption={false}
                onChange={onChangePeriod}
              >
                <Option value={0}>произвольный период</Option>
                <Option value={1}>этот месяц</Option>
                <Option value={2}>этот квартал</Option>
                <Option value={3}>этот год</Option>
                <Option value={4}>прошлый месяц</Option>
                <Option value={5}>прошлый квартал</Option>
                <Option value={6}>прошлый год</Option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Периодичность"}>
              <Select
                style={{ width: "100%" }}
                placeholder="Периодичность"
                optionFilterProp="children"
                filterOption={false}
                onChange={onChangePeriodicity}
                value={fetchParams.periodicity}
              >
                <Option value={"day"}>день</Option>
                <Option value={"week"}>неделя</Option>
                <Option value={"month"}>месяц</Option>
                <Option value={"quarter"}>квартал</Option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Остаток на начало периода"}>
              <InputNumber
                value={saldo}
                onChange={onChangeSaldo}
                style={{ width: "100%" }}
              />
            </FormItem>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
