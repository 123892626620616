// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableSelectFetchCell from "../../../components/EditableCells/EditableSelectFetchCell";

class Specification extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedRowKeys: [] };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteSpecificationItems = () => {
    this.props.onDeleteSpecificationItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      specificationItems,
      onAddSpecificationItem,
      onChangeSpecificationProduct,
      onChangeSpecificationCost,
      onChangeSpecificationQuantity
    } = this.props;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Продукция",
        dataIndex: "product_specification",
        render: (product_specification, record) => (
          <EditableSelectFetchCell
            recordId={record.id}
            selected={product_specification}
            showSearch
            url={"/products/search?"}
            onChange={onChangeSpecificationProduct}
          />
        ),
        width: "50%"
      },
      {
        title: "Количество",
        dataIndex: "quantity",
        key: "quantity",
        render: (quantity, record) => (
          <EditableInputCell
            recordId={record.id}
            value={quantity}
            onChange={onChangeSpecificationQuantity}
          />
        ),
        editable: true,
        width: "25%",
        align: "center"
      },
      {
        title: "Стоимость",
        dataIndex: "cost",
        key: "price",
        render: (cost, record) => (
          <EditableInputCell
            recordId={record.id}
            value={cost}
            onChange={onChangeSpecificationCost}
          />
        ),
        editable: true,
        width: "25%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            {hasSelected ? (
              <Button onClick={this.onDeleteSpecificationItems}>Удалить</Button>
            ) : (
              <div>
                <Button onClick={onAddSpecificationItem}>Добавить</Button>
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={specificationItems}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Specification.propTypes = {
  specificationItems: PropTypes.arrayOf(PropTypes.object)
};
export default Specification;
