import update from "immutability-helper";

const initialState = {
  documents: [],
  errors: false,
  isLoading: false,
  isUploading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_DOCUMENTS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_DOCUMENTS":
      return {
        ...state,
        documents: action.response.data,
        isLoading: false
      };
    case "FETCH_DOCUMENT_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_DOCUMENT":
      return {
        ...state,
        unit: action.response.data,
        isLoading: false
      };

    case "DOCUMENT_UPLOAD":
      return update(state, {
        isUploading: { $set: true }
      });

    case "DOCUMENT_UPLOAD_SUCCEED":
      return update(state, {
        documents: { $set: action.response.data },
        errors: { $set: false },
        isUploading: { $set: false }
      });

    case "DOCUMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploading: { $set: false }
      });

    case "DOCUMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "DOCUMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isLoading: false
      };
    case "DOCUMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    default:
      return state;
  }
}
