// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  PageHeader,
  Pagination,
  Row,
  Col,
  Input,
  Badge,
  Select,
  Icon,
  Tooltip,
} from "antd";
import SelectFetch from "../../../components/SelectFetch";
import PropTypes from "prop-types";
import Filter from "./Filter";

import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;
const Option = Select.Option;

function showStatus(status) {
  switch (status) {
    case "pending":
      return "error";
    case "approved":
      return "processing";
    case "accepted":
      return "success";
    case "succeed":
      return "success";
    case "succeed_tender":
        return "success";

    default:
      return "default";
  }
}

class PurchasesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      loading,
      requestForProposals,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      onChangeFilterPeriod,
      onChangeFilterStatuses,
      onChangeFilterContactTypes,
      onChangeFilterPaused,
      onChangeFilterAssignees,
      downloadReport,
    } = this.props;

    const { selectedRowKeys, isFilterVisible } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Статус",
        dataIndex: "status",
        render: (status, record) => (
          <Badge
            status={showStatus(record.status.id)}
            text={record.status.text}
          />
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Номер",
        dataIndex: "id",
        render: (id, record) => (
          <Link to={`/request_for_proposals/${record.id}/edit`}>{id}</Link>
        ),
        key: "id",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: (date) => moment(date).format("L"),
        align: "center",
        width: "10%",
      },
      {
        title: "Компания",
        dataIndex: "company",
        render: (company, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span style={{ opacity: "0.8" }}>
              {company.inn ? `инн: ${company.inn}` : ""}
            </span>
            <span>{company.text}</span>
          </div>
        ),
        width: "20%",
      },
      {
        title: "Тема / Последняя активность",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{subject}</span>
            <span style={{ opacity: "0.7" }}>
              {record.event ? record.event.description : null}
            </span>
          </div>
        ),
        key: "subject",
        width: "38%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignee",
        render: (assignee) => (assignee ? assignee.text : null),
        width: "22%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/request_for_proposals/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Tooltip
        key="tooltipExcelButton"
        placement="bottom"
        title={"Выгрузить в Excel"}
      >
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.is_paused ||
          fetchParams.statuses ||
          fetchParams.contact_types ||
          fetchParams.assignees
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Заявки`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={requestForProposals}
                // expandedRowRender={record => this.renderExpandedRow(record)}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          statuses={fetchParams.statuses}
          isPaused={fetchParams.is_paused}
          assignees={fetchParams.assignees}
          contact_types={fetchParams.contact_types}
          onClose={this.onFilterToggle}
          onChangePeriod={onChangeFilterPeriod}
          onChangeStatuses={onChangeFilterStatuses}
          onChangeContactTypes={onChangeFilterContactTypes}
          onChangePaused={onChangeFilterPaused}
          onChangeAssignees={onChangeFilterAssignees}
        />
      </PageHeader>
    );
  }
}

PurchasesList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default PurchasesList;
