// @flow
import React, { Component } from "react";
import { Row, Col, Form, Modal, Input, Select } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class Address extends Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      address,
      onChangeCategory,
      onChangeCountry,
      onChangeIndex,
      onChangeState,
      onChangeCity,
      onChangeStreet,
      onChangeBuilding,
      onChangeBuildingExt,
      onChangeFlat
    } = this.props;

    return (
      <Modal
        title={address.is_exist ? "Редактирование адреса" : "Новый адрес"}
        width={650}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("category", {
                  onChange: onChangeCategory,
                  initialValue: address.category
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={"work"}>юридический</Option>
                    <Option value={"shipping"}>доставки</Option>
                    <Option value={"other"}>другое</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 8 }} xs={{ span: 8 }}>
              <FormItem label={"Страна"}>
                {getFieldDecorator(
                  "country",
                  {}
                )(
                  <SelectFetch
                    showSearch
                    placeholder="Страна"
                    onChange={onChangeCountry}
                    selected={address.country ? address.country : null}
                    url={"/countries/search/?"}
                  />
                )}
              </FormItem>
            </Col>

            <Col sm={{ span: 24 }} md={{ span: 8 }} xs={{ span: 8 }}>
              <FormItem label={"Индекс"}>
                {getFieldDecorator("postindex", {
                  onChange: onChangeIndex,
                  initialValue: address.postindex
                })(<Input />)}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"Регион"}>
                {getFieldDecorator("state", {
                  initialValue: address.state ? address.state : null
                })(
                  <SelectFetch
                    showSubtitle
                    showSearch
                    placeholder="Адрес"
                    onChange={onChangeState}
                    selected={address.state ? address.state : null}
                    url={"/states/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"Город"}>
                {getFieldDecorator("city", {
                  initialValue: address.city ? address.city : null
                })(
                  <SelectFetch
                    showSubtitle
                    showSearch
                    placeholder="Город"
                    onChange={onChangeCity}
                    selected={address.city ? address.city : null}
                    url={"/cities/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <FormItem label={"Улица"}>
                {getFieldDecorator("street", {
                  onChange: onChangeStreet,
                  initialValue: address.street
                })(<Input />)}
              </FormItem>
            </Col>
            <Col sm={{ span: 12 }} md={{ span: 8 }} xs={{ span: 8 }}>
              <FormItem label={"Дом"}>
                {getFieldDecorator("building", {
                  onChange: onChangeBuilding,
                  initialValue: address.building
                })(<Input />)}
              </FormItem>
            </Col>
            <Col sm={{ span: 12 }} md={{ span: 8 }} xs={{ span: 8 }}>
              <FormItem label={"Корпус"}>
                {getFieldDecorator("building_ext", {
                  onChange: onChangeBuildingExt,
                  initialValue: address.building_ext
                })(<Input />)}
              </FormItem>
            </Col>
            <Col sm={{ span: 12 }} md={{ span: 8 }} xs={{ span: 8 }}>
              <FormItem label={"Помещение"}>
                {getFieldDecorator("flat", {
                  onChange: onChangeFlat,
                  initialValue: address.flat
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Address);
