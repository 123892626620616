import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        endDate: moment().format("DD.MM.YYYY"),
      },
    };
  }

  componentWillMount = () => {
    this.props.dispatch(
      Actions.fetchInventory(this.state.fetchParams)
    );
  };

  onChangeDate = value => {
    this.state.fetchParams.endDate = moment(value).format("DD.MM.YYYY");
    this.setState({ fetchParams: this.state.fetchParams }, function() {
      this.props.dispatch(Actions.fetchInventory(this.state.fetchParams));
    });
  };

  
  downloadReport = () => {
    Actions.downloadReport(
      this.state.fetchParams,
      "inventory.xlsx",
      "inventory.xlsx"
    );
  };

  render() {
    const { fetchParams } = this.state;
    const { isLoading, report } = this.props;

    return (
      <DataList
        loading={isLoading}
        data={report}
        fetchParams={fetchParams}
        onChangeDate={this.onChangeDate}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  report: state.reports.inventory,
});

export default connect(mapStateToProps)(Inventory);
