// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Select, Button } from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";
import OrderDetails from "./Details";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      report,
      meta,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      onChangePage,
      onChangeLimit,
      downloadReport,
      // details
      isLoadindDetails,
      onDetails,
      details,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    const columns = [
      {
        title: "Ответственный",
        dataIndex: "assignee_name",
        align: "left",
        width: "40%",
      },
      {
        title: "согласование",
        dataIndex: "approving",
        render: (approving, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {approving > 0 ? (
              <a>
                <OrderDetails
                  loading={isLoadindDetails}
                  progress={record.progress}
                  name={approving}
                  data={details}
                  onChange={onDetails.bind(this, record.id, "0")}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        ),

        align: "center",
        width: "10%",
      },
      {
        title: "в работе",
        dataIndex: "working",
        render: (working, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {working > 0 ? (
              <a>
                <OrderDetails
                  loading={isLoadindDetails}
                  progress={record.progress}
                  name={working}
                  data={details}
                  onChange={onDetails.bind(this, record.id, "1")}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        ),
        align: "center",
        width: "10%",
      },
      {
        title: "к отгрузке",
        dataIndex: "shipping",
        render: (shipping, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {shipping > 0 ? (
              <a>
                <OrderDetails
                  loading={isLoadindDetails}
                  progress={record.progress}
                  name={shipping}
                  data={details}
                  onChange={onDetails.bind(this, record.id, "2")}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        ),
        key: "shipping",
        align: "center",
        width: "10%",
      },
      {
        title: "отгружен",
        dataIndex: "shipped",
        render: (shipped, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {shipped > 0 ? (
              <a>
                <OrderDetails
                  loading={isLoadindDetails}
                  progress={record.progress}
                  name={shipped}
                  data={details}
                  onChange={onDetails.bind(this, record.id, "3")}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        ),
        key: "shipped",
        align: "center",
        width: "10%",
      },
      {
        title: "закрыт",
        dataIndex: "closed",
        render: (closed, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {closed > 0 ? (
              <a>
                <OrderDetails
                  loading={isLoadindDetails}
                  progress={record.progress}
                  name={closed}
                  data={details}
                  onChange={onDetails.bind(this, record.id, "4")}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        ),
        key: "closed",
        align: "center",
        width: "10%",
      },
      {
        title: "отменен",
        dataIndex: "canceled",
        render: (canceled, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {canceled > 0 ? (
              <a>
                <OrderDetails
                  loading={isLoadindDetails}
                  progress={record.progress}
                  name={canceled}
                  data={details}
                  onChange={onDetails.bind(this, record.id, "5")}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        ),
        key: "canceled",
        align: "center",
        width: "10%",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Статусы по заказам`}
        // extra={[
        //   <Button
        //     key="3"
        //     type="dashed"
        //     icon="file-excel"
        //     onClick={downloadReport}
        //   >
        //     Загрузить
        //   </Button>,
        // ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={report}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSizeOptions={["30", "60", "100", "200"]}
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
