import React from "react";
import { Form } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

export default function LossReason({
  getFieldDecorator = null,
  onChangeLossReason = null,
  lossReason = null
}) {
  return (
    <FormItem label={"Причина отказа"}>
      {getFieldDecorator("loss_reason", {
        initialValue: lossReason,
        rules: [
          {
            required: true,
            message: "Выберите причину отказа!"
          }
        ]
      })(
        <SelectFetch
          placeholder="Статус"
          onChange={onChangeLossReason}
          selected={lossReason}
          url={`/loss_reasons/search/?`}
        />
      )}
    </FormItem>
  );
}
