import update from "immutability-helper";

const initialState = {
  report: [],
  shipmentsByCategories: [{ data: [], child_items: [] }],
  shipmentsByCategoriesOwn: [
    {
      data: { amount: 0, cost: 0 },
      child_items: [{ data: { amount: 0, cost: 0 } }],
    },
  ],
  cashFlowItems: [],
  shipmentsByCategoriesPie: [],
  shipmentsByRegions: [],
  ordersByRegions: [],
  shipmentsByBusinessFields: [],
  ordersByBusinessFields: [],
  salesByPeriods: [],
  ordersByStatuses: [],
  purchaseFunnel: [],
  userStatistic: [],
  lossReasons: [],
  inventory: [],
  details: [],
  totals: {},
  periods: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  errors: false,
  isLoading: true,
  isLoadingInfo: true,
  isLoadindDetails: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_REPORT_REQUEST":
      return {
        ...state,
        report: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT":
      return {
        ...state,
        report: action.response.data,
        meta: action.response.meta,
        totals: action.response.totals,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_SALES_BY_PERIODS_REQUEST":
      return {
        ...state,
        salesByPeriods: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_SALES_BY_PERIODS":
      return {
        ...state,
        salesByPeriods: action.response.data,
        meta: action.response.meta,
        totals: action.response.totals,
        isLoading: false,
        errors: false,
      };

    case "FETCH_SALES_BY_PERIODS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_SHIPMENTS_BY_CATEGORIES_REQUEST":
      return {
        ...state,
        shipmentsByCategories: [{ data: [], child_items: [] }],
        isLoading: true,
        errors: false,
      };
    case "FETCH_SHIPMENTS_BY_CATEGORIES":
      return {
        ...state,
        shipmentsByCategories: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_SHIPMENTS_BY_CATEGORIES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_SHIPMENTS_BY_REGIONS_REQUEST":
      return {
        ...state,
        shipmentsByRegions: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_SHIPMENTS_BY_REGIONS":
      return {
        ...state,
        shipmentsByRegions: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_SHIPMENTS_BY_REGIONS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_SHIPMENTS_BY_BUSINESS_FIELDS_REQUEST":
      return {
        ...state,
        shipmentsByBusinessFields: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_SHIPMENTS_BY_BUSINESS_FIELDS":
      return {
        ...state,
        shipmentsByBusinessFields: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_SHIPMENTS_BY_BUSINESS_FIELDS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_ORDERS_BY_REGIONS_REQUEST":
      return {
        ...state,
        ordersByRegions: [],
        isLoadingInfo: true,
        errors: false,
      };
    case "FETCH_ORDERS_BY_REGIONS":
      return {
        ...state,
        ordersByRegions: action.response.data,
        isLoadingInfo: false,
        errors: false,
      };

    case "FETCH_ORDERS_BY_REGIONS_FAILURE":
      return {
        ...state,
        isLoadingInfo: false,
        errors: true,
      };

    case "FETCH_ORDERS_BY_BUSINESS_FIELDS_REQUEST":
      return {
        ...state,
        ordersByBusinessFields: [],
        isLoadingInfo: true,
        errors: false,
      };
    case "FETCH_ORDERS_BY_BUSINESS_FIELDS":
      return {
        ...state,
        ordersByBusinessFields: action.response.data,
        isLoadingInfo: false,
        errors: false,
      };

    case "FETCH_ORDERS_BY_BUSINESS_FIELDS_FAILURE":
      return {
        ...state,
        isLoadingInfo: false,
        errors: true,
      };

    case "FETCH_SHIPMENTS_BY_CATEGORIES_OWN_REQUEST":
      return {
        ...state,
        shipmentsByCategoriesOwn: [
          {
            data: { amount: 0, cost: 0 },
            child_items: [{ data: { amount: 0, cost: 0 } }],
          },
        ],
        isLoading: true,
        errors: false,
      };
    case "FETCH_SHIPMENTS_BY_CATEGORIES_OWN":
      return {
        ...state,
        shipmentsByCategoriesOwn: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_SHIPMENTS_BY_CATEGORIES_OWN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_SHIPMENTS_BY_CATEGORIES_PIE_REQUEST":
      return {
        ...state,
        shipmentsByCategoriesPie: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_SHIPMENTS_BY_CATEGORIES_PIE":
      return {
        ...state,
        shipmentsByCategoriesPie: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_SHIPMENTS_BY_CATEGORIES_PIE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_ORDERS_BY_STATUSES_REQUEST":
      return {
        ...state,
        ordersByStatuses: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_ORDERS_BY_STATUSES":
      return {
        ...state,
        ordersByStatuses: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_ORDERS_BY_STATUSES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_ORDERS_BY_STATUSES_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadindDetails: true,
        errors: false,
      };
    case "FETCH_ORDERS_BY_STATUSES_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadindDetails: false,
        errors: false,
      };

    case "FETCH_ORDERS_BY_STATUSES_DETAILS_FAILURE":
      return {
        ...state,
        isLoadindDetails: false,
        errors: true,
      };

    case "FETCH_PURCHASE_FUNNEL_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "FETCH_PURCHASE_FUNNEL":
      return {
        ...state,
        purchaseFunnel: action.response.data,
        details: action.response.details,
        isLoading: false,
        errors: false,
      };

    case "FETCH_PURCHASE_FUNNEL_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_USER_STATISTIC_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "FETCH_USER_STATISTIC":
      return {
        ...state,
        userStatistic: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_USER_STATISTIC_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_LOSS_REASONS_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "FETCH_LOSS_REASONS":
      return {
        ...state,
        lossReasons: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_LOSS_REASONS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_CASHFLOW_REPORT_REQUEST":
      return {
        ...state,
        report: [],
        periods: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_CASHFLOW_REPORT":
      return {
        ...state,
        report: action.response.data,
        periods: action.response.periods,
        isLoading: false,
        errors: false,
      };

    case "FETCH_CASHFLOW_REPORT_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    // cash_flow_items
    case "FETCH_CASHFLOW_ITEMS_REQUEST":
      return {
        ...state,
        cashFlowItems: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_CASHFLOW_ITEMS":
      return {
        ...state,
        cashFlowItems: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_CASHFLOW_ITEMS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_INVENTORY_REPORT_REQUEST":
      return {
        ...state,
        inventory: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_INVENTORY_REPORT":
      return {
        ...state,
        inventory: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_INVENTORY_REPORT_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_CASHFLOW_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_CASHFLOW_ITEM":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };

    case "POST_CASHFLOW_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PATCH_CASHFLOW_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_CASHFLOW_ITEM":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };

    case "PATCH_CASHFLOW_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_CASHFLOW_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_CASHFLOW_ITEM":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_CASHFLOW_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_CASHFLOW_ITEM_VALUE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_CASHFLOW_ITEM_VALUE":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };

    case "POST_CASHFLOW_ITEM_VALUE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    default:
      return state;
  }
}
