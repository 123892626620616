import api from "../api";

const Actions = {};

Actions.fetchDocuments = (ownerID, ownerType) => {
  return dispatch => {
    dispatch({ type: "FETCH_DOCUMENTS_REQUEST" });
    api
      .fetch("/documents", { owner_id: ownerID, owner_type: ownerType })
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_DOCUMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_DOCUMENTS_FAILURE", response });
      });
  };
};

Actions.fetchDocument = documentID => {
  return dispatch => {
    dispatch({ type: "FETCH_DOCUMENT_REQUEST" });
    api
      .fetch(`/documents/${documentID}`)
      .then(response => {
        dispatch({ type: "FETCH_DOCUMENT", response });
      })
      .catch(response => {
        dispatch({ type: "FETCH_DOCUMENT_FAILURE", response });
      });
  };
};

// assets
Actions.onUpload = (ownerID, ownerType, file) => {
  return dispatch => {
    dispatch({
      type: "DOCUMENT_UPLOAD"
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("owner_id", ownerID);
    formData.append("owner_type", ownerType);
    formData.append("content_type", file.type);
    formData.append("asset", file);
    return api
      .postFormData(`/documents`, formData)
      .then(response => {
        api.openNotification(
          "success",
          "Документ",
          "Документ успешно загружен!"
        );
        dispatch({ type: "DOCUMENT_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "DOCUMENT_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onDelete = (id) => {
  return dispatch => {
    dispatch({
      type: "DOCUMENT_DELETE_REQUEST"
    });
    return api
      .delete(`/documents/${id}`)
      .then(response => {
        api.openNotification(
          "success",
          "Документ",
          "Документ успешно удален!"
        );
        dispatch({ type: "DOCUMENT_DELETE_SUCCEED", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Документ", error);
          });
        }
        dispatch({ type: "DOCUMENT_DELETE_FAILED" });
      });
  };
};

export default Actions;
