// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Alert } from "antd";
import accounting from "accounting";
import "moment/locale/ru";

class Statuses extends Component {
  render() {
    const { purchase } = this.props;

    return (
      <Row>
        <Col span={24}>
          <Alert
            message="Формат маски для ввода шифра"
            description="XX/YY/ZZZ, где XX - код производителя (цифры), YY - год производства, ZZZ - порядковый номер спецификации или договора"
            type="info"
            showIcon
          />
        </Col>
        <Col span={24} style={{ paddingTop: "15px" }}>
          <Card style={{ borderColor: "#1890ff" }}>
            <Row gutter={10}>
              <Col span={24} style={{ paddingBottom: "10px" }} />
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ width: "65%" }}>Всего по договору</td>
                    <td
                      style={{ width: "35%", textAlign: "right" }}
                    >{`${accounting.formatNumber(
                      Number(purchase.total) + Number(purchase.vat_total),
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "65%" }}>Оплачено</td>
                    <td
                      style={{ width: "35%", textAlign: "right" }}
                    >{`${accounting.formatNumber(
                      purchase.paid,
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "65%" }}>Субподряд</td>
                    <td
                      style={{ width: "35%", textAlign: "right" }}
                    >{`${accounting.formatNumber(
                      purchase.subcontracts,
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "65%" }}>Материалы</td>
                    <td
                      style={{ width: "35%", textAlign: "right" }}
                    >{`${accounting.formatNumber(
                      purchase.materials,
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                </tbody>
              </table>
              <Col span={24} />
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}
Statuses.propTypes = {
  purchase: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

export default Statuses;
