// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row, Form, Descriptions } from "antd";
import Can from "../../../rules/Can";
import SelectFetch from "../../../components/SelectFetch";

class GeneralInfo extends Component {
  render() {
    const {
      requestForProposal,
      currentUser,
      onChangeAssignee,
      onChangeContactType,
      onChangeContactSource,
    } = this.props;

    return (
      <React.Fragment>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label="Ответственный" span={3}>
              <Can
                role={currentUser.role}
                perform="requestForProposal:assignee"
                yes={() => (
                  <SelectFetch
                    showSearch
                    placeholder="Ответственный"
                    onChange={onChangeAssignee}
                    selected={
                      requestForProposal.assignee
                        ? requestForProposal.assignee
                        : null
                    }
                    url={"/users/search?"}
                  />
                )}
                no={() =>
                  requestForProposal.assignee
                    ? requestForProposal.assignee.text
                    : null
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label="Компания" span={3}>
              {requestForProposal.company
                ? requestForProposal.company.text
                : null}
            </Descriptions.Item>
            <Descriptions.Item label="Тип контакта" span={3}>
              <SelectFetch
                showSearch
                placeholder="Тип контакта"
                onChange={onChangeContactType}
                selected={
                  requestForProposal.contact_type
                    ? requestForProposal.contact_type
                    : null
                }
                url={"/contact_types/search?"}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Источник" span={3}>
              <SelectFetch
                showSearch
                placeholder="Тип контакта"
                onChange={onChangeContactSource}
                selected={
                  requestForProposal.contact_source
                    ? requestForProposal.contact_source
                    : null
                }
                url={"/contact_sources/search?"}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Тема" span={3}>
              {requestForProposal.subject}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={3}>
              {requestForProposal.description}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </React.Fragment>
    );
  }
}

GeneralInfo.propTypes = {
  requestForProposal: PropTypes.object,
};

export default GeneralInfo;
