import api from "../api";
import download from "downloadjs";

const Actions = {};

Actions.fetchPurchases = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PURCHASES_REQUEST" });
    const params = getState().purchases.fetchParams;
    let companies = [];
    if (params.companies) {
      companies = params.companies.map((company) => {
        if (company && company.id) {
          return company.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      companies: companies,
    };

    return api
      .fetch("/purchases", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_PURCHASES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PURCHASES_FAILURE", response });
      });
  };
};

Actions.fetchPurchase = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PURCHASE_REQUEST" });
    return api
      .fetch(`/purchases/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_PURCHASE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification(
              "error",
              "Договор с поставщиком",
              error
            );
          });
        }
        dispatch({ type: "FETCH_PURCHASE_FAILURE", response });
      });
  };
};

Actions.onCreate = (attachments) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PURCHASE_REQUEST" });
    const purchase = getState().purchases.purchase;
    const purchase_payment_schedules = purchase.purchase_payment_schedules.map(
      (item) => {
        return {
          id: item.is_exist ? item.id : null,
          date: item.date,
          payment_type: item.payment_type,
          amount: item.amount,
          percent: item.percent,
          _destroy: item._destroy,
        };
      }
    );
    let documents = [];
    attachments.map((item) => {
      return documents.push({
        name: item.name,
        content_type: item.content_type,
        asset: item.asset,
      });
    });

    const data = {
      purchase: {
        company_id: purchase.company ? purchase.company.id : null,
        agreement: purchase.agreement,
        agreement_date: purchase.agreement_date,
        // code: purchase.code,
        total: purchase.total,
        vat_total: purchase.vat_total,
        purchase_payment_schedules_attributes: purchase_payment_schedules,
        documents_attributes: documents,
      },
    };

    return api
      .post(`/purchases`, data)
      .then((response) => {
        api.openNotification(
          "success",
          "Договор с поставщиком",
          "Успешно создан!"
        );
        dispatch({ type: "POST_PURCHASE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Договор с поставщиком", error);
          });
        }
        dispatch({ type: "POST_PURCHASE_FAILURE", response });
      });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PURCHASE_REQUEST" });
    const purchase = getState().purchases.purchase;
    const purchase_payment_schedules = purchase.purchase_payment_schedules.map(
      (item) => {
        return {
          id: item.is_exist ? item.id : null,
          date: item.date,
          payment_type: item.payment_type,
          amount: item.amount,
          percent: item.percent,
          _destroy: item._destroy,
        };
      }
    );

    const data = {
      purchase: {
        company_id: purchase.company ? purchase.company.id : null,
        agreement: purchase.agreement,
        agreement_date: purchase.agreement_date,
        code: purchase.code,
        total: purchase.total,
        vat_total: purchase.vat_total,
        purchase_payment_schedules_attributes: purchase_payment_schedules,
      },
    };

    return api
      .patch(`/purchases/${id}`, data)
      .then((response) => {
        api.openNotification(
          "success",
          "Договор с поставщиком",
          "Успешно обновлен!"
        );
        dispatch({ type: "PATCH_PURCHASE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Договор с поставщиком", error);
          });
        }
        dispatch({ type: "PATCH_PURCHASE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_PURCHASE_REQUEST" });
    return api
      .delete(`/purchases/${id}`)
      .then((response) => {
        api.openNotification(
          "success",
          "Договор с поставщиком",
          "Успешно удален!"
        );
        dispatch({ type: "DELETE_PURCHASE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Договор с поставщиком", error);
          });
        }
        dispatch({
          type: "DELETE_PURCHASE_FAILURE",
        });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_NEW",
    });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PURCHASE_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PURCHASE_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onShowModalForm = () => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_SHOW_MODAL_FORM",
    });
  };
};

Actions.onChangeVisibleModalForm = () => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_MODAL_FORM_VISIBLE",
    });
  };
};

// General
Actions.onGenerateCode = (value) => {
  return (dispatch) => {
    dispatch({ type: "PURCHASE_CODE_REQUEST" });
    api
      .fetch(`/purchases/autocode`, { value: value.id })
      .then((response) => {
        dispatch({ type: "PURCHASE_CODE_SUCCESS", response });
      })
      .catch((error) => {
        api.handleError(error, "Договор с поставщиком");
        dispatch({ type: "PURCHASE_CODE_REQUEST_FAILURE" });
      });
  };
};

Actions.onChangeCompany = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_COMPANY",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeAgreementDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_AGREEMENT_DATE",
      value: value,
    });
  };
};

Actions.onChangeAgreement = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_AGREEMENT",
      value: value,
    });
  };
};

Actions.onChangeCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_CODE",
      value: value,
    });
  };
};

Actions.onChangeTotal = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_TOTAL",
      value: value,
    });
  };
};

Actions.onChangeVatTotal = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_VAT_TOTAL",
      value: value,
    });
  };
};

// Items
Actions.onAddItem = () => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_ADD_ITEM",
    });
  };
};

Actions.onDeleteItems = (items) => {
  return (dispatch, getState) => {
    items.map((item) => {
      let purchasePaymentSchedules =
        getState().purchases.purchase.purchase_payment_schedules;
      let idx = purchasePaymentSchedules.findIndex(function (p) {
        return p.id == item;
      });
      if (idx === -1) {
        return null;
      }
      if (purchasePaymentSchedules[idx].is_exist) {
        dispatch({
          type: "PURCHASE_ITEM_DESTROY",
          idx: idx,
        });
      } else {
        dispatch({
          type: "PURCHASE_ITEM_DELETE",
          idx: idx,
        });
      }
    });
  };
};

Actions.onChangeItemDate = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_ITEM_DATE",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemPaymentType = (id, item) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_ITEM_PAYMENT_TYPE",
      id: id,
      item: item,
    });
  };
};

Actions.onChangeItemAmount = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_ITEM_AMOUNT",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeItemPercent = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASE_CHANGE_ITEM_PERCENT",
      id: id,
      value: value,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASES_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASES_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCompanies = (values) => {
  return (dispatch) => {
    dispatch({
      type: "PURCHASES_FILTER_COMPANIES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.downloadXLSReport = (params, url = "", filename = "") => {
  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    company: params.company,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      download(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
