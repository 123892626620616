// @flow
import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Tabs,
  Form,
  Table,
  Input,
  DatePicker,
  Button,
  Spin
} from "antd";
import PropTypes from "prop-types";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";

import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

const FormItem = Form.Item;

class ShipmentModalForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteItems = () => {
    this.props.onDeleteItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      isLoading,
      shipment,
      visible,
      onCancel,
      onChangeNumber,
      onChangeDate,
      onChangeItemQuantity,
      onChangeItemAmount,
      onChangeItemAmountVat
    } = this.props;

    const { selectedRowKeys } = this.state;
    const shipmentItems = shipment.shipment_items.filter(function(item) {
      return !item._destroy;
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Продукция",
        dataIndex: "description",
        render: (description, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{description}</span>
            </div>
          );
        },
        align: "center",
        width: "25%"
      },
      {
        title: "Кол-во",
        dataIndex: "quantity",
        render: (quantity, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={quantity}
              onChange={onChangeItemQuantity}
            />
          );
        },
        align: "center",
        width: "15%"
      },
      {
        title: "Сумма НДС",
        dataIndex: "amount_vat",
        render: (amount_vat, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={amount_vat}
              onChange={onChangeItemAmountVat}
            />
          );
        },
        align: "center",
        width: "20%"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={amount}
              onChange={onChangeItemAmount}
            />
          );
        },
        align: "center",
        width: "20%"
      },
      {
        title: "Всего",
        dataIndex: "total",
        render: (total, record) => {
          return accounting.formatNumber(
            Number(record.amount) + Number(record.amount_vat),
            0,
            " "
          );
        },
        align: "center",
        width: "20%"
      }
    ];

    return (
      <Modal
        title="Отгрузка продукции"
        destroyOnClose
        visible={visible}
        width={1000}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={12}>
              <FormItem label={"Номер"}>
                {getFieldDecorator("number", {
                  onChange: onChangeNumber,
                  initialValue: shipment.number,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите номер!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Дата списания"}>
                {getFieldDecorator("date", {
                  onChange: onChangeDate,
                  initialValue: moment(shipment.date),
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите дату списания!"
                    }
                  ]
                })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ minHeight: "40px" }}>
            <Col span={24}>
              {hasSelected ? (
                <Button onClick={this.onDeleteItems}>Удалить</Button>
              ) : null}
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                loading={isLoading}
                rowSelection={rowSelection}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={shipmentItems}
                columns={columns}
                pagination={false}
                rowKey="id"
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

ShipmentModalForm.propTypes = {
  workOrders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(ShipmentModalForm);
