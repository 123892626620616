// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/serviceOrders";
import ModalForm from "./Form";

class ServiceOrderModal extends Component {
  onSave = () => {
    if (this.props.serviceOrder.id) {
      this.props
        .dispatch(Actions.onUpdate(this.props.serviceOrder.id))
        .then(() => {
          this.props.dispatch(Actions.fetchServiceOrders());
        });
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        this.props.dispatch(Actions.fetchServiceOrders());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onChangeDate = value => {
    this.props.dispatch(Actions.onChangeDate(value));
  };

  onChangeOrder = value => {
    this.props.dispatch(Actions.onChangeOrder(value));
  };

  onChangeShipment = value => {
    this.props.dispatch(Actions.onChangeShipment(value));
  };

  onChangeProduct = value => {
    this.props.dispatch(Actions.onChangeProduct(value));
  };

  onChangeCost = value => {
    this.props.dispatch(Actions.onChangeCost(value));
  };

  render() {
    const { isLoading, serviceOrder, isModalVisible } = this.props;
    return (
      <ModalForm
        isLoading={isLoading}
        visible={isModalVisible}
        serviceOrder={serviceOrder}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeDate={this.onChangeDate}
        onChangeOrder={this.onChangeOrder}
        onChangeShipment={this.onChangeShipment}
        onChangeProduct={this.onChangeProduct}
        onChangeCost={this.onChangeCost}
        destroyOnClose
      />
    );
  }
}

ServiceOrderModal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  serviceOrder: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.serviceOrders.isLoading,
  isModalVisible: state.serviceOrders.isModalVisible,
  errors: state.serviceOrders.errors,
  serviceOrder: state.serviceOrders.serviceOrder
});

export default connect(mapStateToProps)(withRouter(ServiceOrderModal));
