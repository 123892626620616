// @flow
import React, { Component } from "react";
import { Form, Table, Modal, Row, Col } from "antd";
import update from "immutability-helper";

import PropTypes from "prop-types";
import BankAccountForm from "./BankAccountForm";

class BankAccounts extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isVisible: false,
    bankAccount: {}
  };

  onEditBankAccount = idx => {
    const { bankAccounts } = this.props;
    this.setState({
      isVisible: true,
      bankAccount: bankAccounts[idx]
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onChangeBankAccount(this.state.bankAccount);
        this.setState({
          isVisible: false
        });
      }
    });
  };


  handleCancel = () => {
    this.setState({
      isVisible: false
    });
  };

  onChangeName = e => {
    this.setState({
      bankAccount: update(this.state.bankAccount, {
        name: { $set: e.target.value }
      })
    });
  };

  onChangeBankName = e => {
    this.setState({
      bankAccount: update(this.state.bankAccount, {
        bank_name: { $set: e.target.value }
      })
    });
  };

  onChangeAccount = e => {
    this.setState({
      bankAccount: update(this.state.bankAccount, {
        account: { $set: e.target.value }
      })
    });
  };

  onChangeCorrAccount = e => {
    this.setState({
      bankAccount: update(this.state.bankAccount, {
        corr_account: { $set: e.target.value }
      })
    });
  };

  onChangeBik = (e) => {
    this.setState({
      bankAccount: update(this.state.bankAccount, {
        bik: { $set: e.target.value }
      })
    });
  };

  render() {
    const { loading, bankAccounts } = this.props;
    const { isVisible, confirmLoading, bankAccount } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Наименование",
        dataIndex: "name",

        render: (name, record, index) => (
          <div>
            <a onClick={this.onEditBankAccount.bind(this, index)}>{name}</a>
          </div>
        ),
        align: "center",
        width: "25%"
      },
      {
        title: "Счет",
        dataIndex: "account",
        render: (account, record) => `${account}`,
        align: "center",
        width: "20%"
      },
      {
        title: "Коррсчет",
        dataIndex: "corr_account",
        render: (corr_account, record) => `${corr_account}`,
        align: "center",
        width: "20%"
      },
      {
        title: "Банк",
        dataIndex: "bank_name",
        render: bank_name => `${bank_name}`,
        align: "center",
        width: "35%"
      }
    ];

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24} style={{ paddingBottom: "15px" }}>
          <Table
            bordered
            size={"middle"}
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={bankAccounts}
            rowKey="id"
          />
        </Col>
        <Modal
          title="Бансковский счет"
          visible={isVisible}
          width={620}
          onOk={this.onSubmit}
          onCancel={this.handleCancel}
        >
          <Form layout={"vertical"} onSubmit={this.onSubmit}>
            <BankAccountForm
              getFieldDecorator={getFieldDecorator}
              bankAccount={bankAccount}
              onChangeName={this.onChangeName}
              onChangeBankName={this.onChangeBankName}
              onChangeAccount={this.onChangeAccount}
              onChangeCorrAccount={this.onChangeCorrAccount}
              onChangeBik={this.onChangeBik}
            />
          </Form>
        </Modal>
      </Row>
    );
  }
}

BankAccounts.propTypes = {
  firms: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(BankAccounts);
