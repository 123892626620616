/// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../actions/session";

import Dashboard from "../Dashboard";
import MainLayout from "../Layout";
import NotFound from "../../components/NotFound";
import Login from "../Login";
import Profile from "../Profile";
import Orders from "../Orders/List";
import Order from "../Orders/Form";
import WorkOrders from "../WorkOrders/List";
import WorkOrder from "../WorkOrders/Form";
import Invoices from "../Invoices/List";
import Invoice from "../Invoices/Form";
import Payments from "../Payments/List";
import Categories from "../Products/Categories";
import Products from "../Products/List";
import Product from "../Products/ProductForm";
import Purchases from "../Purchases/List";
import Purchase from "../Purchases/Form";

import ServiceOrders from "../ServiceOrders/List";

import Companies from "../Companies/List";
import Company from "../Companies/Form";

import Firms from "../Firms/List";
import Firm from "../Firms/Form";

import RequestForProposals from "../RequestForProposals/List";
import RequestForProposal from "../RequestForProposals/Form";

// reports
import SalesByPeriods from "../Reports/SalesByPeriods";
import ShipmentsByCategories from "../Reports/ShipmentsByCategories";
import ShipmentsByCategoriesPie from "../Reports/ShipmentsByCategoriesPie";
import ShipmentsByCategoriesOwn from "../Reports/ShipmentsByCategoriesOwn";
import PlanShipments from "../Reports/PlanShipments";
import LastShipments from "../Reports/LastShipments";
import Cashflow from "../Reports/Cashflow";
import CashflowSettings from "../Reports/Cashflow/Settings";
import CashflowPlan from "../Reports/CashflowPlan";
import ClientAccounts from "../Reports/ClientAccounts";
import Inpayments from "../Reports/Inpayments";
import Outpayments from "../Reports/Outpayments";
import OutpaymentsPlan from "../Reports/OutpaymentsPlan";
import ShipmentsByRegions from "../Reports/ShipmentsByRegions";
import ShipmentsByBusinessFields from "../Reports/ShipmentsByBusinessFields";
import PurchaseFunnel from "../Reports/PurchaseFunnel";
import UserStatistics from "../Reports/UserStatistics";
import LossReasonsReport from "../Reports/LossReasons";
import OrdersByStatuses from "../Reports/OrdersByStatuses";
import Inventory from "../Reports/Inventory";

// references
import Units from "../References/Units/List";
import Unit from "../References/Units/Form";

import Departments from "../References/Departments/List";
import Department from "../References/Departments/Form";

import Users from "../References/Users/List";
import User from "../References/Users/Form";

import Countries from "../References/Countries/List";
import Country from "../References/Countries/Form";

import Cities from "../References/Cities/List";

import ContactSources from "../References/ContactSources/List";

import BusinessFields from "../References/BusinessFields/List";
import LossReasons from "../References/LossReasons/List";

class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.dispatch(Actions.authenticate());
    } else {
      this.props.dispatch(Actions.unauthenticate());
    }
  }

  renderRoutes = isAuthenticated => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );

    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <MainLayout>
            <Switch>
              <PrivateRoute
                isAuthenticated
                path="/"
                exact
                component={Dashboard}
              />

              <PrivateRoute
                isAuthenticated
                path="/profile"
                component={Profile}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders"
                exact
                component={Orders}
              />
              <PrivateRoute
                isAuthenticated
                path="/orders/new"
                exact
                component={Order}
              />
              <PrivateRoute
                isAuthenticated
                path="/orders/:id/edit"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/workOrders"
                exact
                component={WorkOrders}
              />
              <PrivateRoute
                isAuthenticated
                path="/workOrders/new"
                exact
                component={WorkOrder}
              />
              <PrivateRoute
                isAuthenticated
                path="/workOrders/:id/edit"
                exact
                component={WorkOrder}
              />

              <PrivateRoute
                isAuthenticated
                path="/purchases"
                exact
                component={Purchases}
              />

              <PrivateRoute
                isAuthenticated
                path="/purchases/new"
                exact
                component={Purchase}
              />
              <PrivateRoute
                isAuthenticated
                path="/purchases/:id/edit"
                exact
                component={Purchase}
              />

              <PrivateRoute
                isAuthenticated
                path="/service_orders"
                exact
                component={ServiceOrders}
              />

              <PrivateRoute
                isAuthenticated
                path="/invoices"
                exact
                component={Invoices}
              />
              <PrivateRoute
                isAuthenticated
                path="/invoices/new"
                exact
                component={Invoice}
              />
              <PrivateRoute
                isAuthenticated
                path="/invoices/:id/edit"
                exact
                component={Invoice}
              />

              <PrivateRoute
                isAuthenticated
                path="/request_for_proposals"
                exact
                component={RequestForProposals}
              />
              <PrivateRoute
                isAuthenticated
                path="/request_for_proposals/new"
                exact
                component={RequestForProposal}
              />
              <PrivateRoute
                isAuthenticated
                path="/request_for_proposals/:id/edit"
                exact
                component={RequestForProposal}
              />

              <PrivateRoute
                isAuthenticated
                path="/companies"
                exact
                component={Companies}
              />
              <PrivateRoute
                isAuthenticated
                path="/companies/new"
                exact
                component={Company}
              />
              <PrivateRoute
                isAuthenticated
                path="/companies/:id/edit"
                exact
                component={Company}
              />

              <PrivateRoute
                isAuthenticated
                path="/firms"
                exact
                component={Firms}
              />
              <PrivateRoute
                isAuthenticated
                path="/firms/new"
                exact
                component={Firm}
              />
              <PrivateRoute
                isAuthenticated
                path="/firms/:id/edit"
                exact
                component={Firm}
              />

              <PrivateRoute
                isAuthenticated
                path="/payments"
                exact
                component={Payments}
              />

              <Route
                path="/categories"
                render={({ match: { url } }) => (
                  <>
                    <Route path={`${url}/:categoryID`} component={Categories} />
                    <Route
                      path={`${url}/:categoryID/products`}
                      exact
                      component={Products}
                    />
                    <Route
                      path={`${url}/:categoryID/products/new`}
                      component={Product}
                    />
                    <Route
                      path={`${url}/:categoryID/products/:id/edit`}
                      component={Product}
                    />
                  </>
                )}
              />

              <Route
                path="/reports"
                render={({ match: { url } }) => (
                  <>
                    <Route path={`${url}/sales`} component={SalesByPeriods} />
                    <Route
                      path={`${url}/shipments_by_categories`}
                      component={ShipmentsByCategories}
                    />
                    <Route
                      path={`${url}/shipments_by_categories_pie`}
                      component={ShipmentsByCategoriesPie}
                    />
                    <Route
                      path={`${url}/shipments_by_categories_own`}
                      component={ShipmentsByCategoriesOwn}
                    />
                    <Route
                      path={`${url}/shipments_by_regions`}
                      component={ShipmentsByRegions}
                    />
                    <Route
                      path={`${url}/shipments_by_business_fields`}
                      component={ShipmentsByBusinessFields}
                    />
                    <Route
                      path={`${url}/purchase_funnel`}
                      component={PurchaseFunnel}
                    />
                    <Route
                      path={`${url}/user_statistics`}
                      component={UserStatistics}
                    />
                    <Route
                      path={`${url}/loss_reasons`}
                      component={LossReasonsReport}
                    />
                    <Route
                      path={`${url}/lastshipments`}
                      component={LastShipments}
                    />
                    <Route
                      path={`${url}/planshipments`}
                      component={PlanShipments}
                    />
                    <Route exact path={`${url}/cashflow`} component={Cashflow} />
                    <Route exact path={`${url}/cashflow/settings`} component={CashflowSettings} />
                    
                    <Route
                      path={`${url}/plancashflow`}
                      component={CashflowPlan}
                    />
                    <Route
                      path={`${url}/orders_by_statuses`}
                      component={OrdersByStatuses}
                    />
                    <Route
                      path={`${url}/inventory`}
                      component={Inventory}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/clientaccounts`}
                      component={ClientAccounts}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/inpayments`}
                      component={Inpayments}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/outpayments`}
                      component={Outpayments}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/planoutpayments`}
                      component={OutpaymentsPlan}
                    />
                  </>
                )}
              />

              <Route
                path="/references"
                render={({ match: { url } }) => (
                  <>
                    <Route path={`${url}/units/new`} component={Unit} />
                    <Route path={`${url}/units/:id/edit`} component={Unit} />
                    <Route exact path={`${url}/units`} component={Units} />

                    <Route
                      path={`${url}/departments/new`}
                      component={Department}
                    />
                    <Route
                      path={`${url}/departments/:id/edit`}
                      component={Department}
                    />
                    <Route
                      exact
                      path={`${url}/departments`}
                      component={Departments}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/users/new`}
                      component={User}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/users`}
                      exact
                      component={Users}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/users/:id/edit`}
                      component={User}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/countries/new`}
                      component={Country}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/countries`}
                      exact
                      component={Countries}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/countries/:id/edit`}
                      component={Country}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/cities`}
                      exact
                      component={Cities}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/contact_sources`}
                      exact
                      component={ContactSources}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/business_fields`}
                      exact
                      component={BusinessFields}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/loss_reasons`}
                      exact
                      component={LossReasons}
                    />
                  </>
                )}
              />

              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </Switch>
      </Router>
    );
  };

  render() {
    return (
      <div>
        {this.props.isLoading
          ? null
          : this.renderRoutes(this.props.isAuthenticated)}
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.object
};

const mapStateToProps = state => ({
  isAuthenticated: state.session.isAuthenticated,
  isLoading: state.session.isLoading
});
export default connect(mapStateToProps)(App);
