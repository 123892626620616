// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Pagination, Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";
import ModalForm from "../ModalForm";

const Search = Input.Search;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  getFilteredData = () => {
    console.log("123");
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      loading,
      isModalVisible,
      serviceOrders,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onFetchServiceOrder,
      onNew
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Номер",
        dataIndex: "id",
        render: (id, record) => (
          <a onClick={onFetchServiceOrder.bind(this, id)}>{record.id}</a>
        ),
        key: "id",
        width: "5%",
        align: "center"
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: date => moment(date).format("L"),
        width: "10%",
        align: "center"
      },
      {
        title: "Заказ",
        dataIndex: "order",
        render: (order, record) => (
          <Link to={`/orders/${order.id}/edit`}>{order.text}</Link>
        ),
        key: "order",
        width: "15%",
        align: "center"
      },
      {
        title: "Отгрузка",
        dataIndex: "shipment",
        render: (shipment, record) =>
          shipment
            ? `${shipment.text} от ${moment(shipment.date).format("L")}`
            : null,
        key: "shipment",
        width: "25%",
        align: "center"
      },
      {
        title: "Услуга",
        dataIndex: "product",
        render: (product, record) => product.text,
        key: "product",
        width: "25%"
      },
      {
        title: "Сумма",
        dataIndex: "cost",
        render: (cost, record) => accounting.formatNumber(Number(cost), 2, " "),
        align: "right",
        width: "10%"
      },
      {
        title: "Списано",
        dataIndex: "costs",
        render: (costs, record) =>
          accounting.formatNumber(Number(costs), 2, " "),
        align: "right",
        width: "10%"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Button key="2" type="primary" ghost onClick={onNew}>
        Создать
      </Button>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Услуги`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={serviceOrders}
                // expandedRowRender={record => this.renderExpandedRow(record)}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
            <ModalForm visible={isModalVisible} />
          </Row>
        }
      />
    );
  }
}

List.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default List;
