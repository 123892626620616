import React from "react";
import {
  Chart,
  Interval,
  Axis,
  Tooltip,
  Coordinate,
  Legend,
  View,
  Annotation,
  Label,
} from "bizcharts";
import { DataView } from "@antv/data-set";

export default class Pie extends React.Component {
  render() {
    const { height, forceFit = true, data = [] } = this.props;

    const cols = {
      percent: {
        formatter: (val) => {
          val = Math.round(val * 100) + "%";
          return val;
        },
      },
    };

    const dv = new DataView();
    dv.source(data).transform({
      type: "percent",
      field: "count",
      dimension: "item",
      as: "percent",
    });

    return (
      <Chart
        height={height}
        data={dv}
        scale={cols}
        // padding={[0, 30, 70, 60]}
        autoFit
      >
        <Coordinate type={"theta"} radius={0.75} innerRadius={0.6} />
        <Axis visible={false} />

        <Tooltip showTitle={false} />
        <Interval
          adjust="stack"
          position="percent"
          color="item"
          shape="sliceShape"
        />

        
      </Chart>
    );
  }
}
