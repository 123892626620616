// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  render() {
    const {
      visible,
      isPaused,
      startDate,
      endDate,
      statuses,
      contact_types,
      assignees,
      onClose,
      onChangePeriod,
      onChangeStatuses,
      onChangeContactTypes,
      onChangePaused,
      onChangeAssignees,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период создания"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Перенесено"}>
              {getFieldDecorator("type", {
                onChange: onChangePaused,
                initialValue: isPaused,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Тип"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={true}>да</Option>
                  <Option value={false}>нет</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("statuses", {
                onChange: onChangeStatuses,
                initialValue: statuses ? statuses : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"1"}>согласование</Option>
                  <Option value={"2"}>утвержден</Option>
                  <Option value={"3"}>в работе</Option>
                  <Option value={"4"}>успех</Option>
                  <Option value={"7"}>успех (тендер)</Option>
                  <Option value={"5"}>отказ</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Тип контакта"}>
              {getFieldDecorator("contact_types", {
                initialValue: contact_types ? contact_types : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Тип контакта"
                  onChange={onChangeContactTypes}
                  selected={contact_types ? contact_types : []}
                  url={"/contact_types/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("assignees", {
                initialValue: assignees ? assignees : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={onChangeAssignees}
                  selected={assignees ? assignees : []}
                  url={"/users/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
