// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Menu,
  Dropdown,
  Icon,
  Tabs,
  Checkbox,
  DatePicker,
} from "antd";
import General from "./General";
import GeneralInfo from "./GeneralInfo";
import Events from "./Events";
import Products from "./Products";
import Status from "./Status";
import LossReason from "./LossReason";
import Documents from "../../../components/Documents";
import Attachments from "../Attachments";
import moment from "moment";
import "moment/locale/ru";
import canCheck from "../../../rules/canCheck";

const { TabPane } = Tabs;
const FormItem = Form.Item;

class RequestForProposalForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { tabIndex: "1" };
  }

  onChangeTab = (key) => {
    // console.log(key)
    this.setState({ tabIndex: key });
  };

  onChangeStatus = (value) => {
    if (value.id === "failed") {
      this.props.onChangeStatusToFail(value);
    } else {
      this.props.onChangeStatus(value);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const { tabIndex } = this.state;
    const {
      isNew,
      currentUser,
      requestForProposal,
      onClose,
      onCreateCompany,
      onChangeLossReason,
      onChangePaused,
      onChangeDate,
      onChangeNotifyDate,
      onChangeCompany,
      onChangeContactType,
      onChangeContactSource,
      onChangeSubject,
      onChangeDescription,
      onChangeAssignee,
      event,
      isEventsLoading,
      isEventVisible,
      onSaveEvent,
      onAddEvent,
      onCancelEvent,
      onChangeEventType,
      onChangeDescriptionEvent,
      onChangeEventNotifyDate,
      // products
      isProductsLoading,
      isProductVisible,
      onSaveProduct,
      onDeleteProduct,
      onAddProduct,
      onCancelProduct,
      // attachments
      isAttachmentsLoading,
      onDeleteAttachment,
      onUploadAttachment,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const menu = (
      <Menu>
        <Menu.Item key="3" onClick={onCreateCompany}>
          <Icon type="build" />
          Добавить компанию
        </Menu.Item>
      </Menu>
    );

    const canEdit = canCheck(currentUser, "requestForProposal:edit", {
      userId: currentUser.user_id,
      ownerId: requestForProposal.user ? requestForProposal.user.id : null,
    });

    const operations = (
      <Button type="primary" size="small" ghost onClick={onAddProduct}>
        Добавить
      </Button>
    );

    return (
      <Card
        title={
          <div>
            <h2 style={{ marginBottom: 0 }}>
              {`Заявка #${isNew ? "новая" : requestForProposal.id} от ${moment(
                requestForProposal.date
              ).format("LLL")}`}
            </h2>
            <span style={{ fontSize: "12px" }}>
              {requestForProposal.company
                ? requestForProposal.company.text
                : ""}
            </span>
          </div>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
            <Dropdown overlay={menu}>
              <Button style={{ marginLeft: "8px" }}>
                Действия <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        }
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {isNew || canEdit ? (
            <General
              isNew={isNew}
              currentUser={currentUser}
              getFieldDecorator={getFieldDecorator}
              requestForProposal={requestForProposal}
              onChangeCompany={onChangeCompany}
              onChangeContactType={onChangeContactType}
              onChangeContactSource={onChangeContactSource}
              onChangeSubject={onChangeSubject}
              onChangeDescription={onChangeDescription}
              onChangeDate={onChangeDate}
              onChangeAssignee={onChangeAssignee}
            />
          ) : (
            <GeneralInfo
              getFieldDecorator={getFieldDecorator}
              requestForProposal={requestForProposal}
              currentUser={currentUser}
              onChangeContactType={onChangeContactType}
              onChangeContactSource={onChangeContactSource}
              onChangeAssignee={onChangeAssignee}
            />
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {isNew ? null : (
              <Tabs
                onChange={this.onChangeTab}
                defaultActiveKey={tabIndex}
                tabBarExtraContent={tabIndex === "3" ? operations : null}
              >
                <TabPane tab="КП" key="1">
                  <Attachments
                    isLoading={isAttachmentsLoading}
                    attachments={requestForProposal.attachments}
                    onUpload={onUploadAttachment}
                    onDelete={onDeleteAttachment}
                  />
                </TabPane>
                <TabPane tab="Файлы" key="2">
                  <Documents
                    ownerID={requestForProposal.id}
                    ownerType={"RequestProposal"}
                  />
                </TabPane>

                <TabPane tab="Оборудование" key="3">
                  <Products
                    isProductsLoading={isProductsLoading}
                    isProductVisible={isProductVisible}
                    requestProposalItems={
                      requestForProposal.request_proposal_items
                    }
                    onSaveProduct={onSaveProduct}
                    onDeleteProduct={onDeleteProduct}
                    onCancelProduct={onCancelProduct}
                  />
                </TabPane>
              </Tabs>
            )}
          </Col>
        </Col>
        <Col xs={0} sm={0} md={1} lg={1} xl={1} />
        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
          {isNew ? null : (
            <Row gutter={10}>
              <Col span={12}>
                <FormItem label={"Перенесено"}>
                  {getFieldDecorator("is_paused", {
                    initialValue: requestForProposal.is_paused,
                    valuePropName: "checked",
                    onChange: onChangePaused,
                  })(<Checkbox></Checkbox>)}
                </FormItem>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 12 }}>
                <FormItem label={"Напомнить (по email)"}>
                  {getFieldDecorator("notify_date", {
                    onChange: onChangeNotifyDate,
                    initialValue: requestForProposal.notify_date
                      ? moment(requestForProposal.notify_date)
                      : null,
                  })(
                    <DatePicker format={"LLL"} className="ant-btn-width-100" />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Status
                  currentUser={currentUser}
                  getFieldDecorator={getFieldDecorator}
                  rfpId={requestForProposal.id}
                  status={requestForProposal.status}
                  onChangeStatus={this.onChangeStatus}
                />
              </Col>
              {requestForProposal.status &&
              requestForProposal.status.id === "failed" ? (
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <LossReason
                    getFieldDecorator={getFieldDecorator}
                    lossReason={requestForProposal.loss_reason}
                    onChangeLossReason={onChangeLossReason}
                  />
                </Col>
              ) : null}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Events
                  requestProposalEvents={
                    requestForProposal.request_proposal_events
                  }
                  isEventsLoading={isEventsLoading}
                  isEventVisible={isEventVisible}
                  event={event}
                  onSaveEvent={onSaveEvent}
                  onAddEvent={onAddEvent}
                  onCancelEvent={onCancelEvent}
                  onChangeEventType={onChangeEventType}
                  onChangeDescriptionEvent={onChangeDescriptionEvent}
                  onChangeEventNotifyDate={onChangeEventNotifyDate}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Card>
    );
  }
}

RequestForProposalForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  requestForProposal: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(RequestForProposalForm);
