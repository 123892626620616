import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  invoices: [],
  invoice: { company: { id: null, text: "" }, invoice_items: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    status: null,
    search: null,
    sort: null,
    direction: null,
    company_id: null,
    assignee: null,
    currentYear: "false",
    page: 1,
    limit: 20,
  },
  errors: false,
  isFillInWithOrder: false,
  isLoading: true,
};

function recalculationItem(item) {
  let price = Number(item.price);
  let quantity = Number(item.quantity);
  let vat_rate = item.vat_rate ? Number(item.vat_rate.extra.rate) : 0;
  let amount = Number(quantity * price).toFixed(2);
  let amount_vat = Number((amount / 100) * vat_rate).toFixed(2);

  return {
    price: price,
    quantity: quantity,
    amount: amount,
    amount_vat: amount_vat,
  };
}

export default function (state = initialState, action) {
  let idx = -1;
  let recalcItem = {};
  switch (action.type) {
    case "FETCH_INVOICES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_INVOICES":
      return {
        ...state,
        invoices: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_INVOICE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_INVOICE":
      return {
        ...state,
        invoice: action.response.data,
        vatRates: action.response.vat_rates,
        units: action.response.units,
        isLoading: false,
      };
    case "PATCH_INVOICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_INVOICE":
      return {
        ...state,
        errors: false,
        invoice: action.response.data,
        isLoading: false,
      };

    case "PATCH_INVOICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_INVOICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_INVOICE":
      return {
        ...state,
        errors: false,
        invoice: action.response.data,
        isLoading: false,
      };
    case "POST_INVOICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_INVOICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_INVOICE":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_INVOICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "INVOICE_FILL_IN_WITH_ORDER":
      return {
        ...state,
        isFillInWithOrder: true,
        invoice: action.data.invoice,
      };

    case "INVOICE_NEW":
      return {
        ...state,
        errors: false,
        invoice: state.isFillInWithOrder
          ? state.invoice
          : {
              number: null,
              date: moment(),
              duration: 10,
              agreement: null,
              agreement_date: null,
              additional: null,
              vat_included: false,
              total: 0,
              vat_total: 0,
              advance_percent: 0,
              paid: 0,
              ship_address: null,
              special_instruction:
                "Срок поставки продукции: месяц после поступления аванса. \nДоставка: \nУсловия оплаты: предоплата 50% в течение 10 дней с даты подписания спецификации, окончательный расчет 50% в течение 15 дней с даты поставки.",
              company: null,
              assignee: null,
              invoice_items: [],
            },
        isLoading: false,
        isFillInWithOrder: false,
      };

    case "POST_INVOICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_INVOICE":
      return {
        ...state,
        errors: false,
        invoice: action.response.data,
        isLoading: false,
      };
    case "POST_INVOICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_INVOICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_INVOICE":
      return {
        ...state,
        errors: false,
        invoices: action.response.data,
        isLoading: false,
      };
    case "DELETE_INVOICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "REQUEST_INVOICE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_INVOICE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });
    case "INVOICE_CHANGE_PARAMS_COMPANY":
      return update(state, {
        fetchParams: { company_id: { $set: action.value }, page: { $set: 1 } },
      });

    // filters
    case "REQUEST_INVOICE_FILTER_ASSIGNEE":
      return update(state, {
        fetchParams: { assignee: { $set: action.value } },
      });

    case "REQUEST_INVOICE_FILTER_PERIOD":
      return update(state, {
        fetchParams: { currentYear: { $set: action.value } },
      });

    case "INVOICE_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          assignee: { $set: null },
          status: { $set: null },
          company_id: { $set: null },
          product_id: { $set: null },
        },
      });

    case "INVOICES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "INVOICE_CHANGE_ASSIGNEE":
      return update(state, {
        invoice: { assignee: { $set: action.value } },
      });

    case "INVOICE_CHANGE_COMPANY":
      return update(state, {
        invoice: {
          company: { $set: action.value },
          ship_address: {
            $set:
              action.value.extra.addresses.length > 0
                ? action.value.extra.addresses[0].address
                : "",
          },
        },
      });

    case "INVOICE_CHANGE_AGREEMENT":
      return update(state, {
        invoice: { agreement: { $set: action.value } },
      });

    case "INVOICE_CHANGE_AGREEMENT_DATE":
      return update(state, {
        invoice: {
          agreement_date: { $set: moment(action.value, '"L"').add(6, "hours") },
        },
      });

    case "INVOICE_CHANGE_ADDITIONAL":
      return update(state, {
        invoice: { additional: { $set: action.value } },
      });

    case "INVOICE_CHANGE_SHIPPING_METHOD":
      return update(state, {
        invoice: { shipping_method: { $set: action.value } },
      });

    case "INVOICE_CHANGE_BANK_ACCOUNT":
      return update(state, {
        invoice: { bank_account: { $set: action.value } },
      });

    case "INVOICE_CHANGE_SHIPPING_ADDRESS":
      return update(state, {
        invoice: { shipping_address: { $set: action.value } },
      });

    case "INVOICE_CHANGE_SPECIAL_INSTRUCTION":
      return update(state, {
        invoice: { special_instruction: { $set: action.value } },
      });

    case "INVOICE_CHANGE_DATE":
      return update(state, {
        invoice: { date: { $set: action.value } },
      });

    case "INVOICE_CHANGE_DURATION":
      return update(state, {
        invoice: { duration: { $set: action.value } },
      });

    case "INVOICE_CHANGE_ORDER":
      return update(state, {
        invoice: { order: { $set: action.value } },
      });

    case "INVOICE_CHANGE_REQUEST_PROPOSAL":
      return update(state, {
        invoice: { request_proposal: { $set: action.value } },
      });

    case "INVOICE_CHANGE_NUMBER":
      return update(state, {
        invoice: { number: { $set: action.value } },
      });

    case "INVOICE_CHANGE_PAID":
      return update(state, {
        invoice: { paid: { $set: action.value } },
      });

    case "INVOICE_CHANGE_INVOICE_TYPE":
      return update(state, {
        invoice: { invoice_type: { $set: action.value } },
      });

    case "INVOICE_CHANGE_PAYMENT_AMOUNT":
      return update(state, {
        invoice: {
          payment_amount: { $set: action.value },
          payment_percent: { $set: 0 },
        },
      });

    case "INVOICE_CHANGE_PAYMENT_PERCENT":
      return update(state, {
        invoice: {
          payment_percent: { $set: action.value },
          payment_amount: { $set: 0 },
        },
      });

    // items
    case "INVOICE_ADD_ITEM":
      return update(state, {
        invoice: {
          invoice_items: {
            $push: [
              {
                id: Date.now(),
                position: state.invoice.invoice_items.length + 1,
                description: null,
                product: {
                  id: null,
                  text: "выберите продукт",
                },
                vat_rate: {
                  id: null,
                  text: "",
                },
                quantity: 1,
                price: 0,
                amount: 0,
                amount_vat: 0,
                is_exist: false,
                _destroy: false,
              },
            ],
          },
        },
      });

    case "INVOICE_DELETE_ITEM":
      return update(state, {
        invoice: {
          invoice_items: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "INVOICE_DESTROY_ITEM":
      return update(state, {
        invoice: {
          invoice_items: { $splice: [[action.idx, 1]] },
        },
      });

    case "INVOICE_CHANGE_ITEM_PRODUCT":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              product: { $set: action.item },
              description: {
                $set: state.invoice.invoice_items[idx].description
                  ? state.invoice.invoice_items[idx].description
                  : action.item
                  ? action.item.text
                  : null,
              },
              unit: { $set: action.item.extra.unit },
              vat_rate: { $set: action.item.extra.vat_rate },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_DESCRIPTION":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              description: { $set: action.value },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_UNIT":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              unit: { $set: action.item },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_QUANTITY":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: state.invoice.invoice_items[idx].price,
        quantity: action.value,
        vat_rate: state.invoice.invoice_items[idx].vat_rate,
        amount: state.invoice.invoice_items[idx].amount,
        amount_vat: state.invoice.invoice_items[idx].amount_vat,
      });

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              quantity: { $set: action.value },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_PRICE":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: action.value,
        quantity: state.invoice.invoice_items[idx].quantity,
        vat_rate: state.invoice.invoice_items[idx].vat_rate,
        amount: state.invoice.invoice_items[idx].amount,
        amount_vat: state.invoice.invoice_items[idx].amount_vat,
      });

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              price: { $set: action.value },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_VAT_RATE":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      recalcItem = recalculationItem({
        price: state.invoice.invoice_items[idx].price,
        quantity: state.invoice.invoice_items[idx].quantity,
        vat_rate: action.item,
        amount: state.invoice.invoice_items[idx].amount,
        amount_vat: state.invoice.invoice_items[idx].amount_vat,
      });

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              vat_rate: { $set: action.item },
              amount: { $set: recalcItem.amount },
              amount_vat: { $set: recalcItem.amount_vat },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_VAT":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              amount_vat: { $set: action.value },
            },
          },
        },
      });

    case "INVOICE_CHANGE_ITEM_AMOUNT":
      idx = _.findIndex(state.invoice.invoice_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        invoice: {
          invoice_items: {
            [idx]: {
              amount: { $set: action.value },
            },
          },
        },
      });

    default:
      return state;
  }
}
