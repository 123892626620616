// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Divider,
  Button,
  Menu,
  Dropdown,
  Icon
} from "antd";

import SelectFetch from "../../../components/SelectFetch";
import Addresses from "./Addresses";
import Emails from "./Emails";
import Phones from "./Phones";

const FormItem = Form.Item;

class Sidebar extends Component {
  onAddAddress = ({ key }) => {
    this.props.onAddAddress(key);
  };

  onAddPhone = ({ key }) => {
    this.props.onAddPhone(key);
  };

  onAddEmail = ({ key }) => {
    this.props.onAddEmail(key);
  };

  render() {
    const {
      getFieldDecorator,
      assignee,
      addresses,
      contactType,
      contactSource,
      businessField,
      onChangeAssignee,
      onChangeContactType,
      onChangeContactSource,
      onChangeBusinessField,
      // address
      onSaveAddress,
      onDeleteAddress,
      // emails
      emails,
      onSaveEmail,
      onDeleteEmail,
      // phones
      phones,
      onSavePhone,
      onDeletePhone
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Ответственный"}>
              {getFieldDecorator("assignee", {
                initialValue: assignee ? assignee : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите ответственного!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Ответственный"
                  onChange={onChangeAssignee}
                  selected={assignee ? assignee : null}
                  url={"/users/search?"}
                />
              )}
            </FormItem>
          </Col>

          <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Источник"}>
              {getFieldDecorator(
                "contact_source",
                {}
              )(
                <SelectFetch
                  showSearch
                  placeholder="Источник"
                  onChange={onChangeContactSource}
                  selected={contactSource ? contactSource : null}
                  url={"/contact_sources/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Тип контакта"}>
              {getFieldDecorator(
                "contact_type",
                {}
              )(
                <SelectFetch
                  showSearch
                  placeholder="Тип контакта"
                  onChange={onChangeContactType}
                  selected={contactType ? contactType : null}
                  url={"/contact_types/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Сфера деятельности"}>
              {getFieldDecorator(
                "business_field",
                {}
              )(
                <SelectFetch
                  showSearch
                  placeholder="Сфера деятельности"
                  onChange={onChangeBusinessField}
                  selected={businessField}
                  url={"/business_fields/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Addresses
              addresses={addresses}
              onSaveAddress={onSaveAddress}
              onDeleteAddress={onDeleteAddress}
            />
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Phones
              getFieldDecorator={getFieldDecorator}
              phones={phones}
              onSavePhone={onSavePhone}
              onDeletePhone={onDeletePhone}
            />
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Emails
              getFieldDecorator={getFieldDecorator}
              emails={emails}
              onSaveEmail={onSaveEmail}
              onDeleteEmail={onDeleteEmail}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  invoice: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Sidebar;
