// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableDateCell from "../../../components/EditableCells/EditableDateCell";
import EditableSelectCell from "../../../components/EditableCells/EditableSelectCell";
import moment from "moment";
import "moment/locale/ru";

const paymentTypes = [{ value: 1, text: "аванс" }, { value: 2, text: "кредит" }];

class PaymentSchedules extends Component {
  state = { isOpen: false, selectedRowKeys: [] };


  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteItems = () => {
    this.props.onDeleteItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  }

  render() {
    const {
      purchasePaymentSchedules,
      onAddItem,
      onChangeItemDate,
      onChangeItemPaymentType,
      onChangeItemAmount,
      onChangeItemPercent
    } = this.props;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата платежа",
        dataIndex: "date",
        render: (date, record) => {
          return (
            <EditableDateCell
              recordId={record.id}
              value={date}
              onChange={onChangeItemDate}
            />
          );
        },
        align: "center",
        width: "30%"
      },
      {
        title: "Тип",
        dataIndex: "payment_type",
        render: (payment_type, record) => {
          return (
            <EditableSelectCell
              recordId={record.id}
              items={paymentTypes}
              value={payment_type}
              onChange={onChangeItemPaymentType}
            />
          );
        },
        align: "center",
        width: "20%"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={amount}
              onChange={onChangeItemAmount}
            />
          );
        },
        align: "center",
        width: "30%"
      },
      {
        title: "% оплаты",
        dataIndex: "percent",
        render: (percent, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={percent}
              onChange={onChangeItemPercent}
            />
          );
        },
        align: "center",
        width: "20%"
      }
    ];

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            {hasSelected ? (
              <Button onClick={this.onDeleteItems}>Удалить</Button>
            ) : (
              <Button onClick={onAddItem}>Добавить</Button>
            )}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={purchasePaymentSchedules}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PaymentSchedules.propTypes = {
  orderPaymentSchedules: PropTypes.arrayOf(PropTypes.object)
};
export default PaymentSchedules;
