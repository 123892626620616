import api from "../api";

const Actions = {};

Actions.fetchShipments = orderID => {
  return dispatch => {
    dispatch({ type: "FETCH_SHIPMENTS_REQUEST" });
    api
      .fetch("/shipments", { order_id: orderID })
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_SHIPMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_SHIPMENTS_FAILURE", response });
      });
  };
};

Actions.fetchShipment = id => {
  return dispatch => {
    dispatch({ type: "FETCH_SHIPMENT_REQUEST" });
    return api
      .fetch(`/shipments/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_SHIPMENT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Отгрузка", error);
          });
        }
        dispatch({ type: "FETCH_SHIPMENT_FAILURE", response });
      });
  };
};

Actions.onNew = id => {
  return dispatch => {
    dispatch({ type: "FETCH_SHIPMENT_REQUEST" });
    return api
      .fetch(`/shipments/shipment`, { order_id: id })
      .then(response => {
        dispatch({ type: "FETCH_SHIPMENT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Отгрузка", error);
          });
        }
        dispatch({ type: "FETCH_SHIPMENT_FAILURE", response });
      });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_SHIPMENT_REQUEST" });
    const shipment = getState().shipments.shipment;
    const shipmentItems = shipment.shipment_items.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        order_item_id: item.order_item_id,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        vat_rate_id: item.vat_rate ? item.vat_rate.id : null,
        amount_vat: item.amount_vat,
        _destroy: item._destroy
      };
    });

    const data = {
      shipment: {
        active: shipment.active,
        firm_id: shipment.firm_id,
        date: shipment.date,
        number: shipment.number,
        order_id: shipment.order_id,
        work_order_id: shipment.work_order_id,
        shipment_items_attributes: shipmentItems
      }
    };

    return api
      .patch(`/shipments/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_SHIPMENT", response });
        api.openNotification("success", "Отгрузка", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Отгрузка", error);
          });
        }
        dispatch({ type: "PATCH_SHIPMENT_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_SHIPMENT_REQUEST" });
    const shipment = getState().shipments.shipment;
    const shipmentItems = shipment.shipment_items.map(item => {
      return {
        order_item_id: item.order_item_id,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        vat_rate_id: item.vat_rate ? item.vat_rate.id : null,
        amount_vat: item.amount_vat
      };
    });

    const data = {
      shipment: {
        active: shipment.active,
        firm_id: shipment.firm_id,
        date: shipment.date,
        number: shipment.number,
        order_id: shipment.order_id,
        work_order_id: shipment.work_order_id,
        shipment_items_attributes: shipmentItems
      }
    };

    return api
      .post(`/shipments`, data)
      .then(response => {
        dispatch({ type: "POST_SHIPMENT", response });
        api.openNotification("success", "Отгрузка", "Успешно создана!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Отгрузка", error);
          });
        }
        dispatch({ type: "POST_SHIPMENT_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_SHIPMENT_REQUEST" });
    return api
      .delete(`/shipments/${id}`)
      .then(response => {
        api.openNotification("success", "Отгрузка", "Успешно удалено!");
        dispatch({ type: "DELETE_SHIPMENT", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Отгрузка", error);
          });
        }
        dispatch({
          type: "DELETE_SHIPMENT_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_TOGGLE_VISIBLE"
    });
  };
};

Actions.onDeleteItem = idx => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_ITEM_DELETE",
      idx: idx
    });
  };
};

Actions.onDestroyItem = idx => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_DESTROY_ITEM",
      idx: idx
    });
  };
};

Actions.onChangeNumber = value => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_CHANGE_NUMBER",
      value: value
    });
  };
};

Actions.onChangeDate = value => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_CHANGE_DATE",
      value: value
    });
  };
};

Actions.onChangeItemQuantity = (id, value) => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_CHANGE_ITEM_QUANTITY",
      id: id,
      value: value
    });
  };
};

Actions.onChangeItemAmount = (id, value) => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_CHANGE_ITEM_AMOUNT",
      id: id,
      value: value
    });
  };
};

Actions.onChangeItemAmountVat = (id, value) => {
  return dispatch => {
    dispatch({
      type: "SHIPMENT_CHANGE_ITEM_AMOUNT_VAT",
      id: id,
      value: value
    });
  };
};

export default Actions;
