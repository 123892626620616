import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  countries: [],
  country: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_COUNTRIES_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_COUNTRIES":
      return {
        ...state,
        countries: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_COUNTRY_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_COUNTRY":
      return {
        ...state,
        country: action.response.data,
        isLoading: false
      };

    case "PATCH_COUNTRY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "PATCH_COUNTRY":
      return {
        ...state,
        errors: false,
        country: action.response.data,
        isLoading: false
      };

    case "PATCH_COUNTRY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "POST_COUNTRY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_COUNTRY":
      return {
        ...state,
        errors: false,
        country: action.response.data,
        isLoading: false
      };

    case "POST_COUNTRY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "COUNTRY_NEW":
      return {
        ...state,
        errors: false,
        country: { code: null, name: null },
        isLoading: false
      };

    case "DELETE_COUNTRY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "DELETE_COUNTRY":
      return {
        ...state,
        errors: false,
        isLoading: false
      };
    case "DELETE_COUNTRY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "REQUEST_COUNTRY_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_COUNTRY_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "REQUEST_COUNTRY_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value } }
      });

    case "COUNTRY_CHANGE_CODE":
      return update(state, {
        country: { code: { $set: action.value } }
      });

    case "COUNTRY_CHANGE_NAME":
      return update(state, {
        country: { name: { $set: action.value } }
      });

    default:
      return state;
  }
}
