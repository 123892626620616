// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../../actions/users";
import UsersList from "./List";

class Users extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchUsers());
  };

  onDelete = id => {
    this.props
      .dispatch(Actions.onDelete(id))
      .then(() => this.props.dispatch(Actions.fetchUsers()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchUsers()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchUsers()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchUsers()));
  };

  // filters
  onChangeFilterActive = value => {
    this.props
      .dispatch(Actions.onChangeFilterActive(value))
      .then(() => this.props.dispatch(Actions.fetchUsers()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchUsers()));
  };

  render() {
    const { isLoading, users, meta, fetchParams } = this.props;
    return (
      <UsersList
        loading={isLoading}
        users={users}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSearch={this.onSearch}
        onChangeFilterActive={this.onChangeFilterActive}
        onSortBy={this.onSortBy}
      />
    );
  }
}

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.users.isLoading,
  users: state.users.users,
  meta: state.users.meta,
  fetchParams: state.users.fetchParams
});

export default connect(mapStateToProps)(Users);
