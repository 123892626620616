// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Select, Button, Icon } from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

import Info from "../../Orders/Info";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
    };
  }

  onExpand = (expanded, record) => {
    // console.log(expanded, record);
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    this.setState({ expandedRowKeys: keys });
  };

  renderExpandedRow = (record) => {
    return <Info orderId={record.order_id} />;
  };

  render() {
    const { expandedRowKeys } = this.state;
    const {
      loading,
      report,
      meta,
      totals,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      onChangePage,
      downloadReport,
    } = this.props;

    let reportData = report;
    reportData.unshift({
      order_id: Date.now(),
      order_number: null,
      assignee: null,
      company_id: null,
      company_name: null,
      total: totals.total,
      vat_total: totals.vat_total,
      subcontracts: totals.subcontracts,
      materials: totals.materials,
      service: totals.service,
      isTotal: true,
    });

    const dateFormat = "DD.MM.YYYY";

    const columns = [
      {
        title: "Заказ",
        dataIndex: "order_number",
        render: (order_number, record) => {
          if (record.isTotal) {
            return {
              children: <b>Итого</b>,
              props: {
                colSpan: 3,
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: (
              <Link to={`/orders/${record.order_id}/edit`}>{order_number}</Link>
            ),
            props: {
              colSpan: 1,
            },
          };
        },

        width: "10%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignee",
        render: (assignee, record) => {
          if (record.isTotal) {
            return {
              props: {
                colSpan: 0,
              },
            };
          }
          return {
            children: assignee,
            props: {
              colSpan: 1,
            },
          };
        },

        key: "assignee",
        width: "13%",
      },
      {
        title: "Компания",
        dataIndex: "company_name",
        render: (company_name, record) => {
          if (record.isTotal) {
            return {
              props: {
                colSpan: 0,
              },
            };
          }
          return {
            children: (
              <Link to={`/companies/${record.company_id}/edit`}>
                {company_name}
              </Link>
            ),
            props: {
              colSpan: 1,
            },
          };
        },

        key: "company_name",
        width: "22%",
      },
      {
        title: "Продажи (без НДС)",
        dataIndex: "total",
        render: (total, record) => {
          if (record.isTotal) {
            return {
              children: (
                <b>
                  {accounting.formatNumber(
                    Number(total) - Number(record.vat_total),
                    0,
                    " "
                  )}
                </b>
              ),
            };
          }
          return {
            children: accounting.formatNumber(
              Number(total) - Number(record.vat_total),
              0,
              " "
            ),
          };
        },
        align: "right",
        width: "15%",
      },
      {
        title: "Субподряд",
        dataIndex: "subcontracts",
        render: (subcontracts, record) => {
          if (record.isTotal) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(subcontracts), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: accounting.formatNumber(Number(subcontracts), 0, " "),
          };
        },
        align: "right",
        width: "10%",
      },
      {
        title: "Материалы",
        dataIndex: "materials",
        render: (materials, record) => {
          if (record.isTotal) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(materials), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: accounting.formatNumber(Number(materials), 0, " "),
          };
        },
        align: "right",
        width: "10%",
      },
      {
        title: "Услуги",
        dataIndex: "service",
        render: (service, record) => {
          if (record.isTotal) {
            return {
              children: (
                <b>{accounting.formatNumber(Number(service), 0, " ")}</b>
              ),
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: accounting.formatNumber(Number(service), 0, " "),
          };
        },
        align: "right",
        width: "10%",
      },
      {
        title: "Собственные",
        dataIndex: "own",
        render: (cost, record) => {
          let total = Number(record.total) - Number(record.vat_total);
          let own =
            Number(record.total) -
            Number(record.vat_total) -
            Number(record.subcontracts) -
            Number(record.materials) -
            Number(record.service);
          let percentOwn = (own / (total > 0 ? total : own)) * 100;
          if (record.isTotal) {
            return {
              children: (
                <div
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                >
                  <span>{accounting.formatNumber(percentOwn, 0, " ")}%</span>
                  <span>{accounting.formatNumber(own, 0, " ")}</span>
                </div>
              ),
              props: {
                className: "ant-table-total-column",
              },
            };
          }
          return {
            children: (
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                <span>{accounting.formatNumber(percentOwn, 0, " ")}%</span>
                <span>{accounting.formatNumber(own, 0, " ")}</span>
              </div>
            ),
          };
        },

        align: "right",
        width: "10%",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Отгрузки по заказам за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        extra={[
          <Button
            key="3"
            type="dashed"
            icon="file-excel"
            onClick={downloadReport}
          >
            Загрузить
          </Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="order_id"
                // onExpand={this.onExpand}
                // expandedRowKeys={expandedRowKeys}
                expandedRowRender={(record) => this.renderExpandedRow(record)}
                rowClassName={(record) =>
                  record.isTotal ? "ant-table-total-row" : null
                }
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              {/* <Pagination
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                total={meta.total_count}
              /> */}
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
