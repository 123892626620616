// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, DatePicker } from "antd";
import SelectFetch from "../../../components/SelectFetch";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      invoice,
      getFieldDecorator,
      onChangeAssignee,
      onChangeCompany,
      onChangeAgreement,
      onChangeAgreementDate,
      onChangeAdditional,
      onChangeShippingMethod,
      onChangeBankAccount,
      onChangeShippingAddress,
      onChangeSpecialInstruction
    } = this.props;

    const shippingMethodType = invoice.shipping_method
      ? invoice.shipping_method.extra.subtext
      : null;

    return (
      <div>
        <Row gutter={16}>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Компания"}>
              {getFieldDecorator("company", {
                initialValue: invoice.company ? invoice.company : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите заказчика!"
                  }
                ]
              })(
                <SelectFetch
                  showSubtitle
                  showSearch
                  placeholder="Компания"
                  onChange={onChangeCompany}
                  selected={invoice.company ? invoice.company : null}
                  url={"/companies/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Ответственный"}>
              {getFieldDecorator("assignee", {
                initialValue: invoice.assignee ? invoice.assignee : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите ответственного!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Ответственный"
                  onChange={onChangeAssignee}
                  selected={invoice.assignee ? invoice.assignee : null}
                  url={"/users/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={{ span: 10 }} xs={{ span: 10 }}>
            <FormItem label={"Договор"}>
              {getFieldDecorator("agreement", {
                onChange: onChangeAgreement,
                initialValue: invoice.agreement
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 7 }} xs={{ span: 7 }}>
            <FormItem label={"Дата договора"}>
              {getFieldDecorator("agreement_date", {
                onChange: onChangeAgreementDate,
                initialValue: invoice.agreement_date
                  ? moment(invoice.agreement_date)
                  : null
              })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
            </FormItem>
          </Col>
          <Col md={{ span: 7 }} xs={{ span: 7 }}>
            <FormItem label={"Приложение"}>
              {getFieldDecorator("additional", {
                onChange: onChangeAdditional,
                initialValue: invoice.additional
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={{ span: 10 }} xs={{ span: 10 }}>
            <FormItem label={"Способ доставки"}>
              {getFieldDecorator("shipping_method", {
                initialValue: invoice.shipping_method
                  ? invoice.shipping_method
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Введите способ доставки!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Способ доставки"
                  onChange={onChangeShippingMethod}
                  selected={
                    invoice.shipping_method ? invoice.shipping_method : null
                  }
                  url={`/shipping_methods/search/?`}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 14 }} xs={{ span: 14 }}>
            <FormItem label={"Банковский счет"}>
              {getFieldDecorator("bank_account", {
                initialValue: invoice.bank_account
                  ? invoice.bank_account
                  : null,
                // rules: [
                //   {
                //     required: true,
                //     message: "Введите способ доставки!"
                //   }
                // ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Банковский счет"
                  onChange={onChangeBankAccount}
                  selected={
                    invoice.bank_account ? invoice.bank_account : null
                  }
                  url={`/bank_accounts/search/?`}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {shippingMethodType && shippingMethodType == "delivery" ? (
          <Row gutter={16}>
            <Col md={{ span: 24 }} xs={{ span: 24 }}>
              <FormItem label={"Адрес доставки"}>
                {getFieldDecorator("shipping_address", {
                  initialValue: invoice.shipping_address
                    ? invoice.shipping_address
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите адрес доставки!"
                    }
                  ]
                })(
                  <SelectFetch
                    disabled={invoice.company ? false : true}
                    showSubtitle
                    showSearch
                    placeholder="Адрес"
                    onChange={onChangeShippingAddress}
                    selected={
                      invoice.shipping_address ? invoice.shipping_address : null
                    }
                    url={
                      invoice.company
                        ? `/companies/${invoice.company.id}/addresses`
                        : null
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        ) : null}
        <Row gutter={16}>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator("special_instruction", {
                onChange: onChangeSpecialInstruction,
                initialValue: invoice.special_instruction
              })(<TextArea autoSize={{ minRows: 4, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  invoice: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default General;
