import api from "../api";

const Actions = {};

Actions.fetchLossReasons = () => {
  return (dispatch, getState) => {
    const params = getState().lossReasons.fetchParams;
    dispatch({ type: "FETCH_LOSS_REASONS_REQUEST" });
    api
      .fetch("/loss_reasons", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_LOSS_REASONS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_LOSS_REASONS_FAILURE", response });
      });
  };
};

Actions.fetchLossReason = id => {
  return dispatch => {
    dispatch({ type: "FETCH_LOSS_REASON_REQUEST" });
    return api
      .fetch(`/loss_reasons/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_LOSS_REASON", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Причины отказа", error);
          });
        }
        dispatch({ type: "FETCH_LOSS_REASON_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "LOSS_REASON_ADD" });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_LOSS_REASON_REQUEST" });
    const lossReason = getState().lossReasons.lossReason;

    const data = {
      loss_reason: {
        name: lossReason.name
      }
    };

    return api
      .patch(`/loss_reasons/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_LOSS_REASON", response });
        api.openNotification("success", "Причина отказа", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Причина отказа", error);
          });
        }
        dispatch({ type: "PATCH_LOSS_REASON_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_LOSS_REASON_REQUEST" });
    const lossReason = getState().lossReasons.lossReason;

    const data = {
      loss_reason: {
        name: lossReason.name
      }
    };

    return api
      .post(`/loss_reasons`, data)
      .then(response => {
        dispatch({ type: "POST_LOSS_REASON", response });
        api.openNotification("success", "Причина отказа", "Успешно создана!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Причина отказа", error);
          });
        }
        dispatch({ type: "POST_LOSS_REASON_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_LOSS_REASON_REQUEST" });
    return api
      .delete(`/loss_reasons/${id}`)
      .then(response => {
        api.openNotification("success", "Причина отказа", "Успешно удалена!");
        dispatch({ type: "DELETE_LOSS_REASON", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Причина отказа", error);
          });
        }
        dispatch({
          type: "DELETE_LOSS_REASON_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "LOSS_REASON_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_LOSS_REASON_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_LOSS_REASON_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "LOSS_REASON_CHANGE_NAME",
      value: value
    });
  };
};



export default Actions;
