import React from "react";
import { Table } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

const Shipment = ({ shipment, ...rest }) => {
  const columns = [
    {
      title: "Код",
      dataIndex: "order_item_id",
      width: "10%"
    },
    {
      title: "Продукция",
      dataIndex: "product_name",
      width: "50%"
    },
    {
      title: "Кол-во",
      dataIndex: "quantity",
      align: "right",
      width: "20%"
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      render: (amount, record) => (accounting.formatNumber(amount, 0, " ")),
      align: "right",
      width: "20%"
    }
  ];
  const data = shipment ? shipment.shipment_items : []

  return  (
    <Table
      style={{background: "white"}}
      columns={columns}
      dataSource={data}
      size="small"
      title={() => `Отгрузка #${shipment.number} от ${moment(shipment.date).format('LLL')}`}
      pagination={false}
      rowKey="id"
    />
  );
};

export default Shipment;
