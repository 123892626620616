// @flow
import React, { Component } from "react";
import { Table, DatePicker, Select, Button } from "antd";
import { Row, Col, PageHeader } from "antd";
import {
  Chart,
  Tooltip,
  Interval,
  Legend,
  Annotation,
  Coordinate,
} from "bizcharts";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      report,
      details,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      downloadReport,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    const total = report.total + report.sent + report.succeed

    const data = [
      { action: "Заявки", pv: report.total, percent: Number(report.total / total ) },
      { action: "КП", pv: report.sent, percent: Number(report.sent / total ) },
      { action: "Счета", pv: report.invoices, percent: Number(report.invoices / total ) },
      { action: "Заказы", pv: report.succeed, percent: Number(report.succeed / total ) },
    ];

    const columns = [
      {
        title: "Ответсвенный",
        dataIndex: "name",
        align: "left",
        width: "25%",
      },
      {
        title: "Заявки (кол-во)",
        dataIndex: "total",
        align: "right",
        width: "15%",
      },
      {
        title: "КП (кол-во)",
        dataIndex: "sent",
        align: "right",
        width: "15%",
      },
      {
        title: "Счета (кол-во)",
        dataIndex: "invoices",
        align: "right",
        width: "15%",
      },
      {
        title: "Заказы (кол-во)",
        dataIndex: "orders",
        align: "right",
        width: "15%",
      },
      {
        title: "Конверсия, %",
        dataIndex: "percent",
        render: (percent, record) => (accounting.formatNumber((record.orders / record.total) * 100, 2, " ")),
        align: "right",
        width: "15%",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Воронка продаж c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        extra={[
          <Button
            key="3"
            type="dashed"
            icon="file-excel"
            onClick={downloadReport}
          >
            Загрузить
          </Button>
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Chart height={600} data={data} padding={[20, 120, 95]} forceFit>
                <Tooltip
                  showTitle={false}
                  itemTpl='<li data-index={index} style="margin-bottom:4px;"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}<br/><span style="padding-left: 16px">Количество：{pv}</span><br/><span style="padding-left: 16px">Процент：{percent}</span><br/></li>'
                />
                <Coordinate scale={[1, -1]} transpose type="rect" />
                <Legend />
                {data.map((obj) => {
                  return (
                    <Annotation.Text
                      top={true}
                      position={{
                        action: obj.action,
                        percent: "median",
                      }}
                      content={parseInt(obj.percent * 100) + "%"}
                      style={{
                        fill: "#fff",
                        fontSize: "12",
                        textAlign: "center",
                        shadowBlur: 2,
                        shadowColor: "rgba(0, 0, 0, .45)",
                      }}
                    />
                  );
                })}
                <Interval
                  position="action*percent"
                  adjust="symmetric"
                  shape="funnel"
                  color={[
                    "action",
                    ["#0050B3", "#1890FF", "#40A9FF", "#69C0FF", "#BAE7FF"],
                  ]}
                  tooltip={[
                    "action*pv*percent",
                    (action, pv, percent) => {
                      return {
                        name: action,
                        percent: parseInt(percent * 100) + "%",
                        pv: pv,
                      };
                    },
                  ]}
                  label={[
                    "action*pv",
                    (action, pv) => {
                      return { content: action + " " + pv };
                    },
                    {
                      offset: 35,
                      labeLine: {
                        lineWidth: 1,
                        stroke: "rgba(0, 0, 0, 0.15)",
                      },
                    },
                  ]}
                ></Interval>
              </Chart>
            </Col>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={details}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
