// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Checkbox, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  render() {
    const {
      visible,
      startDate,
      endDate,
      isDownloadRows,
      onClose,
      onChangePeriod,
      onChangeDownloadRows,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox
              value="active"
              checked={isDownloadRows}
              onChange={onChangeDownloadRows}
            >
              Выгружать строки (только для выгрузки)
            </Checkbox>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
