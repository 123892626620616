// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Card, Tabs, Form, Button } from "antd";
import Documents from "../../../components/Documents";
import Uploads from "../../../components/Uploads";
import General from "./General";
import Statuses from "./Statuses";
import PaymentSchedules from "./PaymentSchedules";
import Payments from "./Payments";
import WorkOrderItems from "./WorkOrderItems";

const TabPane = Tabs.TabPane;

class PurchaseForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { visible: false, tabIndex: "1" };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      isLoading,
      isNew,
      onClose,
      purchase,
      onChangeCompany,
      onChangeAgreementDate,
      onChangeAgreement,
      onChangeCode,
      onChangeTotal,
      onChangeVatTotal,
      onAddItem,
      onDeleteItems,
      onChangeItemDate,
      onChangeItemPaymentType,
      onChangeItemAmount,
      onChangeItemPercent,
      // tempAttachments
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const purchasePaymentSchedules = purchase.purchase_payment_schedules.filter(
      function (item) {
        return !item._destroy;
      }
    );

    return (
      <Card
        loading={isLoading}
        title={
          <div>
            <h2 style={{ marginBottom: 0 }}>
              {`Договор с поставщиком #${isNew ? "новый" : purchase.code}`}
            </h2>
            <span style={{ fontSize: "12px" }}>
              {purchase.company ? (
                <Link
                  to={`/companies/${purchase.company.id}/edit`}
                  target="_blank"
                >
                  {purchase.company.text}
                </Link>
              ) : (
                ""
              )}
            </span>
          </div>
        }
        extra={
          <div>
            <Button onClick={() => onClose()}>Закрыть</Button>
            <Button
              disabled={isLoading ? true : false}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"} onSubmit={this.onSubmit}>
          <Col span={13}>
            <General
              isNew={isNew}
              getFieldDecorator={getFieldDecorator}
              purchase={purchase}
              onChangeCompany={onChangeCompany}
              onChangeAgreementDate={onChangeAgreementDate}
              onChangeAgreement={onChangeAgreement}
              onChangeCode={onChangeCode}
              onChangeTotal={onChangeTotal}
              onChangeVatTotal={onChangeVatTotal}
            />
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Statuses purchase={purchase} />
          </Col>
          <Col span={13}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="График платежей" key="1">
                <PaymentSchedules
                  purchasePaymentSchedules={purchasePaymentSchedules}
                  onAddItem={onAddItem}
                  onDeleteItems={onDeleteItems}
                  onChangeItemDate={onChangeItemDate}
                  onChangeItemPaymentType={onChangeItemPaymentType}
                  onChangeItemAmount={onChangeItemAmount}
                  onChangeItemPercent={onChangeItemPercent}
                />
              </TabPane>
              <TabPane tab="Платежи" key="2">
                <Payments purchasePayments={purchase.purchase_payments} />
              </TabPane>
              <TabPane tab="Работы и материалы" key="3">
                <WorkOrderItems workOrderItems={purchase.work_order_items} />
              </TabPane>

              <TabPane tab="Файлы" key="4">
                {isNew ? (
                  <Uploads
                    onUpload={onTempUpload}
                    documents={tempAttachments}
                    onDelete={onDeleteTempAttachment}
                  />
                ) : (
                  <Documents ownerID={purchase.id} ownerType={"Purchase"} />
                )}
              </TabPane>
            </Tabs>
          </Col>
        </Form>
      </Card>
    );
  }
}

PurchaseForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  purchase: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(PurchaseForm);
