// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/departments";
import { Spin } from "antd";
import Form from "./Form";

class Department extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchDepartment(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/references/departments/${this.props.department.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/references/departments`);
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeFullName = e => {
    this.props.dispatch(Actions.onChangeFullName(e.target.value));
  };



  render() {
    const { isLoading, department } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
        <Form
          isNew={this.state.isNew}
          department={department}
          onSave={this.onSave}
          onClose={this.onClose}
          onChangeName={this.onChangeName}
          onChangeFullName={this.onChangeFullName}
        />
      );
  }
}

Department.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  department: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.departments.isLoading,
  errors: state.departments.errors,
  department: state.departments.department
});

export default connect(mapStateToProps)(withRouter(Department));
