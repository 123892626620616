// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

import MaterialOrderModal from "../../MaterialOrders/ModalForm";

class MaterialOrders extends Component {
  state = { selectedRowKeys: [] };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteMaterialOrders = () => {
    this.props.onDeleteMaterialOrders(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      isVisibleMaterialOrder,
      onCloseMaterialOrderModal,
      isMaterialOrdersLoading,
      onAddMaterialOrder,
      onEditMaterialOrder,
      onSaveMaterialOrder,
      materialOrders
    } = this.props;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        key: "date",
        render: (date, record) => (
          <div>
            <div>{moment(date).format("L")}</div>
          </div>
        ),
        width: "15%",
        align: "center"
      },
      {
        title: "Номер",
        dataIndex: "number",
        key: "number",
        render: (number, record) => (
          <div>
            <div><a onClick={onEditMaterialOrder.bind(this, record.id)}>{number}</a></div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Всего",
        dataIndex: "total",
        key: "total",
        render: (total, record) =>
          accounting.formatNumber(Number(total), 2, " ", ","),
        width: "20%",
        align: "center"
      },
      {
        title: "Субподряд",
        dataIndex: "total_subcontract",
        key: "total_subcontract",
        render: (total_subcontract, record) =>
          accounting.formatNumber(Number(record.total_subcontract), 2, " ", ","),
        width: "15%",
        align: "center"
      },
      {
        title: "Материалы",
        dataIndex: "total_material",
        key: "total_material",
        render: (total_material, record) =>
          accounting.formatNumber(Number(record.total_material), 2, " ", ","),
        width: "15%",
        align: "center"
      },
      {
        title: "Транспорт",
        dataIndex: "total_transport",
        key: "total_transport",
        render: (total_transport, record) =>
          accounting.formatNumber(Number(record.total_transport), 2, " ", ","),
        width: "15%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            {hasSelected ? (
              <Button onClick={this.onDeleteMaterialOrders}>Удалить</Button>
            ) : (
              <Button onClick={onAddMaterialOrder}>Добавить</Button>
            )}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isMaterialOrdersLoading}
              rowSelection={rowSelection}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={materialOrders}
              rowKey="id"
            />
          </Col>
        </Row>
        <MaterialOrderModal
          visible={isVisibleMaterialOrder}
          onSave={onSaveMaterialOrder}
          onCancel={onCloseMaterialOrderModal}
        />
      </div>
    );
  }
}

MaterialOrders.propTypes = {
  orderSubcontracts: PropTypes.arrayOf(PropTypes.object)
};
export default MaterialOrders;
