// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/requestForProposals";
import { Spin } from "antd";
import Form from "./Form";

class RequestForProposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchRequestForProposal(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.setState({ isNew: false }, () => {
            this.props.history.push(
              `/request_for_proposals/${this.props.requestForProposal.id}/edit`
            );
          });
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/request_for_proposals`);
  };

  onCreateCompany = () => {
    this.props.history.push(`/companies/new`);
  };

  onChangeStatus = value => {
    this.props
      .dispatch(Actions.onChangeStatus(this.props.match.params.id, value.id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchRequestForProposalEvents(this.props.match.params.id)
          );
        }
      });
  };

  onChangeStatusToFail = value => {
    this.props.dispatch(Actions.onChangeStatusToFail(value));
  };

  onChangeLossReason = value => {
    this.props
      .dispatch(
        Actions.onChangeLossReason(this.props.match.params.id, value.id)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchRequestForProposalEvents(this.props.match.params.id)
          );
        }
      });
  };

  onChangePaused = (e) => {
    this.props
      .dispatch(
        Actions.onChangePaused(this.props.match.params.id, e.target.checked)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchRequestForProposalEvents(this.props.match.params.id)
          );
        }
      });
  };

  onChangeDate = value => {
    this.props.dispatch(Actions.onChangeDate(value));
  };

  onChangeNotifyDate = value => {
    this.props.dispatch(Actions.onChangeNotifyDate(value));
  };

  onChangeCompany = value => {
    this.props.dispatch(Actions.onChangeCompany(value));
  };

  onChangeContactType = value => {
    this.props.dispatch(Actions.onChangeContactType(value));
  };

  onChangeContactSource = value => {
    this.props.dispatch(Actions.onChangeContactSource(value));
  };

  onChangeSubject = e => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = e => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeAssignee = value => {
    this.props.dispatch(Actions.onChangeAssignee(value));
  };

  // event
  onSaveEvent = () => {
    if (this.props.event.id) {
      this.props.dispatch(Actions.onUpdateEvent(this.props.match.params.id));
    } else {
      this.props
        .dispatch(Actions.onCreateEvent(this.props.match.params.id))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchRequestForProposalEvents(this.props.match.params.id)
            );
          }
        });
    }
  };

  onAddEvent = () => {
    this.props.dispatch(Actions.onAddEvent());
  };

  onCancelEvent = () => {
    this.props.dispatch(Actions.onCancelEvent());
  };

  onChangeEventType = value => {
    this.props.dispatch(Actions.onChangeEventType(value));
  };

  onChangeDescriptionEvent = e => {
    this.props.dispatch(Actions.onChangeDescriptionEvent(e.target.value));
  };

  onChangeEventNotifyDate= value => {
    this.props.dispatch(Actions.onChangeEventNotifyDate(value));
  };

  // products
  onSaveProduct = product => {
    this.props
      .dispatch(Actions.onCreateProduct(this.props.match.params.id, product.id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchRequestForProposalItems(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteProduct = product_id => {
    this.props
      .dispatch(Actions.onDeleteProduct(this.props.match.params.id, product_id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchRequestForProposalItems(this.props.match.params.id)
          );
        }
      });
  };

  onAddProduct = () => {
    this.props.dispatch(Actions.onAddProduct());
  };

  onCancelProduct = () => {
    this.props.dispatch(Actions.onCancelProduct());
  };

   // attachments
   onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  render() {
    const {
      isLoading,
      requestForProposal,
      isEventsLoading,
      isEventVisible,
      event,
      isProductsLoading,
      isProductVisible,
      currentUser,
      isAttachmentsLoading
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={this.state.isNew}
        currentUser={currentUser}
        requestForProposal={requestForProposal}
        onClose={this.onClose}
        onSave={this.onSave}
        onCreateCompany={this.onCreateCompany}
        onChangeStatus={this.onChangeStatus}
        onChangeStatusToFail={this.onChangeStatusToFail}
        onChangeLossReason={this.onChangeLossReason}
        onChangePaused={this.onChangePaused}
        onChangeDate={this.onChangeDate}
        onChangeNotifyDate={this.onChangeNotifyDate}
        onChangeCompany={this.onChangeCompany}
        onChangeContactType={this.onChangeContactType}
        onChangeContactSource={this.onChangeContactSource}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeAssignee={this.onChangeAssignee}
        // event
        isEventsLoading={isEventsLoading}
        isEventVisible={isEventVisible}
        event={event}
        onSaveEvent={this.onSaveEvent}
        onAddEvent={this.onAddEvent}
        onCancelEvent={this.onCancelEvent}
        onChangeEventType={this.onChangeEventType}
        onChangeDescriptionEvent={this.onChangeDescriptionEvent}
        onChangeEventNotifyDate={this.onChangeEventNotifyDate}
        // products
        isProductsLoading={isProductsLoading}
        isProductVisible={isProductVisible}
        onSaveProduct={this.onSaveProduct}
        onDeleteProduct={this.onDeleteProduct}
        onAddProduct={this.onAddProduct}
        onCancelProduct={this.onCancelProduct}
        // attachments
        isAttachmentsLoading={isAttachmentsLoading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
      />
    );
  }
}

RequestForProposal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  requestForProposal: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.requestForProposals.isLoading,
  errors: state.requestForProposals.errors,
  requestForProposal: state.requestForProposals.requestForProposal,
  isEventsLoading: state.requestForProposals.isEventsLoading,
  isEventVisible: state.requestForProposals.isEventVisible,
  event: state.requestForProposals.event,
  // products
  isProductsLoading: state.requestForProposals.isProductsLoading,
  isProductVisible: state.requestForProposals.isProductVisible,
  // attachments
  isAttachmentsLoading: state.requestForProposals.isAttachmentsLoading,
});

export default connect(mapStateToProps)(withRouter(RequestForProposal));
