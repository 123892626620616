// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  List,
  Button,
  Upload,
  Popover,
  Tooltip,
  Avatar,
  Icon,
} from "antd";

import moment from "moment";
import "moment/locale/ru";

const { Dragger } = Upload;

function selectIcon(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "file-pdf";
    case "image/png":
      return "file-image";
    case "image/jpeg":
      return "file-image";
    case "application/msword":
      return "file-word";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "file-word";
    case "application/vnd.ms-excel":
      return "file-excel";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "file-excel";
    case "message/rfc822":
      return "mail";
    default:
      return "file-unknown";
  }
}

function selectColor(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "red";
    case "image/png":
      return "brown";
    case "image/jpeg":
      return "brown";
    case "application/msword":
      return "cornflowerblue";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "cornflowerblue";
    case "application/vnd.ms-excel":
      return "green";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "green";
    default:
      return "brown";
  }
}

class DocumentsList extends Component {
  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUpload(file);
  };

  render() {
    const { isLoading, isUploading, documents, onUpload, onDelete } =
      this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <List
            loading={isLoading || isUploading}
            dataSource={documents}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Tooltip placement="bottom" title={"Удалить"}>
                    <Popover
                      content={
                        <Button
                          type="danger"
                          style={{ width: "100%" }}
                          onClick={this.props.onDelete.bind(this, item.id)}
                        >
                          Удалить
                        </Button>
                      }
                      title="Удаление файла"
                      trigger="click"
                    >
                      <Button type="dashed" shape="circle" icon="close" />
                    </Popover>
                  </Tooltip>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "transparent",
                        color: selectColor(item.content_type),
                      }}
                      icon={selectIcon(item.content_type)}
                      size="large"
                    />
                  }
                  title={
                    <a href={item.asset} download={item.name}>
                      {item.name}
                    </a>
                  }
                  description={item.file_size}
                />
              </List.Item>
            )}
          >
            <Dragger customRequest={this.onUpload} showUploadList={false}>
              <p className="ant-upload-hint">
                Перетащите файл в эту область или кликните для загрузки.
              </p>
            </Dragger>
          </List>
        </Col>
      </Row>
    );
  }
}

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
};
export default DocumentsList;
