// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Form, Input, DatePicker } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";
const { TextArea } = Input;

const FormItem = Form.Item;

class General extends Component {
  render() {
    const {
      isNew,
      currentUser,
      getFieldDecorator,
      requestForProposal,
      onChangeCompany,
      onChangeContactType,
      onChangeContactSource,
      onChangeSubject,
      onChangeDescription,
      onChangeDate,
      onChangeAssignee
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Дата заявки"}>
              {getFieldDecorator("date", {
                onChange: onChangeDate,
                initialValue: moment(requestForProposal.date),
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите дату договора!"
                  }
                ]
              })(<DatePicker format={"LLL"} className="ant-btn-width-100" />)}
            </FormItem>
          </Col>
          {isNew ? null : (
            <Col md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"Ответственный"}>
                {getFieldDecorator("assignee", {
                  initialValue: requestForProposal.assignee
                    ? requestForProposal.assignee
                    : null,
                    // rules: [
                    //   {
                    //     required: requestForProposal.status ? (requestForProposal.status.id == "pending" ? false : true) : false,
                    //     message: "Введите ответственного!"
                    //   }
                    // ]
                })(
                  <SelectFetch
                    showSearch
                    placeholder="Ответственный"
                    onChange={onChangeAssignee}
                    selected={
                      requestForProposal.assignee
                        ? requestForProposal.assignee
                        : null
                    }
                    url={"/users/search?"}
                  />
                )}
              </FormItem>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Компания"}>
              {getFieldDecorator("company", {
                initialValue: requestForProposal.company
                  ? requestForProposal.company
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, выберите компанию!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  showSubtitle
                  showFooter={false}
                  placeholder="Компания"
                  onChange={onChangeCompany}
                  selected={
                    requestForProposal.company
                      ? requestForProposal.company
                      : null
                  }
                  url={"/companies/search?"}
                />
              )}
            </FormItem>
          </Col>
          
          <Col span={12}>
            <FormItem label={"Тип контакта"}>
              {getFieldDecorator("contact_type", {
                initialValue: requestForProposal.contact_type
                  ? requestForProposal.contact_type
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Выберите тип контакта!"
                  }
                ]
              })(
                <SelectFetch
                  placeholder="Тип контакта"
                  onChange={onChangeContactType}
                  selected={
                    requestForProposal.contact_type
                      ? requestForProposal.contact_type
                      : null
                  }
                  url={"/contact_types/search?"}
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label={"Источник"}>
              {getFieldDecorator("contact_source", {
                initialValue: requestForProposal.contact_source
                  ? requestForProposal.contact_source
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Выберите источник контакта!"
                  }
                ]
              })(
                <SelectFetch
                  placeholder="Источник"
                  onChange={onChangeContactSource}
                  selected={
                    requestForProposal.contact_source
                      ? requestForProposal.contact_source
                      : null
                  }
                  url={"/contact_sources/search?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Тема"}>
              {getFieldDecorator("subject", {
                onChange: onChangeSubject,
                initialValue: requestForProposal.subject,
                rules: [
                  {
                    required: true,
                    message: "Заполните тему!"
                  }
                ]
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("description", {
                onChange: onChangeDescription,
                initialValue: requestForProposal.description
              })(<TextArea autoSize={{ minRows: 6, maxRows: 10 }} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

General.propTypes = {
  order: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default General;
