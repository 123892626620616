// @flow
import React, { Component } from "react";
import { Row, Col, Form, Modal, Input, Select } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

class Email extends Component {
    

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      email,
      onChangeCategory,
      onChangeEmail
    } = this.props;

    return (
      <Modal
        title={email.is_exist ? "Редактирование email" : "Новый email"}
        width={650}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("category", {
                  onChange: onChangeCategory,
                  initialValue: email.category
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={"work"}>рабочий</Option>
                    <Option value={"personal"}>личный</Option>
                    <Option value={"other"}>другой</Option>
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col sm={{ span: 24 }} md={{ span: 16 }} xs={{ span: 16 }}>
              <FormItem label={"Email"}>
                {getFieldDecorator("email", {
                  onChange: onChangeEmail,
                  initialValue: email.email
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Email);
