import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  purchases: [],
  purchase: { purchase_items: [], purchase_payment_schedules: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    startDate: null,
    endDate: null,
    companies: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isLoadingCode: true,
  isVisibleModalForm: false,
};

function setCode(code = "00", date = Date.now(), agreement = "000") {
  let year = moment(date).format("YY");
  return `${code}/${year}/${agreement}`;
}

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const purchasesFilters = localStorage.getItem("purchasesFilters");
  if (purchasesFilters) {
    localFetchParams = JSON.parse(purchasesFilters);
  } else {
    localStorage.setItem("purchasesFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let total = 0;
  let percent = 0;
  let amount = 0;
  let totalPercent = 0;
  let totalPurchasePaymentSchedules = 0;
  let idx = -1;
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_PURCHASES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PURCHASES":
      return {
        ...state,
        purchases: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_PURCHASE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PURCHASE":
      return {
        ...state,
        purchase: action.response.data,
        isLoading: false,
      };

    case "PATCH_PURCHASE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_PURCHASE":
      return {
        ...state,
        errors: false,
        purchase: action.response.data,
        isLoading: false,
      };

    case "PATCH_PURCHASE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_PURCHASE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_PURCHASE":
      return {
        ...state,
        errors: false,
        purchase: action.response.data,
        isLoading: false,
        isVisibleModalForm: false,
      };

    case "POST_PURCHASE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_PURCHASE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_PURCHASE":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_PURCHASE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PURCHASE_NEW":
      return {
        ...state,
        purchase: {
          agreement_date: moment(),
          code: null,
          company: null,
          agreement: "спецификация № ",
          total: 0,
          vat_total: 0,
          purchase_payment_schedules: [],
        },
        errors: false,
        isLoading: false,
      };

    case "REQUEST_PURCHASE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_PURCHASE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "PURCHASES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "PURCHASE_CODE_REQUEST":
      return update(state, {
        isLoadingCode: { $set: true },
      });

    case "PURCHASE_CODE_SUCCESS":
      return update(state, {
        isLoadingCode: { $set: false },
        purchase: {
          code: {
            $set: setCode(
              state.purchase.company.extra.code
                ? state.purchase.company.extra.code
                : undefined,
              state.purchase.agreement_date,
              action.response.data
            ),
          },
        },
      });

    case "PURCHASE_SHOW_MODAL_FORM":
      return update(state, {
        isVisibleModalForm: { $set: !state.isVisibleModalForm },
        purchase: {
          agreement_date: { $set: moment() },
          code: { $set: null },
          company: { $set: null },
          agreement: { $set: "спецификация № " },
          total: { $set: 0 },
          vat_total: { $set: 0 },
          purchase_payment_schedules: { $set: [] },
        },
      });

    case "PURCHASE_CHANGE_MODAL_FORM_VISIBLE":
      return update(state, {
        isVisibleModalForm: { $set: !state.isVisibleModalForm },
      });

    // General

    case "PURCHASE_CHANGE_COMPANY":
      return update(state, {
        purchase: { company: { $set: action.value } },
      });

    case "PURCHASE_CHANGE_AGREEMENT_DATE":
      return update(state, {
        purchase: {
          agreement_date: { $set: action.value },
        },
      });

    case "PURCHASE_CHANGE_AGREEMENT":
      return update(state, {
        purchase: { agreement: { $set: action.value } },
      });

    case "PURCHASE_CHANGE_CODE":
      return update(state, {
        purchase: { code: { $set: action.value } },
      });

    case "PURCHASE_CHANGE_TOTAL":
      return update(state, {
        purchase: { total: { $set: action.value } },
      });

    case "PURCHASE_CHANGE_VAT_TOTAL":
      return update(state, {
        purchase: { vat_total: { $set: action.value } },
      });

    // Items
    case "PURCHASE_ADD_ITEM":
      total = Number(state.purchase.total) + Number(state.purchase.vat_total);
      totalPurchasePaymentSchedules = state.purchase.purchase_payment_schedules.reduce(
        (sum, record) => sum + Number(record.amount),
        0
      );
      totalPercent = state.purchase.purchase_payment_schedules.reduce(
        (sum, record) => sum + Number(record.percent),
        0
      );
      percent = 100 - totalPercent;
      return update(state, {
        purchase: {
          purchase_payment_schedules: {
            $push: [
              {
                id: Date.now(),
                payment_type: 1,
                date: moment(),
                amount: total - totalPurchasePaymentSchedules,
                percent: percent,
                is_exist: false,
                _destroy: false,
              },
            ],
          },
        },
      });

    case "PURCHASE_ITEM_DELETE":
      return update(state, {
        purchase: {
          purchase_payment_schedules: { $splice: [[action.idx, 1]] },
        },
      });

    case "PURCHASE_ITEM_DESTROY":
      return update(state, {
        purchase: {
          purchase_payment_schedules: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "PURCHASE_CHANGE_ITEM_DATE":
      idx = _.findIndex(state.purchase.purchase_payment_schedules, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        purchase: {
          purchase_payment_schedules: {
            [idx]: {
              date: { $set: action.value },
            },
          },
        },
      });

    case "PURCHASE_CHANGE_ITEM_PURCHASE_TYPE":
      idx = _.findIndex(state.purchase.purchase_payment_schedules, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        purchase: {
          purchase_payment_schedules: {
            [idx]: {
              payment_type: { $set: action.item.value },
            },
          },
        },
      });

    case "PURCHASE_CHANGE_ITEM_AMOUNT":
      idx = _.findIndex(state.purchase.purchase_payment_schedules, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        purchase: {
          purchase_payment_schedules: {
            [idx]: {
              amount: { $set: action.value },
            },
          },
        },
      });

    case "PURCHASE_CHANGE_ITEM_PERCENT":
      idx = _.findIndex(state.purchase.purchase_payment_schedules, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      total = Number(state.purchase.total) + Number(state.purchase.vat_total);
      amount = Number((total / 100) * action.value).toFixed(2);

      return update(state, {
        purchase: {
          purchase_payment_schedules: {
            [idx]: {
              percent: { $set: action.value },
              amount: { $set: amount },
            },
          },
        },
      });

    // filters
    case "PURCHASES_CLEAR_FILTERS":
      localStorage.removeItem("purchasesFilters");

      return update(state, {
        fetchParams: {
          startDate: { $set: null },
          endDate: { $set: null },
          companies: { $set: null },
        },
      });

    case "PURCHASES_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "PURCHASES_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "PURCHASES_FILTER_COMPANIES":
      return update(state, {
        fetchParams: {
          companies: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
