import React from "react";
import { Row, Col, Popover, Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

export default function OrderDetails({
  loading = false,
  name = null,
  data = [],
  onChange = null,
}) {
  return (
    <Popover
      placement="right"
      content={<Details loading={loading} data={data} />}
      title="Расшифровка"
      trigger="click"
      onVisibleChange={onChange}
    >
      <span style={{ cursor: "pointer" }}>{name}</span>
    </Popover>
  );
}

function Details({ loading = false, data = null }) {
  const columns = [
    {
      title: "Заказ",
      dataIndex: "number",
      render: (number, record) => (
        <Link to={`/orders/${record.id}/edit`} target="_blank">
          {record.number}
        </Link>
      ),

      key: "number",
      align: "left",
      width: "15%",
    },
    {
      title: "Дата",
      dataIndex: "date",
      render: (date) => moment(date).format("L"),
      key: "date",
      align: "center",
      width: "20%",
    },
    {
      title: "Компания",
      dataIndex: "company_name",
      key: "company_name",
      align: "left",
      width: "50%",
    },
    {
      title: "Сумма",
      dataIndex: "total",
      render: (total) => accounting.formatNumber(total, 0, " "),
      key: "total",
      align: "right",
      width: "15%",
    },
  ];

  return (
    <div style={{ width: 640 }}>
      <Table
        rowClassName={"progress-row"}
        size={"small"}
        loading={loading}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
}
