// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/invoices";
import OrdersActions from "../../../actions/orders";
import { Spin } from "antd";
import InvoiceForm from "./Form";
import moment from "moment";
import "moment/locale/ru";
import { v4 as uuidv4 } from 'uuid';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchInvoice(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.props.dispatch(Actions.fetchInvoice(this.props.match.params.id));
      } else {
        this.setState({ isNew: true }, () => {
          this.props.dispatch(Actions.onNew());
        });
      }
    }
  }


  onSave = () => {
    if (this.props.invoice.id) {
      this.props.dispatch(Actions.onUpdate(this.props.invoice.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        this.setState({ isNew: false });
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/invoices`);
  };

  downloadReport = () => {
    if (this.props.invoice.id) {
      Actions.downloadReport(this.props.invoice.id, this.props.invoice.number);
    }
  }

  downloadReportWithStamp = () => {
    if (this.props.invoice.id) {
      Actions.downloadReport(this.props.invoice.id, this.props.invoice.number, true);
    }
  }

  onDeleteItems = ids => {
    ids.map(id => {
      let idx = this.props.invoice.invoice_items.findIndex(function(o) {
        return o.id == id;
      });
      if (idx === -1) {
        return null;
      }
      if (this.props.invoice.invoice_items[idx].is_exist) {
        this.props.dispatch(Actions.onDeleteItem(idx));
      } else {
        this.props.dispatch(Actions.onDestroyItem(idx));
      }
    });
  };

  onFillInOrder = () => {
    const { invoice } = this.props;
    const order_items = invoice.invoice_items.map(item => {
      return {
        id: uuidv4(),
        position: item.position,
        product: item.product,
        unit: item.unit,
        vat_rate: item.vat_rate,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        amount_vat: item.amount_vat,
        will_shipped: moment(),
        will_shipped_days: 0,
        shipped: 0,
        shipped_quantity: 0,
        is_exist: false,
        _destroy: false
      };
    });

    const data = {
      order: {
        status: "approving",
        number: null,
        date: moment(),
        agreement: invoice.agreement,
        agreement_date: invoice.agreement_date,
        additional: invoice.additional,
        request_proposal: invoice.request_proposal,
        vat_included: invoice.vat_included,
        total: invoice.total,
        vat_total: invoice.vat_total,
        ship_address: invoice.ship_address,
        shipping_address: invoice.shipping_address,
        special_instruction: invoice.special_instruction,
        company: invoice.company,
        bank_account: invoice.bank_account,
        assignee: null,
        work_order: null,
        order_items: order_items,
        order_payment_schedules: [],
        shipments: [],
        invoices: []
      }
    };

    this.props.dispatch(OrdersActions.onFillInWithInvoice(data)).then(() => {
      this.props.history.push(`/orders/new`);
    });
  };

  onChangeAssignee = value => {
    this.props.dispatch(Actions.onChangeAssignee(value));
  };

  onChangeCompany = value => {
    this.props.dispatch(Actions.onChangeCompany(value));
  };

  onChangeAgreement = e => {
    this.props.dispatch(Actions.onChangeAgreement(e.target.value));
  };

  onChangeAgreementDate = value => {
    this.props.dispatch(Actions.onChangeAgreementDate(value));
  };

  onChangeAdditional = e => {
    this.props.dispatch(Actions.onChangeAdditional(e.target.value));
  };

  onChangeShippingMethod = value => {
    this.props.dispatch(Actions.onChangeShippingMethod(value));
  };

  onChangeBankAccount = value => {
    this.props.dispatch(Actions.onChangeBankAccount(value));
  };

  onChangeShippingAddress = value => {
    this.props.dispatch(Actions.onChangeShippingAddress(value));
  };

  onChangeSpecialInstruction = e => {
    this.props.dispatch(Actions.onChangeSpecialInstruction(e.target.value));
  };

  // status
  onChangeDate = value => {
    this.props.dispatch(Actions.onChangeDate(value));
  };

  onChangeDuration = value => {
    this.props.dispatch(Actions.onChangeDuration(value));
  };

  onChangeOrder = value => {
    this.props.dispatch(Actions.onChangeOrder(value));
  };

  onChangeRequestProposal = value => {
    this.props.dispatch(Actions.onChangeRequestProposal(value));
  };

  onChangeNumber = e => {
    this.props.dispatch(Actions.onChangeNumber(e.target.value));
  };

  onChangePaid = value => {
    this.props.dispatch(Actions.onChangePaid(value));
  };

  onChangeInvoiceType = value => {
    this.props.dispatch(Actions.onChangeInvoiceType(value));
  };

  onChangePaymentAmount = value => {
    this.props.dispatch(Actions.onChangePaymentAmount(value));
  };

  onChangePaymentPercent = value => {
    this.props.dispatch(Actions.onChangePaymentPercent(value));
  };

  // Items
  onAddItem = () => {
    this.props.dispatch(Actions.onAddItem());
  };

  onChangeItemProduct = (id, item) => {
    this.props.dispatch(Actions.onChangeItemProduct(id, item));
  };

  onChangeItemDescription = (id, value) => {
    this.props.dispatch(Actions.onChangeItemDescription(id, value));
  };

  onChangeItemUnit = (id, item) => {
    this.props.dispatch(Actions.onChangeItemUnit(id, item));
  };

  onChangeItemQuantity = (id, value) => {
    this.props.dispatch(Actions.onChangeItemQuantity(id, value));
  };

  onChangeItemPrice = (id, value) => {
    this.props.dispatch(Actions.onChangeItemPrice(id, value));
  };

  onChangeItemVatRate = (id, item) => {
    this.props.dispatch(Actions.onChangeItemVatRate(id, item));
  };

  onChangeItemVat = (id, value) => {
    this.props.dispatch(Actions.onChangeItemVat(id, value));
  };

  onChangeItemAmount = (id, value) => {
    this.props.dispatch(Actions.onChangeItemAmount(id, value));
  };

  render() {
    const { isLoading, invoice } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <InvoiceForm
        isNew={this.state.isNew}
        invoice={invoice}
        onSave={this.onSave}
        downloadReport={this.downloadReport}
        downloadReportWithStamp={this.downloadReportWithStamp}
        onClose={this.onClose}
        onFillInOrder={this.onFillInOrder}
        onChangeAssignee={this.onChangeAssignee}
        onChangeCompany={this.onChangeCompany}
        onChangeAgreement={this.onChangeAgreement}
        onChangeAgreementDate={this.onChangeAgreementDate}
        onChangeAdditional={this.onChangeAdditional}
        onChangeShippingMethod={this.onChangeShippingMethod}
        onChangeBankAccount={this.onChangeBankAccount}
        onChangeShippingAddress={this.onChangeShippingAddress}
        onChangeSpecialInstruction={this.onChangeSpecialInstruction}
        // status
        onChangeDate={this.onChangeDate}
        onChangeDuration={this.onChangeDuration}
        onChangeOrder={this.onChangeOrder}
        onChangeRequestProposal={this.onChangeRequestProposal}
        onChangeNumber={this.onChangeNumber}
        onChangePaid={this.onChangePaid}
        onChangeInvoiceType={this.onChangeInvoiceType}
        onChangePaymentAmount={this.onChangePaymentAmount}
        onChangePaymentPercent={this.onChangePaymentPercent}
        // items
        onAddItem={this.onAddItem}
        onDeleteItems={this.onDeleteItems}
        onChangeItemProduct={this.onChangeItemProduct}
        onChangeItemDescription={this.onChangeItemDescription}
        onChangeItemUnit={this.onChangeItemUnit}
        onChangeItemQuantity={this.onChangeItemQuantity}
        onChangeItemPrice={this.onChangeItemPrice}
        onChangeItemVatRate={this.onChangeItemVatRate}
        onChangeItemVat={this.onChangeItemVat}
        onChangeItemAmount={this.onChangeItemAmount}
      />
    );
  }
}

Invoice.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  invoice: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.invoices.isLoading,
  errors: state.invoices.errors,
  invoice: state.invoices.invoice
});

export default connect(mapStateToProps)(withRouter(Invoice));
