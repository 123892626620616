import update from "immutability-helper";

const initialState = {
  currentUser: {},
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CURRENT_USER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_CURRENT_USER":
      return {
        ...state,
        currentUser: action.response.data,
        isLoading: false
      };
    case "PATCH_CURRENT_USER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_CURRENT_USER":
      return {
        ...state,
        currentUser: action.response.data,
        isLoading: false
      };

    case "PATCH_CURRENT_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };
    case "PATCH_PASSWORD_CURRENT_USER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_PASSWORD_CURRENT_USER":
      return {
        ...state,
        isLoading: false
      };
    case "PATCH_PASSWORD_CURRENT_USER_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "CURRENT_USER_CHANGE_NAME":
      return update(state, {
        currentUser: { first_name: { $set: action.value } }
      });

    case "CURRENT_USER_CHANGE_MIDDLE_NAME":
      return update(state, {
        currentUser: { middle_name: { $set: action.value } }
      });

    case "CURRENT_USER_CHANGE_LAST_NAME":
      return update(state, {
        currentUser: { last_name: { $set: action.value } }
      });
    case "CURRENT_USER_CHANGE_EMAIL":
      return update(state, {
        currentUser: { email: { $set: action.value } }
      });

    case "CURRENT_USER_CHANGE_PASSWORD":
      return update(state, {
        currentUser: { password: { $set: action.value } }
      });

    case "CURRENT_USER_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        currentUser: { password_confirmation: { $set: action.value } }
      });

    default:
      return state;
  }
}
