import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Button } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSaveProduct(this.state.product);
      }
    });
  };

  onChangeProduct = value => {
    this.setState({ product: value });
  };

  render() {
    const { product } = this.state;
    const { onCancelProduct } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <FormItem label={"Продукция"}>
            {getFieldDecorator("product", {
              initialValue: product,
              rules: [
                {
                  required: true,
                  message: "Выберите продукцю!"
                }
              ]
            })(
              <SelectFetch
                showSearch
                placeholder="Продукция"
                onChange={this.onChangeProduct}
                selected={product}
                url={`/products/search/?`}
              />
            )}
          </FormItem>
          <Col span={12} offset={6}>
            <Button type="danger" ghost onClick={onCancelProduct}>
              Отменить
            </Button>
            <Button
              type="primary"
              ghost
              style={{ marginLeft: "10px" }}
              onClick={this.onSubmit}
            >
              Сохранить
            </Button>
          </Col>
        </Col>
      </Row>
    );
  }
}

Product.propTypes = {
  product: PropTypes.object
};

export default Form.create()(Product);
