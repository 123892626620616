// @flow
import React, { Component } from "react";
import { Form, Table, Modal, Row, Col } from "antd";
import update from "immutability-helper";

import PropTypes from "prop-types";
import EmailForm from "./EmailForm";

class Emails extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isVisible: false,
    email: {}
  };

  onEditEmail = idx => {
    const { emails } = this.props;
    this.setState({
      isVisible: true,
      email: emails[idx]
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onChangeEmail(this.state.email);
        this.setState({
          isVisible: false
        });
      }
    });
  };


  handleCancel = () => {
    this.setState({
      isVisible: false
    });
  };

  onChangeEmail = e => {
    this.setState({
      email: update(this.state.email, {
        email: { $set: e.target.value }
      })
    });
  };

  

  render() {
    const { loading, emails } = this.props;
    const { isVisible, confirmLoading, email } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Наименование",
        dataIndex: "email",

        render: (email, record, index) => (
          <div>
            <a onClick={this.onEditEmail.bind(this, index)}>{email}</a>
          </div>
        ),
        align: "center",
        width: "100%"
      },
    ];

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24} style={{ paddingBottom: "15px" }}>
          <Table
            bordered
            size={"middle"}
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={emails}
            rowKey="id"
          />
        </Col>
        <Modal
          title="Email"
          visible={isVisible}
          width={620}
          onOk={this.onSubmit}
          onCancel={this.handleCancel}
        >
          <Form layout={"vertical"} onSubmit={this.onSubmit}>
            <EmailForm
              getFieldDecorator={getFieldDecorator}
              email={email}
              onChangeEmail={this.onChangeEmail}
            />
          </Form>
        </Modal>
      </Row>
    );
  }
}

Emails.propTypes = {
  firms: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(Emails);
