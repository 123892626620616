// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/purchases";
import { Spin, message } from "antd";
import update from "immutability-helper";
import PurchaseForm from "./Form";

class Purchase extends Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false, tempAttachments: [] };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchPurchase(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.props.dispatch(Actions.fetchPurchase(this.props.match.params.id));
      } else {
        this.setState({ isNew: true }, () => {
          this.props.dispatch(Actions.onNew());
        });
      }
    }
  }

  onSave = () => {
    const { tempAttachments } = this.state;
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate(tempAttachments)).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/purchases/${this.props.purchase.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/purchases`);
  };

  onChangeCompany = value => {
    this.props.dispatch(Actions.onChangeCompany(value)).then(() => {
      // this.props.dispatch(Actions.onGenerateCode(value))
      if (!value.extra.code) {
        message.warning('У поставщика нет шифра в настройках! Шифр будет сгенерирован неверно.');
      }
    });
  };

  onChangeAgreementDate = value => {
    this.props.dispatch(Actions.onChangeAgreementDate(value));
  };

  onChangeAgreement = e => {
    this.props.dispatch(Actions.onChangeAgreement(e.target.value));
  };

  onChangeCode = e => {
    this.props.dispatch(Actions.onChangeCode(e.target.value));
  };

  onChangeTotal = e => {
    this.props.dispatch(Actions.onChangeTotal(e.target.value));
  };

  onChangeVatTotal = e => {
    this.props.dispatch(Actions.onChangeVatTotal(e.target.value));
  };

  // Items
  onAddItem = () => {
    this.props.dispatch(Actions.onAddItem());
  };

  onDeleteItems = ids => {
    this.props.dispatch(Actions.onDeleteItems(ids));
  };

  onChangeItemDate = (id, value) => {
    this.props.dispatch(Actions.onChangeItemDate(id, value));
  };

  onChangeItemPaymentType = (id, value) => {
    this.props.dispatch(Actions.onChangeItemPaymentType(id, value));
  };

  onChangeItemAmount = (id, value) => {
    // console.log(id, value)
    this.props.dispatch(Actions.onChangeItemAmount(id, value));
  };

  onChangeItemPercent = (id, value) => {
    this.props.dispatch(Actions.onChangeItemPercent(id, value));
  };

   // tempAttachments
   onTempUpload = (attachment) => {
    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $push: [attachment],
      }),
    });
  };

  onDeleteTempAttachment = (id) => {
    let idx = this.state.tempAttachments.findIndex(function (o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $splice: [[idx, 1]],
      }),
    });
  };

  render() {
    const { isNew, tempAttachments } = this.state;
    const { isLoading, purchase } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <PurchaseForm
        isNew={isNew}
        purchase={purchase}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeCompany={this.onChangeCompany}
        onChangeAgreementDate={this.onChangeAgreementDate}
        onChangeAgreement={this.onChangeAgreement}
        onChangeCode={this.onChangeCode}
        onChangeTotal={this.onChangeTotal}
        onChangeVatTotal={this.onChangeVatTotal}
        onAddItem={this.onAddItem}
        onDeleteItems={this.onDeleteItems}
        onChangeItemDate={this.onChangeItemDate}
        onChangeItemPaymentType={this.onChangeItemPaymentType}
        onChangeItemAmount={this.onChangeItemAmount}
        onChangeItemPercent={this.onChangeItemPercent}
        // tempAttachments
        tempAttachments={tempAttachments}
        onTempUpload={this.onTempUpload}
        onDeleteTempAttachment={this.onDeleteTempAttachment}
      />
    );
  }
}

Purchase.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  purchase: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.purchases.isLoading,
  errors: state.purchases.errors,
  purchase: state.purchases.purchase
});

export default connect(mapStateToProps)(withRouter(Purchase));
