// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Select, Icon } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";
import SelectFetch from "../../../components/SelectFetch";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;
const Option = Select.Option;

class InvoicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      filterAssigneeVisible: false,
      filterPeriodVisible: false
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // filters
  onChangeFilterAssignee = item => {
    this.setState({ filterAssigneeVisible: false }, () => {
      this.props.onChangeFilterAssignee(item.id);
    });
  };

  onResetFilterAssignee = () => {
    this.setState({ filterAssigneeVisible: false }, () => {
      this.props.onChangeFilterAssignee(null);
    });
  };

  onChangeFilterPeriod = value => {
    this.setState({ filterPeriodVisible: false }, () => {
      this.props.onChangeFilterPeriod(value);
    });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  renderExpandedRow = record => {
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={12}>
          <table style={{ width: "100%" }}>
            <tbody>
              {record.invoice_items.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <td style={{ width: "65%" }}>{item.description}</td>
                    <td style={{ width: "15%" }}>{`${item.quantity} шт.`}</td>
                    <td style={{ width: "20%" }}>{`${accounting.formatNumber(
                      item.amount,
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      loading,
      invoices,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Номер",
        dataIndex: "number",
        render: (number, record) => (
          <Link to={`/invoices/${record.id}/edit`}>{number}</Link>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "number",
        width: "10%"
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: date => moment(date).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "date",
        filterIcon: filtered => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.currentYear == "true" ? "#1890ff" : undefined
            }}
          />
        ),
        filterDropdown: (
          <div className="custom-filter-dropdown">
            <Row gutter={10}>
              <Col span={24}>
                <Select
                  style={{ width: "100%" }}
                  value={fetchParams.currentYear}
                  placeholder="Период"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={this.onChangeFilterPeriod}
                >
                  <Option value={"false"}>все</Option>
                  <Option value={"true"}>текущий год</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "10px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={this.onResetFilterActive}>
                  Сбросить
                </Button>
              </Col>
            </Row>
          </div>
        ),
        filterDropdownVisible: this.state.filterPeriodVisible,
        onFilterDropdownVisibleChange: visible =>
          this.setState({ filterPeriodVisible: visible }),
        width: "10%"
      },
      {
        title: "Компания",
        dataIndex: "company",
        render: company => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span style={{ opacity: "0.7" }}>{company.inn}</span>
            <span>
              <Link to={`/companies/${company.id}/edit`}>{company.text}</Link>
            </span>
          </div>
        ),
        width: "30%"
      },
      {
        title: "Заказ",
        dataIndex: "order",
        render: order => (
          <Link to={`/orders/${order.id}/edit`}>{order.text}</Link>
        ),
        width: "10%"
      },
      {
        title: "Аванс, %",
        dataIndex: "advance_percent",
        render: advance_percent =>
          accounting.formatNumber(advance_percent, 0, " "),
        align: "right",
        width: "7%"
      },
      {
        title: "Оплачено",
        dataIndex: "paid",
        render: paid => accounting.formatNumber(paid, 0, " "),
        align: "right",
        width: "10%"
      },
      {
        title: "Сумма",
        dataIndex: "total",
        render: total => accounting.formatNumber(total, 0, " "),
        align: "right",
        width: "10%"
      },
      {
        title: "Ответственный",
        dataIndex: "assignee",
        render: assignee => assignee.text,
        width: "13%",
        filterIcon: filtered => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.assignee ? "#1890ff" : undefined
            }}
          />
        ),
        filterDropdown: (
          <div className="custom-filter-dropdown">
            <Row gutter={10}>
              <Col span={24}>
                <SelectFetch
                  showSearch
                  placeholder="Ответственный"
                  onChange={this.onChangeFilterAssignee}
                  // selected={fetchParams.assignee ? fetchParams.assignee : null}
                  url={"/users/search?"}
                />
              </Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "10px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={this.onResetFilterAssignee}>
                  Сбросить
                </Button>
              </Col>
            </Row>
          </div>
        ),
        filterDropdownVisible: this.state.filterAssigneeVisible,
        onFilterDropdownVisibleChange: visible =>
          this.setState({ filterAssigneeVisible: visible })
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/invoices/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Счета`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={invoices}
                expandedRowRender={record => this.renderExpandedRow(record)}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

InvoicesList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default InvoicesList;
