import api from "../api";

const Actions = {};

Actions.fetchFirms = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_FIRMS_REQUEST" });
    api
      .fetch("/firms")
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_FIRMS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_FIRMS_FAILURE", response });
      });
  };
};

Actions.fetchFirm = (id) => {
  return dispatch => {
    dispatch({ type: "FETCH_FIRM_REQUEST" });
    api
      .fetch(`/firms/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_FIRM", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Организация", error);
          });
        }
        dispatch({ type: "FETCH_FIRM_FAILURE", response });
      });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_FIRM_REQUEST" });
    const firm = getState().firms.firm;

    const data = {
      firm: {
        name: firm.name,
        full_name: firm.full_name,
        prefix: firm.prefix,
        ceo: firm.ceo,
        ceo_title: firm.ceo_title,
        cfo: firm.cfo,
        cfo_title: firm.cfo_title,
        inn: firm.inn,
        kpp: firm.kpp,
        ogrn: firm.ogrn,
        okpo: firm.okpo,
        bank_account_primary_id: firm.bank_account_primary ? firm.bank_account_primary.id : null,
        bank_accounts_attributes: firm.bank_accounts,
        emails_attributes: firm.emails
      }
    };

    return api
      .patch(`/firms/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_FIRM", response });
        api.openNotification(
          "success",
          "Организация",
          "Успешно обновлено!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Организация", error);
          });
        }
        dispatch({ type: "PATCH_FIRM_FAILURE", response });
      });
  };
};

// header
Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeFullName = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_FULL_NAME",
      value: value
    });
  };
};

Actions.onChangePrefix = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_PREFIX",
      value: value
    });
  };
};

Actions.onChangeInn = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_INN",
      value: value
    });
  };
};

Actions.onChangeKpp = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_KPP",
      value: value
    });
  };
};

Actions.onChangeOgrn = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_OGRN",
      value: value
    });
  };
};

Actions.onChangeOkpo = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_OKPO",
      value: value
    });
  };
};

Actions.onChangeCeo = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_CEO",
      value: value
    });
  };
};

Actions.onChangeCeoTitle = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_CEO_TITLE",
      value: value
    });
  };
};

Actions.onChangeCfo = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_CFO",
      value: value
    });
  };
};


Actions.onChangeCfoTitle = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_CFO_TITLE",
      value: value
    });
  };
};

Actions.onChangeBankAccountPrimary = value => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_BANK_ACCOUNT_PRIMARY",
      value: value
    });
  };
};

Actions.onChangeBankAccount = item => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_BANK_ACCOUNT",
      item: item
    });
  };
};

Actions.onChangeEmail = item => {
  return dispatch => {
    dispatch({
      type: "FIRM_CHANGE_EMAIL",
      item: item
    });
  };
};


export default Actions;
