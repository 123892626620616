// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, Menu, Dropdown, Icon, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import EditableDateCell from "../../../components/EditableCells/EditableDateCell";

import moment from "moment";
import "moment/locale/ru";

class PurchasePaymentSchedules extends Component {
  onSelectMenu = (record, { key }) => {
    if (key === "1") {
      this.props.onAddItem(record.id);
    } else {
      this.props.onFillBySpecification(record.id, record.product.id);
      // console.log(record.id, record.product.id);
    }
  };

  render() {
    const {
      isPurchasePaymentSchedulesLoading,
      purchasePaymentSchedules
    } = this.props;

    const columns = [
      {
        title: "Договор",
        dataIndex: "purchase",
        render: (purchase, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>
              <Link to={`/purchases/${purchase.id}/edit`}>
                {purchase.agreement}
              </Link>
            </span>
          </div>
        ),
        align: "center",
        width: "20%"
      },
      {
        title: "Шифр",
        dataIndex: "purchase",
        render: (purchase, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>
              {purchase.text}
            </span>
          </div>
        ),
        key: "agreement",
        align: "center",
        width: "20%"
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: date => moment(date).format("L"),
        align: "center",
        width: "10%"
      },
      {
        title: "Cумма",
        dataIndex: "amount",
        render: (amount, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>{accounting.formatNumber(amount, 0, " ")}</span>
          </div>
        ),
        align: "right",
        width: "10%"
      },
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isPurchasePaymentSchedulesLoading}
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={purchasePaymentSchedules}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PurchasePaymentSchedules.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.object)
};
export default PurchasePaymentSchedules;
