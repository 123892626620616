import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  products: [],
  product: { name: "", product_specifications: [] },
  categories: [],
  category: { name: "", description: "", is_exist: false, children: [] },
  orders: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    category_id: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isCategoryLoading: true,
  isProductLoading: false,
  isOrdersLoading: true,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_PRODUCTS_REQUEST":
      return {
        ...state,
        isProductLoading: true,
      };
    case "FETCH_PRODUCTS":
      return {
        ...state,
        isProductLoading: false,
        products: action.response.data,
        meta: action.response.meta,
      };
    case "FETCH_PRODUCT_REQUEST":
      return {
        ...state,
        isProductLoading: true,
      };
    case "FETCH_PRODUCT":
      return {
        ...state,
        product: action.response.data,
        isProductLoading: false,
      };
    case "PATCH_PRODUCT_REQUEST":
      return {
        ...state,
        isProductLoading: true,
      };
    case "PATCH_PRODUCT":
      return {
        ...state,
        errors: false,
        product: action.response.data,
        isProductLoading: false,
      };

    case "PATCH_PRODUCT_FAILURE":
      return {
        ...state,
        errors: true,
        isProductLoading: false,
      };

    // product_categories
    case "FETCH_CATEGORIES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CATEGORIES":
      return {
        ...state,
        isLoading: false,
        categories: action.response.data,
      };

    // orders
    case "FETCH_PRODUCT_ORDERS_REQUEST":
      return {
        ...state,
        isOrdersLoading: true,
      };
    case "FETCH_PRODUCT_ORDERS":
      return {
        ...state,
        isOrdersLoading: false,
        orders: action.response.data,
      };

    case "FETCH_PRODUCT_ORDERS_FAILURE":
      return {
        ...state,
        errors: true,
        isOrdersLoading: false,
      };

    // change category
    case "PRODUCT_CHANGE_CATEGORY_REQUEST":
      return {
        ...state,
        isProductLoading: true,
      };
    case "PRODUCT_CHANGE_CATEGORY":
      return {
        ...state,
        isProductLoading: false,
      };

    case "PRODUCT_CHANGE_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isProductLoading: false,
      };

    case "PRODUCTS_SELECT_CATEGORY":
      return update(state, {
        fetchParams: { category_id: { $set: action.value }, page: { $set: 1 } },
      });
    // end product_categories

    case "POST_PRODUCT_REQUEST":
      return {
        ...state,
        errors: false,
        isProductLoading: true,
      };
    case "POST_PRODUCT":
      return {
        ...state,
        errors: false,
        product: action.response.data,
        isProductLoading: false,
      };
    case "POST_PRODUCT_FAILURE":
      return {
        ...state,
        errors: true,
        isProductLoading: false,
      };

    case "DELETE_PRODUCT_REQUEST":
      return {
        ...state,
        errors: false,
        isProductLoading: true,
      };
    case "DELETE_PRODUCT":
      return {
        ...state,
        errors: false,
        products: action.response.data,
        isProductLoading: false,
      };
    case "DELETE_PRODUCT_FAILURE":
      return {
        ...state,
        errors: true,
        isProductLoading: false,
      };
    case "REQUEST_PRODUCT_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_PRODUCTS_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "PRODUCT_NEW":
      return {
        ...state,
        errors: false,
        product: {
          active: true,
          product_type: null,
          product_category: null,
          name: null,
          description: null,
          sku: null,
          price: 0,
          quantity: 0,
          cost: 0,
          unit: null,
          vat_rate: null,
          is_price: false,
          product_specifications: [],
        },
        isProductLoading: false,
      };

    case "CATEGORY_NEW":
      return {
        ...state,
        errors: false,
        category: {
          id: null,
          name: null,
          ancestry: action.ancestry,
          position: 0,
        },
        isCategoriesLoading: false,
      };

    // Genetal
    case "PRODUCT_CHANGE_NAME":
      return update(state, {
        product: { name: { $set: action.value } },
      });

    case "PRODUCT_CHANGE_DESCRIPTION":
      return update(state, {
        product: { description: { $set: action.value } },
      });

    case "PRODUCT_CHANGE_SKU":
      return update(state, {
        product: { sku: { $set: action.value } },
      });

    case "PRODUCT_CHANGE_PRICE":
      return update(state, {
        product: { price: { $set: action.value } },
      });

    case "PRODUCT_CHANGE_UNIT":
      return update(state, {
        product: { unit: { $set: action.value } },
      });

    case "PRODUCT_CHANGE_ACTIVE":
      return update(state, {
        product: { active: { $set: action.value } },
      });

    case "PRODUCT_CHANGE_IS_PRICE":
      return update(state, {
        product: { is_price: { $set: action.value } },
      });

    // Items
    case "PRODUCT_ADD_SPECIFICATION_ITEM":
      return update(state, {
        product: {
          product_specifications: {
            $push: [
              {
                id: Date.now(),
                product_specification: {
                  id: null,
                  text: "выберите материал",
                },
                quantity: 1,
                cost: 0,
                is_exist: false,
                _destroy: false,
              },
            ],
          },
        },
      });

    case "PRODUCT_SPECIFICATION_ITEM_DELETE":
      return update(state, {
        product: {
          product_specifications: { $splice: [[action.idx, 1]] },
        },
      });

    case "PRODUCT_SPECIFICATION_ITEM_DESTROY":
      return update(state, {
        product: {
          product_specifications: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "PRODUCT_CHANGE_SPECIFICATION_ITEM_PRODUCT":
      idx = _.findIndex(state.product.product_specifications, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        product: {
          product_specifications: {
            [idx]: {
              product_specification: { $set: action.value },
            },
          },
        },
      });

    case "PRODUCT_CHANGE_SPECIFICATION_ITEM_QUANTITY":
      idx = _.findIndex(state.product.product_specifications, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        product: {
          product_specifications: {
            [idx]: {
              quantity: { $set: action.value },
            },
          },
        },
      });

    case "PRODUCT_CHANGE_SPECIFICATION_ITEM_COST":
      idx = _.findIndex(state.product.product_specifications, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        product: {
          product_specifications: {
            [idx]: {
              cost: { $set: action.value },
            },
          },
        },
      });

    // categories
    case "FETCH_CATEGORY_REQUEST":
      return {
        ...state,
        isCategoryLoading: true,
      };
    case "FETCH_CATEGORY":
      return {
        ...state,
        category: action.response.data,
        isCategoryLoading: false,
      };

    case "POST_CATEGORY_REQUEST":
      return {
        ...state,
        errors: false,
        isCategoriesLoading: true,
      };
    case "POST_CATEGORY_SUCCEED":
      return {
        ...state,
        errors: false,
        categories: action.response.data,
        isCategoriesLoading: false,
      };

    case "POST_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isCategoriesLoading: false,
      };

    case "PATCH_CATEGORY_REQUEST":
      return {
        ...state,
        isCategoriesLoading: true,
      };
    case "PATCH_CATEGORY_SUCCEED":
      return {
        ...state,
        errors: false,
        categories: action.response.data,
        isCategoriesLoading: false,
      };

    case "PATCH_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isCategoriesLoading: false,
      };

    case "DELETE_CATEGORY_REQUEST":
      return {
        ...state,
        errors: false,
        isCategoriesLoading: true,
      };
    case "DELETE_CATEGORY_SUCCEED":
      return {
        ...state,
        errors: false,
        categories: action.response.data,
        isCategoriesLoading: false,
      };
    case "DELETE_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isCategoriesLoading: false,
      };

    case "FETCH_CATEGORIES_REQUEST":
      return {
        ...state,
        isCategoriesLoading: true,
      };
    case "FETCH_CATEGORIES":
      return {
        ...state,
        isCategoriesLoading: false,
        categories: action.response.data,
      };

    case "CATEGORY_CHANGE_NAME":
      return update(state, {
        category: { name: { $set: action.value } },
      });

    case "CATEGORY_CHANGE_ACTIVE":
      return update(state, {
        category: { is_active: { $set: action.value } },
      });

      case "CATEGORY_CHANGE_SUGGEST":
      return update(state, {
        category: { is_suggest: { $set: action.value } },
      });

    default:
      return state;
  }
}
