// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        endDate: moment().format("DD.MM.YYYY"),
        assignee: null
      }
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchClientAccounts(this.state.fetchParams));
  };

  onChangeDate = value => {
    this.state.fetchParams.endDate = moment(value).format("DD.MM.YYYY");
    this.setState({ fetchParams: this.state.fetchParams }, function() {
      this.props.dispatch(Actions.fetchClientAccounts(this.state.fetchParams));
    });
  };

  downloadReport = () => {
    Actions.downloadReport(this.state.fetchParams, 'clientaccounts.xlsx', 'clientaccounts.xlsx'); 
  }

  // filters
  onChangeFilterAssignee = value => {
    this.state.fetchParams.assignee = value;
    this.setState({ fetchParams: this.state.fetchParams }, function() {
      this.props.dispatch(Actions.fetchClientAccounts(this.state.fetchParams));
    });
  };

  render() {
    const { isLoading, report, totals } = this.props;
    const { fetchParams } = this.state;
    return (
      <DataList
        loading={isLoading}
        report={report}
        totals={totals}
        fetchParams={fetchParams}
        onChangeDate={this.onChangeDate}
        downloadReport={this.downloadReport}
        onChangeFilterAssignee={this.onChangeFilterAssignee}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.reports.isLoading,
  report: state.reports.report,
  totals: state.reports.totals
});

export default connect(mapStateToProps)(Report);
