// @flow
import React, { Component } from "react";
import { Table } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

class ServicerOderItems extends Component {
  render() {
    const { shipment, serviceOrderItems } = this.props;

    const columns = [
      {
        title: "Продукция",
        dataIndex: "shipment_item",
        render: (shipment_item, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {shipment_item ? shipment_item.product : null}
            </span>
            <span>
              {shipment_item ? shipment_item.description : null}
            </span>
          </div>
        ),
        width: "70%",
        align: "center"
      },
      {
        title: "Списано",
        dataIndex: "cost",
        render: (cost, record) => accounting.formatNumber(Number(cost), 2, " "),
        align: "right",
        width: "30%"
      }
    ];

    return (
      <Table
        bordered
        size={"small"}
        pagination={false}
        columns={columns}
        dataSource={serviceOrderItems}
        title={() =>
          `Распределение затрат на продукцию по отгрузке #${
            shipment.text
          } от ${moment(shipment.date).format("L")}`
        }
        rowKey="id"
      />
    );
  }
}

ServicerOderItems.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object)
};

export default ServicerOderItems;
