// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Card, Select, Form, Input, DatePicker } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      getFieldDecorator,
      workOrder,
      onChangeDate,
      onChangeNumber,
      onChangeStatus,
      onChangeAssignee
    } = this.props;

    let subcontracts = 0
    let materials = 0
    let tansports = 0
    
    workOrder.items.map((item) => {
      subcontracts += item.work_order_items.filter(function(item) {
        return item.cost_type == "contract";
      })
      .reduce((sum, record) => sum + Number(record.material_cost), 0);
    })

    workOrder.items.map((item) => {
      materials += item.work_order_items.filter(function(item) {
        return item.cost_type == "material";
      })
      .reduce((sum, record) => sum + Number(record.material_cost), 0);
    })
    
    workOrder.items.map((item) => {
      tansports += item.work_order_items.filter(function(item) {
        return item.cost_type == "transport";
      })
      .reduce((sum, record) => sum + Number(record.material_cost), 0);
    })  
    

    return (
      <Card style={{ bworkOrderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem label={"Дата заказ-наряда"}>
              {getFieldDecorator("date", {
                onChange: onChangeDate,
                initialValue: moment(workOrder.date)
              })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Номер"}>
              {getFieldDecorator("number", {
                onChange: onChangeNumber,
                initialValue: workOrder.number
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                initialValue: workOrder.status,
                onChange: onChangeStatus
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Способ оплаты"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"approving"}>согласование</Option>
                  <Option value={"working"}>в работе</Option>
                  <Option value={"closed"}>закрыт</Option>
                  <Option value={"canceled"}>отменен</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Ответственный"}>
              {getFieldDecorator("assignee", {
                initialValue: workOrder.assignee ? workOrder.assignee : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите ответственного!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Ответственный"
                  onChange={onChangeAssignee}
                  selected={workOrder.assignee ? workOrder.assignee : null}
                  url={"/users/search?"}
                />
              )}
            </FormItem>
          </Col>

          <Col span={24} style={{ paddingBottom: "10px" }} />
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "65%" }}>Всего</td>
                <td
                  style={{ width: "35%", textAlign: "right" }}
                >{`${accounting.formatNumber(workOrder.total, 2, " ", ",")} руб.`}</td>
              </tr>
              <tr>
                <td style={{ width: "65%" }}>Субподряд</td>
                <td
                  style={{ width: "35%", textAlign: "right" }}
                >{`${accounting.formatNumber(subcontracts, 2, " ", ",")} руб.`}</td>
              </tr>
              <tr>
                <td style={{ width: "65%" }}>Материалы</td>
                <td
                  style={{ width: "35%", textAlign: "right" }}
                >{`${accounting.formatNumber(materials, 2, " ", ",")} руб.`}</td>
              </tr>
              <tr>
                <td style={{ width: "65%" }}>Транспорт</td>
                <td
                  style={{ width: "35%", textAlign: "right" }}
                >{`${accounting.formatNumber(tansports, 2, " ", ",")} руб.`}</td>
              </tr>
            </tbody>
          </table>
          <Col span={24} />
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  workOrder: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Statuses;
