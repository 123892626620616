import api from "../api";
import download from "downloadjs";

const Actions = {};

Actions.fetchSalesByPeriods = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SALES_BY_PERIODS_REQUEST" });
    api
      .fetch("/reports/sales", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SALES_BY_PERIODS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SALES_BY_PERIODS_FAILURE", response });
      });
  };
};

Actions.fetchShipmentsByCategories = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES_REQUEST" });
    api
      .fetch("/reports/shipments_by_categories", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES_FAILURE", response });
      });
  };
};

Actions.fetchShipmentsByCategoriesPie = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES_PIE_REQUEST" });
    api
      .fetch("/reports/shipments_by_categories_pie", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES_PIE", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_SHIPMENTS_BY_CATEGORIES_PIE_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchShipmentsByRegions = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SHIPMENTS_BY_REGIONS_REQUEST" });
    api
      .fetch("/reports/shipments_by_regions", params)
      .then((response) => {
        dispatch({ type: "FETCH_SHIPMENTS_BY_REGIONS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_SHIPMENTS_BY_REGIONS_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchShipmentsByBusinessFields = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SHIPMENTS_BY_BUSINESS_FIELDS_REQUEST" });
    api
      .fetch("/reports/shipments_by_business_fields", params)
      .then((response) => {
        dispatch({ type: "FETCH_SHIPMENTS_BY_BUSINESS_FIELDS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_SHIPMENTS_BY_BUSINESS_FIELDS_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchOrdersByRegions = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDERS_BY_REGIONS_REQUEST" });
    api
      .fetch("/reports/orders_by_regions", params)
      .then((response) => {
        dispatch({ type: "FETCH_ORDERS_BY_REGIONS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_ORDERS_BY_REGIONS_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchOrdersByBusinessFields = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDERS_BY_BUSINESS_FIELDS_REQUEST" });
    api
      .fetch("/reports/orders_by_business_fields", params)
      .then((response) => {
        dispatch({ type: "FETCH_ORDERS_BY_BUSINESS_FIELDS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_ORDERS_BY_BUSINESS_FIELDS_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchShipmentsByCategoriesOwn = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES_OWN_REQUEST" });
    api
      .fetch("/reports/shipments_by_categories_own", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SHIPMENTS_BY_CATEGORIES_OWN", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_SHIPMENTS_BY_CATEGORIES_OWN_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchCashflow = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_CASHFLOW_REPORT_REQUEST" });
    return api
      .fetch("/reports/cashflow", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_CASHFLOW_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_CASHFLOW_REPORT_FAILURE", response });
      });
  };
};

// cash_flow_items
Actions.fetchCashflowItems = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_CASHFLOW_ITEMS_REQUEST" });
    return api
      .fetch("/cash_flow_items", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_CASHFLOW_ITEMS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_CASHFLOW_ITEMS_FAILURE", response });
      });
  };
};

Actions.onCreateCashFlowItem = (item) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CASHFLOW_ITEM_REQUEST" });
    const data = {
      cash_flow_item: {
        name: item.name,
        cashflow_type: item.cashflow_type,
        position: item.position,
      },
    };
    return api
      .post(`/cash_flow_items`, data)
      .then((response) => {
        api.openNotification("success", "Статья ДДС", "Успешно создана!");
        dispatch({ type: "POST_CASHFLOW_ITEM", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Статья ДДС", error);
          });
        }
        dispatch({ type: "POST_CASHFLOW_ITEM_FAILURE", response });
      });
  };
};

Actions.onUpdateCashFlowItem = (item) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CASHFLOW_ITEM_REQUEST" });
    const data = { cash_flow_item: item };
    return api
      .patch(`/cash_flow_items/${item.id}`, data)
      .then((response) => {
        api.openNotification("success", "Статья ДДС", "Успешно обновлена!");
        dispatch({ type: "PATCH_CASHFLOW_ITEM", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Статья ДДС", error);
          });
        }
        dispatch({ type: "PATCH_CASHFLOW_ITEM_FAILURE", response });
      });
  };
};

Actions.onDeleteCashFlowItem = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_CASHFLOW_ITEM_REQUEST" });
    return api
      .delete(`/cash_flow_items/${id}`)
      .then((response) => {
        api.openNotification("success", "Статья ДДС", "Успешно удалена!");
        dispatch({ type: "DELETE_CASHFLOW_ITEM", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Статья ДДС", error);
          });
        }
        dispatch({
          type: "DELETE_CASHFLOW_ITEM_FAILURE",
        });
      });
  };
};

Actions.onSaveCashFlowValue = (item) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CASHFLOW_ITEM_VALUE_REQUEST" });
    const data = { cash_flow_value: item };
    return api
      .post(`/cash_flow_items/${item.cash_flow_item_id}/cash_flow_values`, data)
      .then((response) => {
        api.openNotification("success", "Значение ДДС", "Успешно создано!");
        dispatch({ type: "POST_CASHFLOW_ITEM_VALUE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Значение ДДС", error);
          });
        }
        dispatch({ type: "POST_CASHFLOW_ITEM_VALUE_FAILURE", response });
      });
  };
};

Actions.onDeleteCashFlowValues = (id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CASHFLOW_ITEM_REQUEST" });
    return api
      .patch(`/cash_flow_items/${id}/destroy_values`, {
        startDate: startDate,
        endDate: endDate,
      })
      .then((response) => {
        api.openNotification("success", "Статья ДДС", "Значения удалены!");
        dispatch({ type: "PATCH_CASHFLOW_ITEM", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Статья ДДС", error);
          });
        }
        dispatch({ type: "PATCH_CASHFLOW_ITEM_FAILURE", response });
      });
  };
};

Actions.fetchPlanCashflow = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/plancashflow", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};
Actions.fetchInpayments = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/inpayments", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

// outpayments
Actions.fetchOutpayments = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/outpayments", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

Actions.fetchPlanOutpayments = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/planoutpayments", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

Actions.fetchLastShipments = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/lastshipments", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

Actions.fetchPlanShipments = (params) => {
  const data = {
    startShippingDate: params.startShippingDate,
    endShippingDate: params.endShippingDate,
  };
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    return api
      .fetch("/reports/planshipments", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

Actions.fetchClientAccounts = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/clientaccounts", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

Actions.fetchOrdersByStatuses = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDERS_BY_STATUSES_REQUEST" });
    api
      .fetch("/reports/statuses", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ORDERS_BY_STATUSES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDERS_BY_STATUSES_FAILURE", response });
      });
  };
};

Actions.fetchOrdersByStatusesDetails = (userId, status, startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDERS_BY_STATUSES_DETAILS_REQUEST" });
    const params = {
      user_id: userId,
      status: status,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch(`/reports/statuses_details`, params)
      .then((response) => {
        dispatch({ type: "FETCH_ORDERS_BY_STATUSES_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({
          type: "FETCH_ORDERS_BY_STATUSES_DETAILS_FAILURE",
          response,
        });
      });
  };
};

Actions.fetchPurchaseFunnel = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PURCHASE_FUNNEL_REQUEST" });
    api
      .fetch("/reports/purchase_funnel", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_PURCHASE_FUNNEL", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PURCHASE_FUNNEL_FAILURE", response });
      });
  };
};

Actions.fetchUserStatistic = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_STATISTIC_REQUEST" });
    api
      .fetch("/reports/user_statistics", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_USER_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_USER_STATISTIC_FAILURE", response });
      });
  };
};

Actions.fetchLossReasons = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_LOSS_REASONS_REQUEST" });
    api
      .fetch("/reports/loss_reasons", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_LOSS_REASONS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_LOSS_REASONS_FAILURE", response });
      });
  };
};

Actions.fetchInventory = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_INVENTORY_REPORT_REQUEST" });
    return api
      .fetch("/reports/inventory", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_INVENTORY_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_INVENTORY_REPORT_FAILURE", response });
      });
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  api
    .fetchBlob(`/reports/${url}`, params)
    .then((response) => {
      //console.log(response)
      download(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REPORT_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

export default Actions;
