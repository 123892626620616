// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Input, Avatar, Button, Tooltip } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableTextAreaCell from "../../../components/EditableCells/EditableTextAreaCell";
import EditableDateCell from "../../../components/EditableCells/EditableDateCell";
import EditableSelectFetchCell from "../../../components/EditableCells/EditableSelectFetchCell";

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteItems = () => {
    let selected = [];
    this.state.selectedRowKeys.map(item => {
      return selected.push(item.substring(0, item.length - 2));
    });
    selected = Array.from(new Set(selected));
    this.props.onDeleteItems(selected);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      invoiceItems,
      onAddItem,
      onChangeItemProduct,
      onChangeItemDescription,
      onChangeItemUnit,
      onChangeItemQuantity,
      onChangeItemPrice,
      onChangeItemVatRate,
      onChangeItemVat,
      onChangeItemAmount
    } = this.props;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const data = [];
    invoiceItems.map(item => {
      data.push({
        isFirst: true,
        key: `${item.id}-1`,
        id: item.id,
        product: item.product,
        unit: item.unit,
        quantity: item.quantity,
        price: item.price,
        vat_rate: item.vat_rate,
        amount_vat: item.amount_vat,
        amount: item.amount,
        shipped: item.shipped
      });

      data.push({
        isFirst: false,
        key: `${item.id}-2`,
        id: item.id,
        description: item.description,
        will_shipped_days: item.will_shipped_days,
        will_shipped: item.will_shipped,
        shipped_quantity: item.shipped_quantity
      });
    });

    const columns = [
      {
        title: "Наименование",
        dataIndex: "product",
        render: (product, record) => {
          if (record.isFirst) {
            return (
              <EditableSelectFetchCell
                showSubtitle
                showTooltip
                highlight
                recordId={record.id}
                selected={product}
                showSearch
                url={"/products/search?"}
                onChange={onChangeItemProduct}
              />
            );
          }
          return {
            children: [
              <EditableTextAreaCell
                key={`description-${record.id}`}
                recordId={record.id}
                value={record.description}
                onChange={onChangeItemDescription}
              />
            ],
            props: {
              colSpan: 7
            }
          };
        },
        width: "25%"
      },
      {
        title: "Ед.изм.",
        dataIndex: "unit",
        render: (unit, record) => {
          if (record.isFirst) {
            return (
              <EditableSelectFetchCell
                recordId={record.id}
                selected={unit}
                url={"/units/search/?"}
                onChange={onChangeItemUnit}
              />
            );
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },
        editable: true,
        width: "9%",
        align: "center"
      },
      {
        title: "Количество",
        dataIndex: "quantity",
        render: (quantity, record) => {
          if (record.isFirst) {
            return (
              <EditableInputCell
                recordId={record.id}
                value={quantity}
                precision={0}
                onChange={onChangeItemQuantity}
              />
            );
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },

        editable: true,
        width: "7%",
        align: "center"
      },
      {
        title: "Цена",
        dataIndex: "price",
        render: (price, record) => {
          if (record.isFirst) {
            return (
              <EditableInputCell
                recordId={record.id}
                value={price}
                onChange={onChangeItemPrice}
              />
            );
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },

        width: "10%",
        align: "center"
      },
      {
        title: "Ставка НДС",
        dataIndex: "vat_rate",
        render: (vat_rate, record) => {
          if (record.isFirst) {
            return (
              <EditableSelectFetchCell
                key={`vat_rate-${record.id}`}
                recordId={record.id}
                selected={vat_rate}
                url={"/vat_rates/search/?"}
                onChange={onChangeItemVatRate}
              />
            );
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },
        editable: true,
        width: "12%",
        align: "center"
      },
      {
        title: "Сумма НДС",
        dataIndex: "amount_vat",
        render: (amount_vat, record) => {
          if (record.isFirst) {
            return (
              <EditableInputCell
                recordId={record.id}
                value={amount_vat}
                onChange={onChangeItemVat}
              />
            );
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },

        width: "12%",
        align: "center"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record) => {
          if (record.isFirst) {
            return (
              <EditableInputCell
                recordId={record.id}
                value={amount}
                onChange={onChangeItemAmount}
              />
            );
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },

        width: "12%",
        align: "center"
      },
      {
        title: "Всего",
        dataIndex: "total",
        render: (total, record) => {
          if (record.isFirst) {
            return {
              children: accounting.formatNumber(Number(record.amount) + Number(record.amount_vat), 2, " ", ","),
              props: {
                rowSpan: 2
              }
            };
          }
          return {
            props: {
              colSpan: 0
            }
          };
        },
        className: "ant-table-total-column",
        width: "13%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            {hasSelected ? (
              <Button onClick={this.onDeleteItems}>Удалить</Button>
            ) : (
              <div>
                <Button onClick={onAddItem}>Добавить</Button>
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              rowClassName={() => "editable-row"}
              rowSelection={rowSelection}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={data}
              rowKey="key"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Items.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Items;
