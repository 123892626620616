// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Tabs, Form, Card } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BankAccounts from "./BankAccounts";
import Emails from "./Emails";

const TabPane = Tabs.TabPane;

class FirmForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { tabIndex: "1" };
  }

  onChangeTab = key => {
    this.setState({ tabIndex: key });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  onClose = () => {
    window.history.back();
  };

  render() {
    const { tabIndex } = this.state;
    const {
      isNew,
      isLoading,
      firm,
      onChangeName,
      onChangeFullName,
      onChangePrefix,
      onChangeInn,
      onChangeKpp,
      onChangeOgrn,
      onChangeOkpo,
      onChangeCeo,
      onChangeCeoTitle,
      onChangeCfo,
      onChangeCfoTitle,
      onChangeBankAccountPrimary,
      onChangeBankAccount,
      onChangeEmail
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Компания ${
            isNew ? "новая" : firm.name
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={this.onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"} onSubmit={this.onSubmit}>
          <Row gutter={16}>
            <Col sm={{ span: 24 }} md={{ span: 13 }} xs={{ span: 13 }}>
              <Row gutter={16}>
                <Header
                  name={firm.name}
                  full_name={firm.full_name}
                  prefix={firm.prefix}
                  ceo={firm.ceo}
                  ceo_title={firm.ceo_title}
                  cfo={firm.cfo}
                  cfo_title={firm.cfo_title}
                  bank_account_primary={firm.bank_account_primary}
                  getFieldDecorator={getFieldDecorator}
                  onChangeName={onChangeName}
                  onChangeFullName={onChangeFullName}
                  onChangePrefix={onChangePrefix}
                  onChangeCeo={onChangeCeo}
                  onChangeCeoTitle={onChangeCeoTitle}
                  onChangeCfo={onChangeCfo}
                  onChangeCfoTitle={onChangeCfoTitle}
                  onChangeBankAccountPrimary={onChangeBankAccountPrimary}
                />
              </Row>
            </Col>
            <Col sm={{ span: 0 }} md={{ span: 1 }} xs={{ span: 1 }} />
            <Col sm={{ span: 24 }} md={{ span: 10 }} xs={{ span: 10 }}>
              <Sidebar
                inn={firm.inn}
                kpp={firm.kpp}
                ogrn={firm.ogrn}
                okpo={firm.okpo}
                getFieldDecorator={getFieldDecorator}
                onChangeInn={onChangeInn}
                onChangeKpp={onChangeKpp}
                onChangeOgrn={onChangeOgrn}
                onChangeOkpo={onChangeOkpo}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Tabs activeKey={tabIndex} onChange={this.onChangeTab}>
              <TabPane tab="Банковские счета" key="1">
                <BankAccounts
                  bankAccounts={firm.bank_accounts}
                  onChangeBankAccount={onChangeBankAccount}
                />
              </TabPane>
              <TabPane tab="Emails" key="2">
                <Emails
                  emails={firm.emails}
                  onChangeEmail={onChangeEmail}
                />
              </TabPane>
            </Tabs>
          </Row>
        </Form>
      </Card>
    );
  }
}

FirmForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  firm: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeShop: PropTypes.func,
  onChangeName: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onChangeSlug: PropTypes.func
};

export default Form.create()(FirmForm);
