const rules = {
  manager: {
    static: [
      "requestForProposal:create"
    ],
    dynamic: {
      "requestForProposal:edit": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      }
    }
  },

  administrator: {
    static: [
      "requestForProposal:create",
      "requestForProposal:delete",
      "requestForProposal:assignee"
    ],
    dynamic: {
      "requestForProposal:edit": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      }
    }
  }
};

export default rules;