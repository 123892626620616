import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  contactSources: [],
  contactSource: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isModalVisible: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CONTACT_SOURCES_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_CONTACT_SOURCES":
      return {
        ...state,
        contactSources: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_CONTACT_SOURCE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_CONTACT_SOURCE":
      return {
        ...state,
        contactSource: action.response.data,
        isLoading: false,
        isModalVisible: true
      };
    case "PATCH_CONTACT_SOURCE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_CONTACT_SOURCE":
      return {
        ...state,
        contactSource: action.response.data,
        isLoading: false,
        isModalVisible: false
      };

    case "POST_CONTACT_SOURCE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_CONTACT_SOURCE":
      return {
        ...state,
        errors: false,
        contactSource: {},
        isLoading: false,
        isModalVisible: false
      };

    case "POST_CONTACT_SOURCE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "CONTACT_SOURCE_ADD":
      return {
        ...state,
        contactSource: {
          name: null
        },
        errors: false,
        isLoading: false,
        isModalVisible: true
      };

    case "DELETE_CONTACT_SOURCE_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_CONTACT_SOURCE":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_CONTACT_SOURCE_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_CONTACT_SOURCE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_CONTACT_SOURCE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "CONTACT_SOURCE_TOGGLE_VISIBLE":
      return {
        ...state,
        isModalVisible: !state.isModalVisible
      };

    case "CONTACT_SOURCE_CHANGE_NAME":
      return update(state, {
        contactSource: {
          name: { $set: action.value }
        }
      });

    default:
      return state;
  }
}
