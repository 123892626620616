import api from "../api";
import download from "downloadjs";
import { history } from "../index";

const Actions = {};

Actions.fetchInvoices = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_INVOICES_REQUEST" });
    const params = getState().invoices.fetchParams;
    api
      .fetch("/invoices", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_INVOICES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_INVOICES_FAILURE", response });
      });
  };
};

Actions.fetchInvoice = (id) => {
  return dispatch => {
    dispatch({ type: "FETCH_INVOICE_REQUEST" });
    api
      .fetch(`/invoices/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_INVOICE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Счет", error);
          });
        }
        dispatch({ type: "FETCH_INVOICE_FAILURE", response });
      });
  };
};

Actions.onFillInWithOrder = (data) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_FILL_IN_WITH_ORDER",
      data: data
    });
    return Promise.resolve();
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "INVOICE_NEW",
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_INVOICE_REQUEST" });
    const invoice = getState().invoices.invoice;
    const invoice_items_attributes = invoice.invoice_items.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        position: item.position,
        product_id: item.product ? item.product.id : null,
        unit_id: item.unit ? item.unit.id : null,
        vat_rate_id: item.vat_rate ? item.vat_rate.id : null,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        amount_vat: item.amount_vat,
        _destroy: item._destroy
      };
    });
    const data = {
      invoice: {
        number: invoice.number,
        date: invoice.date,
        invoice_type: invoice.invoice_type,
        duration: invoice.duration,
        agreement: invoice.agreement,
        agreement_date: invoice.agreement_date,
        additional: invoice.additional,
        vat_included: invoice.vat_included,
        total: invoice.total,
        vat_total: invoice.vat_total,
        advance_percent: invoice.advance_percent,
        paid: invoice.paid,
        payment_amount: invoice.payment_amount,
        payment_percent: invoice.payment_percent,
        ship_address: invoice.ship_address,
        shipping_method_id:invoice.shipping_method ? invoice.shipping_method.id : null,
        shipping_address_id: invoice.shipping_address ? invoice.shipping_address.id : null,
        special_instruction: invoice.special_instruction,
        company_id: invoice.company ? invoice.company.id : null,
        bank_account_id:invoice.bank_account ? invoice.bank_account.id : null,
        assignee_id: invoice.assignee ? invoice.assignee.id : null,
        order_id: invoice.order ? invoice.order.id : null,
        request_proposal_id: invoice.request_proposal ? invoice.request_proposal.id : null,
        invoice_items_attributes: invoice_items_attributes,
      }
    };
    return api
      .patch(`/invoices/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_INVOICE", response });
        api.openNotification("success", "Счет", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Счет", error);
          });
        }
        dispatch({ type: "PATCH_INVOICE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_INVOICE_REQUEST" });
    const invoice = getState().invoices.invoice;
    const invoice_items_attributes = invoice.invoice_items.map(item => {
      return {
        position: item.position,
        product_id: item.product ? item.product.id : null,
        unit_id: item.unit ? item.unit.id : null,
        vat_rate_id: item.vat_rate ? item.vat_rate.id : null,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        amount_vat: item.amount_vat,
        _destroy: item._destroy
      };
    });
    const data = {
      invoice: {
        number: invoice.number,
        date: invoice.date,
        invoice_type: invoice.invoice_type,
        duration: invoice.duration,
        agreement: invoice.agreement,
        agreement_date: invoice.agreement_date,
        additional: invoice.additional,
        vat_included: invoice.vat_included,
        total: invoice.total,
        vat_total: invoice.vat_total,
        advance_percent: invoice.advance_percent,
        paid: invoice.paid,
        payment_amount: invoice.payment_amount,
        payment_percent: invoice.payment_percent,
        ship_address: invoice.ship_address,
        shipping_method_id:invoice.shipping_method ? invoice.shipping_method.id : null,
        shipping_address_id: invoice.shipping_address ? invoice.shipping_address.id : null,
        special_instruction: invoice.special_instruction,
        company_id: invoice.company ? invoice.company.id : null,
        bank_account_id:invoice.bank_account ? invoice.bank_account.id : null,
        assignee_id: invoice.assignee ? invoice.assignee.id : null,
        order_id: invoice.order ? invoice.order.id : null,
        request_proposal_id: invoice.request_proposal ? invoice.request_proposal.id : null,
        invoice_items_attributes: invoice_items_attributes,
      }
    };
    return api
      .post(`/invoices`, data)
      .then(response => {
        dispatch({ type: "POST_INVOICE", response });
        api.openNotification("success", "Счет", "Успешно создан!");
        history.push(`/invoices/${response.data.id}/edit`);
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Счет", error);
          });
        }
        dispatch({ type: "POST_INVOICE_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_INVOICE_REQUEST" });
    return api
      .delete(`/invoices/${id}`)
      .then(response => {
        api.openNotification("success", "Счет", "Успешно удален!");
        dispatch({ type: "DELETE_INVOICE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Счет", error);
          });
        }
        dispatch({
          type: "DELETE_INVOICE_FAILURE"
        });
      });
  };
};


Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_INVOICE_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_INVOICE_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeCompanyParams = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_PARAMS_COMPANY",
      value: value
    });
    return Promise.resolve();
  };
};

// filters
Actions.onChangeFilterAssignee = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_INVOICE_FILTER_ASSIGNEE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_INVOICE_FILTER_PERIOD",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CLEAR_FILTERS",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "INVOICES_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};


Actions.onChangeAssignee = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ASSIGNEE",
      value: value
    });
  };
};

Actions.onChangeCompany = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_COMPANY",
      value: value
    });
  };
};

Actions.onChangeAgreement = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_AGREEMENT",
      value: value
    });
  };
};

Actions.onChangeAgreementDate = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_AGREEMENT_DATE",
      value: value
    });
  };
};

Actions.onChangeAdditional = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ADDITIONAL",
      value: value
    });
  };
};

Actions.onChangeShippingMethod = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_SHIPPING_METHOD",
      value: value
    });
  };
};

Actions.onChangeBankAccount = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_BANK_ACCOUNT",
      value: value
    });
  };
};

Actions.onChangeShippingAddress = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_SHIPPING_ADDRESS",
      value: value
    });
  };
};

Actions.onChangeSpecialInstruction = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_SPECIAL_INSTRUCTION",
      value: value
    });
  };
};

Actions.onChangeDate = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_DATE",
      value: value
    });
  };
};

Actions.onChangeDuration = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_DURATION",
      value: value
    });
  };
};

Actions.onChangeOrder = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ORDER",
      value: value
    });
  };
};

Actions.onChangeRequestProposal = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_REQUEST_PROPOSAL",
      value: value
    });
  };
};

Actions.onChangeNumber = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_NUMBER",
      value: value
    });
  };
};

Actions.onChangePaid = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_PAID",
      value: value
    });
  };
};

Actions.onChangeInvoiceType = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_INVOICE_TYPE",
      value: value
    });
  };
};

Actions.onChangePaymentAmount = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_PAYMENT_AMOUNT",
      value: value
    });
  };
};

Actions.onChangePaymentPercent = value => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_PAYMENT_PERCENT",
      value: value
    });
  };
};


// table
Actions.onAddItem = () => {
  return dispatch => {
    dispatch({
      type: "INVOICE_ADD_ITEM"
    });
  };
};

Actions.onDeleteItem = idx => {
  return dispatch => {
    dispatch({
      type: "INVOICE_DELETE_ITEM",
      idx: idx
    });
  };
};

Actions.onDestroyItem = idx => {
  return dispatch => {
    dispatch({
      type: "INVOICE_DESTROY_ITEM",
      idx: idx
    });
  };
};

Actions.onChangeItemProduct = (id, item) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_PRODUCT",
      id: id,
      item: item
    });
  };
};

Actions.onChangeItemDescription = (id, value) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_DESCRIPTION",
      id: id,
      value: value
    });
  };
};

Actions.onChangeItemUnit = (id, item) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_UNIT",
      id: id,
      item: item
    });
  };
};

Actions.onChangeItemQuantity = (id, value) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_QUANTITY",
      id: id,
      value: value
    });
  };
};

Actions.onChangeItemPrice = (id, value) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_PRICE",
      id: id,
      value: value
    });
  };
};

Actions.onChangeItemVatRate = (id, item) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_VAT_RATE",
      id: id,
      item: item
    });
  };
};

Actions.onChangeItemVat = (id, value) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_VAT",
      id: id,
      value: value
    });
  };
};

Actions.onChangeItemAmount = (id, value) => {
  return dispatch => {
    dispatch({
      type: "INVOICE_CHANGE_ITEM_AMOUNT",
      id: id,
      value: value
    });
  };
};

Actions.downloadReport = (id, number="", stamp = false) => {
  const params = {stamp : stamp}
  api
    .fetchBlob(`/invoices/${id}.pdf`, params)
    .then(response => {
      //console.log(response)
      download(response, `invoice_${number}.pdf`);
    })
    .catch(response => {
      console.log(response.error);
    });
};

export default Actions;
