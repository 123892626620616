import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
const SubMenu = Menu.SubMenu;

function commomRoutes() {
  return [
    {
      active: true,
      path: "/",
      exact: true,
      key: 1,
      name: "Рабочий стол",
      icon: "dashboard",
      divider: false,
      subroutes: [],
    },
    {
      active: true,
      path: null,
      key: 2,
      name: "Продажи",
      icon: "calculator",
      divider: false,
      subroutes: [
        {
          active: true,
          path: "/request_for_proposals",
          key: 21,
          name: "Заявки",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/orders",
          key: 22,
          name: "Заказы",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/invoices",
          key: 23,
          name: "Счета",
          icon: null,
          divider: false,
          subroutes: [],
        },
      ],
    },
    {
      active: true,
      path: null,
      key: 3,
      name: "Производство",
      icon: "build",
      divider: false,
      subroutes: [
        {
          active: true,
          path: "/workOrders",
          key: 31,
          name: "Заказ-наряды",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/purchases",
          key: 32,
          name: "Договоры с поставщиками",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/service_orders",
          key: 33,
          name: "Услуги",
          icon: null,
          divider: false,
          subroutes: [],
        },
      ],
    },
    {
      active: true,
      path: "/companies",
      exact: true,
      key: 4,
      name: "Компании",
      icon: "team",
      divider: false,
      subroutes: [],
    },
    {
      active: true,
      path: "/categories/all/products",
      exact: true,
      key: 5,
      name: "Склад",
      icon: "database",
      divider: false,
      subroutes: [],
    },
    {
      active: true,
      path: "/payments",
      exact: true,
      key: 6,
      name: "Платежи",
      icon: "wallet",
      divider: false,
      subroutes: [],
    },
  ];
}

function reportRoutes() {
  return [
    {
      active: true,
      path: null,
      key: 7,
      name: "Отчеты",
      icon: "bar-chart",
      divider: false,
      subroutes: [
        {
          active: true,
          path: "/reports/sales",
          key: 71,
          name: "Отгрузки по заказам за период",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/shipments_by_categories_pie",
          key: 72,
          name: "Отгрузки по группам оборудования (диаграмма)",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/shipments_by_categories",
          key: 73,
          name: "Отгрузки по группам оборудования",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/shipments_by_categories_own",
          key: 74,
          name: "Отгрузки по группам оборудования (собственные)",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/orders_by_statuses",
          key: 75,
          name: "Статусы по заказам",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: null,
          key: `divider-${76}`,
          name: null,
          icon: null,
          divider: true,
          subroutes: [],
        },
        {
          active: true,
          path: null,
          key: 76,
          name: "Маркетинг",
          icon: "bar-chart",
          divider: false,
          subroutes: [
            {
              active: true,
              path: "/reports/shipments_by_regions",
              key: 761,
              name: "Отгрузки по регионам",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/shipments_by_business_fields",
              key: 762,
              name: "Отгрузки по видам деятельности",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/purchase_funnel",
              key: 763,
              name: "Воронка продаж",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/user_statistics",
              key: 764,
              name: "Статистика по менеджерам",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/loss_reasons",
              key: 765,
              name: "Причины отказа",
              icon: null,
              divider: false,
              subroutes: [],
            },
          ],
        },
        {
          active: true,
          path: null,
          key: 77,
          name: "Денежные средства",
          icon: "bar-chart",
          divider: false,
          subroutes: [
            {
              active: true,
              path: "/reports/inpayments",
              key: 771,
              name: "Поступления ДС",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/plancashflow",
              key: 772,
              name: "Поступления ДС (план)",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/outpayments",
              key: 773,
              name: "Списания ДС",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/planoutpayments",
              key: 774,
              name: "Списания ДС (план)",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/cashflow",
              key: 775,
              name: "Движение денежных средств",
              icon: null,
              divider: false,
              subroutes: [],
            },
            {
              active: true,
              path: "/reports/clientaccounts",
              key: 776,
              name: "Дебиторская задолженность",
              icon: null,
              divider: false,
              subroutes: [],
            },
          ],
        },
        {
          active: true,
          path: null,
          key: `divider-${78}`,
          name: null,
          icon: null,
          divider: true,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/lastshipments",
          key: 78,
          name: "Последние отгрузки (оборудование)",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/planshipments",
          key: 79,
          name: "Плановые отгрузки (оборудование)",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/reports/inventory",
          key: 791,
          name: "Незавершенное производство",
          icon: null,
          divider: false,
          subroutes: [],
        },
      ],
    },
  ];
}

function referenceRoutes() {
  return [
    {
      active: true,
      path: null,
      key: `ref-${1}`,
      name: "Справочники",
      icon: "setting",
      divider: false,
      subroutes: [
        {
          active: true,
          path: "/firms",
          key: `ref-${11}`,
          name: "Организации",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/units",
          key: `ref-${12}`,
          name: "Единицы измерения",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/department",
          key: `ref-${13}`,
          name: "Подразделения",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/users",
          key: `ref-${14}`,
          name: "Пользователи",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/countries",
          key: `ref-${15}`,
          name: "Страны",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/cities",
          key: `ref-${16}`,
          name: "Города",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/contact_sources",
          key: `ref-${17}`,
          name: "Источники контактов",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/business_fields",
          key: `ref-${18}`,
          name: "Сферы деятельности",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/loss_reasons",
          key: `ref-${19}`,
          name: "Причины отказа",
          icon: null,
          divider: false,
          subroutes: [],
        },
        {
          active: true,
          path: "/references/client_accounts",
          key: `ref-${191}`,
          name: "Корректировка расчетов",
          icon: null,
          divider: false,
          subroutes: [],
        },
      ],
    },
  ];
}

function roleRoutes(role) {
  switch (role) {
    case "administrator":
      return commomRoutes().concat(reportRoutes()).concat(referenceRoutes());
    case "manager":
      return commomRoutes().concat(reportRoutes());
    case "viewer":
      return commomRoutes().concat(reportRoutes());
    default:
      return [];
  }
}

function menuLeaf(item = {}) {
  if (item.subroutes.length > 0) {
    return (
      <SubMenu
        key={item.key}
        title={
          <span>
            <Icon type={item.icon} />
            <span>{item.name}</span>
          </span>
        }
      >
        {item.subroutes.map((child) => {
          return menuLeaf(child);
        })}
      </SubMenu>
    );
  } else {
    if (item.active) {
      return !item.divider ? (
        <Menu.Item key={item.key}>
          <Link to={item.path} className="nav-text">
            {item.icon ? <Icon type={item.icon} /> : null}
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      ) : (
        <Menu.Divider key={item.key} />
      );
    }
  }
}

export default function MenuRoutes({ role, collapsed, services }) {
  let routes = roleRoutes(role, services).filter(function (item) {
    return item.active;
  });

  return (
    <Menu
      theme="dark"
      collapsed={collapsed}
      mode="inline"
      defaultSelectedKeys={["1"]}
    >
      {routes.map((item, i) => menuLeaf(item))}
    </Menu>
  );
}
