// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../../actions/businessFields";
import List from "./List";

class BusinessFields extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchBusinessFields());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchBusinessFields());
      });
    });
  };

  onFetchBusinessField = (id) => {
    this.props.dispatch(Actions.fetchBusinessField(id));
  }

  onNew = () => {
    this.props.dispatch(Actions.onNew());
  }

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchBusinessFields()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchBusinessFields()));
  };

  render() {
    const { isLoading, isModalVisible, businessFields, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        isModalVisible={isModalVisible}
        businessFields={businessFields}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onFetchBusinessField={this.onFetchBusinessField}
        onNew={this.onNew}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.businessFields.isLoading,
  isModalVisible: state.businessFields.isModalVisible,
  businessFields: state.businessFields.businessFields,
  meta: state.businessFields.meta,
  fetchParams: state.businessFields.fetchParams
});

export default connect(mapStateToProps)(BusinessFields);
