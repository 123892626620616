// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Tree } from "antd";
import { Row, Col, Card, Button } from "antd";
import Category from "./Category";

const TreeNode = Tree.TreeNode;

function categoryLeaf(item = {}) {
  return (
    <TreeNode
      title={
        <Link to={{ pathname: `/categories/${item.id}/products` }}>
          {item.name}
        </Link>
      }
      key={item.id}
    >
      {item.children.map((child) => {
        return categoryLeaf(child);
      })}
    </TreeNode>
  );
}

class CategoriesTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      selectedCategory: 0,
    };
  }

  // category
  onSelectCategory = (node) => {
    // console.log(Number(node[0]));
    this.setState({ selectedCategory: Number(node[0]) });
  };

  onOpenCategory = (node) => {
    const { selectedCategory } = this.state;
    if (selectedCategory) {
      this.props.fetchCategory(selectedCategory);
    }
    this.setState({ isVisible: true });
  };

  onAddCategory = () => {
    const { selectedCategory } = this.state;
    this.setState(
      {
        isVisible: true,
      },
      () => {
        this.props.onAddCategory(selectedCategory > 0 ? selectedCategory : null);
      }
    );
  };

  onSaveCategory = () => {
    const { category } = this.props;
    // console.log(category);
    this.setState({ isVisible: false }, () => {
      if (category.id) {
        this.props.onSave(category);
      } else {
        this.props.onCreate(category);
      }
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedCategory);
  };

  render() {
    const { isVisible, selectedCategory } = this.state;
    const {
      isCategoryLoading,
      category,
      categories,
      onChangeName,
      onChangePosition,
      onChangeActive,
      onChangeSuggest
    } = this.props;

    const openButton =
      selectedCategory > 0 ? (
        <Button
          key={2}
          onClick={this.onOpenCategory}
          style={{ marginLeft: 10 }}
        >
          Открыть
        </Button>
      ) : null;

    const deleteButton =
      selectedCategory > 0 ? (
        <Button key="1" type="danger" ghost onClick={this.onDelete}>
          Удалить
        </Button>
      ) : null;

    return (
      <Card
        // loading={isLoading}
        title={[
          <Button key={1} onClick={this.onAddCategory}>
            Создать
          </Button>,
          openButton,
        ]}
        extra={deleteButton}
      >
        <Tree
          showLine
          defaultExpandedKeys={["0"]}
          style={{ height: 600, overflow: "auto" }}
          onSelect={this.onSelectCategory}
        >
          <TreeNode
            title={
              <Link to={{ pathname: `/categories/all/products` }}>
                Категории
              </Link>
            }
            key="0"
          >
            {categories.map((item, i) => categoryLeaf(item))};
          </TreeNode>
        </Tree>
        <Category
          isLoading={isCategoryLoading}
          visible={isVisible}
          category={category}
          onSave={this.onSaveCategory}
          onCancel={this.onCancel}
          onChangeName={onChangeName}
          onChangePosition={onChangePosition}
          onChangeActive={onChangeActive}
          onChangeSuggest={onChangeSuggest}
        />
      </Card>
      // <Tree
      //   showLine
      //   // selectedKeys={selectedKeys}
      //   defaultExpandedKeys={["0"]}
      //   onSelect={this.props.onSelectCategory}
      // >
      //   <TreeNode
      //     title={
      //       <Link to={{ pathname: `/categories/all/products` }}>Категории</Link>
      //     }
      //     key="0"
      //   >
      //     {categories.map((item, i) => (
      //       <TreeNode
      //         title={
      //           <Link to={{ pathname: `/categories/${item.id}/products` }}>
      //             {item.name}
      //           </Link>
      //         }
      //         key={item.id}
      //       >
      //         {item.children.map((child, i) => {
      //           return (
      //             <TreeNode
      //               title={
      //                 <Link
      //                   to={{ pathname: `/categories/${child.id}/products` }}
      //                 >
      //                   {child.name}
      //                 </Link>
      //               }
      //               key={`${child.id}`}
      //             />
      //           );
      //         })}
      //         ;
      //       </TreeNode>
      //     ))}
      //     ;
      //   </TreeNode>
      // </Tree>
    );
  }
}

CategoriesTree.propTypes = {
  onSelectCategory: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
};

export default CategoriesTree;
