// @flow
import React, { Component } from "react";
import { Table, Form, Select, Button } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const Option = Select.Option;
const FormItem = Form.Item;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      period,
      onChangePeriod,
      onChangePeriodicity,
      downloadReport
    } = this.props;

    let isFixed = false;
    if (loading === false) {
      isFixed = report[0].data.length > 3 ? true : false
    }
    let colDateFormat = "MMM YYYY";
    if (fetchParams.periodicity == "quarters") {
      colDateFormat = "Qo квартал YYYY";
    }

    let reportData = [];
    if (!loading) {
      report.map(item => {
        reportData.push({
          id: item.id,
          name: item.name,
          position: item.position,
          data: item.data,
          is_group: item.is_group
        });
        item.child_items.map((child, i) => {
          reportData.push({
            id: `${item.id}-${i}`,
            name: child.name,
            position: child.position,
            data: child.data,
            is_group: child.is_group
          });
        });
      });
    }

    let columns = [
      {
        title: "Оборудование",
        render: (product, record) => record.name,
        key: "product_name",
        width: 400,
        fixed: isFixed
      }
    ];

    if (!loading) {
      reportData[0].data.map((item, i) => {
        columns.push({
          title: moment(item.period).format(colDateFormat),
          children: [
            {
              title: "Кол.,шт.",
              render: (quantity, record, index) =>
                reportData[index].data[i].quantity > 0
                  ? accounting.formatNumber(
                      reportData[index].data[i].quantity,
                      0,
                      " "
                    )
                  : "-",
              key: `subcolumn-${i}-even`,
              width: 90,
              align: "center"
            },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <span>Объем продаж,</span>
                  <br />
                  <span>тыс. руб</span>
                </div>
              ),
              render: (amount, record, index) =>
                reportData[index].data[i].amount > 0
                  ? accounting.formatNumber(
                      reportData[index].data[i].amount / 1000,
                      2,
                      " "
                    )
                  : "-",
              key: `subcolumn-${i}-odd`,
              width: 150,
              align: "center"
            }
          ]
        });
      });
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Отгрузки по группам оборудования`}
        extra={[
          <Button key="3" type="dashed" icon="file-excel" onClick={downloadReport}>Загрузить</Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="id"
                scroll={{ x: "max-content" }}
                rowClassName={record =>
                  record.is_group ? "ant-table-total-row" : null
                }
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Form layout={"vertical"}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormItem label={"Период"}>
                {getFieldDecorator("period", {
                  initialValue: period,
                  onChange: onChangePeriod
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Период"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={1}>этот месяц</Option>
                    <Option value={2}>этот квартал</Option>
                    <Option value={3}>этот год</Option>
                    <Option value={4}>прошлый месяц</Option>
                    <Option value={5}>прошлый квартал</Option>
                    <Option value={6}>прошлый год</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormItem label={"Периодичность"}>
                {getFieldDecorator("periodicity", {
                  initialValue: fetchParams.periodicity,
                  onChange: onChangePeriodicity
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Периодичность"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={"months"}>месяц</Option>
                    <Option value={"quarters"}>квартал</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Form>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(DataList);
