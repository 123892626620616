import update from "immutability-helper";
import moment from "moment";
moment.locale("ru");

const initialState = {
  sales: [],
  totals: {subcontracts: 0, materials: 0},
  payments: [],
  schedules: [],
  categories: [],
  companies: { debt: [], count: 0 },
  orders: { series: [], count: 0 },
  invoices: { series: [], count: 0 },
  fetchParams: {
    startDate: moment()
      .add(-1, "months")
      .add(1, "days")
      .format("DD.MM.YYYY"),
    endDate: moment().format("DD.MM.YYYY")
  },
  errors: false,
  isCompaniesLoading: true,
  isOrdersLoading: true,
  isInvoicesLoading: true,
  isCategoriesLoading: true,
  isSalesLoading: true,
  isPaymentsLoading: true,
  isSchedulesLoading: true
};

function recalcCategories(data) {
  const categories = [];
  data.map((item, i) => {
    return categories.push({
      item: item.name,
      count: Number(item.amount)
    });
  });
  return categories;
}

export default function(state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD_FETCH_COMPANIES_REQUEST":
      return {
        ...state,
        isCompaniesLoading: true
      };

    case "DASHBOARD_FETCH_COMPANIES":
      return update(state, {
        companies: { $set: action.response.data },
        isCompaniesLoading: { $set: false }
      });

    case "DASHBOARD_FETCH_COMPANIES_FAILURE":
      return {
        ...state,
        isCompaniesLoading: false
      };

    case "DASHBOARD_FETCH_ORDERS_REQUEST":
      return {
        ...state,
        isOrdersLoading: true
      };

    case "DASHBOARD_FETCH_ORDERS":
      return update(state, {
        orders: { $set: action.response.data },
        isOrdersLoading: { $set: false }
      });

    case "DASHBOARD_FETCH_ORDERS_FAILURE":
      return {
        ...state,
        isOrdersLoading: false
      };

    case "DASHBOARD_FETCH_INVOICES_REQUEST":
      return {
        ...state,
        isInvoicesLoading: true
      };

    case "DASHBOARD_FETCH_INVOICES":
      return update(state, {
        invoices: { $set: action.response.data },
        isInvoicesLoading: { $set: false }
      });

    case "DASHBOARD_FETCH_INVOICES_FAILURE":
      return {
        ...state,
        isInvoicesLoading: false
      };

    case "DASHBOARD_FETCH_SALES_REQUEST":
      return {
        ...state,
        isSalesLoading: true
      };

    case "DASHBOARD_FETCH_SALES":
      return update(state, {
        sales: { $set: action.response.data },
        totals: { $set: action.response.totals },
        isSalesLoading: { $set: false }
      });

    case "DASHBOARD_FETCH_SALES_FAILURE":
      return {
        ...state,
        isSalesLoading: false
      };

    case "DASHBOARD_FETCH_PAYMENTS_REQUEST":
      return {
        ...state,
        isPaymentsLoading: true
      };

    case "DASHBOARD_FETCH_PAYMENTS":
      return update(state, {
        payments: { $set: action.response.data },
        isPaymentsLoading: { $set: false }
      });

    case "DASHBOARD_FETCH_PAYMENTS_FAILURE":
      return {
        ...state,
        isPaymentsLoading: false
      };

    case "DASHBOARD_FETCH_SCHEDULES_REQUEST":
      return {
        ...state,
        isSchedulesLoading: true
      };
    case "DASHBOARD_FETCH_SCHEDULES":
      return update(state, {
        schedules: { $set: action.response.data },
        isSchedulesLoading: { $set: false }
      });

    case "DASHBOARD_FETCH_SCHEDULES_FAILURE":
      return {
        ...state,
        isSchedulesLoading: true,
        errors: false
      };

    case "DASHBOARD_FETCH_CATEGORIES_REQUEST":
      return {
        ...state,
        isCategoriesLoading: true,
        errors: false
      };

    case "DASHBOARD_FETCH_CATEGORIES":
      return update(state, {
        categories: { $set: recalcCategories(action.response.data) },
        isCategoriesLoading: { $set: false },
        errors: { $set: false }
      });

    case "DASHBOARD_FETCH_CATEGORIES_FAILURE":
      return {
        ...state,
        isCategoriesLoading: false,
        errors: true
      };

    case "DASHBOARD_CHANGE_DATE_RANGE":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate }
        }
      });

    default:
      return state;
  }
}
