// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  List,
  Card,
  Select,
  Form,
  Input,
  InputNumber,
  DatePicker
} from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      getFieldDecorator,
      invoice,
      invoiceItems,
      onChangeDate,
      onChangeDuration,
      onChangeOrder,
      onChangeRequestProposal,
      onChangeNumber,
      onChangePaid,
      onChangeInvoiceType,
      onChangePaymentAmount,
      onChangePaymentPercent
    } = this.props;

    const total = invoiceItems.reduce(
      (sum, record) => sum + Number(record.amount) + Number(record.amount_vat),
      0
    );
    // const advance_percent = Number(invoice.advance_percent);
    const subtotal = Number(total) - Number(invoice.paid)
    let payment    = 0;

      if (Number(invoice.payment_percent) > 0) {
        payment = Number((subtotal / 100) * invoice.payment_percent).toFixed(2);
      } else if (Number(invoice.payment_amount) > 0) {
        payment = subtotal - Number(invoice.payment_amount);
      }

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem label={"Дата счета"}>
              {getFieldDecorator("date", {
                onChange: onChangeDate,
                initialValue: moment(invoice.date)
              })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
            </FormItem>
          </Col>

          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Номер"}>
              {getFieldDecorator("number", {
                onChange: onChangeNumber,
                initialValue: invoice.number
              })(
                <Input
                  disabled={invoice.id ? false : true}
                  placeholder={
                    invoice.id ? "" : "установливается автоматически"
                  }
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label={"Заказ"}>
              {getFieldDecorator(
                "order",
                {}
              )(
                <SelectFetch
                  showSearch
                  placeholder="Заказ"
                  onChange={onChangeOrder}
                  selected={invoice.order ? invoice.order : null}
                  url={
                    invoice.company
                      ? `/orders/search/?company_id=${invoice.company.id}`
                      : `/orders/search?`
                  }
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label={"Заявка"}>
              {getFieldDecorator(
                "request_proposal",
                {}
              )(
                <SelectFetch
                  showSubtitle
                  showSearch
                  placeholder="Заявка"
                  onChange={onChangeRequestProposal}
                  selected={invoice.request_proposal}
                  url={
                    invoice.company
                      ? `/request_proposals/search/?company_id=${invoice.company.id}`
                      : `/request_proposals/search?`
                  }
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label={"Срок оплаты (дн.)"}>
              {getFieldDecorator("duration", {
                onChange: onChangeDuration,
                initialValue: invoice.duration
              })(
                <InputNumber style={{ width: "100%" }} precision={0} min={0} />
              )}
            </FormItem>
          </Col>

          <Col span={24} style={{ paddingBottom: "10px" }} />
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "60%" }}>Всего по счету</td>
                <td style={{ width: "40%", textAlign: "right" }}>
                  <InputNumber
                    disabled
                    style={{ width: "100%", color: "rgba(0, 0, 0, 0.65)" }}
                    precision={2}
                    min={0}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }
                    value={total}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Получен аванс</td>
                <td style={{ width: "40%", textAlign: "right" }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    precision={2}
                    min={0}
                    value={invoice.paid}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }
                    onChange={onChangePaid}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Вид оплаты</td>
                <td style={{ width: "40%", textAlign: "right" }}>
                  <Select
                    value={invoice.invoice_type}
                    placeholder="Вид оплаты"
                    optionFilterProp="children"
                    filterOption={false}
                    onChange={onChangeInvoiceType}
                  >
                    <Option value={1}>аванс</Option>
                    <Option value={2}>к оплате</Option>
                    <Option value={3}>окончательная оплата</Option>
                    <Option value={4}>произвольный</Option>
                  </Select>
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Сумма к оплате</td>
                <td style={{ width: "40%", textAlign: "right" }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    precision={2}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }
                    value={invoice.payment_amount}
                    onChange={onChangePaymentAmount}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Процент к оплате, %</td>
                <td style={{ width: "40%", textAlign: "right" }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace("%", "")}
                    value={invoice.payment_percent}
                    onChange={onChangePaymentPercent}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>К оплате</td>
                <td style={{ width: "40%", textAlign: "right" }}>
                  <InputNumber
                    disabled
                    style={{ width: "100%", color: "rgba(0, 0, 0, 0.65)" }}
                    precision={2}
                    value={payment}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Col span={24} />
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  invoice: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Statuses;
