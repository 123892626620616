// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment().startOf("month").format("DD.MM.YYYY"),
        endDate: moment().endOf("month").format("DD.MM.YYYY"),
      },
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
  };

  onClose = () => {
    this.props.history.push(`/reports/cashflow`);
  };

  onChangePeriod = (value) => {
    const startDate = moment(value).startOf("month").format("DD.MM.YYYY");
    const endDate = moment(value).endOf("month").format("DD.MM.YYYY");
    this.setState(
      {
        fetchParams: { startDate: startDate, endDate: endDate },
      },
      () => {
        this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
      }
    );
  };
  // cashFlowItems
  onSaveCashFlowItem = (item) => {
    if (item.id) {
      this.props.dispatch(Actions.onUpdateCashFlowItem(item)).then(() => {
        this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
      });
    } else {
      this.props.dispatch(Actions.onCreateCashFlowItem(item)).then(() => {
        this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
      });
    }
  };

  onDeleteCashFlowItem = (id) => {
    this.props.dispatch(Actions.onDeleteCashFlowItem(id)).then(() => {
      this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
    });
  };

  // cashFlowValues
  onSaveCashFlowValue = (item) => {
    this.props.dispatch(Actions.onSaveCashFlowValue(item)).then(() => {
      this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
    });
  };

  onDeleteCashFlowValues = (cashFlowItemId) => {
    const startDate = this.state.fetchParams.startDate
    const endDate = this.state.fetchParams.endDate
    this.props.dispatch(Actions.onDeleteCashFlowValues(cashFlowItemId, startDate, endDate)).then(() => {
      this.props.dispatch(Actions.fetchCashflowItems(this.state.fetchParams));
    });
  };

  render() {
    const { isLoading, cashFlowItems } = this.props;
    const { fetchParams } = this.state;
    return (
      <DataList
        loading={isLoading}
        cashFlowItems={cashFlowItems}
        fetchParams={fetchParams}
        onClose={this.onClose}
        onChangePeriod={this.onChangePeriod}
        onSaveCashFlowItem={this.onSaveCashFlowItem}
        onDeleteCashFlowItem={this.onDeleteCashFlowItem}
        onSaveCashFlowValue={this.onSaveCashFlowValue}
        onDeleteCashFlowValues={this.onDeleteCashFlowValues}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  cashFlowItems: state.reports.cashFlowItems,
});

export default connect(mapStateToProps)(Settings);
