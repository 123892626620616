import React from "react";
import { Chart, Axis, Geom, Tooltip } from "bizcharts";
import accounting from "accounting";

export default class Bar extends React.Component {
  render() {
    const {
      height,
      forceFit = true,
      data = []
    } = this.props;

    const cols = {
      sales: { tickInterval: 500 },
      total: {
        alias: 'сумма',
        formatter: function (val) {
          return `${accounting.formatNumber(val, 0, " ")} руб.`;
        }
      }
    };

    const tickLine = {
      lineWidth: 1, // 刻度线宽
      stroke: '#ccc', // 刻度线的颜色
      length: 5, // 刻度线的长度, **原来的属性为 line**,可以通过将值设置为负数来改变其在轴上的方向
    }

    return (
      <Chart height={height} padding={'auto'} data={data} scale={cols} forceFit={forceFit}>
        <Axis name="period" visible={true} tickLine={tickLine}/>
        <Axis name="total" />
        <Tooltip crosshairs={{ type: "y" }} />
        <Geom type="interval" position="period*total" />
      </Chart>
    );
  }
}
