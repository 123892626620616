import React, { Component } from "react";
import { Form, Input, Col, Row } from "antd";
const FormItem = Form.Item;

class EmailForm extends React.Component {
  render() {
    const { getFieldDecorator, email, onChangeEmail } = this.props;
    return (
      <Row gutter={16}>
        <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
          <FormItem label={"Email"}>
            {getFieldDecorator("email", {
              onChange: onChangeEmail,
              initialValue: email.email,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите email!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

export default EmailForm;
