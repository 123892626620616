import api from "../api";
import download from "downloadjs";

const Actions = {};

Actions.fetchRequestForProposals = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_REQUEST_FOR_PROPOSALS_REQUEST" });
    const params = getState().requestForProposals.fetchParams;
    let assignees = [];
    if (params.assignees) {
      assignees = params.assignees.map((assignee) => {
        if (assignee && assignee.id) {
          return assignee.id;
        }
      });
    }

    let contact_types = [];
    if (params.contact_types) {
      contact_types = params.contact_types.map((contact_type) => {
        if (contact_type && contact_type.id) {
          return contact_type.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
      page: params.page,
      limit: params.limit,
      statuses: params.statuses,
      contact_types: contact_types,
      assignees: assignees,
      is_paused: params.is_paused,
    };
    return api
      .fetch("/request_proposals", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REQUEST_FOR_PROPOSALS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REQUEST_FOR_PROPOSALS_FAILURE", response });
      });
  };
};

Actions.fetchRequestForProposal = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REQUEST_FOR_PROPOSAL_REQUEST" });
    api
      .fetch(`/request_proposals/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_REQUEST_FOR_PROPOSAL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "FETCH_REQUEST_FOR_PROPOSAL_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_REQUEST_FOR_PROPOSAL_REQUEST" });
    const requestForProposal =
      getState().requestForProposals.requestForProposal;

    const data = {
      request_proposal: {
        status: requestForProposal.status ? requestForProposal.status.id : null,
        date: requestForProposal.date,
        notify_date: requestForProposal.notify_date,
        company_id: requestForProposal.company
          ? requestForProposal.company.id
          : null,
        contact_type_id: requestForProposal.contact_type
          ? requestForProposal.contact_type.id
          : null,
        contact_source_id: requestForProposal.contact_source
          ? requestForProposal.contact_source.id
          : null,
        subject: requestForProposal.subject,
        description: requestForProposal.description,
        assignee_id: requestForProposal.assignee
          ? requestForProposal.assignee.id
          : null,
      },
    };

    return api
      .post(`/request_proposals`, data)
      .then((response) => {
        api.openNotification("success", "Заявка", "Успешно создана!");
        dispatch({ type: "POST_REQUEST_FOR_PROPOSAL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "POST_REQUEST_FOR_PROPOSAL_FAILURE", response });
      });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_REQUEST" });
    const requestForProposal =
      getState().requestForProposals.requestForProposal;

    const data = {
      request_proposal: {
        date: requestForProposal.date,
        notify_date: requestForProposal.notify_date,
        company_id: requestForProposal.company
          ? requestForProposal.company.id
          : null,
        contact_type_id: requestForProposal.contact_type
          ? requestForProposal.contact_type.id
          : null,
        contact_source_id: requestForProposal.contact_source
          ? requestForProposal.contact_source.id
          : null,
        subject: requestForProposal.subject,
        description: requestForProposal.description,
        assignee_id: requestForProposal.assignee
          ? requestForProposal.assignee.id
          : null,
      },
    };

    return api
      .patch(`/request_proposals/${id}`, data)
      .then((response) => {
        api.openNotification("success", "Заявка", "Успешно обновлена!");
        dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_REQUEST_FOR_PROPOSAL_REQUEST" });
    return api
      .delete(`/request_proposals/${id}`)
      .then((response) => {
        api.openNotification("success", "Заявка", "Успешно удален!");
        dispatch({ type: "DELETE_REQUEST_FOR_PROPOSAL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "DELETE_REQUEST_FOR_PROPOSAL_FAILURE",
        });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/request_proposals/${id}/request_proposal_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_UPLOAD",
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    return api
      .postFormData(
        `/request_proposals/${id}/request_proposal_attachments`,
        formData
      )
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (request_proposal_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(
        `/request_proposals/${request_proposal_id}/request_proposal_attachments/${id}`
      )
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_NEW",
    });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REQUEST_FOR_PROPOSAL_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REQUEST_FOR_PROPOSAL_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

// General
Actions.fetchSatatuses = (value) => {
  return (dispatch) => {
    dispatch({ type: "REQUEST_FOR_PROPOSAL_STATUSES_REQUEST" });
    api
      .fetch(`/request_proposals/statuses`, { value: value.id })
      .then((response) => {
        dispatch({ type: "REQUEST_FOR_PROPOSAL_STATUSES_SUCCESS", response });
      })
      .catch((response) => {
        dispatch({
          type: "REQUEST_FOR_PROPOSAL_STATUSES_REQUEST_FAILURE",
          response,
        });
      });
  };
};

Actions.onChangeStatus = (id, status) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_REQUEST_STATUS" });
    const data = { status: status };

    return api
      .patch(`/request_proposals/${id}/status`, data)
      .then((response) => {
        api.openNotification("success", "Заявка", "Статус изменен!");
        dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_STATUS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "PATCH_REQUEST_FOR_PROPOSAL_FAILURE_STATUS",
          response,
        });
      });
  };
};

Actions.onChangeStatusToFail = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_STATUS_TO_FAIL",
      value: value,
    });
  };
};

Actions.onChangeLossReason = (id, lossReasonId) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_REQUEST_LOSS_REASON" });
    const data = { loss_reason_id: lossReasonId };

    return api
      .patch(`/request_proposals/${id}/loss_reason`, data)
      .then((response) => {
        api.openNotification("success", "Заявка", "Статус изменен на отказ!");
        dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_LOSS_REASON", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "PATCH_REQUEST_FOR_PROPOSAL_FAILURE_LOSS_REASON",
          response,
        });
      });
  };
};

Actions.onChangePaused = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_REQUEST_PAUSED" });
    const data = { is_paused: value };

    return api
      .patch(`/request_proposals/${id}/paused`, data)
      .then((response) => {
        api.openNotification(
          "success",
          "Заявка",
          "Отметка о переносе изменена!"
        );
        dispatch({ type: "PATCH_REQUEST_FOR_PROPOSAL_PAUSED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "PATCH_REQUEST_FOR_PROPOSAL_FAILURE_PAUSED",
          response,
        });
      });
  };
};

Actions.onChangeDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_DATE",
      value: value,
    });
  };
};

Actions.onChangeNotifyDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_NOTIFY_DATE",
      value: value,
    });
  };
};

Actions.onChangeCompany = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_COMPANY",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeContactType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_CONTACT_TYPE",
      value: value,
    });
  };
};

Actions.onChangeContactSource = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_CONTACT_SOURCE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeSubject = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_SUBJECT",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeAssignee = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_ASSIGNEE",
      value: value,
    });
  };
};

// events
Actions.fetchRequestForProposalEvents = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REQUEST_FOR_PROPOSAL_EVENTS_REQUEST" });
    return api
      .fetch(`/request_proposals/${id}/request_proposal_events`)
      .then((response) => {
        dispatch({ type: "FETCH_REQUEST_FOR_PROPOSAL_EVENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "FETCH_REQUEST_FOR_PROPOSAL_EVENTS_FAILURE",
          response,
        });
      });
  };
};

Actions.onCreateEvent = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_REQUEST_FOR_PROPOSAL_EVENT_REQUEST" });
    const event = getState().requestForProposals.event;
    const data = {
      request_proposal_event: {
        event_type: event.event_type,
        description: event.description,
        notify_date: event.notify_date,
      },
    };
    return api
      .post(`/request_proposals/${id}/request_proposal_events`, data)
      .then((response) => {
        api.openNotification("success", "Заявка", "Событие успешно создано!");
        dispatch({ type: "POST_REQUEST_FOR_PROPOSAL_EVENT", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "POST_REQUEST_FOR_PROPOSAL_EVENT_FAILURE", response });
      });
  };
};

Actions.onAddEvent = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_ADD_EVENT",
    });
  };
};

Actions.onCancelEvent = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CANCEL_EVENT",
    });
  };
};

Actions.onChangeEventType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_EVENT_TYPE",
      value: value,
    });
  };
};

Actions.onChangeDescriptionEvent = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_EVENT_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeEventNotifyDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CHANGE_EVENT_NOTIFY_DATE",
      value: value,
    });
  };
};

// products
Actions.fetchRequestForProposalItems = (id) => {
  return (dispatch) => {
    dispatch({ type: "REQUEST_FOR_PROPOSAL_ITEMS" });
    return api
      .fetch(`/request_proposals/${id}/request_proposal_items`)
      .then((response) => {
        dispatch({ type: "REQUEST_FOR_PROPOSAL_ITEMS_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "REQUEST_FOR_PROPOSAL_ITEMS_FAILURE",
          response,
        });
      });
  };
};

Actions.onCreateProduct = (id, product_id) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_REQUEST_FOR_PROPOSAL_PRODUCT" });
    const data = {
      request_proposal_item: {
        product_id: product_id,
      },
    };
    return api
      .post(`/request_proposals/${id}/request_proposal_items`, data)
      .then((response) => {
        api.openNotification(
          "success",
          "Заявка",
          "Продукция успешно добавлена!"
        );
        dispatch({
          type: "POST_REQUEST_FOR_PROPOSAL_PRODUCT_SUCCEED",
          response,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "POST_REQUEST_FOR_PROPOSAL_EVENT_PRODUCT_FAILURE",
          response,
        });
      });
  };
};

Actions.onDeleteProduct = (id, product_id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_REQUEST_FOR_PROPOSAL_PRODUCT" });
    return api
      .delete(`/request_proposals/${id}/request_proposal_items/${product_id}`)
      .then((response) => {
        api.openNotification("success", "Заявка", "Продукция успешно удалена!");
        dispatch({
          type: "DELETE_REQUEST_FOR_PROPOSAL_PRODUCT_SUCCEED",
          response,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "DELETE_REQUEST_FOR_PROPOSAL_PRODUCT_FAILURE",
        });
      });
  };
};

Actions.onAddProduct = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_ADD_PRODUCT",
    });
  };
};

Actions.onCancelProduct = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSAL_CANCEL_PRODUCT",
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterAssignees = (values) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_FILTER_ASSIGNEES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatuses = (values) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_FILTER_STATUS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterContactTypes = (values) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_FILTER_CONTACT_TYPE",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPaused = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FOR_PROPOSALS_FILTER_PAUSED",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.downloadXLSReport = (params, url = "", filename = "") => {
  let assignees = [];
  if (params.assignees) {
    assignees = params.assignees.map((assignee) => {
      if (assignee && assignee.id) {
        return assignee.id;
      }
    });
  }

  let contact_types = [];
  if (params.contact_types) {
    contact_types = params.contact_types.map((contact_type) => {
      if (contact_type && contact_type.id) {
        return contact_type.id;
      }
    });
  }

  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    assignees: assignees,
    statuses: params.statuses,
    contact_types: contact_types,
    company: params.company,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      download(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
