import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

const Order = ({ isLoading, orders, ...rest }) => {
  const columns = [
    {
      title: "Заказ",
      dataIndex: "order_number",
      render: (number, record) => (
        <Link to={`/orders/${record.id}/edit`}>{number}</Link>
      ),
      align: "center",
      width: "15%"
    },
    {
      title: "Компания",
      dataIndex: "company_name",
      render: (company_name, record) => (
        <Link to={`/companies/${record.company_id}/edit`}>{company_name}</Link>
      ),
      align: "center",
      width: "20%"
    },
    {
      title: "Дата",
      dataIndex: "order_date",
      render: (order_date, record) => (`${moment(order_date).format('LLL')}`),
      align: "center",
      width: "15%"
    },
    {
      title: "Продукция",
      dataIndex: "product_name",
      width: "25%"
    },
    {
      title: "Кол-во",
      dataIndex: "quantity",
      align: "right",
      width: "10%"
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      render: (amount, record) => (accounting.formatNumber(amount, 0, " ")),
      align: "right",
      width: "15%"
    }
  ];

  orders.map((order, i) => {
    order.key = i
  })

  return orders.length > 0 ? (
    <Table
      loading={isLoading}
      style={{background: "white"}}
      columns={columns}
      dataSource={orders}
      size="small"
      pagination={false}
      rowKey="key"
    />
  ) : null;
};

export default Order;
