// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

class Payments extends Component {
  state = { isOpen: false };

  onSelectOption = item => {
    this.onCloseDropdown();
    this.props.onAddItem(item);
  };

  render() {
    const {
      orderPayments
    } = this.props;

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        key: "date",
        render: (date, record) => (
          <div>
            <div>{moment(date).format("L")}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Номер",
        dataIndex: "number",
        key: "number",
        render: (number, record) => (
          <div>
            <div>{number}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Номер п/п",
        dataIndex: "payment_number",
        key: "payment_number",
        render: (payment_number, record) => (
          <div>
            <div>{payment_number}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Счет",
        dataIndex: "invoice",
        key: "invoice",
        render: (invoice, record) => (
          <div>
           
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        key: "amount",
        render: (amount, record) =>
          accounting.formatNumber(
            Number(amount),
            0,
            " "
          ),
        width: "20%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={orderPayments}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Payments.propTypes = {
  orderPayments: PropTypes.arrayOf(PropTypes.object)
};
export default Payments;
