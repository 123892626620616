// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment()
          .startOf("month")
          .format("DD.MM.YYYY"),
        endDate: moment().format("DD.MM.YYYY"),
        financing: null,
        department: null,
        page: 1,
        limit: 100
      },
      period: 0
    };
  }

  componentWillMount = () => {
    // console.log("Test")
    this.props.dispatch(
      Actions.fetchShipmentsByRegions(this.state.fetchParams)
    );
  };

  onChangeDateRange = dates => {
    this.state.fetchParams.startDate = moment(dates[0]).format("L");
    this.state.fetchParams.endDate = moment(dates[1]).format("L");
    this.setState(
      { fetchParams: this.state.fetchParams, period: 0 },
      function() {
        this.props.dispatch(
          Actions.fetchShipmentsByRegions(this.state.fetchParams)
        );
      }
    );
  };

  onChangePeriod = value => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 1:
        startDate = moment()
          .startOf("months")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 2:
        startDate = moment()
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 3:
        startDate = moment()
          .startOf("years")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "years")
          .endOf("year")
          .format("DD.MM.YYYY");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
    }
    this.state.fetchParams.startDate = startDate;
    this.state.fetchParams.endDate = endDate;
    this.setState(
      { fetchParams: this.state.fetchParams, period: value },
      function() {
        this.props.dispatch(
          Actions.fetchShipmentsByRegions(this.state.fetchParams)
        );
      }
    );
  };

  onChangePage = value => {
    this.state.fetchParams.page = value;
    this.setState({ fetchParams: this.state.fetchParams }, function() {
      this.props.dispatch(
        Actions.fetchShipmentsByRegions(this.state.fetchParams)
      );
    });
  };

  onChangeLimit = (current, size) => {
    this.state.fetchParams.limit = size;
    this.setState({ fetchParams: this.state.fetchParams }, function() {
      this.props.dispatch(
        Actions.fetchShipmentsByRegions(this.state.fetchParams)
      );
    });
  };

  downloadReport = () => {
    Actions.downloadReport(
      this.state.fetchParams,
      "shipments_by_regions.xlsx",
      "shipments_by_regions.xlsx"
    );
  };

  render() {
    const { isLoading, isLoadingInfo, report } = this.props;
    const { fetchParams, period } = this.state;
    return (
      <DataList
        loading={isLoading}
        report={report}
        fetchParams={fetchParams}
        period={period}
        onChangeDateRange={this.onChangeDateRange}
        onChangePeriod={this.onChangePeriod}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.reports.isLoading,
  isLoadingInfo: state.reports.isLoading,
  report: state.reports.shipmentsByRegions
});

export default connect(mapStateToProps)(Report);
