import React from "react";

import "./style.css";

const Field = ({ label, value, color,  ...rest }) => (
  <div className={"field"} {...rest}>
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

export default Field;
