// @flow
import React, { Component } from "react";
import { Table, DatePicker, Select, Button } from "antd";
import { Row, Col, PageHeader } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";
    

    const columns = [
      {
        title: "Причина",
        dataIndex: "loss_reason_name",
        align: "left",
        width: "30%",
      },
      {
        title: "Заявки (кол-во)",
        dataIndex: "qty",
        align: "right",
        width: "15%",
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Статистика по причинам отказа c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={report}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
