// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Icon,
  Card,
  Select,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      getFieldDecorator,
      isLoadingWorkOrder,
      order,
      onChangeDate,
      onChangeStatus,
      onChangeSourceType,
      onChangeRequestProposal,
      onChangeNumber,
      onCreateWorkOrder,
    } = this.props;

    const addonButton = order.work_order ? (
      <Tooltip placement="topLeft" title={"Открыть заказ-наряд"}>
        <Link to={`/workOrders/${order.work_order.id}/edit`}>
          <Icon type="form" />
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={"Создать заказ-наряд"}>
        <Icon
          type={isLoadingWorkOrder ? "loading" : "plus"}
          style={{ cursor: "pointer" }}
          onClick={onCreateWorkOrder}
        />
      </Tooltip>
    );

    const requestProposalLabel = order.request_proposal ? (
      <Tooltip placement="topLeft" title={"Открыть заявку"}>
        <Link
          to={`/request_for_proposals/${order.request_proposal.id}/edit`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Заявка
        </Link>
      </Tooltip>
    ) : (
      "Заявка"
    );

    const total = order.order_items.reduce(
      (sum, record) => sum + Number(record.amount) + Number(record.amount_vat),
      0
    );
    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem label={"Дата заказа"}>
              {getFieldDecorator("date", {
                onChange: onChangeDate,
                initialValue: moment(order.date),
                rules: [
                  {
                    required: true,
                    message: "Введите дату!",
                  },
                ],
              })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Номер"}>
              {getFieldDecorator("number", {
                onChange: onChangeNumber,
                initialValue: order.number,
                rules: [
                  {
                    required: true,
                    message: "Введите номер!",
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                initialValue: order.status,
                onChange: onChangeStatus,
                rules: [
                  {
                    required: true,
                    message: "Введите статус!",
                  },
                ],
              })(
                <Select
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"approving"}>согласование</Option>
                  <Option value={"working"}>в работе</Option>
                  <Option value={"for_shipping"}>к отгрузке</Option>
                  <Option value={"shipped"}>отгружен</Option>
                  <Option value={"closed"}>закрыт</Option>
                  <Option value={"canceled"}>отменен</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label={"Источник"}>
              {getFieldDecorator("source_type", {
                initialValue: order.source_type,
                onChange: onChangeSourceType,
                rules: [
                  {
                    required: true,
                    message: "Введите источник!",
                  },
                ],
              })(
                <Select
                  placeholder="Источник"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"request"}>заявка</Option>
                  <Option value={"tender"}>тендер</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          {order.id ? (
            <Col md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"Заказ-наряд"}>
                {getFieldDecorator("work_order", {
                  initialValue: order.work_order ? order.work_order.text : "",
                })(<Input readOnly addonAfter={addonButton} />)}
              </FormItem>
            </Col>
          ) : null}

          <Col span={12}>
            <FormItem label={requestProposalLabel}>
              {getFieldDecorator(
                "request_proposal",
                {}
              )(
                <SelectFetch
                  showSubtitle
                  showSearch
                  placeholder="Заявка"
                  onChange={onChangeRequestProposal}
                  selected={order.request_proposal}
                  url={
                    order.company
                      ? `/request_proposals/search/?company_id=${order.company.id}`
                      : `/request_proposals/search?`
                  }
                />
              )}
            </FormItem>
          </Col>

          <Col span={24} style={{ paddingBottom: "10px" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "65%" }}>Всего по заказу</td>
                  <td
                    style={{ width: "35%", textAlign: "right" }}
                  >{`${accounting.formatNumber(total, 2, " ", ",")} руб.`}</td>
                </tr>
                <tr>
                  <td style={{ width: "65%" }}>Оплачено</td>
                  <td
                    style={{ width: "35%", textAlign: "right" }}
                  >{`${accounting.formatNumber(
                    order.paid,
                    2,
                    " ",
                    ","
                  )} руб.`}</td>
                </tr>
                <tr>
                  <td style={{ width: "65%" }}>Субподряд</td>
                  <td
                    style={{ width: "35%", textAlign: "right" }}
                  >{`${accounting.formatNumber(
                    order.subcontracts,
                    2,
                    " ",
                    ","
                  )} руб.`}</td>
                </tr>
                <tr>
                  <td style={{ width: "65%" }}>Материалы</td>
                  <td
                    style={{ width: "35%", textAlign: "right" }}
                  >{`${accounting.formatNumber(
                    order.materials,
                    2,
                    " ",
                    ","
                  )} руб.`}</td>
                </tr>
                <tr>
                  <td style={{ width: "65%" }}>Транспорт</td>
                  <td
                    style={{ width: "35%", textAlign: "right" }}
                  >{`${accounting.formatNumber(
                    order.transports,
                    2,
                    " ",
                    ","
                  )} руб.`}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col span={24} style={{ paddingBottom: "10px" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "35%" }}>Счета</td>
                  <td style={{ width: "65%", textAlign: "right" }}>
                    {order.invoices.map((invoice) => (
                      <Link
                        to={`/invoices/${invoice.id}/edit`}
                      >{`№${invoice.number} `}</Link>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  order: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangeStatus: PropTypes.func,
};

export default Statuses;
