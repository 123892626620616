import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import _ from "lodash/array";

const Option = Select.Option;

class EditableSelectCell extends React.Component {
  constructor(props) {
    super(props);
    this.onEscFunction = this.onEscFunction.bind(this);
  }

  static defaultProps = {
    placeholder: "Выберите значение",
    items: [],
    highlight: false
  };

  state = {
    editing: false
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  componentWillUnmount() {
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  onEscFunction(e) {
    const { editing } = this.state;
    if (editing && this.cell.contains(e.target) && e.keyCode === 27) {
      this.toggleEdit();
    }
  }

  onSelect = (value) => {
    const { recordId, items, onChange } = this.props;
    let idx = _.findIndex(items, ["value", value]);
    if (idx === -1) {
      return null;
    }
    this.toggleEdit();
    onChange(recordId, items[idx]);
  };


  onBlur = () => {
    this.toggleEdit();
  };

  render() {
    const { editing } = this.state;
    const { value, placeholder, items, highlight } = this.props;
    let valueText = null;
    let idx = _.findIndex(items, ["value", value]);
      if (idx === -1) {
        valueText = null;
      } else {
        valueText = items[idx].text;
      }
    return (
      <div className="editable-cell" ref={node => (this.cell = node)}>
        {editing ? (
          <Select
            ref={node => (this.input = node)}
            value={value}
            dropdownMatchSelectWidth
            defaultOpen
            placeholder={placeholder}
            onSelect={this.onSelect}
            onBlur={this.onBlur}
            filterOption={false}
          >
            {items.map((item, i) => (
              <Option
                key={i}
                value={item.value}
                style={{ whiteSpace: "normal" }}
              >
                {item.text}
              </Option>
            ))}
          </Select>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
            onClick={this.toggleEdit}
          >
            {highlight ? <a>valueText</a> : valueText}
          </div>
        )}
      </div>
    );
  }
}

export default EditableSelectCell;
