// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Form, Select, Input, InputNumber } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

class CashFlowItem extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      cashFlowItem,
      onChangeName,
      onChangeCashflowType,
      onChangePosition,
    } = this.props;
    return (
      <Modal
        title={"Статья ДДС"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("cashflow_type", {
                  initialValue: cashFlowItem.cashflow_type,
                  onChange: onChangeCashflowType,
                })(
                  <Select
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={1}>приход</Option>
                    <Option value={2}>расход</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: cashFlowItem.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Позиция в списке"}>
                {getFieldDecorator("amount", {
                  onChange: onChangePosition,
                  initialValue: cashFlowItem.position,
                })(<InputNumber style={{ width: "100%" }} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CashFlowItem);
