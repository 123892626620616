// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Card, Badge } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

class Invoices extends Component {
  onChangePage = value => {
    this.props.onChangePage(value);
  };

  render() {
    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        render: date => moment(date).format("L"),
        width: "20%"
      },
      {
        title: "Номер",
        dataIndex: "number",
        render: (number, record) => (
          <Link to={`/invoices/${record.id}/edit`}>{number}</Link>
        ),
        width: "20%"
      },
      {
        title: "Сумма",
        dataIndex: "total",
        render: (total, record) => accounting.formatNumber(total, 0, " "),
        align: "right",
        width: "20%"
      },
      {
        title: "Оплачено",
        dataIndex: "paid",
        render: paid => accounting.formatNumber(paid, 0, " "),
        align: "right",
        width: "20%"
      },
    ];

    const { loading, invoices } = this.props;
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Table
            bordered
            size={"middle"}
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={invoices}
            rowKey="id"
          />
        </Col>
      </Row>
    );
  }
}

Invoices.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Invoices;
