// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Select, Input, Button, DatePicker } from "antd";

import moment from "moment";
import "moment/locale/ru";

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class Event extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSaveEvent(this.state);
      }
    });
  };

  render() {
    const {
      event,
      onCancelEvent,
      onChangeEventType,
      onChangeDescriptionEvent,
      onChangeEventNotifyDate,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={"vertical"}>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          {event.event_type == "remind" ? (
            <Col span={24}>
              <FormItem label={"Дата напоминания"}>
                {getFieldDecorator("notify_date", {
                  onChange: onChangeEventNotifyDate,
                  initialValue: event.notify_date
                    ? moment(event.notify_date)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Заполните дату напоминания!",
                    },
                  ],
                })(<DatePicker showTime format={"LLL"} className="ant-btn-width-100" />)}
              </FormItem>
            </Col>
          ) : null}

          <Col span={24}>
            <FormItem label={"Описание активности"}>
              {getFieldDecorator("description", {
                onChange: onChangeDescriptionEvent,
                initialValue: event.description,
                rules: [
                  {
                    required: true,
                    message: "Заполните описание!",
                  },
                ],
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={10}>
            <FormItem label={"Тип активности"}>
              {getFieldDecorator("event_type", {
                initialValue: event.event_type,
                onChange: onChangeEventType,
                rules: [
                  {
                    required: true,
                    message: "Введите тип!",
                  },
                ],
              })(
                <Select
                  placeholder="Тип активности"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value="email">письмо</Option>
                  <Option value="phone">звонок</Option>
                  <Option value="meeting">встреча</Option>
                  <Option value="remind">напоминание</Option>
                  <Option value="other">другое</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={14} style={{ paddingTop: "29px", textAlign: "right" }}>
            <Button type="danger" ghost onClick={onCancelEvent}>
              Отменить
            </Button>
            <Button
              type="primary"
              ghost
              style={{ marginLeft: "10px" }}
              onClick={this.onSubmit}
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

Event.propTypes = {
  orderEvent: PropTypes.arrayOf(PropTypes.object),
};

export default Form.create()(Event);
