// @flow
import React, { Component } from "react";
import { Table, Form, Select, DatePicker, Button } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      height,
      loading,
      report,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      downloadReport
    } = this.props;

    let totalOwn = 0;
    if (!loading) {
      totalOwn = report.reduce(
        (sum, record) =>
          sum + Number(record.data.amount) - Number(record.data.cost),
        0
      );
    }

    let reportData = [];
    if (!loading) {
      report.map(item => {
        let itemOwn = Number(item.data.amount) - Number(item.data.cost);
        reportData.push({
          id: item.id,
          name: item.name,
          position: item.position,
          amount: item.data.amount,
          cost: item.data.cost,
          own: itemOwn,
          percentOwn: (itemOwn / Number(item.data.amount > 0 ? item.data.amount : itemOwn)) * 100,
          percentInTotalOwn: (itemOwn / totalOwn) * 100,
          quantity: item.data.quantity,
          vat: item.data.vat,
          is_group: item.is_group
        });
        item.child_items.map((child, i) => {
          let childOwn = Number(child.data.amount) - Number(child.data.cost);
          let amount = Number(child.data.amount) || 0;
          reportData.push({
            id: `${item.id}-${i}`,
            name: child.name,
            position: child.position,
            amount: amount,
            cost: child.data.cost,
            own: childOwn,
            percentOwn: (childOwn / (amount > 0 ? amount : childOwn)) * 100,
            percentInTotalOwn: (childOwn / totalOwn) * 100,
            quantity: child.data.quantity,
            vat: child.data.vat,
            is_group: child.is_group
          });
        });
      });
    }

    let columns = [
      {
        title: "Оборудование",
        render: (product, record) => record.name,
        key: "product_name",
        width: "28%",
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Количество,</span>
            <br />
            <span>шт.</span>
          </div>
        ),
        render: (quantity, record) =>
          record.quantity > 0
            ? accounting.formatNumber(record.quantity, 0, " ")
            : "-",
        key: "quantity",
        width: "12%",
        align: "center"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Объем продаж,</span>
            <br />
            <span>тыс. руб.</span>
          </div>
        ),
        render: (amount, record) =>
          record.amount > 0
            ? accounting.formatNumber(record.amount / 1000, 2, " ")
            : "-",
        key: "amount",
        width: "12%",
        align: "center"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Затраты,</span>
            <br />
            <span>тыс. руб.</span>
          </div>
        ),
        render: (cost, record) =>
          record.quantity > 0
            ? accounting.formatNumber(record.cost / 1000, 2, " ")
            : "-",
        key: "cost",
        width: "12%",
        align: "center"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Собственный объем,</span>
            <br />
            <span>тыс. руб.</span>
          </div>
        ),
        render: (own, record) =>
          record.own > 0
            ? accounting.formatNumber(Number(record.own) / 1000, 2, " ")
            : "-",
        width: "12%",
        align: "center"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>%,</span>
            <br />
            <span>собственных</span>
          </div>
        ),
        render: (percentOwn, record) =>
          record.amount > 0
            ? accounting.formatNumber(
                Number(record.percentOwn),
                2,
                " "
              )
            : "-",
        width: "12%",
        align: "center"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>% в общем,</span>
            <br />
            <span>объеме</span>
            <br />
            <span>собственных</span>
          </div>
        ),
        render: (percentInTotalOwn, record) =>
          record.amount > 0
            ? accounting.formatNumber(
                Number(record.percentInTotalOwn),
                2,
                " "
              )
            : "-",
        width: "12%",
        align: "center"
      }
    ];

    const { getFieldDecorator } = this.props.form;
    const dateFormat = "DD.MM.YYYY";

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Отгрузки по группам оборудования`}
        extra={[
          <Button key="3" type="dashed" icon="file-excel" onClick={downloadReport}>Загрузить</Button>,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="id"
                scroll={{ y: height - 360 }}
                rowClassName={record =>
                  record.is_group ? "ant-table-total-row" : null
                }
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Form layout={"vertical"}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormItem label={"Периодичность"}>
                {getFieldDecorator("startDate", {
                  initialValue: [
                    moment(fetchParams.startDate, dateFormat),
                    moment(fetchParams.endDate, dateFormat)
                  ],
                  onChange: onChangeDateRange
                })(
                  <RangePicker
                    style={{ width: "100%" }}
                    format={dateFormat}
                    onChange={onChangeDateRange}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormItem label={"Период"}>
                {getFieldDecorator("period", {
                  initialValue: period,
                  onChange: onChangePeriod
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Период"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={1}>этот месяц</Option>
                    <Option value={2}>этот квартал</Option>
                    <Option value={3}>этот год</Option>
                    <Option value={4}>прошлый месяц</Option>
                    <Option value={5}>прошлый квартал</Option>
                    <Option value={6}>прошлый год</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Form>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(DataList);
