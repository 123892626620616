import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  requestForProposals: [],
  requestForProposal: {
    request_proposal_events: [],
    request_proposal_items: [],
  },
  event: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    search: null,
    statuses: null,
    contact_types: null,
    assignees: null,
    is_paused: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isEventsLoading: false,
  isEventVisible: false,
  isProductsLoading: false,
  isAttachmentsLoading: false,
  isProductVisible: false,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const requestForProposalsFilters = localStorage.getItem(
    "requestForProposalsFilters"
  );
  if (requestForProposalsFilters) {
    localFetchParams = JSON.parse(requestForProposalsFilters);
  } else {
    localStorage.setItem(
      "requestForProposalsFilters",
      JSON.stringify(fetchParams)
    );
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_REQUEST_FOR_PROPOSALS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_REQUEST_FOR_PROPOSALS":
      return {
        ...state,
        requestForProposals: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_REQUEST_FOR_PROPOSAL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_REQUEST_FOR_PROPOSAL":
      return {
        ...state,
        requestForProposal: action.response.data,
        isLoading: false,
      };

    case "PATCH_REQUEST_FOR_PROPOSAL_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_REQUEST_FOR_PROPOSAL":
      return {
        ...state,
        errors: false,
        requestForProposal: action.response.data,
        isLoading: false,
      };

    case "PATCH_REQUEST_FOR_PROPOSAL_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_REQUEST_FOR_PROPOSAL_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_REQUEST_FOR_PROPOSAL":
      return {
        ...state,
        errors: false,
        requestForProposal: action.response.data,
        isLoading: false,
      };

    case "POST_REQUEST_FOR_PROPOSAL_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_REQUEST_FOR_PROPOSAL_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_REQUEST_FOR_PROPOSAL":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_REQUEST_FOR_PROPOSAL_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "REQUEST_FOR_PROPOSAL_NEW":
      return {
        ...state,
        requestForProposal: {
          date: moment(),
          company: null,
          contact_type: null,
          contact_source: null,
          assignee: null,
          request_proposal_events: [],
        },
        errors: false,
        isLoading: false,
      };

    case "REQUEST_REQUEST_FOR_PROPOSAL_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    // attachments
    case "FETCH_ATTACHMENTS_REQUEST":
      return {
        ...state,
        isAttachmentLoading: true,
      };

    case "FETCH_ATTACHMENTS":
      return update(state, {
        requestForProposal: { attachments: { $set: action.response.data } },
        isAttachmentLoading: { $set: false },
      });
    case "FETCH_ATTACHMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    case "ATTACHMENT_UPLOAD":
      return update(state, {
        isAttachmentLoading: { $set: true },
      });

    case "ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        requestForProposal: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    case "REQUEST_REQUEST_FOR_PROPOSAL_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    // General
    case "PATCH_REQUEST_FOR_PROPOSAL_REQUEST_STATUS":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_REQUEST_FOR_PROPOSAL_STATUS":
      return update(state, {
        requestForProposal: {
          status: { $set: action.response.data.status },
          loss_reason: { $set: action.response.data.loss_reason },
        },
      });

    case "PATCH_REQUEST_FOR_PROPOSAL_FAILURE_STATUS":
      return update(state, {
        requestForProposal: { status: { $set: state.status } },
        errors: { $set: true },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_STATUS_TO_FAIL":
      return update(state, {
        requestForProposal: { status: { $set: action.value } },
      });

    // loss_reason
    case "PATCH_REQUEST_FOR_PROPOSAL_REQUEST_LOSS_REASON":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_REQUEST_FOR_PROPOSAL_LOSS_REASON":
      return update(state, {
        requestForProposal: {
          status: { $set: action.response.data.status },
          loss_reason: { $set: action.response.data.loss_reason },
        },
      });

    case "PATCH_REQUEST_FOR_PROPOSAL_FAILURE_LOSS_REASON":
      return update(state, {
        requestForProposal: { loss_reason: { $set: state.loss_reason } },
        errors: { $set: true },
      });

    // paused
    case "PATCH_REQUEST_FOR_PROPOSAL_REQUEST_PAUSED":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_REQUEST_FOR_PROPOSAL_PAUSED":
      return update(state, {
        requestForProposal: {
          is_paused: { $set: action.response.data.is_paused },
        },
      });

    case "PATCH_REQUEST_FOR_PROPOSAL_FAILURE_PAUSED":
      return {
        ...state,
        errors: true,
      };

    case "REQUEST_FOR_PROPOSAL_CHANGE_DATE":
      return update(state, {
        requestForProposal: { date: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_NOTIFY_DATE":
      return update(state, {
        requestForProposal: { notify_date: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_COMPANY":
      return update(state, {
        requestForProposal: {
          company: { $set: action.value },
          contact_type: { $set: action.value.extra.contact_type },
        },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_CONTACT_TYPE":
      return update(state, {
        requestForProposal: { contact_type: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_CONTACT_SOURCE":
      return update(state, {
        requestForProposal: { contact_source: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_SUBJECT":
      return update(state, {
        requestForProposal: { subject: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_DESCRIPTION":
      return update(state, {
        requestForProposal: { description: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_ASSIGNEE":
      return update(state, {
        requestForProposal: { assignee: { $set: action.value } },
      });

    // events
    case "FETCH_REQUEST_FOR_PROPOSAL_EVENTS_REQUEST":
      return {
        ...state,
        isEventsLoading: true,
      };
    case "FETCH_REQUEST_FOR_PROPOSAL_EVENTS":
      return update(state, {
        requestForProposal: {
          request_proposal_events: { $set: action.response.data },
        },
        isEventsLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_REQUEST_FOR_PROPOSAL_EVENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isEventsLoading: false,
      };

    case "POST_REQUEST_FOR_PROPOSAL_EVENT_REQUEST":
      return {
        ...state,
        errors: false,
        isEventsLoading: true,
      };
    case "POST_REQUEST_FOR_PROPOSAL_EVENT":
      return update(state, {
        requestForProposal: {
          request_proposal_events: { $set: action.response.data },
        },
        isEventsLoading: { $set: false },
        isEventVisible: { $set: false },
        errors: { $set: false },
      });

    case "POST_REQUEST_FOR_PROPOSAL_EVENT_FAILURE":
      return {
        ...state,
        errors: true,
        isEventsLoading: false,
      };

    case "REQUEST_FOR_PROPOSAL_ADD_EVENT":
      return {
        ...state,
        event: { id: null, event_type: "email", description: "" },
        isEventVisible: true,
      };

    case "REQUEST_FOR_PROPOSAL_CANCEL_EVENT":
      return {
        ...state,
        event: { id: null, event_type: "email", description: "", notify_date: null },
        isEventVisible: false,
      };
    case "REQUEST_FOR_PROPOSAL_CHANGE_EVENT_TYPE":
      return update(state, {
        event: { event_type: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_EVENT_DESCRIPTION":
      return update(state, {
        event: { description: { $set: action.value } },
      });

    case "REQUEST_FOR_PROPOSAL_CHANGE_EVENT_NOTIFY_DATE":
      return update(state, {
        event: { notify_date: { $set: action.value } },
      });

    // products
    case "REQUEST_FOR_PROPOSAL_ITEMS":
      return {
        ...state,
        isProductsLoading: true,
      };
    case "REQUEST_FOR_PROPOSAL_ITEMS_SUCCEED":
      return update(state, {
        requestForProposal: {
          request_proposal_items: { $set: action.response.data },
        },
        isProductsLoading: { $set: false },
        errors: { $set: false },
      });

    case "REQUEST_FOR_PROPOSAL_ITEMS_FAILURE":
      return {
        ...state,
        errors: true,
        isProductsLoading: false,
      };

    case "POST_REQUEST_FOR_PROPOSAL_PRODUCT":
      return {
        ...state,
        errors: false,
        isProductsLoading: true,
      };
    case "POST_REQUEST_FOR_PROPOSAL_PRODUCT_SUCCEED":
      return update(state, {
        requestForProposal: {
          request_proposal_items: { $set: action.response.data },
        },
        isProductsLoading: { $set: false },
        isProductVisible: { $set: false },
        errors: { $set: false },
      });

    case "POST_REQUEST_FOR_PROPOSAL_EVENT_PRODUCT_FAILURE":
      return {
        ...state,
        errors: true,
        isProductsLoading: false,
      };

    case "REQUEST_FOR_PROPOSAL_ADD_PRODUCT":
      return {
        ...state,
        isProductVisible: true,
      };

    case "REQUEST_FOR_PROPOSAL_CANCEL_PRODUCT":
      return {
        ...state,
        isProductVisible: false,
      };

    case "DELETE_REQUEST_FOR_PROPOSAL_PRODUCT":
      return {
        ...state,
        errors: false,
      };
    case "DELETE_REQUEST_FOR_PROPOSAL_PRODUCT_SUCCEED":
      return {
        ...state,
        errors: false,
      };
    case "DELETE_REQUEST_FOR_PROPOSAL_PRODUCT_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // filters
    case "REQUEST_FOR_PROPOSALS_CLEAR_FILTERS":
      localStorage.removeItem("requestForProposalsFilters");

      return update(state, {
        fetchParams: {
          statuses: { $set: null },
          assignees: { $set: null },
        },
      });

    case "REQUEST_FOR_PROPOSALS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "REQUEST_FOR_PROPOSALS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "REQUEST_FOR_PROPOSALS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          statuses: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "REQUEST_FOR_PROPOSALS_FILTER_CONTACT_TYPE":
      return update(state, {
        fetchParams: {
          contact_types: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "REQUEST_FOR_PROPOSALS_FILTER_ASSIGNEES":
      return update(state, {
        fetchParams: {
          assignees: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "REQUEST_FOR_PROPOSALS_FILTER_PAUSED":
      return update(state, {
        fetchParams: {
          is_paused: { $set: action.value },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
