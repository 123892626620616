// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Drawer, Timeline, Typography, Icon, Button } from "antd";
import api from "../../api";

import moment from "moment";
import "moment/locale/ru";

const { Paragraph, Text } = Typography;

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetching: true,
      visible: false,
    };
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  fetchActivity = async (parentId, parentType) => {
    this.setState({ fetching: true, visible: true });
    const response = await api.fetch(`/activities`, {
      parent_id: parentId,
      parent_type: parentType,
    });
    const data = response.data;
    this.setState({ data, fetching: false });
  };

  render() {
    const { visible, fetching, data } = this.state;
    const { parentId, parentType } = this.props;

    return (
      <Fragment>
        <Button
          type={this.state.visible ? "primary" : "ghost"}
          key="drawerActivityButton"
          icon="eye"
          style={{ marginLeft: "8px" }}
          onClick={this.fetchActivity.bind(this, parentId, parentType)}
        />
        <OpenDrawer
          visible={visible}
          loading={fetching}
          data={data}
          onClose={this.onClose}
        />
      </Fragment>
    );
  }
}

function OpenDrawer({
  visible = false,
  loading = true,
  data = [],
  onClose = {},
}) {
  return (
    <Drawer
      width={540}
      title="Лог"
      placement="right"
      visible={visible}
      onClose={onClose}
    >
      <Timeline>
        {data.map((item) => {
          return (
            <Timeline.Item
              color={
                item.action_type == "deleted"
                  ? "red"
                  : item.action_type == "created"
                  ? "blue"
                  : "green"
              }
              key={item.id}
              dot={<Icon type="history" style={{ fontSize: "20px" }} />}
            >
              <p style={{ opacity: "0.7" }}>
                {moment(item.date).format("LLL")} - {item.user.name}
              </p>
              <p>{`${item.action_name}(а) ${item.record.human_name}`}</p>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
              <Text code>
                {item.value}
                </Text>
              </Paragraph>
              
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Drawer>
  );
}

Activities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
};
export default Activities;
