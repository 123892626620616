// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, DatePicker, Select, Button, Icon, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  render() {
    const {
      visible,
      type,
      startDate,
      endDate,
      companies,
      onChangeType,
      onChangePeriod,
      onChangeCompanies,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return visible ? (
      <Card size="small" title="Фильтры">
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <FormItem label={"Тип"}>
              {getFieldDecorator("type", {
                onChange: onChangeType,
                initialValue: type,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Тип"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={1}>входящие</Option>
                  <Option value={2}>исходящие</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <FormItem label={"Период"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Компании"}>
              {getFieldDecorator("companies", {
                initialValue: companies ? companies : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Компании"
                  onChange={onChangeCompanies}
                  selected={companies ? companies : []}
                  url={"/companies/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Card>
    ) : null;
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
