// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";
import ShipmentModal from "../../Shipments/ModalForm";

class Shipments extends Component {
  state = { isOpen: false, selectedRowKeys: [] };

  onSelectOption = item => {
    this.onCloseDropdown();
    this.props.onAddItem(item);
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteShipments = () => {
    // console.log(this.state.selectedRowKeys)
    this.props.onDeleteShipments(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      isVisibleShipment,
      onCloseShipmentModal,
      isShipmentsLoading,
      onAddShipment,
      onEditShipment,
      onSaveShipment,
      shipments
    } = this.props;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата",
        dataIndex: "date",
        key: "date",
        render: (date, record) => (
          <div>
            <div>{moment(date).format("L")}</div>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Номер",
        dataIndex: "number",
        key: "number",
        render: (number, record) => (
          <div>
            <a onClick={onEditShipment.bind(this, record.id)}>{number}</a>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Сумма (без НДС)",
        dataIndex: "total",
        key: "total",
        render: (total, record) =>
          accounting.formatNumber(
            Number(record.total) - Number(record.vat_total),
            0,
            " "
          ),
        width: "20%",
        align: "center"
      },
      {
        title: "НДС",
        dataIndex: "vat_total",
        key: "vat_total",
        render: (vat_total, record) =>
          accounting.formatNumber(Number(record.vat_total), 0, " "),
        width: "20%",
        align: "center"
      },
      {
        title: "Всего",
        dataIndex: "total",
        key: "total_with_vat",
        render: (total, record) =>
          accounting.formatNumber(Number(record.total), 0, " "),

        rowSpan: 2,
        width: "20%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            {hasSelected ? (
              <Button onClick={this.onDeleteShipments}>Удалить</Button>
            ) : (
              <Button onClick={onAddShipment}>Добавить</Button>
            )}
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isShipmentsLoading}
              rowSelection={rowSelection}
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={shipments}
              rowKey="id"
            />
          </Col>
        </Row>
        <ShipmentModal
          visible={isVisibleShipment}
          onSave={onSaveShipment}
          onCancel={onCloseShipmentModal}
        />
      </div>
    );
  }
}

Shipments.propTypes = {
  shipments: PropTypes.arrayOf(PropTypes.object)
};
export default Shipments;
