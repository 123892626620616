// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Pagination, Row, Col, Input, Icon, Tooltip } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

import Filter from "./Filter";

const Search = Input.Search;

function checkPaymentSchedules(total, total_payment_schedules) {
  let totalWithVat = parseFloat(total);
  let totalPaymentSchedules = parseFloat(total_payment_schedules);
  if (totalWithVat == totalPaymentSchedules) {
    return <span style={{ color: "green" }}>график заполнен</span>;
  } else {
    return <span style={{ color: "red" }}>график не заполнен</span>;
  }
}

class PurchasesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChange = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      purchases,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      onClearFilters,
      onChangeFilterPeriod,
      onChangeFilterCompanies,
      downloadReport
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата",
        dataIndex: "agreement_date",
        render: (agreement_date) => moment(agreement_date).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        width: "10%",
      },
      {
        title: "Договор",
        dataIndex: "agreement",
        render: (agreement, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/purchases/${record.id}/edit`}>{agreement}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>
              {checkPaymentSchedules(
                record.total,
                record.total_payment_schedules
              )}
            </span>
          </div>
        ),

        key: "number",
        width: "20%",
      },
      {
        title: "Шифр",
        dataIndex: "code",
        key: "code",
        width: "10%",
      },
      {
        title: "Поставщик",
        dataIndex: "company",
        render: (company, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span style={{ opacity: "0.8" }}>
              {company.inn ? `инн: ${company.inn}` : ""}
            </span>
            <span>{company.text}</span>
          </div>
        ),
        width: "30%",
      },
      {
        title: "Сумма",
        dataIndex: "total",
        render: (total, record) =>
          accounting.formatNumber(
            Number(total) + Number(record.vat_total),
            0,
            " "
          ),
        align: "right",
        width: "15%",
      },
      {
        title: "Оплачено",
        dataIndex: "paid",
        render: (paid, record) => accounting.formatNumber(paid, 0, " "),
        align: "right",
        width: "15%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/purchases/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Tooltip key="tooltipExcelButton" placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate || fetchParams.companies ? "primary" : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Договоры с поставщиками`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={purchases}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          companies={fetchParams.companies}
          onChangePeriod={onChangeFilterPeriod}
          onChangeCompanies={onChangeFilterCompanies}
        />
      </PageHeader>
    );
  }
}

PurchasesList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default PurchasesList;
