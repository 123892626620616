// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  PageHeader,
  Pagination,
  Button,
  Badge,
  Input,
  Tag,
  Select,
  Icon,
} from "antd";
import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;
const Option = Select.Option;

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      filterActiveVisible: false,
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // filters
  onChangeFilterActive = (value) => {
    this.setState({ filterActiveVisible: false }, () => {
      this.props.onChangeFilterActive(value);
    });
  };

  onChange = (pagination, filters, sorter) => {
    console.log(sorter);
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const {
      loading,
      users,
      meta,
      fetchParams,
      onChangePage,
      onChangeLimit,
      onSearch,
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Активен",
        dataIndex: "active",
        render: (active, record) => (
          <span>
            <Badge status={active ? "success" : "error"} />
          </span>
        ),
        key: "active",
        width: "5%",
        align: "center",
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.status ? "#1890ff" : undefined,
            }}
          />
        ),
        filterDropdown: (
          <div className="custom-filter-dropdown">
            <Row gutter={10}>
              <Col span={24}>
                <Select
                  style={{ width: "100%" }}
                  value={fetchParams.active}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={this.onChangeFilterActive}
                >
                  <Option value={true}>активен</Option>
                  <Option value={false}>неактивен</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "10px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={this.onResetFilterActive}>
                  Сбросить
                </Button>
              </Col>
            </Row>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.active ? "#1890ff" : undefined,
            }}
          />
        ),
        filterDropdownVisible: this.state.filterActiveVisible,
        onFilterDropdownVisibleChange: (visible) =>
          this.setState({ filterActiveVisible: visible }),
      },
      {
        title: "ФИО",
        dataIndex: "last_name",
        sorter: true,
        sortDirections: ["ascend", "descend"],
        render: (last_name, record) => (
          <Link to={`/references/users/${record.id}/edit`}>{record.fio}</Link>
        ),
        key: "last_name",
        width: "40%",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: "25%",
      },
      {
        title: "Роль",
        dataIndex: "role",
        render: (role) => {
          switch (role) {
            case "manager":
              return <Tag color="geekblue">менеджер</Tag>;
            case "administrator":
              return <Tag color="volcano">администратор</Tag>;
            default:
              return <Tag color="geekblue">пользователь</Tag>;
          }
        },

        key: "role",
        width: "15%",
        align: "center",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        key: "created_at",
        width: "15%",
        align: "center",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/references/users/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Пользователи`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
            allowClear
          />,
          mainButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={users}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default UsersList;
