// eslint-disable 
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import store from "./store";
import "./style/index.css";
import App from "./containers/App";
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import ru_RU from "antd/lib/locale-provider/ru_RU";
import moment from "moment";
moment.locale("ru");

export const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ru_RU}>
      <App history={history} />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
