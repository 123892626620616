// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spin } from "antd";
import Actions from "../../../actions/products";
import ProductForm from "./Form";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchProduct(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdateProduct(this.props.match.params.id));
    } else {
      this.props
        .dispatch(Actions.onCreateProduct(this.props.match.params.categoryID))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(Actions.fetchProducts()).then(() => {
              this.props.history.push(
                `/categories/${this.props.match.params.categoryID}/products/${
                  this.props.product.id
                }/edit`
              );
            });
          }
        });
    }
  };

  onClose = () => {
    this.props.history.push(
      `/categories/${this.props.match.params.categoryID}/products`
    );
  };

  // General
  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDescription = e => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeSku = e => {
    this.props.dispatch(Actions.onChangeSku(e.target.value));
  };

  onChangePrice = e => {
    this.props.dispatch(Actions.onChangePrice(e.target.value));
  };

  onChangeUnit = item => {
    this.props.dispatch(Actions.onChangeUnit(item));
  };

  onChangeActive = ({ target: { checked } }) => {
    this.props.dispatch(Actions.onChangeActive(checked));
  };

  onChangeIsPrice = ({ target: { checked } }) => {
    this.props.dispatch(Actions.onChangeIsPrice(checked));
  };

  // Items
  onAddSpecificationItem = () => {
    this.props.dispatch(Actions.onAddSpecificationItem());
  };

  onDeleteSpecificationItems = ids => {
    this.props.dispatch(Actions.onDeleteSpecificationItems(ids));
  };

  onChangeSpecificationProduct = (id, item) => {
    this.props.dispatch(Actions.onChangeSpecificationProduct(id, item));
  };

  onChangeSpecificationCost = (id, value) => {
    this.props.dispatch(Actions.onChangeSpecificationCost(id, value));
  };

  onChangeSpecificationQuantity = (id, value) => {
    this.props.dispatch(Actions.onChangeSpecificationQuantity(id, value));
  };

  // tabs
  fetchOrders = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchOrders(this.props.match.params.id));
    }
    
  };

  render() {
    const { isLoading, product, orders, isOrdersLoading } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <ProductForm
        isNew={this.state.isNew}
        product={product}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeName={this.onChangeName}
        onChangeDescription={this.onChangeDescription}
        onChangeSku={this.onChangeSku}
        onChangePrice={this.onChangePrice}
        onChangeUnit={this.onChangeUnit}
        onChangeActive={this.onChangeActive}
        onChangeIsPrice={this.onChangeIsPrice}
        onAddSpecificationItem={this.onAddSpecificationItem}
        onChangeProduct={this.onChangeProduct}
        onChangeQuantity={this.onChangeQuantity}
        onChangeCost={this.onChangeCost}
        onAddSpecificationItem={this.onAddSpecificationItem}
        onDeleteSpecificationItems={this.onDeleteSpecificationItems}
        onChangeSpecificationProduct={this.onChangeSpecificationProduct}
        onChangeSpecificationCost={this.onChangeSpecificationCost}
        onChangeSpecificationQuantity={this.onChangeSpecificationQuantity}
        // tabs
        fetchOrders={this.fetchOrders}
        isOrdersLoading={isOrdersLoading}
        orders={orders}
      />
    );
  }
}

Product.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.products.isProductLoading,
  product: state.products.product,
  isOrdersLoading: state.products.isLoading,
  orders: state.products.orders,
  errors: state.products.errors
});

export default connect(mapStateToProps)(Product);
