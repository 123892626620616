// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/workOrders";
import WorkOrdersList from "./WorkOrdersList";

class WorkOrders extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchWorkOrders());
    });
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchWorkOrders());
      });
    });
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchWorkOrders()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchWorkOrders()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchWorkOrders()));
  };

  // filters
  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchWorkOrders()))
      .then(() => {
        localStorage.setItem(
          "workOrdersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterDownloadRows = (e) => {
    this.props
      .dispatch(Actions.onChangeFilterDownloadRows(e.target.checked))
      .then(() =>
        localStorage.setItem(
          "workOrdersFilters",
          JSON.stringify(this.props.fetchParams)
        )
      );
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadXLSReport(
      params,
      "work_orders.xlsx",
      params.startDate
        ? `work_orders ${params.startDate}-${params.endDate}.xlsx`
        : "work_orders.xlsx"
    );
  };

  render() {
    return (
      <WorkOrdersList
        loading={this.props.isLoading}
        workOrders={this.props.workOrders}
        meta={this.props.meta}
        fetchParams={this.props.fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onDelete={this.onDelete}
        onSortBy={this.onSortBy}
        // filters
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterDownloadRows={this.onChangeFilterDownloadRows}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.workOrders.isLoading,
  workOrders: state.workOrders.workOrders,
  meta: state.workOrders.meta,
  fetchParams: state.workOrders.fetchParams
});

export default connect(mapStateToProps)(WorkOrders);
