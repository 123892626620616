// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/orders";
import InvoicesActions from "../../../actions/invoices";
import ShipmentsActions from "../../../actions/shipments";
import { Spin } from "antd";
import OrderForm from "./Form";
import moment from "moment";
import _ from "lodash/array";
import { v4 as uuidv4 } from 'uuid';

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [{ id: null, name: null }],
      variants: [],
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchOrder(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.props.dispatch(Actions.fetchOrder(this.props.match.params.id));
      } else {
        this.setState({ isNew: true }, () => {
          this.props.dispatch(Actions.onNew());
        });
      }
    }
  }

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.setState({ isNew: false }, () => {
            this.props.history.push(`/orders/${this.props.order.id}/edit`);
          });
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/orders`);
  };

  onFillInvoice = () => {
    const { order } = this.props;
    const invoice_items = order.order_items.map(item => {
      return {
        id: uuidv4(),
        position: item.position,
        product: item.product,
        unit: item.unit,
        vat_rate: item.vat_rate,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        amount: item.amount,
        amount_vat: item.amount_vat,
        is_exist: false,
        _destroy: false
      };
    });

    const data = {
      invoice: {
        status: "approving",
        number: null,
        date: moment(),
        duration: 10,
        order:{ id: order.id, text: order.number },
        agreement: order.agreement,
        agreement_date: order.agreement_date,
        additional: order.additional,
        vat_included: order.vat_included,
        total: order.total,
        vat_total: order.vat_total,
        advance_percent: 0,
        paid: 0,
        ship_address: order.ship_address,
        special_instruction: order.special_instruction,
        company: order.company,
        assignee: order.assignee,
        invoice_items: invoice_items
      }
    };

    this.props.dispatch(InvoicesActions.onFillInWithOrder(data)).then(() => {
      this.props.history.push(`/invoices/new`);
    });
  };

  onCreateWorkOrder = () => {
    const { order } = this.props;
    const data = {
      status: "approving",
      number: order.number,
      company_id: order.company.id,
      order_id: order.id,
      date: moment(),
      work_order_items: []
    };
    this.props.dispatch(Actions.onCreateWorkOrder(data));
  };

  fetchSuggestions = value => {
    return Actions.fetchSuggestions(value).then(variants => variants);
  };

  onChangeDate = value => {
    this.props.dispatch(Actions.onChangeDate(moment(value).format()));
  };

  onChangeStatus = value => {
    this.props.dispatch(Actions.onChangeStatus(value));
  };

  onChangeSourceType = value => {
    this.props.dispatch(Actions.onChangeSourceType(value));
  };

  onChangeRequestProposal = value => {
    this.props.dispatch(Actions.onChangeRequestProposal(value));
  };

  onChangeNumber = e => {
    this.props.dispatch(Actions.onChangeNumber(e.target.value));
  };

  onChangeAssignee = value => {
    this.props.dispatch(Actions.onChangeAssignee(value));
  };

  onChangeCompany = value => {
    this.props.dispatch(Actions.onChangeCompany(value));
  };

  onChangeAgreement = e => {
    this.props.dispatch(Actions.onChangeAgreement(e.target.value));
  };

  onChangeAgreementDate = value => {
    this.props.dispatch(Actions.onChangeAgreementDate(value));
  };

  onChangeAdditional = e => {
    this.props.dispatch(Actions.onChangeAdditional(e.target.value));
  };

  onChangeShipAddress = e => {
    this.props.dispatch(Actions.onChangeShipAddress(e.target.value));
  };

  onChangeShippingAddress = value => {
    this.props.dispatch(Actions.onChangeShippingAddress(value));
  };

  onChangeSpecialInstruction = e => {
    this.props.dispatch(Actions.onChangeSpecialInstruction(e.target.value));
  };

  // Items
  onAddItem = () => {
    this.props.dispatch(Actions.onAddItem());
  };

  onDeleteItems = ids => {
    ids.map(id => {
      let idx = this.props.order.order_items.findIndex(function(o) {
        return o.id == id;
      });
      if (idx === -1) {
        return null;
      }
      if (this.props.order.order_items[idx].is_exist) {
        this.props.dispatch(Actions.onDeleteItem(idx));
      } else {
        this.props.dispatch(Actions.onDestroyItem(idx));
      }
    });
  };

  onChangeItemProduct = (id, item) => {
    this.props.dispatch(Actions.onChangeItemProduct(id, item));
  };

  onChangeItemDescription = (id, value) => {
    this.props.dispatch(Actions.onChangeItemDescription(id, value));
  };

  onChangeItemUnit = (id, item) => {
    this.props.dispatch(Actions.onChangeItemUnit(id, item));
  };

  onChangeItemQuantity = (id, value) => {
    this.props.dispatch(Actions.onChangeItemQuantity(id, value));
  };

  onChangeItemPrice = (id, value) => {
    this.props.dispatch(Actions.onChangeItemPrice(id, value));
  };

  onChangeItemVatRate = (id, item) => {
    this.props.dispatch(Actions.onChangeItemVatRate(id, item));
  };

  onChangeItemVat = (id, value) => {
    this.props.dispatch(Actions.onChangeItemVat(id, value));
  };

  onChangeItemWillShipped = (id, value) => {
    if (moment(value).isValid()) {
      this.props.dispatch(Actions.onChangeItemWillShipped(id, moment(value).startOf('day').toString()));
    } else {
      // console.log(value);
      this.props.dispatch(Actions.onChangeItemWillShipped(id, null));
    }
  };

  onChangeItemWillShippedDays = (id, value) => {
    this.props.dispatch(Actions.onChangeItemWillShippedDays(id, value));
  };

  // shipments
  onDeleteShipments = ids => {
    ids.map(id => {
      this.props
        .dispatch(ShipmentsActions.onDelete(id))
        .then(() => {
          this.props.dispatch(
            Actions.fetchOrderItems(this.props.match.params.id)
          );
        })
        .then(() => {
          this.props.dispatch(
            ShipmentsActions.fetchShipments(this.props.match.params.id)
          );
        });
    });
  };

  fetchOrderItems = () => {
    this.props.dispatch(Actions.fetchOrderItems(this.props.match.params.id));
  };

  onAddShipment = () => {
    this.props
      .dispatch(ShipmentsActions.onNew(this.props.match.params.id))
      .then(() => {
        this.props.dispatch(ShipmentsActions.onToggleVisible());
      });
  };

  onEditShipment = id => {
    this.props.dispatch(ShipmentsActions.fetchShipment(id)).then(() => {
      this.props.dispatch(ShipmentsActions.onToggleVisible());
    });
  };

  onSaveShipment = () => {
    const orderId = this.props.match.params.id;

    if (this.props.shipment.id) {
      this.props
        .dispatch(ShipmentsActions.onUpdate(this.props.shipment.id))
        .then(() => {
          this.props.dispatch(ShipmentsActions.fetchShipments(orderId));
        })
        .then(() => {
          this.props.dispatch(Actions.fetchOrderItems(orderId));
        })
        .then(() => {
          this.props.dispatch(ShipmentsActions.onToggleVisible());
        });
    } else {
      this.props
        .dispatch(ShipmentsActions.onCreate(orderId))
        .then(() => {
          this.props.dispatch(ShipmentsActions.fetchShipments(orderId));
        })
        .then(() => {
          this.props.dispatch(Actions.fetchOrderItems(orderId));
        })
        .then(() => {
          this.props.dispatch(ShipmentsActions.onToggleVisible());
        });
    }
  };

  onCloseShipmentModal = () => {
    this.setState({ isVisibleShipmen: false });
  };

  fetchShipments = () => {
    this.props.dispatch(
      ShipmentsActions.fetchShipments(this.props.match.params.id)
    );
  };

  // paymentSchedules
  onAddPaymentSchedule = () => {
    this.props.dispatch(Actions.onAddPaymentSchedule());
  };

  onDeletePaymentSchedules = ids => {
    ids.map(id => {
      let idx = _.findIndex(this.props.order.order_payment_schedules, [
        "id",
        id
      ]);
      if (idx === -1) {
        return null;
      }
      if (this.props.order.order_payment_schedules[idx].is_exist) {
        this.props.dispatch(Actions.onDeletePaymentSchedule(idx));
      } else {
        this.props.dispatch(Actions.onDestroyPaymentSchedule(idx));
      }
    });
  };

  onChangePaymentSchedulesDate = (id, value) => {
    if (moment(value).isValid()) {
      this.props.dispatch(Actions.onChangePaymentSchedulesDate(id, moment(value).startOf('day').toString()));
    } else {
      // console.log(value);
      this.props.dispatch(Actions.onChangePaymentSchedulesDate(id, null));
    }
  };

  onChangePaymentSchedulesPaymentType = (id, item) => {
    // console.log(id, item)
    this.props.dispatch(Actions.onChangePaymentSchedulesPaymentType(id, item));
  };

  onChangePaymentSchedulesAmount = (id, value) => {
    this.props.dispatch(Actions.onChangePaymentSchedulesAmount(id, value));
  };

  onChangePaymentSchedulesPercent = (id, value) => {
    this.props.dispatch(Actions.onChangePaymentSchedulesPercent(id, value));
  };

  render() {
    const {
      isLoading,
      isLoadingWorkOrder,
      order,
      isShipmentsLoading,
      shipments
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <OrderForm
        isLoadingWorkOrder={isLoadingWorkOrder}
        isNew={this.state.isNew}
        order={order}
        onSave={this.onSave}
        onClose={this.onClose}
        onFillInvoice={this.onFillInvoice}
        fetchSuggestions={this.fetchSuggestions}
        onChangeDate={this.onChangeDate}
        onChangeNumber={this.onChangeNumber}
        onChangeStatus={this.onChangeStatus}
        onChangeSourceType={this.onChangeSourceType}
        onChangeRequestProposal={this.onChangeRequestProposal}
        onChangeAssignee={this.onChangeAssignee}
        onChangeCompany={this.onChangeCompany}
        onChangeAgreement={this.onChangeAgreement}
        onChangeAgreementDate={this.onChangeAgreementDate}
        onChangeAdditional={this.onChangeAdditional}
        onChangeShipAddress={this.onChangeShipAddress}
        onChangeShippingAddress={this.onChangeShippingAddress}
        onChangeSpecialInstruction={this.onChangeSpecialInstruction}
        onCreateWorkOrder={this.onCreateWorkOrder}
        onAddItem={this.onAddItem}
        onDeleteItems={this.onDeleteItems}
        onChangeItemProduct={this.onChangeItemProduct}
        onChangeItemDescription={this.onChangeItemDescription}
        onChangeItemUnit={this.onChangeItemUnit}
        onChangeItemQuantity={this.onChangeItemQuantity}
        onChangeItemPrice={this.onChangeItemPrice}
        onChangeItemVatRate={this.onChangeItemVatRate}
        onChangeItemVat={this.onChangeItemVat}
        onChangeItemWillShipped={this.onChangeItemWillShipped}
        onChangeItemWillShippedDays={this.onChangeItemWillShippedDays}
        // shipments
        isVisibleShipment={this.state.isVisibleShipment}
        onDeleteShipments={this.onDeleteShipments}
        onAddShipment={this.onAddShipment}
        onEditShipment={this.onEditShipment}
        onSaveShipment={this.onSaveShipment}
        fetchShipments={this.fetchShipments}
        onCloseShipmentModal={this.onCloseShipmentModal}
        isShipmentsLoading={isShipmentsLoading}
        shipments={shipments}
        // paymentSchedules
        onAddPaymentSchedule={this.onAddPaymentSchedule}
        onDeletePaymentSchedules={this.onDeletePaymentSchedules}
        onChangePaymentSchedulesDate={this.onChangePaymentSchedulesDate}
        onChangePaymentSchedulesPaymentType={
          this.onChangePaymentSchedulesPaymentType
        }
        onChangePaymentSchedulesAmount={this.onChangePaymentSchedulesAmount}
        onChangePaymentSchedulesPercent={this.onChangePaymentSchedulesPercent}
      />
    );
  }
}

Order.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.orders.isLoading,
  isLoadingWorkOrder: state.orders.isLoadingWorkOrder,
  errors: state.orders.errors,
  order: state.orders.order,
  isShipmentsLoading: state.shipments.isLoading,
  shipments: state.shipments.shipments,
  shipment: state.shipments.shipment
});

export default connect(mapStateToProps)(withRouter(Order));
