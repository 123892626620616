import api from "../api";

const Actions = {};

Actions.fetchBusinessFields = () => {
  return (dispatch, getState) => {
    const params = getState().businessFields.fetchParams;
    dispatch({ type: "FETCH_BUSINESS_FIELDS_REQUEST" });
    api
      .fetch("/business_fields", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_BUSINESS_FIELDS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_BUSINESS_FIELDS_FAILURE", response });
      });
  };
};

Actions.fetchBusinessField = id => {
  return dispatch => {
    dispatch({ type: "FETCH_BUSINESS_FIELD_REQUEST" });
    return api
      .fetch(`/business_fields/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_BUSINESS_FIELD", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Сферы деятельности", error);
          });
        }
        dispatch({ type: "FETCH_BUSINESS_FIELD_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "BUSINESS_FIELD_ADD" });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_BUSINESS_FIELD_REQUEST" });
    const businessField = getState().businessFields.businessField;

    const data = {
      business_field: {
        name: businessField.name
      }
    };

    return api
      .patch(`/business_fields/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_BUSINESS_FIELD", response });
        api.openNotification("success", "Сфера деятельности", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Сфера деятельности", error);
          });
        }
        dispatch({ type: "PATCH_BUSINESS_FIELD_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_BUSINESS_FIELD_REQUEST" });
    const businessField = getState().businessFields.businessField;

    const data = {
      business_field: {
        name: businessField.name
      }
    };

    return api
      .post(`/business_fields`, data)
      .then(response => {
        dispatch({ type: "POST_BUSINESS_FIELD", response });
        api.openNotification("success", "Сфера деятельности", "Успешно создана!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Сфера деятельности", error);
          });
        }
        dispatch({ type: "POST_BUSINESS_FIELD_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_BUSINESS_FIELD_REQUEST" });
    return api
      .delete(`/business_fields/${id}`)
      .then(response => {
        api.openNotification("success", "Сфера деятельности", "Успешно удалена!");
        dispatch({ type: "DELETE_BUSINESS_FIELD", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Сфера деятельности", error);
          });
        }
        dispatch({
          type: "DELETE_BUSINESS_FIELD_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "BUSINESS_FIELD_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_BUSINESS_FIELD_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_BUSINESS_FIELD_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "BUSINESS_FIELD_CHANGE_NAME",
      value: value
    });
  };
};



export default Actions;
