// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/invoices";
import InvoicesList from "./InvoicesList";

class Invoices extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      this.props.dispatch(Actions.fetchInvoices());
    })
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchInvoices());
      });
    });
  };


  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchInvoices()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchInvoices()));
  };

  onChangeLimit = (current, size) => {
    // this.props
    //   .dispatch(Actions.onChangeLimit(size))
    //   .then(() => this.props.dispatch(Actions.fetchInvoices()));
  };

  // filters
  onChangeFilterAssignee = value => {
    this.props
      .dispatch(Actions.onChangeFilterAssignee(value))
      .then(() => this.props.dispatch(Actions.fetchInvoices()));
  };

  onChangeFilterPeriod = value => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(value))
      .then(() => this.props.dispatch(Actions.fetchInvoices()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchInvoices()));
  };

  render() {
    const { isLoading, invoices, meta, fetchParams } = this.props;
    return (
      <InvoicesList
        loading={isLoading}
        invoices={invoices}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onChangeFilterAssignee={this.onChangeFilterAssignee}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.invoices.isLoading,
  invoices: state.invoices.invoices,
  meta: state.invoices.meta,
  fetchParams: state.invoices.fetchParams
});

export default connect(mapStateToProps)(Invoices);
