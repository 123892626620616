import React, { Component } from "react";
import { Form, Input, Col, Row } from "antd";
const FormItem = Form.Item;

class BankAccountForm extends React.Component {
  render() {
    const {
      getFieldDecorator,
      bankAccount,
      onChangeName,
      onChangeBankName,
      onChangeAccount,
      onChangeCorrAccount,
      onChangeBik
    } = this.props;
    return (
      <Row gutter={16}>
        <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: bankAccount.name,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
          <FormItem label={"Наименование банка"}>
            {getFieldDecorator("bank_name", {
              onChange: onChangeBankName,
              initialValue: bankAccount.bank_name,
              rules: [
                {
                  required: true,
                  message: "Введите БИК!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col sm={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Счет"}>
            {getFieldDecorator("account", {
              onChange: onChangeAccount,
              initialValue: bankAccount.account,
              rules: [
                {
                  required: true,
                  message: "Введите счет!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col sm={{ span: 12 }} md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Коррсчет"}>
            {getFieldDecorator("corr_account", {
              onChange: onChangeCorrAccount,
              initialValue: bankAccount.corr_account,
              rules: [
                {
                  required: true,
                  message: "Введите коррсчет!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col sm={{ span: 8 }} md={{ span: 8 }} xs={{ span: 8 }}>
          <FormItem label={"БИК"}>
            {getFieldDecorator("bik", {
              onChange: onChangeBik,
              initialValue: bankAccount.bik,
              rules: [
                {
                  required: true,
                  message: "Введите БИК!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

export default BankAccountForm;
