// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/companies";
import CompaniesList from "./CompaniesList";

class Companies extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchCompanies());
    });
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchCompanies());
      });
    });
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchCompanies()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchCompanies()));
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchCompanies()).then(() => {
        localStorage.setItem(
          "companyFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };


  onChangeFilterCreatedPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterCreatedPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchCompanies()))
      .then(() => {
        localStorage.setItem(
          "companyFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterBusinessFields = values => {
    this.props.dispatch(Actions.onChangeFilterBusinessFields(values)).then(() => {
      this.props.dispatch(Actions.fetchCompanies()).then(() => {
        localStorage.setItem(
          "companyFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterStates = values => {
    this.props.dispatch(Actions.onChangeFilterStates(values)).then(() => {
      this.props.dispatch(Actions.fetchCompanies()).then(() => {
        localStorage.setItem(
          "companyFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterContactTypes = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterContactTypes(values))
      .then(() => this.props.dispatch(Actions.fetchCompanies()))
      .then(() => {
        localStorage.setItem(
          "companyFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadReport(
      params,
      "companies.xlsx",
      params.startDate
        ? `companies ${params.startCreatedDate}-${params.endCreatedDate}.xlsx`
        : "companies.xlsx"
    );
  };

  render() {
    return (
      <CompaniesList
        loading={this.props.isLoading}
        companies={this.props.companies}
        meta={this.props.meta}
        fetchParams={this.props.fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterCreatedPeriod={this.onChangeFilterCreatedPeriod}
        onChangeFilterBusinessFields={this.onChangeFilterBusinessFields}
        onChangeFilterStates={this.onChangeFilterStates}
        onChangeFilterContactTypes={this.onChangeFilterContactTypes}
        downloadReport={this.downloadReport}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.companies.isLoading,
  companies: state.companies.companies,
  meta: state.companies.meta,
  fetchParams: state.companies.fetchParams
});

export default connect(mapStateToProps)(Companies);
