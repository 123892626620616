// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Actions from "../../../../actions/reports";
import Order from "./order";

class Info extends Component {
  componentDidMount = () => {
    const { businessFieldId, startDate, endDate } = this.props;
    const params = {
      businessFieldId: businessFieldId,
      startDate: startDate,
      endDate: endDate
    };
    // console.log("componentDidMount", stateId);
    // if (businessFieldId) {
      this.props.dispatch(Actions.fetchOrdersByBusinessFields(params));
    // }
  };

  // componentDidUpdate(prevProps) {
  //   const { recordId, stateId, startDate, endDate } = this.props;
  //   const params = {state_id: stateId, startDate: startDate, endDate: endDate};
  //   console.log("componentDidUpdate", stateId)
  //   if (recordId !== prevProps.recordId) {
  //     if (this.props.match.params.id) {
  //       this.props.dispatch(Actions.fetchOrdersByRegions(params));
  //     }
  //   }
  // }

  render() {
    const { isLoading, orders } = this.props;
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Order isLoading={isLoading} orders={orders} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.reports.isLoadingInfo,
  orders: state.reports.ordersByBusinessFields
});

export default connect(mapStateToProps)(Info);
