// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Tag, Icon, Tooltip } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

class CompaniesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;

    const {
      loading,
      companies,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      onClearFilters,
      onChangeFilterCreatedPeriod,
      onChangeFilterBusinessFields,
      onChangeFilterStates,
      onChangeFilterContactTypes,
      downloadReport,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Наименование",
        dataIndex: "full_name",
        render: (full_name, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/companies/${record.id}/edit`}>{full_name}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>{record.inn_kpp}</span>
          </div>
        ),
        align: "center",
        width: "25%",
      },
      {
        title: "Заказы (кол-во / сумма)",
        dataIndex: "orders_total",
        render: (orders_total, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {record.orders_count
                ? accounting.formatNumber(record.orders_count, 0, " ")
                : null}
            </span>
            <span>
              {record.orders_total
                ? accounting.formatNumber(record.orders_total, 0, " ")
                : null}
            </span>
          </div>
        ),
        align: "right",
        width: "15%",
      },
      {
        title: "Сфера деятельности / Адрес",
        dataIndex: "address",
        render: (addresses, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ opacity: "0.8" }}>
              {record.business_field ? (
                <Tag color="geekblue">{record.business_field.text}</Tag>
              ) : null}
            </span>
            <span style={{ opacity: "0.8" }}>
              {record.addresses.length > 0 ? record.addresses[0].address : ""}
            </span>
          </div>
        ),
        align: "center",
        width: "35%",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        key: "created_at",
        width: "10%",
        align: "center",
      },
      {
        title: "Ответственный",
        dataIndex: "assignee",
        render: (assignee) => (assignee ? assignee.text : null),
        align: "center",
        width: "15%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/companies/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startCreatedDate ||
          fetchParams.businessFields ||
          fetchParams.contact_types ||
          fetchParams.states
            ? "primary"
            : "ghost"
        }
        key="filterButton"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Компании`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={companies}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          onClose={this.onFilterToggle}
          status={fetchParams.status}
          startCreatedDate={fetchParams.startCreatedDate}
          endCreatedDate={fetchParams.endCreatedDate}
          businessFields={fetchParams.businessFields}
          states={fetchParams.states}
          contactTypes={fetchParams.contact_types}
          onClearFilters={onClearFilters}
          onChangeCreatedPeriod={onChangeFilterCreatedPeriod}
          onChangeBusinessFields={onChangeFilterBusinessFields}
          onChangeStates={onChangeFilterStates}
          onChangeContactTypes={onChangeFilterContactTypes}
        />
      </PageHeader>
    );
  }
}

CompaniesList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default CompaniesList;
