import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  workOrders: [],
  workOrder: { work_order_items: [], items: [{ work_order_items: [] }] },
  purchasePaymentSchedules: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    status: null,
    search: null,
    currentYear: false,
    sort: null,
    direction: null,
    isDownloadRows: false,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isWorkOrderItemsLoading: false,
  isPurchasePaymentSchedulesLoading: false,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const workOrdersFilters = localStorage.getItem("workOrdersFilters");
  if (workOrdersFilters) {
    localFetchParams = JSON.parse(workOrdersFilters);
  } else {
    localStorage.setItem("workOrdersFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let idx = -1;
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_WORK_ORDERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_WORK_ORDERS":
      return {
        ...state,
        workOrders: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_WORK_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_WORK_ORDER":
      return {
        ...state,
        workOrder: action.response.data,
        vatRates: action.response.vat_rates,
        units: action.response.units,
        isLoading: false,
      };

    case "FETCH_PURCHASE_PAYMENT_SCHEDULES_REQUEST":
      return {
        ...state,
        isPurchasePaymentSchedulesLoading: true,
      };
    case "FETCH_PURCHASE_PAYMENT_SCHEDULES":
      return update(state, {
        purchasePaymentSchedules: { $set: action.response.data },

        isPurchasePaymentSchedulesLoading: { $set: false },
      });

    case "FETCH_PURCHASE_PAYMENT_SCHEDULES_FAILURE":
      return {
        ...state,
        errors: true,
        isPurchasePaymentSchedulesLoading: false,
      };

    case "PATCH_WORK_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_WORK_ORDER":
      return {
        ...state,
        errors: false,
        workOrder: action.response.data,
        isLoading: false,
      };

    case "PATCH_WORK_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_WORK_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_WORK_ORDER":
      return {
        ...state,
        errors: false,
        workOrders: action.response.data,
        isLoading: false,
      };
    case "DELETE_WORK_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };
    case "REQUEST_WORK_ORDER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_WORK_ORDER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "WORK_ORDERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "REQUEST_WORK_ORDER_FILTER_PERIOD":
      return update(state, {
        fetchParams: { currentYear: { $set: action.value } },
      });

    // General
    case "WORK_ORDER_CHANGE_DATE":
      return update(state, {
        workOrder: { date: { $set: action.value } },
      });

    case "WORK_ORDER_CHANGE_NUMBER":
      return update(state, {
        workOrder: { number: { $set: action.value } },
      });

    case "WORK_ORDER_CHANGE_STATUS":
      return update(state, {
        workOrder: { status: { $set: action.item } },
      });

    case "WORK_ORDER_CHANGE_ASSIGNEE":
      return update(state, {
        workOrder: { assignee: { $set: action.item } },
      });

    case "WORK_ORDER_CHANGE_STOCKED":
      return update(state, {
        workOrder: {
          order_items: { [action.idx]: { stocked: { $set: action.value } } },
        },
      });

    // Items
    case "WORK_ORDER_ADD_ITEM":
      return update(state, {
        workOrder: {
          work_order_items: {
            $push: [
              {
                id: Date.now(),
                position: 0,
                cost_type: 1,
                order_item_id: action.orderItemID,
                description: "",
                product: {
                  id: null,
                  text: "выберите материал",
                },
                company: {
                  id: null,
                  text: "",
                },
                purchase: {
                  id: null,
                  text: "",
                },
                quantity: 1,
                for_order: 0,
                ordered: 0,
                is_in_stock: true,
                is_exist: false,
                _destroy: false,
              },
            ],
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_PRODUCT":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              product: { $set: action.item },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_UNIT":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              unit: { $set: action.item },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_QUANTITY":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              quantity: { $set: action.value },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_FOR_ORDER":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              for_order: { $set: action.value },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_ORDERED":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              ordered: { $set: action.value },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_DESCRIPTION":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              description: { $set: action.value },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_SUBCONTRACT":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              cost_type: { $set: action.value },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_COMPANY":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              company: { $set: action.item },
            },
          },
        },
      });

    case "WORK_ORDER_CHANGE_ITEM_PURCHASE":
      idx = _.findIndex(state.workOrder.work_order_items, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        workOrder: {
          work_order_items: {
            [idx]: {
              purchase: { $set: action.value },
            },
          },
        },
      });

    // specification
    case "REQUEST_WORK_ORDER_SPECIFICATION":
      return {
        ...state,
        // isLoading: true
      };
    case "FETCH_WORK_ORDER_SPECIFICATION":
      const workOrderItems = [];
      const specification = action.response.data.product_specifications;
      specification.map((item) => {
        workOrderItems.push({
          id: `${item.id}-${Date.now()}`,
          status: null,
          position: 0,
          cost_type: 1,
          order_id: null,
          order_item_id: action.orderItemID,
          description: item.product_specification.text,
          product: {
            id: item.product_specification.id,
            text: item.product_specification.text,
          },
          unit: {
            id: null,
            text: "",
          },
          company: {
            id: null,
            text: "",
          },
          purchase: {
            id: null,
            text: "",
          },
          quantity: item.quantity,
          for_order: 0,
          ordered: 0,
          is_in_stock: true,
          is_exist: false,
          _destroy: false,
        });
      });

      return update(state, {
        workOrder: {
          work_order_items: {
            $push: workOrderItems,
          },
        },
        // isLoading: false
      });

    case "FETCH_WORK_ORDER_SPECIFICATION_FAILURE":
      return {
        ...state,
        // isLoading: false
      };

    // work_items
    case "FETCH_WORK_ORDER_ITEMS_REQUEST":
      return {
        ...state,
        isWorkOrderItemsLoading: true,
      };
    case "FETCH_WORK_ORDER_ITEMS":
      return update(state, {
        workOrder: { items: { $set: action.response.data } },
        isWorkOrderItemsLoading: { $set: false },
      });

    case "FETCH_WORK_ORDER_ITEMS_FAILURE":
      return {
        ...state,
        errors: true,
        isWorkOrderItemsLoading: false,
      };

    case "PATCH_WORK_ORDER_ITEM_REQUEST":
      return {
        ...state,
        isWorkOrderItemsLoading: true,
        errors: false,
      };
    case "PATCH_WORK_ORDER_ITEM":
      return {
        ...state,
        isWorkOrderItemsLoading: false,
        errors: false,
      };

    case "PATCH_WORK_ORDER_ITEM_FAILURE":
      return {
        ...state,
        isWorkOrderItemsLoading: false,
        errors: true,
      };

    case "POST_WORK_ORDER_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
        isWorkOrderItemsLoading: true,
      };
    case "POST_WORK_ORDER_ITEM":
      return {
        ...state,
        errors: false,
        isWorkOrderItemsLoading: false,
      };

    case "POST_WORK_ORDER_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
        isWorkOrderItemsLoading: false,
      };

    case "DELETE_WORK_ORDER_ITEMS_REQUEST":
      return {
        ...state,
        isWorkOrderItemsLoading: true,
        errors: false,
      };
    case "DELETE_WORK_ORDER_ITEMS_SUCCEED":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: false,
      };

    case "DELETE_WORK_ORDER_ITEMS_FAILURE":
      return {
        ...state,
        isWorkOrderItemsLoading: false,
        errors: true,
      };
    
    // filters
    case "WORK_ORDERS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "WORK_ORDERS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "WORK_ORDERS_FILTER_DOWNLOAD_ROWS":
      return update(state, {
        fetchParams: {
          isDownloadRows: { $set: action.value },
        },
      });

    default:
      return state;
  }
}
