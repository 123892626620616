// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, DatePicker } from "antd";
import { Tabs } from "antd";
import SelectFetch from "../../../components/SelectFetch";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

class General extends Component {
  render() {
    const {
      order,
      getFieldDecorator,
      onChangeAssignee,
      onChangeCompany,
      onChangeAgreement,
      onChangeAgreementDate,
      onChangeAdditional,
      onChangeShipAddress,
      onChangeShippingAddress,
      onChangeSpecialInstruction
    } = this.props;

    return (
      <div>
        <Row gutter={16}>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Компания"}>
              {getFieldDecorator("company", {
                initialValue: order.company ? order.company : null,
                rules: [
                  {
                    required: true,
                    message: "Введите заказчика!"
                  }
                ]
              })(
                <SelectFetch
                  showSubtitle
                  showSearch
                  placeholder="Компания"
                  onChange={onChangeCompany}
                  selected={order.company ? order.company : null}
                  url={"/companies/search?"}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Ответственный"}>
              {getFieldDecorator("assignee", {
                initialValue: order.assignee ? order.assignee : null,
                rules: [
                  {
                    required: true,
                    message: "Введите ответственного!"
                  }
                ]
              })(
                <SelectFetch
                 showSearch
                  placeholder="Ответственный"
                  onChange={onChangeAssignee}
                  selected={order.assignee ? order.assignee : null}
                  url={"/users/search?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={{ span: 10 }} xs={{ span: 10 }}>
            <FormItem label={"Договор"}>
              {getFieldDecorator("agreement", {
                onChange: onChangeAgreement,
                initialValue: order.agreement
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 7 }} xs={{ span: 7 }}>
            <FormItem label={"Дата договора"}>
              {getFieldDecorator("agreement_date", {
                onChange: onChangeAgreementDate,
                initialValue: order.agreement_date ? moment(order.agreement_date) : null
              })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
            </FormItem>
          </Col>
          <Col md={{ span: 7 }} xs={{ span: 7 }}>
            <FormItem label={"Приложение"}>
              {getFieldDecorator("additional", {
                onChange: onChangeAdditional,
                initialValue: order.additional
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={{ span: 24 }} xs={{ span: 24 }}>
            <FormItem label={"Адрес доставки"}>
              {getFieldDecorator("shipping_address", {
                initialValue: order.shipping_address ? order.shipping_address : null,
                rules: [
                  {
                    required: true,
                    message: "Введите адрес доставки!"
                  }
                ]
              })(
                <SelectFetch
                  disabled={order.company ? false : true}
                  showSubtitle
                  showSearch
                  placeholder="Адрес"
                  onChange={onChangeShippingAddress}
                  selected={order.shipping_address ? order.shipping_address : null}
                  url={order.company ? `/companies/${order.company.id}/addresses` : null}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Адрес доставки"}>
              {getFieldDecorator("ship_address", {
                onChange: onChangeShipAddress,
                initialValue: order.ship_address
              })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Дополнительно"}>
              {getFieldDecorator("special_instruction", {
                onChange: onChangeSpecialInstruction,
                initialValue: order.special_instruction
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  order: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeSpecialInstruction: PropTypes.func
};

export default General;
