// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, PageHeader, Badge, Button, Select, Icon, Tooltip } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";
import Filter from "./Filter";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

function showStatus(status) {
  switch (status) {
    case "approving":
      return "error";
    case "working":
      return "processing";
    case "closed":
      return "success";

    default:
      return "default";
  }
}

const Search = Input.Search;
const Option = Select.Option;

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChange = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onChangeFilterPeriod = (value) => {
    this.setState({ filterPeriodVisible: false }, () => {
      this.props.onChangeFilterPeriod(value);
    });
  };

  renderExpandedRow = (record) => {
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={12}>
          <table style={{ width: "100%" }}>
            <tbody>
              {record.order_items.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <td style={{ width: "65%" }}>{item.description}</td>
                    <td style={{ width: "15%" }}>{`${item.quantity} шт.`}</td>
                    <td style={{ width: "20%" }}>{`${accounting.formatNumber(
                      item.amount,
                      0,
                      " "
                    )} руб.`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      workOrders,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      onChangeFilterPeriod,
      onChangeFilterDownloadRows,
      downloadReport,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Номер, статус",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>
              <Badge
                status={showStatus(record.status)}
                text={record.status_locale}
              />
            </span>
            <span>
              <Link to={`/workOrders/${record.id}/edit`}>{number}</Link>
            </span>
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "number",
        align: "center",
        width: "12%",
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: (date) => moment(date).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "date",
      },
      {
        title: "График пр-ва",
        dataIndex: "start_stocked",
        render: (start_stocked, record) => {
          if (record.start_stocked == record.end_stocked) {
            return (
              <span>
                {start_stocked ? moment(start_stocked).format("L") : "без даты"}
              </span>
            );
          } else {
            return (
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                <span>{moment(record.end_stocked).format("L")}</span>
                <span>{moment(start_stocked).format("L")}</span>
              </div>
            );
          }
        },
        align: "center",
        width: "9%",
      },
      {
        title: "Заказчик",
        dataIndex: "company",
        render: (company) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span style={{ opacity: "0.7" }}>{company.inn}</span>
            <span>
              <Link to={`/companies/${company.id}/edit`}>{company.text}</Link>
            </span>
          </div>
        ),
        width: "25%",
      },
      {
        title: "Заказ, сумма",
        dataIndex: "order",
        render: (order, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>{accounting.formatNumber(order.total, 0, " ")}</span>
            <span>
              <Link to={`/orders/${order.id}/edit`}>{order.text}</Link>
            </span>
          </div>
        ),
        align: "right",
        width: "10%",
      },
      {
        title: "Субподряд, материалы",
        dataIndex: "total",
        render: (total, record) => (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <span>
              {accounting.formatNumber(record.total_material, 0, " ")}
            </span>
            <span>
              {accounting.formatNumber(record.total_subcontract, 0, " ")}
            </span>
          </div>
        ),
        align: "right",
        width: "10%",
      },
      {
        title: "Требуется, Заказать, Заказано",
        dataIndex: "quantity",
        render: (quantity, record) => (
          <Row>
            <Col span={8}>
              <Badge
                count={accounting.formatNumber(quantity, 0, " ")}
                style={{ backgroundColor: "#52c41a" }}
              />
            </Col>
            <Col span={8}>
              <Badge
                count={accounting.formatNumber(record.for_order, 0, " ")}
                style={{ backgroundColor: "#52c41a" }}
              />
            </Col>
            <Col span={8}>
              {Number(record.for_order) - Number(record.ordered) == 0 ? (
                <Badge
                  count={accounting.formatNumber(record.ordered, 0, " ")}
                  style={{ backgroundColor: "#52c41a" }}
                />
              ) : (
                <Badge
                  showZero
                  count={accounting.formatNumber(record.ordered, 0, " ")}
                />
              )}
            </Col>
          </Row>
        ),
        className: "ant-table-total-column",
        align: "center",
        width: "13%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignee",
        render: (assignee) => assignee.text,
        width: "12%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : null;

    let excelButton = (
      <Tooltip key="tooltipExcelButton" placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={fetchParams.startDate ? "primary" : "ghost"}
        key="filterButton"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Заказ-наряды`}
        extra={[
          <Search
            allowClear
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={workOrders}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          onClose={this.onFilterToggle}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          isDownloadRows={fetchParams.isDownloadRows}
          onChangePeriod={onChangeFilterPeriod}
          onChangeDownloadRows={onChangeFilterDownloadRows}
        />
      </PageHeader>
    );
  }
}

OrdersList.propTypes = {
  workOrders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default OrdersList;
