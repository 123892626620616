// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment().startOf("month").format("DD.MM.YYYY"),
        endDate: moment().format("DD.MM.YYYY"),
        page: 1,
        limit: 100,
      },
      period: 0,
    };
  }

  componentWillMount = () => {
    // console.log("Test")
    this.props.dispatch(Actions.fetchOrdersByStatuses(this.state.fetchParams));
  };

  onChangeDateRange = (dates) => {
    this.state.fetchParams.startDate = moment(dates[0]).format("L");
    this.state.fetchParams.endDate = moment(dates[1]).format("L");
    this.setState(
      { fetchParams: this.state.fetchParams, period: 0 },
      function () {
        this.props.dispatch(
          Actions.fetchOrdersByStatuses(this.state.fetchParams)
        );
      }
    );
  };

  onChangePeriod = (value) => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 1:
        startDate = moment().startOf("months").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 2:
        startDate = moment().startOf("quarter").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 3:
        startDate = moment().startOf("years").format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY");
        endDate = moment().add(-1, "years").endOf("year").format("DD.MM.YYYY");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY");
        endDate = moment().format("DD.MM.YYYY");
    }
    this.state.fetchParams.startDate = startDate;
    this.state.fetchParams.endDate = endDate;
    this.setState(
      { fetchParams: this.state.fetchParams, period: value },
      function () {
        this.props.dispatch(
          Actions.fetchOrdersByStatuses(this.state.fetchParams)
        );
      }
    );
  };

  onChangePage = (value) => {
    this.state.fetchParams.page = value;
    this.setState({ fetchParams: this.state.fetchParams }, function () {
      this.props.dispatch(Actions.fetchLastShipments(this.state.fetchParams));
    });
  };

  onChangeLimit = (current, size) => {
    this.state.fetchParams.limit = size;
    this.setState({ fetchParams: this.state.fetchParams }, function () {
      this.props.dispatch(Actions.fetchLastShipments(this.state.fetchParams));
    });
  };

  downloadReport = () => {
    // Actions.downloadReport(this.state.fetchParams, "lastshipments.xlsx", "lastshipments.xlsx");
  };

  onDetails = (user_id, status) => {
    const { fetchParams } = this.state;
    this.props.dispatch(
      Actions.fetchOrdersByStatusesDetails(
        user_id,
        status,
        fetchParams.startDate,
        fetchParams.endDate
      )
    );
  };

  render() {
    const {
      isLoading,
      report,
      meta,
      totals,
      isLoadindDetails,
      details,
    } = this.props;
    const { fetchParams, period } = this.state;
    return (
      <DataList
        loading={isLoading}
        report={report}
        meta={meta}
        totals={totals}
        fetchParams={fetchParams}
        period={period}
        onChangeDateRange={this.onChangeDateRange}
        onChangePeriod={this.onChangePeriod}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        downloadReport={this.downloadReport}
        // details
        isLoadindDetails={isLoadindDetails}
        details={details}
        onDetails={this.onDetails}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  report: state.reports.ordersByStatuses,
  meta: state.reports.meta,
  totals: state.reports.totals,
  // details
  isLoadindDetails: state.reports.isLoadindDetails,
  details: state.reports.details,
});

export default connect(mapStateToProps)(Report);
