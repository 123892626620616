// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Form, DatePicker, InputNumber } from "antd";
import moment from "moment";
import "moment/locale/ru";

const dateFormat = "L";
const FormItem = Form.Item;

class CashFlowValue extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      cashFlowValue,
      onChangeCashFlowValueDate,
      onChangeCashFlowValueAmount,
    } = this.props;
    return (
      <Modal
        title={"Новое значение"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Дата"}>
                {getFieldDecorator("date", {
                  onChange: onChangeCashFlowValueDate,
                  initialValue: moment(cashFlowValue.date, dateFormat),
                })(
                  <DatePicker
                    format={dateFormat}
                    className="ant-btn-width-100"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Сумма"}>
                {getFieldDecorator("amount", {
                  onChange: onChangeCashFlowValueAmount,
                  initialValue: cashFlowValue.amount,
                })(<InputNumber style={{ width: "100%" }} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CashFlowValue);
