import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  materialOrders: [],
  materialOrder: {
    number: null,
    date: null,
    total: 0,
    total_subcontract: 0,
    total_material: 0,
    material_order_items: []
  },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isVisible: false
};

export default function(state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_MATERIAL_ORDERS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_MATERIAL_ORDERS":
      return {
        ...state,
        materialOrders: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_MATERIAL_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_MATERIAL_ORDER":
      return {
        ...state,
        materialOrder: action.response.data,
        isLoading: false
      };
    case "PATCH_MATERIAL_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_MATERIAL_ORDER":
      return {
        ...state,
        materialOrder: action.response.data,
        isLoading: false
      };

    case "POST_MATERIAL_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_MATERIAL_ORDER":
      return {
        ...state,
        errors: false,
        materialOrder: {
          number: null,
          date: null,
          total: 0,
          total_subcontract: 0,
          total_material: 0,
          material_order_items: []
        },
        isLoading: false
      };

    case "POST_MATERIAL_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };
    case "DELETE_MATERIAL_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_MATERIAL_ORDER":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_MATERIAL_ORDER_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_MATERIAL_ORDER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_MATERIAL_ORDER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "MATERIAL_ORDER_CHANGE_DATE":
      return update(state, {
        materialOrder: {
          date: { $set: action.value }
        }
      });

    case "MATERIAL_ORDER_TOGGLE_VISIBLE":
      return {
        ...state,
        isVisible: !state.isVisible
      };

    case "MATERIAL_ORDER_CHANGE_DATE":
      return update(state, {
        materialOrder: {
          date: { $set: action.value }
        }
      });

    case "MATERIAL_ORDER_CHANGE_ITEM_QUANTITY":
      return update(state, {
        materialOrder: {
          material_order_items: {
            [action.idx]: { quantity: { $set: action.value } }
          }
        }
      });

    case "MATERIAL_ORDER_CHANGE_ITEM_COST":
      return update(state, {
        materialOrder: {
          material_order_items: {
            [action.idx]: { cost: { $set: action.value } }
          }
        }
      });

    case "MATERIAL_ORDER_ITEM_DELETE":
      return update(state, {
        materialOrder: {
          material_order_items: {
            [action.idx]: { _destroy: { $set: true } }
          }
        }
      });

    case "MATERIAL_ORDER_DESTROY_ITEM":
      return update(state, {
        materialOrder: {
          material_order_items: { $splice: [[action.idx, 1]] }
        }
      });

    default:
      return state;
  }
}
