// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/payments";
import PaymentsList from "./List";

class Payments extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchPayments());
    });
  };

  onDelete = id => {
    // this.props.dispatch(Actions.deleteUser(id));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchPayments()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchPayments()));
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchPayments()).then(() => {
        localStorage.setItem(
          "paymentsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterType = value => {
    this.props.dispatch(Actions.onChangeFilterType(value)).then(() => {
      this.props.dispatch(Actions.fetchPayments()).then(() => {
        localStorage.setItem(
          "paymentsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchPayments()))
      .then(() => {
        localStorage.setItem(
          "paymentsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCompanies = values => {
    this.props
      .dispatch(Actions.onChangeFilterCompanies(values))
      .then(() => this.props.dispatch(Actions.fetchPayments()))
      .then(() => {
        localStorage.setItem(
          "paymentsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  render() {
    return (
      <PaymentsList
        loading={this.props.isLoading}
        payments={this.props.payments}
        meta={this.props.meta}
        fetchParams={this.props.fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterType={this.onChangeFilterType}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterCompanies={this.onChangeFilterCompanies}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.payments.isLoading,
  payments: state.payments.payments,
  meta: state.payments.meta,
  fetchParams: state.payments.fetchParams
});

export default connect(mapStateToProps)(Payments);
