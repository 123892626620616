import React, { Component } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/ru";

class EditableDateCell extends React.Component {
  constructor(props) {
    super(props);
    this.onEscFunction = this.onEscFunction.bind(this);
  }

  static defaultProps = {
    defaultStyle: { paddingRight: 24 },
    allowClear: true
  };

  state = {
    value: this.props.value ? moment(this.props.value) : null,
    editing: false
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  componentWillUnmount() {
    document.addEventListener("keydown", this.onEscFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  onEscFunction(e) {
    const { editing } = this.state;
    if (editing && this.cell.contains(e.target) && e.keyCode === 27) {
      this.toggleEdit();
    }
  }

  onChange = value => {
    const { recordId, onChange } = this.props;
    this.toggleEdit();
    onChange(recordId, value);
  };


  render() {
    const { value, editing } = this.state;
    const { defaultStyle, style, allowClear } = this.props;
    return (
      <div className="editable-cell" ref={node => (this.cell = node)}>
        {editing ? (
          <DatePicker
            allowClear={allowClear}
            autoFocus
            value={value ? moment(value) : null}
            ref={node => (this.input = node)}
            onChange={this.onChange}
            format={"L"}
          />
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ ...defaultStyle, ...style }}
            onClick={this.toggleEdit}
          >
            {value ? moment(value).format("L") : null}
          </div>
        )}
      </div>
    );
  }
}

export default EditableDateCell;
