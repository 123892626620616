// @flow
import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Tabs,
  Form,
  Table,
  Input,
  DatePicker,
  Alert,
  Button,
  message,
} from "antd";
import PropTypes from "prop-types";
import SelectFetch from "../../../components/SelectFetch";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableDateCell from "../../../components/EditableCells/EditableDateCell";
import EditableSelectCell from "../../../components/EditableCells/EditableSelectCell";
import Uploads from "../../../components/Uploads";
import InputMask from "react-input-mask";

import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

const paymentTypes = [
  { value: 1, text: "аванс" },
  { value: 2, text: "кредит" },
];

class ModalPurchaseForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDeleteItems = () => {
    this.props.onDeleteItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onCreate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (this.props.purchase.purchase_payment_schedules.length == 0) {
        message.error("Неоходимо заполнить график платежей!");
      } else {
        this.props.onCreate();
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      isVisible,
      onCancel,
      purchase,
      onChangeCompany,
      onChangeAgreementDate,
      onChangeAgreement,
      onChangeCode,
      onChangeTotal,
      onChangeVatTotal,
      onAddItem,
      onChangeItemDate,
      onChangeItemPaymentType,
      onChangeItemAmount,
      onChangeItemPercent,
      // tempAttachments
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
    } = this.props;

    const purchasePaymentSchedules = purchase.purchase_payment_schedules.filter(
      function (item) {
        return !item._destroy;
      }
    );

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата платежа",
        dataIndex: "date",
        render: (date, record, index) => {
          return (
            <EditableDateCell
              recordId={record.id}
              value={moment(date)}
              onChange={onChangeItemDate}
            />
          );
        },
        align: "center",
        width: "30%",
      },
      {
        title: "Тип",
        dataIndex: "payment_type",
        render: (payment_type, record, index) => {
          return (
            <EditableSelectCell
              recordId={record.id}
              items={paymentTypes}
              value={payment_type}
              onChange={onChangeItemPaymentType}
            />
          );
        },
        align: "center",
        width: "20%",
      },
      {
        title: "% оплаты",
        dataIndex: "percent",
        render: (percent, record, index) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={percent}
              onChange={onChangeItemPercent}
            />
          );
        },
        align: "center",
        width: "20%",
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount, record, index) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={amount}
              onChange={onChangeItemAmount}
            />
          );
        },
        align: "center",
        width: "30%",
      },
    ];

    return (
      <Modal
        title="Договор с поставщиком"
        visible={isVisible}
        width={860}
        onOk={this.onCreate}
        onCancel={onCancel}
        destroyOnClose
      >
        <Form layout={"vertical"}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Основное" key="1">
              <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={12}>
                  <FormItem label={"Поставщик"}>
                    {getFieldDecorator("company", {
                      rules: [
                        {
                          required: true,
                          message: "Пожалуйста, выберите подрядчика!",
                        },
                      ],
                    })(
                      <SelectFetch
                        showSearch
                        placeholder="Поставщик"
                        onChange={onChangeCompany}
                        selected={purchase.company ? purchase.company : null}
                        url={"/companies/search?"}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={"Дата договора"}>
                    {getFieldDecorator("agreementDate", {
                      onChange: onChangeAgreementDate,
                      initialValue: moment(purchase.agreementDate),
                      rules: [
                        {
                          required: true,
                          message: "Пожалуйста, введите дату договора!",
                        },
                      ],
                    })(
                      <DatePicker format={"L"} className="ant-btn-width-100" />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={"Наименование"}>
                    {getFieldDecorator("agreement", {
                      onChange: onChangeAgreement,
                      initialValue: purchase.agreement,
                      rules: [
                        {
                          required: true,
                          message: "Пожалуйста, введите наименование договора!",
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={"Шифр"}>
                    {getFieldDecorator("code", {
                      onChange: onChangeCode,
                      initialValue: purchase.code,
                      rules: [
                        {
                          required: false,
                          message: "Пожалуйста, введите шифр договора!",
                        },
                      ],
                    })(<InputMask disabled={true} className="ant-input" mask="99/99/999" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={"Сумма договора (без НДС)"}>
                    {getFieldDecorator("total", {
                      onChange: onChangeTotal,
                      initialValue: purchase.total,
                      rules: [
                        {
                          required: true,
                          message: "Пожалуйста, введите сумму договора!",
                        },
                      ],
                    })(<Input type="number" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={"НДС"}>
                    {getFieldDecorator("vat_total", {
                      onChange: onChangeVatTotal,
                      initialValue: purchase.vat_total,
                      rules: [
                        {
                          required: true,
                          message: "Пожалуйста, введите сумму договора!",
                        },
                      ],
                    })(<Input type="number" />)}
                  </FormItem>
                </Col>
                <Col span={24}>
                  <Alert
                    message="Формат маски для ввода шифра"
                    description="XX/YY/ZZZ, где XX - код производителя (цифры), YY - год производства, ZZZ - порядковый номер спецификации или договора"
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="График платежей" key="2">
              <Row
                gutter={{ md: 8, lg: 24, xl: 48 }}
                style={{ marginBottom: "10px" }}
              >
                <Col span={24}>
                  {hasSelected ? (
                    <Button onClick={this.onDeleteItems}>Удалить</Button>
                  ) : (
                    <Button onClick={onAddItem}>Добавить</Button>
                  )}
                </Col>
              </Row>
              <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                  <Table
                    rowSelection={rowSelection}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={purchasePaymentSchedules}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Файлы" key="3">
            <Uploads
                onUpload={onTempUpload}
                documents={tempAttachments}
                onDelete={onDeleteTempAttachment}
              />
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
    );
  }
}

ModalPurchaseForm.propTypes = {
  workOrders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default Form.create()(ModalPurchaseForm);
