// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form, InputNumber, DatePicker, Button } from "antd";
import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

import SelectFetch from "../../../components/SelectFetch";
import ServicerOderItems from "./ServicerOderItems";

const FormItem = Form.Item;

class ModalForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      serviceOrder,
      visible,
      onCancel,
      onChangeDate,
      onChangeOrder,
      onChangeShipment,
      onChangeProduct,
      onChangeCost
    } = this.props;

    const serviceOrderItems = serviceOrder.service_order_items || [];

    return (
      <Modal
        title={`Списание услуг (работ) #${
          serviceOrder.id ? serviceOrder.id : "новое"
        } `}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={12}>
              <FormItem label={"Дата списания"}>
                {getFieldDecorator("date", {
                  onChange: onChangeDate,
                  initialValue: moment(serviceOrder.date),
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите дату списания!"
                    }
                  ]
                })(<DatePicker format={"L"} className="ant-btn-width-100" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={12}>
              <FormItem label={"Заказ"}>
                {getFieldDecorator("service", {
                  initialValue: serviceOrder.order ? serviceOrder.order : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, заказ!"
                    }
                  ]
                })(
                  <SelectFetch
                    showSearch
                    placeholder="Заказ"
                    onChange={onChangeOrder}
                    selected={serviceOrder.order ? serviceOrder.order : null}
                    url={"/orders/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Отгрузка"}>
                {getFieldDecorator("shipment", {
                  initialValue: serviceOrder.shipment
                    ? serviceOrder.shipment
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, отгрузку!"
                    }
                  ]
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Отгрузка"
                    onChange={onChangeShipment}
                    selected={
                      serviceOrder.shipment ? serviceOrder.shipment : null
                    }
                    url={`/shipments/search/?order_id=${
                      serviceOrder.order ? serviceOrder.order.id : null
                    }`}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Услуга"}>
                {getFieldDecorator("product", {
                  initialValue: serviceOrder.product
                    ? serviceOrder.product
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, услугу!"
                    }
                  ]
                })(
                  <SelectFetch
                    showSearch
                    placeholder="Услуга"
                    onChange={onChangeProduct}
                    selected={
                      serviceOrder.product ? serviceOrder.product : null
                    }
                    url={"/products/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Сумма"}>
                {getFieldDecorator("cost", {
                  onChange: onChangeCost,
                  initialValue: serviceOrder.cost,
                  rules: [
                    {
                      required: true,
                      message: "Введите сумму!"
                    }
                  ]
                })(<InputNumber min={0} style={{ width: "100%" }} decimalSeparator={','} />)}
              </FormItem>
            </Col>
          </Row>
          {serviceOrderItems.length > 0 ? (
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col span={24}>
                <ServicerOderItems
                  serviceOrderItems={serviceOrderItems}
                  shipment={serviceOrder.shipment}
                />
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  workOrders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(ModalForm);
