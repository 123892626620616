// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input, Button } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

import Filter from "./Filter";

const Search = Input.Search;

class PaymentsList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangePage = (value) => {
    this.props.onChangePage(value);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      payments,
      meta,
      fetchParams,
      onSearch,
      // filters
      onClearFilters,
      onChangeFilterType,
      onChangeFilterPeriod,
      onChangeFilterCompanies,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Номер, п/п",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{number}</span>
            <span style={{ opacity: "0.8" }}>{record.payment_number}</span>
          </div>
        ),
        key: "number",
        width: "10%",
        align: "center",
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: (date) => moment(date).format("L"),
        width: "10%",
        align: "center",
      },
      {
        title: "Заказ",
        dataIndex: "order",
        render: (order, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {order.id ? (
                <Link target={"_blank"} to={`/orders/${order.id}/edit`}>
                  {order.number}
                </Link>
              ) : null}
            </span>
          </div>
        ),
        key: "order",
        width: "10%",
        align: "center",
      },
      {
        title: "Cпецификация",
        dataIndex: "purchase",
        render: (purchase, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ opacity: "0.8" }}>
              {purchase.id ? (
                <Link target={"_blank"} to={`/purchases/${purchase.id}/edit`}>
                  {purchase.code}
                </Link>
              ) : null}
            </span>
          </div>
        ),
        key: "purchase",
        width: "10%",
        align: "center",
      },
      {
        title: "Компания",
        dataIndex: "company",
        render: (company, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{company.name}</span>
            <span style={{ opacity: "0.8" }}>
              {company.inn ? `инн: ${company.inn}` : ""}
            </span>
            <span style={{ opacity: "0.8" }}>
              {record.description}
            </span>
          </div>
        ),
        width: "35%",
      },
      {
        title: "Поступление",
        dataIndex: "income",
        render: (income, record) => accounting.formatNumber(income, 0, " "),
        align: "right",
        width: "15%",
      },
      {
        title: "Списание",
        dataIndex: "outcome",
        render: (outcome, record) => accounting.formatNumber(outcome, 0, " "),
        align: "right",
        width: "15%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      null
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate || fetchParams.type || fetchParams.companies
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
      onBack={() => window.history.back()}
        title={`Платежи`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          filterButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              size={"middle"}
              loading={loading}
              pagination={false}
              columns={columns}
              dataSource={payments}
              // expandedRowRender={record => this.renderExpandedRow(record)}
              rowKey="id"
            />
          </Col>
          <Col span={24} style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <Pagination
              pageSize={fetchParams.limit}
              current={meta.current_page}
              onChange={this.onChangePage}
              total={meta.total_count}
            />
          </Col>
        </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          type={fetchParams.type}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          companies={fetchParams.companies}
          onChangeType={onChangeFilterType}
          onChangePeriod={onChangeFilterPeriod}
          onChangeCompanies={onChangeFilterCompanies}
        />
      </PageHeader>
    );
  }
}

PaymentsList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default PaymentsList;
