// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class WorkOrderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      isAddingAssignee: false,
    };
  }

  onSubmit = (e) => {
    const { workOrderItem } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (workOrderItem.quantity == 0) {
        // console.log(values.productPrice)
        this.props.form.setFields({
          quantity: {
            value: values.quantity,
            errors: [new Error("Количество не может быть 0!")],
          },
        });
        return;
      }

      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      workOrderItem,
      onChangePosition,
      onChangeProduct,
      onChangeUnit,
      onChangeQuantity,
      onChangeForOrder,
      onChangeOrdered,
      onChangeDescription,
      onChangeCostType,
      onChangeCompany,
      onChangePurchase,
    } = this.props;

    return (
      <Modal
        title={
          workOrderItem.id ? workOrderItem.name : "Новый Материал / Продукция"
        }
        width={760}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Материал / Продукция"}>
                {getFieldDecorator("product", {
                  initialValue: workOrderItem.product
                    ? workOrderItem.product
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите значение!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Материал / Продукция"
                    onChange={onChangeProduct}
                    selected={
                      workOrderItem.product ? workOrderItem.product : null
                    }
                    url={`/products/search?isSuggest=true`}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Описание"}>
                {getFieldDecorator("description", {
                  onChange: onChangeDescription,
                  initialValue: workOrderItem.description,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Позиция"}>
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  value={workOrderItem.position}
                  onChange={onChangePosition}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Ед.изм."}>
                {getFieldDecorator("unit", {
                  initialValue: workOrderItem.unit ? workOrderItem.unit : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите значение!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Ед.изм."
                    onChange={onChangeUnit}
                    selected={workOrderItem.unit ? workOrderItem.unit : null}
                    url={`/units/search?`}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("periodicity", {
                  initialValue: workOrderItem.cost_type,
                  onChange: onChangeCostType,
                  rules: [
                    {
                      required: true,
                      message: "Введите значение!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={"contract"}>субподряд</Option>
                    <Option value={"material"}>материал</Option>
                    <Option value={"transport"}>транспорт</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem label={"Требуется"}>
                {getFieldDecorator("quantity", {
                  onChange: onChangeQuantity,
                  initialValue: workOrderItem.quantity,
                  rules: [
                    {
                      required: true,
                      message: "Введите значение!",
                    },
                  ],
                })(
                  <InputNumber min={0} max={10000} style={{ width: "100%" }} />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Заказать"}>
                {getFieldDecorator("for_order", {
                  onChange: onChangeForOrder,
                  initialValue: workOrderItem.for_order,
                })(
                  <InputNumber min={0} max={10000} style={{ width: "100%" }} />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Заказано"}>
                {getFieldDecorator("ordered", {
                  onChange: onChangeOrdered,
                  initialValue: workOrderItem.ordered,
                })(
                  <InputNumber min={0} max={10000} style={{ width: "100%" }} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: 15 }}>
            <Col span={12}>
              <FormItem label={"Поставщик"}>
                {getFieldDecorator("company", {
                  initialValue: workOrderItem.company
                    ? workOrderItem.company
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите значение!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Поставщик"
                    onChange={onChangeCompany}
                    selected={
                      workOrderItem.company ? workOrderItem.company : null
                    }
                    url={`/companies/search?`}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Договор"}>
                {getFieldDecorator("purchase", {
                  initialValue: workOrderItem.purchase
                    ? workOrderItem.purchase
                    : null,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Введите значение!",
                  //   },
                  // ],
                })(
                  <SelectFetch
                    disabled={workOrderItem.company ? false : true}
                    showSearch
                    showSubtitle
                    placeholder="Договор"
                    onChange={onChangePurchase}
                    selected={
                      workOrderItem.purchase ? workOrderItem.purchase : null
                    }
                    url={
                      workOrderItem.company
                        ? `/purchases/search?company_id=${workOrderItem.company.id}`
                        : `/purchases/search?`
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(WorkOrderItem);
