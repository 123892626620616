// @flow
import React, { Component } from "react";
import { Row, Col, Popover, Table, Tooltip, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";
import api from "../../../api";

export default class EntityOpeningHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      fetching: true,
    };
  }

  fetchCashflowDetails = async (cashflowType, isAuto, cashflowItemId, periodicity, date, endDate) => {
    const response = await api.fetch(
      `/reports/cashflow_details`,
      ({
        cashflowType: cashflowType,
        isAuto: isAuto,
        cashflowItemId: cashflowItemId,
        periodicity: periodicity,
        date: date
      })
    );
    const data = response.data;
    this.setState({ data: data, fetching: false });
  };

  render() {
    const { fetching, data } = this.state;
    const { cashflowType, isAuto, cashflowItemId, periodicity, date, amount } = this.props;

    const content = (
      <div style={{ width: 600 }}>
        <CashflowDetails loading={fetching} data={data} />
      </div>
    );

    return (
      <Popover
        placement="right"
        content={content}
        title="Расшифровка"
        trigger="click"
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={this.fetchCashflowDetails.bind(
            this,
            cashflowType,
            isAuto,
            cashflowItemId,
            periodicity,
            date
          )}
        >
          {amount}
        </span>
      </Popover>
    );
  }
}

function CashflowDetails({ loading = true, data = [] }) {
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      render: (number, record) => (
        record.ref ? (
          <Link to={`/${record.ref}/${record.ref_id}/edit`} target="_blank">
          {`${record.number}`}
        </Link>
        ) : (
          `${record.name}`
        )
        
      ),
      key: "number",
      align: "center",
      width: "25%",
    },
    {
      title: "Компания",
      dataIndex: "company_name",
      key: "company_name",
      align: "center",
      width: "35%",
    },
    {
      title: "Дата платежа",
      dataIndex: "date",
      render: (date) => (date ? moment(date).format("DD.MM.YYYY") : null),
      key: "date",
      align: "center",
      width: "20%",
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      render: (amount) => {
        return accounting.formatNumber(amount, 0, " ");
      },
      key: "amount",
      align: "center",
      width: "20%",
    },
  ];

  return (
    <Row style={{ width: 600 }}>
      <Col span={24}>
        <Table
          rowClassName={"progress-row"}
          size={"small"}
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          scroll={{ y: 340 }}
        />
      </Col>
    </Row>
  );
}
