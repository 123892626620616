// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Form, Button, Icon } from "antd";
import { Row, Col, PageHeader } from "antd";
import SelectFetch from "../../../components/SelectFetch";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      filterAssigneeVisible: false
    };
  }

  onChangeFilterAssignee = item => {
    this.setState({ filterAssigneeVisible: false }, () => {
      this.props.onChangeFilterAssignee(item.id);
    });
  };

  onResetFilterAssignee = () => {
    this.setState({ filterAssigneeVisible: false }, () => {
      this.props.onChangeFilterAssignee(null);
    });
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      onChangeDate,
      downloadReport
    } = this.props;

    let reportData = report;

    let total = report.reduce(
      (sum, record) => sum + Number(record.shipped) - Number(record.paid),
      0
    );

    if (reportData.length > 0) {
      reportData.push({
        order_id: Date.now(),
        order_number: null,
        order_date: null,
        schedules_dates: null,
        shipments_dates: null,
        company_name: null,
        assignee: null,
        shipped: total,
        shipped_vat: 0,
        paid: 0,
        paid_vat: 0,
        isGoup: true
      });
    }

    const columns = [
      {
        title: "График платежей",
        dataIndex: "schedules_dates",
        render: (schedules_dates, record) => {
          if (record.isGoup) {
            return {
              children: <b>Итого</b>,
              props: {
                colSpan: 3,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: schedules_dates ? schedules_dates.map((date) => { return moment(date).format('L') }).join(', ') : null
          };
        },
        align: "center",
        width: "15%",
        sorter: (a, b) => {
          return new Date(a.schedules_date) - new Date(b.schedules_date);
        }
      },
      {
        title: "Отгрузки",
        dataIndex: "shipments_dates",
        render: (shipments_dates, record) => {
          if (record.isGoup) {
            return {
              props: {
                colSpan: 0,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: shipments_dates ? shipments_dates.map((date) => { return moment(date).format('L') }).join(', ') : null
          };
        },
        align: "center",
        width: "15%",
        sorter: (a, b) => {
          return new Date(a.shipments_date) - new Date(b.shipments_date);
        }
      },
      {
        title: "Заказ",
        dataIndex: "order_number",
        render: (order_number, record) => {
          if (record.isGoup) {
            return {
              props: {
                colSpan: 0,
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: (
              <Link to={`/orders/${record.order_id}/edit`}>{order_number}</Link>
            )
          };
        },
        align: "center",
        width: "15%"
      },

      {
        title: "Сумма задолженности",
        dataIndex: "shipped",
        render: (shipped, record) => {
          if (record.isGoup) {
            return {
              children: (
                <b>
                  {accounting.formatNumber(
                    Number(shipped) - Number(record.paid),
                    2,
                    " "
                  )}
                </b>
              ),
              props: {
                className: "ant-table-total-column"
              }
            };
          }
          return {
            children: accounting.formatNumber(
              Number(shipped) - Number(record.paid),
              2,
              " "
            )
          };
        },
        align: "right",
        width: "15%"
      },

      {
        title: "Компания",
        dataIndex: "company_name",
        render: (company_name, record) => {
          return {
            children: company_name
          };
        },

        key: "company_name",
        width: "25%"
      },
      {
        title: "Ответственный",
        render: (assignee, record) => {
          return {
            children: record.assignee
          };
        },
        filterIcon: filtered => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.assignee ? "#1890ff" : undefined
            }}
          />
        ),
        filterDropdown: (
          <div className="custom-filter-dropdown">
            <Row gutter={10}>
              <Col span={24}>
                <SelectFetch
                  showSearch
                  placeholder="Ответственный"
                  onChange={this.onChangeFilterAssignee}
                  // selected={fetchParams.assignee ? fetchParams.assignee : null}
                  url={"/users/search?"}
                />
              </Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "10px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={this.onResetFilterAssignee}>
                  Сбросить
                </Button>
              </Col>
            </Row>
          </div>
        ),
        filterDropdownVisible: this.state.filterAssigneeVisible,
        onFilterDropdownVisibleChange: visible =>
          this.setState({ filterAssigneeVisible: visible }),
        key: "assignee",
        width: "15%"
      }
    ];

    const { getFieldDecorator } = this.props.form;
    const dateFormat = "DD.MM.YYYY";

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Дебиторская задолженность на  ${fetchParams.endDate}`}
        extra={[
          <Button
            key="3"
            type="dashed"
            icon="file-excel"
            onClick={downloadReport}
          >
            Загрузить
          </Button>
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24} style={{ paddingBottom: "15px" }}>
              <Table
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={reportData}
                rowKey="order_id"
                rowClassName={record =>
                  record.isGoup ? "ant-table-total-row" : null
                }
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"На дату"}>
              {getFieldDecorator("endDate", {
                onChange: onChangeDate,
                initialValue: moment(fetchParams.endDate, dateFormat)
              })(
                <DatePicker format={dateFormat} className="ant-btn-width-100" />
              )}
            </FormItem>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default Form.create()(DataList);
