// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";

class DepartmentForm extends Component {

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      onClose,
      isNew,
      isLoading,
      department,
      onChangeName,
      onChangeFullName,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Подразделение #${
            isNew ? ' новая' : department.name
            }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col span={14}>
            <Row>
              <Header
                department={department}
                getFieldDecorator={getFieldDecorator}
                onChangeName={onChangeName}
                onChangeFullName={onChangeFullName}
              />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

DepartmentForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  department: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func,
};

export default Form.create()(DepartmentForm);
